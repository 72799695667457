<template>
  <div class="_navi-list">
    <!-- menu -->

    <div class="_navi-item">
      <router-link
        class="nav-toppage _navi-link -ic"
        :to="{
          name: 'top',
          params: { mc_code: mcCode },
        }"
      >
        トップページ
      </router-link>
    </div>
    <!-- / _navi-item -->
    <div class="_navi-item">
      <router-link
        class="nav-mypage _navi-link -ic"
        :to="{
          name: 'mypage',
          params: { mc_code: mcCode },
        }"
      >
        ユーザー情報
      </router-link>
    </div>
    <!-- / _navi-item -->
    <div class="_navi-item">
      <router-link
        class="_navi-link -ic nav-newinfo"
        :to="{
          name: 'information-1',
          params: { mc_code: mcCode },
        }"
        >お知らせ
      </router-link>
    </div>
    <!-- / _navi-item -->

    <!-- category start -->

    <div id="menucolumn00">
      <!-- menucolumn start -->

      <div class="_navi-item">
        <router-link
          class="top _navi-link -ic"
          :to="{
            name: 'top',
            params: { mc_code: mcCode },
          }"
        >
          トップページ</router-link
        >
      </div>
      <!-- menucolumn start -->

      <div class="_navi-item">
        <router-link
          class="_navi-link -ic nav-information"
          :to="{
            name: 'in-information',
            params: { mc_code: mcCode },
          }"
          >お知らせ
        </router-link>
      </div>

      <!-- menucolumn start -->
      <div class="_navi-item">
        <a
          href="https://www.mansion-concierge.com/i-salon/survey_list.php"
          class="_navi-link -ic"
          ontouchstart=""
          >アンケート</a
        >
      </div>
      <!-- menucolumn end -->
    </div>
    <!-- category start -->

    <div id="menucolumn01">
      <!-- menucolumn start -->

      <div class="_navi-item">
        <a
          href="https://www.mansion-concierge.com/i-salon/album.php"
          :to="{
            params: { mc_code: mcCode },
          }"
          class="menu01 _navi-link -ic"
          ontouchstart=""
          >施工レポート</a
        >
      </div>
      <!-- menucolumn start -->

      <div class="_navi-item">
        <a
          href="https://www.mansion-concierge.com/i-salon/episode.php"
          class="menu02 _navi-link -ic"
          ontouchstart=""
          >クレヴィアができるまで</a
        >
      </div>
      <!-- menucolumn end -->
    </div>
    <!-- category start -->

    <div id="menucolumn02">
      <!-- menucolumn start -->

      <div class="_navi-item">
        <a
          href="https://www.mansion-concierge.com/i-salon/option.php"
          class="menu01 _navi-link -ic"
          ontouchstart=""
          >セレクト／各種オプションのご案内</a
        >
      </div>
      <!-- menucolumn start -->
      <!--//非表示//-->
      <div class="_navi-item">
        <a
          href="https://www.mansion-concierge.com/i-salon/premium.php"
          class="menu02 _navi-link -ic"
          ontouchstart=""
          >プレミアムサポート<span class="_navi-link-sub" style="font-size:87%"
            >（有償）</span
          ></a
        >
      </div>
      <!--////-->
      <!-- menucolumn start -->

      <div class="_navi-item">
        <router-link
          class="menu03 _navi-link -ic"
          :to="{ name: 'friend', params: { mc_code: mcCode } }"
        >
          クレヴィアお友達紹介</router-link
        >
      </div>
      <!-- menucolumn start -->

      <div class="_navi-item">
        <a
          href="https://www.mansion-concierge.com/i-salon/service_pre.php"
          class="menu04 _navi-link -ic"
          ontouchstart=""
          >各種優待サービス案内</a
        >
      </div>
      <!-- menucolumn end -->
    </div>
    <!-- category start -->

    <div id="menucolumn03">
      <!-- menucolumn start -->

      <div class="_navi-item">
        <a
          href="https://www.mansion-concierge.com/i-salon/schedule.php"
          class="menu01 _navi-link -ic"
          ontouchstart=""
          >ご入居までのスケジュール</a
        >
      </div>
      <!-- menucolumn start -->

      <div class="_navi-item">
        <a
          href="https://www.mansion-concierge.com/i-salon/procedure.php"
          class="menu02 _navi-link -ic"
          ontouchstart=""
          >引越し前後のお手続き</a
        >
      </div>
      <!-- menucolumn start -->

      <div class="_navi-item">
        <router-link
          class="menu03 _navi-link -ic"
          :to="{
            name: 'archive-list',
            params: { mc_code: mcCode, category: 1 },
          }"
        >
          図面／ご入居関連資料</router-link
        >
      </div>
      <!-- menucolumn end -->
    </div>
    <!-- category start -->

    <div id="menucolumn04">
      <!-- menucolumn start -->

      <div class="_navi-item">
        <a
          href="https://www.mansion-concierge.com/i-salon/areainfo.php"
          class="menu01 _navi-link -ic"
          ontouchstart=""
          >地域情報</a
        >
      </div>
      <!-- menucolumn start -->

      <div class="_navi-item">
        <a
          href="https://www.mansion-concierge.com/i-salon/qa.php"
          class="menu02 _navi-link -ic"
          ontouchstart=""
          >Q&A</a
        >
      </div>
      <!-- menucolumn start -->

      <div class="_navi-item">
        <a
          href="https://www.mansion-concierge.com/i-salon/kurashicolumn.php"
          class="menu03 _navi-link -ic"
          ontouchstart=""
          >暮らしのコラム</a
        >
      </div>
      <!-- menucolumn end -->
    </div>
    <!-- category end -->

    <div class="_navi-item">
      <router-link
        class="_navi-link -ic nav-contact"
        :to="{
          name: 'in-contact',
          params: { mc_code: mcCode },
        }"
        >お問い合わせ</router-link
      >
    </div>
    <!-- / _navi-item -->

    <div class="_navi-item">
      <a :href="logoutUrl" class="_navi-link -ic nav-logout" ontouchstart="">
        ログアウト
      </a>
    </div>
    <!-- / _navi-item -->

    <!-- /menu -->
  </div>
</template>

<script>
export default {};
</script>

<style></style>
