<template>
  <div id="icon-menu">
    <section class="main-section">
      <ul class="top-btns">
        <li class="top-btns-item top-btns-item-01">
          <router-link
            :to="{
              name: 'information-1',
              params: { mc_code: mcCode },
            }"
            ><span class="top-btns-item-img"></span
            ><span class="top-btns-item-text">お知らせ</span></router-link
          >
        </li>

        <li
          class="top-btns-item top-btns-item-04"
          v-if="isPermission('OwnedNonResident')"
        >
          <router-link
            :to="{
              name: 'meeting',
              params: { mc_code: mcCode },
            }"
            ><span class="top-btns-item-img"></span
            ><span class="top-btns-item-text">総会</span></router-link
          >
        </li>
        <li
          class="top-btns-item top-btns-item-05"
          v-if="isPermission('Director')"
        >
          <router-link
            :to="{
              name: 'bod',
              params: { mc_code: mcCode },
            }"
            ><span class="top-btns-item-img"></span
            ><span class="top-btns-item-text">理事会</span></router-link
          >
        </li>
        <li class="top-btns-item top-btns-item-02">
          <router-link
            :to="{
              name: 'archive',
              params: { mc_code: mcCode },
            }"
            ><span class="top-btns-item-img"></span
            ><span class="top-btns-item-text">マンション書庫</span></router-link
          >
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
