<template>
  <div class="_main-container friend" id="jumpTo">
    <div class="mainContentBox">
      <div class="mainContentBoxInner" v-if="status == 'edit'">
        <div class="pageTitleWrapper">
          <h2 class="pageTitle">定期アフターサービス申込み</h2>
        </div>
        <div class="pageCaption">
          <span class="pageCaption-caution"
            >※ご希望の日程に沿えない場合がございます。予めご了承ください。<br />※当日は確認のみになります。補修する場合は別途日程調整させて頂きます。</span
          >
        </div>
        <div class="pp-box">
          <span class="pp-box-heading"
            >アフターサービスに関する個人情報の取扱いについて</span
          >
          <span class="pp-box-r">伊藤忠都市開発株式会社</span>
          <p>
            当社はアフターサービス業務の遂行にあたり、個人情報保護に関する法令等を遵守するとともに、アフターサービスのお申込等でお客様からご提供いただいた個人情報の取扱いに関して下記の通り定め、
            これを遵守いたします。
          </p>

          <div class="pp-box-p">
            <span class="pp-box-h">1. 個人情報の収集及び利用目的</span>
            <ul>
              <li>
                <span class="pp-num">(1)</span
                ><span class="pp-txt"
                  >当社ではアフターサービスをご提供するために、定期点検時及び補修等のお申込時に、お客様のご氏名、マンション名、部屋番号、ご連絡先電話番号等をお伺いいたします。</span
                >
              </li>
              <li>
                <span class="pp-num">(2)</span
                ><span class="pp-txt"
                  >お客様からご提供いただいた個人情報は、当社がアフターサービスを円滑・確実にご提供し、その実施状況を管理するために利用いたします。</span
                >
              </li>
            </ul>
          </div>

          <div class="pp-box-p">
            <span class="pp-box-h">2. 情報の提供</span>
            <span class="pp-box-txt"
              >お客様からご提供いただいた個人情報は、以下の場合を除き、第三者への開示または提供はいたしません。</span
            >
            <ul>
              <li>
                <span class="pp-num">(1)</span
                ><span class="pp-txt"
                  >お客様がご購入された物件の売主及び販売会社に提供する場合</span
                >
              </li>
              <li>
                <span class="pp-num">(2)</span
                ><span class="pp-txt"
                  >上記の利用目的の達成に必要な限りにおいて、当社の業務委託先または業務提携先(施工会社、管理会社、広告代理店、システム会社等)に開示する場合</span
                >
              </li>
              <li>
                <span class="pp-num">(3)</span
                ><span class="pp-txt"
                  >法令等の規定に基づき、裁判所等の官公庁より要求された場合</span
                >
              </li>
              <li>
                <span class="pp-num">(4)</span
                ><span class="pp-txt"
                  >お客様や当社の権利、財産、安全などの保護のために必要がある場合</span
                >
              </li>
              <li>
                <span class="pp-num">(5)</span
                ><span class="pp-txt">お客様に同意いただいた場合</span>
              </li>
            </ul>
          </div>

          <div class="pp-box-p">
            <span class="pp-box-h">3. 個人情報の管理</span>
            <span class="pp-box-txt"
              >当社は、お客様の個人情報を安全に管理するために、最大の注意と必要な措置を講じます。</span
            >
            <ul>
              <li>
                <span class="pp-num">(1)</span
                ><span class="pp-txt"
                  >個人情報への外部からの不正なアクセス、個人情報の紛失・破壊・改ざん・漏えい等の防止に対する合理的かっ適切な安全対策を行っております。</span
                >
              </li>
              <li>
                <span class="pp-num">(2)</span
                ><span class="pp-txt"
                  >当社内に個人情報管理責任者を置き、個人情報の適切な管理に努めております。</span
                >
              </li>
              <li>
                <span class="pp-num">(3)</span
                ><span class="pp-txt"
                  >個人情報保護に関する規則等を定め、当社社員に周知徹底を行っております。</span
                >
              </li>
              <li>
                <span class="pp-num">(4)</span
                ><span class="pp-txt"
                  >個人情報の取扱いを外部に委託する場合には、個人情報を適正に取り扱っていると認められる者を選定のうえ、個人情報保護のための必要な契約を締結し、その他法令上必要な措置を講じます。</span
                >
              </li>
            </ul>
          </div>

          <div class="pp-box-p">
            <span class="pp-box-h">4. お問合せについて</span>
            <span class="pp-box-txt"
              >アフターサービス及ぴ個人情報の取扱い等についてのお問合せは、下記にて承ります。</span
            >
            <span class="pp-box-txt-indent"
              >伊藤忠都市開発株式会社カスタマーサービス課</span
            >
            <span class="pp-box-txt-indent"
              >〈電話番号〉 0120-717-600 (24時問受付)</span
            >
            <span class="pp-box-txt-indent"
              >〈住所〉東京都港区赤坂2-9-1 オリックス赤坂2丁目ビル7階</span
            >
          </div>

          <span class="pp-box-r">以上</span>
        </div>
        <section class="mainContent-form">
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for=""
                >アフターサービスに関する個人情報の取扱いについて</label
              >
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div>
                    <input
                      type="checkbox"
                      id="agree_yes"
                      name=""
                      v-model.trim="$v.values.agree.$model"
                    />
                    <label for="agree_yes">同意します</label>
                  </div>
                  <div class="error" v-if="!$v.values.agree.sameAs">
                    同意が必要です
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">マンション名</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id="">{{ mansionName }}</label>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">部屋番号</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id="">{{ currentUser.roomNo }}</label>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="L_NAME">お名前</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dt class="formTableBox_ttl"><label for="L_NAME">姓</label></dt>
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="40"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder="例:伊藤"
                    name="lastName"
                    v-model.trim="$v.values.sei.$model"
                  />
                  <div class="error" v-if="!$v.values.sei.required">
                    必須です
                  </div>
                </dd>
                <dt class="formTableBox_ttl"><label for="F_NAME">名</label></dt>
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="F_NAME"
                    maxlength="40"
                    class="formTableBox_input--name u-ime-ja"
                    placeholder="例:忠太郎"
                    name="firstName"
                    v-model.trim="$v.values.mei.$model"
                  />
                  <div class="error" v-if="!$v.values.mei.required">
                    必須です
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="L_NAME">ふりがな</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dt class="formTableBox_ttl">
                  <label for="L_NAME">せい</label>
                </dt>
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="40"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder="例:いとう"
                    name="lastName"
                    v-model.trim="$v.values.kanaSei.$model"
                  />
                  <div class="error" v-if="!$v.values.kanaSei.required">
                    必須です
                  </div>
                </dd>
                <dt class="formTableBox_ttl">
                  <label for="F_NAME">めい</label>
                </dt>
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="F_NAME"
                    maxlength="40"
                    class="formTableBox_input--name u-ime-ja"
                    placeholder="例:ちゅうたろう"
                    name="firstName"
                    v-model.trim="$v.values.kanaMei.$model"
                  />
                  <div class="error" v-if="!$v.values.kanaMei.required">
                    必須です
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">メールアドレス</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id="">{{ values.email }}</label>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="">電話番号１</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id=""
                    ><input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-en"
                      maxlength="128"
                      name=""
                      v-model.trim="$v.values.phone_1.$model"
                  /></label>
                  <div class="error" v-if="!$v.values.phone_1.required">
                    必須です
                  </div>
                  <div class="error" v-if="!$v.values.phone_1.decimal">
                    半角番号を入力してください
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">電話番号２</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id=""
                    ><input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-en"
                      maxlength="128"
                      name=""
                      v-model.trim="$v.values.phone_2.$model"
                  /></label>
                  <div class="error" v-if="!$v.values.phone_2.decimal">
                    番号を入力してください
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="">専有部補修希望</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox forRadioBox">
                  <div>
                    <input
                      type="radio"
                      id="senyuu_hoshu_yes"
                      name=""
                      value="希望します"
                      v-model.trim="$v.values.senyuu_hoshu.$model"
                    />
                    <label for="senyuu_hoshu_yes">希望します</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="senyuu_hoshu_no"
                      name=""
                      value="希望しません"
                      v-model.trim="$v.values.senyuu_hoshu.$model"
                    />
                    <label for="senyuu_hoshu_no">希望しません</label>
                  </div>
                  <div class="error" v-if="!$v.values.senyuu_hoshu.required">
                    必須です
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">希望訪問日程（担当者による目視確認等）</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div class="formTableBox_itemBox_heading">第一希望</div>
                  <select
                    class="select-box"
                    v-model.trim="$v.values.date1_1.$model"
                  >
                    <option value="" selected="">選択してください</option>
                    <option
                      v-for="(item, index) in date1_1_options"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>

                  <div class="formTableBox_itemBox_heading">第二希望</div>
                  <select
                    class="select-box"
                    v-model.trim="$v.values.date1_2.$model"
                  >
                    <option value="" selected="">選択してください</option>
                    <option
                      v-for="(item, index) in date1_2_options"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>

                  <div class="formTableBox_itemBox_heading">第三希望</div>
                  <select
                    class="select-box"
                    v-model.trim="$v.values.date1_3.$model"
                  >
                    <option value="" selected="">選択してください</option>
                    <option
                      v-for="(item, index) in date1_3_options"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>

                  <div class="formTableBox_itemBox_heading">
                    その他ご希望日・お時間
                  </div>
                  <label id=""
                    ><input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-en"
                      maxlength="128"
                      name=""
                      v-model.trim="$v.values.date1_others.$model"
                  /></label>
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">不具合内容1</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div class="formTableBox_itemBox_heading">場所</div>
                  <select
                    class="select-box"
                    v-model.trim="$v.values.case1_basho.$model"
                  >
                    <option value="" selected="">選択してください</option>
                    <option
                      v-for="(item, index) in case1_basho_options"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>

                  <div class="formTableBox_itemBox_heading">部位</div>
                  <input
                    type="text"
                    id=""
                    class="u-input--w100p u-ime-en"
                    maxlength="128"
                    name=""
                    v-model.trim="$v.values.case1_area.$model"
                  />
                  <div class="formTableBox_itemBox_heading">状況</div>
                  <input
                    type="text"
                    id=""
                    class="u-input--w100p u-ime-en"
                    maxlength="128"
                    name=""
                    v-model.trim="$v.values.case1_condition.$model"
                  />
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">不具合内容2</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div class="formTableBox_itemBox_heading">場所</div>
                  <select
                    class="select-box"
                    v-model.trim="$v.values.case2_basho.$model"
                  >
                    <option value="" selected="">選択してください</option>
                    <option
                      v-for="(item, index) in case2_basho_options"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>

                  <div class="formTableBox_itemBox_heading">部位</div>
                  <input
                    type="text"
                    id=""
                    class="u-input--w100p u-ime-en"
                    maxlength="128"
                    name=""
                    v-model.trim="$v.values.case2_area.$model"
                  />
                  <div class="formTableBox_itemBox_heading">状況</div>
                  <input
                    type="text"
                    id=""
                    class="u-input--w100p u-ime-en"
                    maxlength="128"
                    name=""
                    v-model.trim="$v.values.case2_condition.$model"
                  />
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">不具合内容3</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div class="formTableBox_itemBox_heading">場所</div>
                  <select
                    class="select-box"
                    v-model.trim="$v.values.case3_basho.$model"
                  >
                    <option value="" selected="">選択してください</option>
                    <option
                      v-for="(item, index) in case3_basho_options"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>

                  <div class="formTableBox_itemBox_heading">部位</div>
                  <input
                    type="text"
                    id=""
                    class="u-input--w100p u-ime-en"
                    maxlength="128"
                    name=""
                    v-model.trim="$v.values.case3_area.$model"
                  />
                  <div class="formTableBox_itemBox_heading">状況</div>
                  <input
                    type="text"
                    id=""
                    class="u-input--w100p u-ime-en"
                    maxlength="128"
                    name=""
                    v-model.trim="$v.values.case3_condition.$model"
                  />
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">不具合内容4</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div class="formTableBox_itemBox_heading">場所</div>
                  <select
                    class="select-box"
                    v-model.trim="$v.values.case4_basho.$model"
                  >
                    <option value="" selected="">選択してください</option>
                    <option
                      v-for="(item, index) in case4_basho_options"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>

                  <div class="formTableBox_itemBox_heading">部位</div>
                  <input
                    type="text"
                    id=""
                    class="u-input--w100p u-ime-en"
                    maxlength="128"
                    name=""
                    v-model.trim="$v.values.case4_area.$model"
                  />
                  <div class="formTableBox_itemBox_heading">状況</div>
                  <input
                    type="text"
                    id=""
                    class="u-input--w100p u-ime-en"
                    maxlength="128"
                    name=""
                    v-model.trim="$v.values.case4_condition.$model"
                  />
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">不具合内容5</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div class="formTableBox_itemBox_heading">場所</div>
                  <select
                    class="select-box"
                    v-model.trim="$v.values.case5_basho.$model"
                  >
                    <option value="" selected="">選択してください</option>
                    <option
                      v-for="(item, index) in case5_basho_options"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>

                  <div class="formTableBox_itemBox_heading">部位</div>
                  <input
                    type="text"
                    id=""
                    class="u-input--w100p u-ime-en"
                    maxlength="128"
                    name=""
                    v-model.trim="$v.values.case5_area.$model"
                  />
                  <div class="formTableBox_itemBox_heading">状況</div>
                  <input
                    type="text"
                    id=""
                    class="u-input--w100p u-ime-en"
                    maxlength="128"
                    name=""
                    v-model.trim="$v.values.case5_condition.$model"
                  />
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">不具合内容6</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div class="formTableBox_itemBox_heading">場所</div>
                  <select
                    class="select-box"
                    v-model.trim="$v.values.case6_basho.$model"
                  >
                    <option value="" selected="">選択してください</option>
                    <option
                      v-for="(item, index) in case6_basho_options"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>

                  <div class="formTableBox_itemBox_heading">部位</div>
                  <input
                    type="text"
                    id=""
                    class="u-input--w100p u-ime-en"
                    maxlength="128"
                    name=""
                    v-model.trim="$v.values.case6_area.$model"
                  />
                  <div class="formTableBox_itemBox_heading">状況</div>
                  <input
                    type="text"
                    id=""
                    class="u-input--w100p u-ime-en"
                    maxlength="128"
                    name=""
                    v-model.trim="$v.values.case6_condition.$model"
                  />
                </div>
              </div>
            </dd>
          </dl>

          <div class="hideTrg" :class="{ active: isAdd }" @click="isAdd = true">
            <span>＋追加する</span>
          </div>

          <div class="hideDl" :class="{ active: isAdd }">
            <dl class="formTable">
              <dt class="formTable_ttl">
                <label for="">不具合内容7</label>
              </dt>
              <dd class="formTable_data">
                <div class="formTableBox">
                  <div class="formTableBox_itemBox">
                    <div class="formTableBox_itemBox_heading">場所</div>
                    <select
                      class="select-box"
                      v-model.trim="$v.values.case7_basho.$model"
                    >
                      <option value="" selected="">選択してください</option>
                      <option
                        v-for="(item, index) in case7_basho_options"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>

                    <div class="formTableBox_itemBox_heading">部位</div>
                    <input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-en"
                      maxlength="128"
                      name=""
                      v-model.trim="$v.values.case7_area.$model"
                    />
                    <div class="formTableBox_itemBox_heading">状況</div>
                    <input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-en"
                      maxlength="128"
                      name=""
                      v-model.trim="$v.values.case7_condition.$model"
                    />
                  </div>
                </div>
              </dd>
            </dl>

            <dl class="formTable">
              <dt class="formTable_ttl">
                <label for="">不具合内容8</label>
              </dt>
              <dd class="formTable_data">
                <div class="formTableBox">
                  <div class="formTableBox_itemBox">
                    <div class="formTableBox_itemBox_heading">場所</div>
                    <select
                      class="select-box"
                      v-model.trim="$v.values.case8_basho.$model"
                    >
                      <option value="" selected="">選択してください</option>
                      <option
                        v-for="(item, index) in case8_basho_options"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>

                    <div class="formTableBox_itemBox_heading">部位</div>
                    <input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-en"
                      maxlength="128"
                      name=""
                      v-model.trim="$v.values.case8_area.$model"
                    />
                    <div class="formTableBox_itemBox_heading">状況</div>
                    <input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-en"
                      maxlength="128"
                      name=""
                      v-model.trim="$v.values.case8_condition.$model"
                    />
                  </div>
                </div>
              </dd>
            </dl>

            <dl class="formTable">
              <dt class="formTable_ttl">
                <label for="">不具合内容9</label>
              </dt>
              <dd class="formTable_data">
                <div class="formTableBox">
                  <div class="formTableBox_itemBox">
                    <div class="formTableBox_itemBox_heading">場所</div>
                    <select
                      class="select-box"
                      v-model.trim="$v.values.case9_basho.$model"
                    >
                      <option value="" selected="">選択してください</option>
                      <option
                        v-for="(item, index) in case9_basho_options"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>

                    <div class="formTableBox_itemBox_heading">部位</div>
                    <input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-en"
                      maxlength="128"
                      name=""
                      v-model.trim="$v.values.case9_area.$model"
                    />
                    <div class="formTableBox_itemBox_heading">状況</div>
                    <input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-en"
                      maxlength="128"
                      name=""
                      v-model.trim="$v.values.case9_condition.$model"
                    />
                  </div>
                </div>
              </dd>
            </dl>

            <dl class="formTable">
              <dt class="formTable_ttl">
                <label for="">不具合内容10</label>
              </dt>
              <dd class="formTable_data">
                <div class="formTableBox">
                  <div class="formTableBox_itemBox">
                    <div class="formTableBox_itemBox_heading">場所</div>
                    <select
                      class="select-box"
                      v-model.trim="$v.values.case10_basho.$model"
                    >
                      <option value="" selected="">選択してください</option>
                      <option
                        v-for="(item, index) in case10_basho_options"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>

                    <div class="formTableBox_itemBox_heading">部位</div>
                    <input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-en"
                      maxlength="128"
                      name=""
                      v-model.trim="$v.values.case10_area.$model"
                    />
                    <div class="formTableBox_itemBox_heading">状況</div>
                    <input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-en"
                      maxlength="128"
                      name=""
                      v-model.trim="$v.values.case10_condition.$model"
                    />
                  </div>
                </div>
              </dd>
            </dl>

            <dl class="formTable">
              <dt class="formTable_ttl">
                <label for="">不具合内容11</label>
              </dt>
              <dd class="formTable_data">
                <div class="formTableBox">
                  <div class="formTableBox_itemBox">
                    <div class="formTableBox_itemBox_heading">場所</div>
                    <select
                      class="select-box"
                      v-model.trim="$v.values.case11_basho.$model"
                    >
                      <option value="" selected="">選択してください</option>
                      <option
                        v-for="(item, index) in case11_basho_options"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>

                    <div class="formTableBox_itemBox_heading">部位</div>
                    <input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-en"
                      maxlength="128"
                      name=""
                      v-model.trim="$v.values.case11_area.$model"
                    />
                    <div class="formTableBox_itemBox_heading">状況</div>
                    <input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-en"
                      maxlength="128"
                      name=""
                      v-model.trim="$v.values.case11_condition.$model"
                    />
                  </div>
                </div>
              </dd>
            </dl>

            <dl class="formTable">
              <dt class="formTable_ttl">
                <label for="">不具合内容12</label>
              </dt>
              <dd class="formTable_data">
                <div class="formTableBox">
                  <div class="formTableBox_itemBox">
                    <div class="formTableBox_itemBox_heading">場所</div>
                    <select
                      class="select-box"
                      v-model.trim="$v.values.case12_basho.$model"
                    >
                      <option value="" selected="">選択してください</option>
                      <option
                        v-for="(item, index) in case12_basho_options"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>

                    <div class="formTableBox_itemBox_heading">部位</div>
                    <input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-en"
                      maxlength="128"
                      name=""
                      v-model.trim="$v.values.case12_area.$model"
                    />
                    <div class="formTableBox_itemBox_heading">状況</div>
                    <input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-en"
                      maxlength="128"
                      name=""
                      v-model.trim="$v.values.case12_condition.$model"
                    />
                  </div>
                </div>
              </dd>
            </dl>
          </div>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">その他質問等</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <textarea
                    rows="8"
                    class="u-input--w100p u-ime-ja"
                    v-model.trim="$v.values.text.$model"
                  ></textarea>
                </div>
              </div>
            </dd>
          </dl>
          <div class="cn-back-btn">
            <a href="javascript:void(0);" @click="onConfirm">次へ</a>
          </div>
        </section>
      </div>

      <div class="mainContentBoxInner" v-else-if="status == 'confirm'">
        <div class="pageTitleWrapper">
          <h2 class="pageTitle">定期アフターサービス申込み</h2>
        </div>

        <div class="pageCaption">
          <div class="contactCaptionDetail">
            入力内容をご確認ください。<br />
            よろしければ「送信」ボタンをクリックしてください。
          </div>
        </div>

        <section class="mainContent-form">
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>マンション名</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ mansionName }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>部屋番号</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ currentUser.roomNo }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">名前</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ values.sei }} {{ values.mei }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">ふりがな</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ values.kanaSei }} {{ values.kanaMei }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">メールアドレス</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">{{ values.email }}</div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">電話番号1</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">{{ values.phone_1 }}</div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">電話番号2</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">{{ values.phone_2 }}</div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">専有部補修希望</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ values.senyuu_hoshu }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">希望日程</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div>第一希望：{{ values.date1_1 }}</div>
                  <div>第二希望：{{ values.date1_2 }}</div>
                  <div>第三希望：{{ values.date1_3 }}</div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">その他／ご希望日・お時間</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ values.date1_others }}
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">不具合内容1</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div>{{ values.case1_basho }}</div>
                  <div>{{ values.case1_area }}</div>
                  <div>{{ values.case1_condition }}</div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">不具合内容2</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div>{{ values.case2_basho }}</div>
                  <div>{{ values.case2_area }}</div>
                  <div>{{ values.case2_condition }}</div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">不具合内容3</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div>{{ values.case3_basho }}</div>
                  <div>{{ values.case3_area }}</div>
                  <div>{{ values.case3_condition }}</div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">不具合内容4</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div>{{ values.case4_basho }}</div>
                  <div>{{ values.case4_area }}</div>
                  <div>{{ values.case4_condition }}</div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">不具合内容5</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div>{{ values.case5_basho }}</div>
                  <div>{{ values.case5_area }}</div>
                  <div>{{ values.case5_condition }}</div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">不具合内容6</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div>{{ values.case6_basho }}</div>
                  <div>{{ values.case6_area }}</div>
                  <div>{{ values.case6_condition }}</div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">不具合内容7</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div>{{ values.case7_basho }}</div>
                  <div>{{ values.case7_area }}</div>
                  <div>{{ values.case7_condition }}</div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">不具合内容8</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div>{{ values.case8_basho }}</div>
                  <div>{{ values.case8_area }}</div>
                  <div>{{ values.case8_condition }}</div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">不具合内容9</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div>{{ values.case9_basho }}</div>
                  <div>{{ values.case9_area }}</div>
                  <div>{{ values.case9_condition }}</div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">不具合内容10</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div>{{ values.case10_basho }}</div>
                  <div>{{ values.case10_area }}</div>
                  <div>{{ values.case10_condition }}</div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">不具合内容11</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div>{{ values.case11_basho }}</div>
                  <div>{{ values.case11_area }}</div>
                  <div>{{ values.case11_condition }}</div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">不具合内容12</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div>{{ values.case12_basho }}</div>
                  <div>{{ values.case12_area }}</div>
                  <div>{{ values.case12_condition }}</div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">その他</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <pre>{{ values.text }}</pre>
                </div>
              </div>
            </dd>
          </dl>

          <div class="cn-back-btn">
            <a href="javascript:void(0);" @click="status = 'edit'">戻る</a>
          </div>
          <div class="cn-back-btn">
            <a href="javascript:void(0);" @click="onPost">送信</a>
          </div>
        </section>
      </div>

      <div class="mainContentBoxInner" v-else-if="status == 'complete'">
        <div class="pageTitleWrapper">
          <h2 class="pageTitle">定期アフターサービス申込み</h2>
        </div>

        <div class="pageCaption">
          <div class="contactCaptionDetail">申込みを受け付けました。</div>
        </div>

        <section class="mainContent-form">
          <div class="cn-back-btn">
            <router-link
              :to="{
                name: 'top',
                params: { mc_code: mcCode },
              }"
              >トップページへ</router-link
            >
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import authHeader from "../../services/auth-header";
import { required, email, decimal, sameAs } from "vuelidate/lib/validators";
// import FormService from "../../services/form.service";
import OrderService from "../../services/order.service";
import OrderSchemaService from "../../services/order_schema.service";
import store from "../../store";
const { JSONPath } = require("jsonpath-plus");
export default {
  name: "contact",
  // mixins: [Navi],
  data() {
    return {
      isAdd: false,
      files1: [],
      files2: [],
      files3: [],
      isFileSelecting: false,
      uploadApi: `${process.env.VUE_APP_API_ENDPOINT}/api/file/attach`,
      status: "edit",
      values: {
        agree: false,
        sei: "",
        mei: "",
        kanaSei: "",
        kanaMei: "",
        email: "",
        phone_1: "",
        phone_2: "",
        senyuu_hoshu: "",
        date1_1: "",
        date1_2: "",
        date1_3: "",
        date1_others: "",
        date2_1: "",
        date2_2: "",
        date2_3: "",
        date2_others: "",
        case1_basho: "",
        case1_area: "",
        case1_condition: "",
        case1_image: "",
        case2_basho: "",
        case2_area: "",
        case2_condition: "",
        case2_image: "",
        case3_basho: "",
        case3_area: "",
        case3_condition: "",
        case3_image: "",
        case4_basho: "",
        case4_area: "",
        case4_condition: "",
        case5_basho: "",
        case5_area: "",
        case5_condition: "",
        case6_basho: "",
        case6_area: "",
        case6_condition: "",
        case7_basho: "",
        case7_area: "",
        case7_condition: "",
        case8_basho: "",
        case8_area: "",
        case8_condition: "",
        case9_basho: "",
        case9_area: "",
        case9_condition: "",
        case10_basho: "",
        case10_area: "",
        case10_condition: "",
        case11_basho: "",
        case11_area: "",
        case11_condition: "",
        case12_basho: "",
        case12_area: "",
        case12_condition: "",
        text: "",
      },
      schema: null,
      date1_1_options: {},
      date1_2_options: {},
      date1_3_options: {},
      date2_1_options: {},
      date2_2_options: {},
      date2_3_options: {},
      case1_basho_options: {},
      case2_basho_options: {},
      case3_basho_options: {},
      case4_basho_options: {},
      case5_basho_options: {},
      case6_basho_options: {},
      case7_basho_options: {},
      case8_basho_options: {},
      case9_basho_options: {},
      case10_basho_options: {},
      case11_basho_options: {},
      case12_basho_options: {},
    };
  },
  validations: {
    values: {
      agree: { sameAs: sameAs(() => true) },
      sei: { required },
      mei: { required },
      kanaSei: { required },
      kanaMei: { required },
      email: { required, email },
      phone_1: { required, decimal },
      phone_2: { decimal },
      senyuu_hoshu: { required },
      date1_1: {},
      date1_2: {},
      date1_3: {},
      date1_others: {},
      date2_1: {},
      date2_2: {},
      date2_3: {},
      date2_others: {},
      case1_basho: {},
      case1_area: {},
      case1_condition: {},
      case1_image: {},
      case2_basho: {},
      case2_area: {},
      case2_condition: {},
      case2_image: {},
      case3_basho: {},
      case3_area: {},
      case3_condition: {},
      case3_image: {},
      case4_basho: {},
      case4_area: {},
      case4_condition: {},
      case5_basho: {},
      case5_area: {},
      case5_condition: {},
      case6_basho: {},
      case6_area: {},
      case6_condition: {},
      case7_basho: {},
      case7_area: {},
      case7_condition: {},
      case8_basho: {},
      case8_area: {},
      case8_condition: {},
      case9_basho: {},
      case9_area: {},
      case9_condition: {},
      case10_basho: {},
      case10_area: {},
      case10_condition: {},
      case11_basho: {},
      case11_area: {},
      case11_condition: {},
      case12_basho: {},
      case12_area: {},
      case12_condition: {},
      text: {},
    },
  },
  mounted: async function() {
    console.log("+++ contact.mounted");
    await this.$nextTick();
    const user = store.state.auth.user;
    console.log(user);
    const result = await OrderSchemaService.find({
      mansion: user.mansion.id,
      directory: "after",
    });
    console.log("result", result);
    this.schema = result.data[0].schema;

    const date1_1 = JSONPath({
      path: "$..[?(@property === 'name' && @ == 'date1_1')]^",
      json: this.schema,
    });
    this.date1_1_options = date1_1[0].options;
    console.log("this.date1_1_options", this.date1_1_options);
    const date1_2 = JSONPath({
      path: "$..[?(@property === 'name' && @ == 'date1_2')]^",
      json: this.schema,
    });
    this.date1_2_options = date1_2[0].options;
    console.log("this.date1_2_options", this.date1_2_options);
    const date1_3 = JSONPath({
      path: "$..[?(@property === 'name' && @ == 'date1_3')]^",
      json: this.schema,
    });
    this.date1_3_options = date1_3[0].options;
    console.log("this.date1_3_options", this.date1_3_options);

    const date2_1 = JSONPath({
      path: "$..[?(@property === 'name' && @ == 'date2_1')]^",
      json: this.schema,
    });
    this.date2_1_options = date2_1[0].options;
    console.log("this.date2_1_options", this.date2_1_options);
    const date2_2 = JSONPath({
      path: "$..[?(@property === 'name' && @ == 'date2_2')]^",
      json: this.schema,
    });
    this.date2_2_options = date2_2[0].options;
    console.log("this.date2_2_options", this.date2_2_options);
    const date2_3 = JSONPath({
      path: "$..[?(@property === 'name' && @ == 'date2_3')]^",
      json: this.schema,
    });
    this.date2_3_options = date2_3[0].options;
    console.log("this.date2_3_options", this.date2_3_options);
    this.values.email = user.email;

    this.case1_basho_options = this.getOptions(this.schema, "case1_basho");
    this.case2_basho_options = this.getOptions(this.schema, "case2_basho");
    this.case3_basho_options = this.getOptions(this.schema, "case3_basho");
    this.case4_basho_options = this.getOptions(this.schema, "case4_basho");
    this.case5_basho_options = this.getOptions(this.schema, "case5_basho");
    this.case6_basho_options = this.getOptions(this.schema, "case6_basho");
    this.case7_basho_options = this.getOptions(this.schema, "case7_basho");
    this.case8_basho_options = this.getOptions(this.schema, "case8_basho");
    this.case9_basho_options = this.getOptions(this.schema, "case9_basho");
    this.case10_basho_options = this.getOptions(this.schema, "case10_basho");
    this.case11_basho_options = this.getOptions(this.schema, "case11_basho");
    this.case12_basho_options = this.getOptions(this.schema, "case12_basho");

    console.log("case1_basho_options", this.case1_basho_options);
  },
  computed: {
    header: function() {
      return authHeader();
    },
    mansionName() {
      return this.mansion != null ? this.mansion.name : "";
    },
  },
  methods: {
    clearAllImage1() {
      this.files1 = [];
      this.values.case1_image = "";
    },
    clearAllImage2() {
      this.files2 = [];
      this.values.case2_image = "";
    },
    clearAllImage3() {
      this.files3 = [];
      this.values.case3_image = "";
    },
    /**
     * Has changed
     * @param  Object|undefined   newFile   Read only
     * @param  Object|undefined   oldFile   Read only
     * @return undefined
     */
    // eslint-disable-next-line
    inputFile1(newFile, oldFile) {
      if (newFile && !oldFile) {
        // Add file
      }

      if (newFile && oldFile) {
        // Update file

        // Start upload
        if (newFile.active !== oldFile.active) {
          console.log("Start upload", newFile.active, newFile);

          // // min size
          // if (newFile.size >= 0 && newFile.size < 100 * 1024) {
          //   newFile = this.$refs.upload1.update(newFile, { error: "size" });
          // }
        }

        // Upload progress
        if (newFile.progress !== oldFile.progress) {
          console.log("progress", newFile.progress, newFile);
        }

        // Upload error
        if (newFile.error !== oldFile.error) {
          console.log("error", newFile.error, newFile);
        }

        // Uploaded successfully
        if (newFile.success !== oldFile.success) {
          console.log("success", newFile.success, newFile);
          this.values.case1_image = newFile.response.url;
        }
      }

      if (!newFile && oldFile) {
        // Remove file

        // Automatically delete files on the server
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/file/delete?id=' + oldFile.response.id,
          // });
        }
      }

      // Automatic upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (!this.$refs.upload1.active) {
          this.$refs.upload1.active = true;
        }
      }
    },
    inputFile2(newFile, oldFile) {
      if (newFile && !oldFile) {
        // Add file
      }

      if (newFile && oldFile) {
        // Update file

        // Start upload
        if (newFile.active !== oldFile.active) {
          console.log("Start upload", newFile.active, newFile);

          // // min size
          // if (newFile.size >= 0 && newFile.size < 100 * 1024) {
          //   newFile = this.$refs.upload2.update(newFile, { error: "size" });
          // }
        }

        // Upload progress
        if (newFile.progress !== oldFile.progress) {
          console.log("progress", newFile.progress, newFile);
        }

        // Upload error
        if (newFile.error !== oldFile.error) {
          console.log("error", newFile.error, newFile);
        }

        // Uploaded successfully
        if (newFile.success !== oldFile.success) {
          console.log("success", newFile.success, newFile);
          this.values.case2_image = newFile.response.url;
        }
      }

      if (!newFile && oldFile) {
        // Remove file

        // Automatically delete files on the server
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/file/delete?id=' + oldFile.response.id,
          // });
        }
      }

      // Automatic upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (!this.$refs.upload2.active) {
          this.$refs.upload2.active = true;
        }
      }
    },
    inputFile3(newFile, oldFile) {
      if (newFile && !oldFile) {
        // Add file
      }

      if (newFile && oldFile) {
        // Update file

        // Start upload
        if (newFile.active !== oldFile.active) {
          console.log("Start upload", newFile.active, newFile);

          // // min size
          // if (newFile.size >= 0 && newFile.size < 100 * 1024) {
          //   newFile = this.$refs.upload3.update(newFile, { error: "size" });
          // }
        }

        // Upload progress
        if (newFile.progress !== oldFile.progress) {
          console.log("progress", newFile.progress, newFile);
        }

        // Upload error
        if (newFile.error !== oldFile.error) {
          console.log("error", newFile.error, newFile);
        }

        // Uploaded successfully
        if (newFile.success !== oldFile.success) {
          console.log("success", newFile.success, newFile);
          this.values.case3_image = newFile.response.url;
        }
      }

      if (!newFile && oldFile) {
        // Remove file

        // Automatically delete files on the server
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/file/delete?id=' + oldFile.response.id,
          // });
        }
      }

      // Automatic upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (!this.$refs.upload3.active) {
          this.$refs.upload3.active = true;
        }
      }
    },
    /**
     * Pretreatment
     * @param  Object|undefined   newFile   Read and write
     * @param  Object|undefined   oldFile   Read only
     * @param  Function           prevent   Prevent changing
     * @return undefined
     */
    inputFilter: function(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          return prevent();
        }
      }

      // Create a blob field
      newFile.blob = "";
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file);
      }
    },
    // async uploadData() {
    //   console.log(this.values);
    //   // (category, sei, mei, mansionName,mansion, roomNo, result)
    //   const result = await OrderService.editByDirectroy("saigai", this.values);
    //   this.status = "complete";
    //   this.scrollTop();
    //   console.log(result);
    // },
    getOptions(json, name) {
      const res = JSONPath({
        path: `$..[?(@property === 'name' && @ == '${name}')]^`,
        json,
      });
      return res[0].options;
    },
    scrollTop: function() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    onConfirm() {
      console.log("onConfirm");
      console.log(this.values);
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        this.status = "confirm";
        this.scrollTop();
      }
    },
    async onPost() {
      console.log("onPost");
      console.log(this.values);
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        // (category, sei, mei, mansionName,mansion, roomNo, result)
        const result = await OrderService.editByDirectroy("after", this.values);
        this.status = "complete";
        this.scrollTop();
        console.log(result);
      }
    },
  },
};
</script>

<style>
.flex-wrapper {
  display: flex;
}
</style>
