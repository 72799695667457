<template>
  <div class="_login-wrapper"></div>
</template>

<script>
import store from "../store";
export default {
  async mounted() {
    await this.$nextTick();
    store.dispatch("auth/logout");
    // this.$router.push("/login");
    this.$cookies.remove("m-code");
    this.$cookies.remove("u-status");
    this.$cookies.remove("m-status");
    window.location = "/login";
    // window.location = process.env.VUE_APP_ISALON_LOGOUT_ENDPOINT;
  },
};
</script>

<style></style>
