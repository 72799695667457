<template>
  <div class="mainContentBox">
    <div class="mainContentBoxInner" v-if="state == 'edit'">
      <div class="pageTitleWrapper mypage">
        <h2 class="pageTitle">お知らせ通知メール設定</h2>
      </div>

      <div class="pageCaption">
        <div class="contactCaptionDetail">
          お知らせ通知メール設定を変更します。
        </div>
      </div>

      <section class="mainContent-form">
        <dl class="formTable">
          <dt class="formTable_ttl">
            <span class="icoForm-requir">必須</span>
            <label>お知らせ通知メール</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                <span v-for="(item, index) in labels" :key="index">
                  <label>
                    <input
                      type="radio"
                      id="notice"
                      class=""
                      name="notice"
                      v-model="needToSendEmail"
                      :value="item.value"
                    />
                    &nbsp;{{ item.label }}&nbsp;</label
                  >
                </span>
              </div>
            </div>
          </dd>
        </dl>

        <div class="cn-back-btn">
          <a href="javascript:void(0);" @click="onPreChange">変更</a>
        </div>
      </section>
    </div>
    <div class="mainContentBoxInner" v-else>
      <div class="pageTitleWrapper mypage">
        <h2 class="pageTitle">お知らせ通知メール設定変更</h2>
      </div>

      <div class="pageCaption">
        <div class="contactCaptionDetail">
          <span class="caution">{{ notificationStr }}に変更しました</span>
        </div>
      </div>

      <section class="mainContent-form">
        <div class="cn-back-btn">
          <!-- <router-link
            :to="{
              name: 'mypage',
              params: { mc_code: mcCode },
            }"
            >ユーザー情報へ</router-link
          > -->
          <!--//
          <span class="backLink">
            <a href="https://www.mansion-concierge.com/iuc-salon/logout.php">
            >//-->
          <!--//<a
            //  href="javascript:void(0);"
            //  @click="$router.push({ name: 'logout' })"
            >//-->
          <!--//
              →ログインページへ</a
            >
          </span>
//-->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import UserService from "../../../../services/user.service";
export default {
  data() {
    return {
      needToSendEmail: false,
      state: "edit",
      labels: [
        { value: true, label: "受け取る" },
        { value: false, label: "受け取らない" },
      ],
    };
  },
  validations: {
    needToSendEmail: {
      required,
    },
  },
  computed: {
    notificationStr() {
      return this.needToSendEmail ? "受け取る" : "受け取らない";
    },
    currentUser() {
      return this.$store.state.user;
    },
  },
  async mounted() {
    await this.$nextTick();
    console.log(this.currentUser);

    const res = await UserService.getSettings();
    console.log(res);
    this.needToSendEmail = res.data.needToSendEmail;
  },
  methods: {
    async onPreChange() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        try {
          const result = await UserService.updateSettings({
            needToSendEmail: this.needToSendEmail,
          });
          console.log(result);
          this.state = "confirm";
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style></style>
