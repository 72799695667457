<template>
  <div id="vote">
    <div class="section-block form-set" v-if="isEditor">
      <div class="form-set-name"><label>部屋番号選択</label></div>
      <div class="form-set-input">
        <span class="select-box-container">
          <select class="select-box" v-model="selectedRoomNo">
            <option value="" selected>選択してください</option>
            <option
              :value="room.roomNo"
              v-for="(room, index) in members"
              :key="index"
              >{{ room.roomNo }}</option
            >
          </select>
        </span>
      </div>
    </div>

    <div class="btns_wrapper_two" v-if="!(isDone && !isEditor) && isMember()">
      <button
        class="bt bt-two-yes"
        @click="onVote(0)"
        :class="{
          active:
            selectedRoomNo == ''
              ? data.result[0].indexOf(currentUser.roomNo) > -1
              : data.result[0].indexOf(selectedRoomNo) > -1,
        }"
      >
        {{ titles[0] }}
      </button>
      <button
        class="bt bt-two-no"
        @click="onVote(1)"
        :class="{
          active:
            selectedRoomNo == ''
              ? data.result[1].indexOf(currentUser.roomNo) > -1
              : data.result[1].indexOf(selectedRoomNo) > -1,
        }"
      >
        {{ titles[1] }}
      </button>
    </div>

    <div class="text text_border">
      <div class="sche_view sche_view_child">
        <span class="sche_view_title">{{ data.tag[0] }}：</span
        ><span
          class="sche_view_room"
          v-for="(roomNo, index) in data.result[0]"
          :key="index"
          >{{ roomNo }}</span
        >
      </div>
      <div class="sche_view sche_view_child">
        <span class="sche_view_title">{{ data.tag[1] }}：</span
        ><span
          class="sche_view_room"
          v-for="(roomNo, index) in data.result[1]"
          :key="index"
          >{{ roomNo }}</span
        >
      </div>
      <div class="sche_view sche_view_child">
        <span class="sche_view_title">{{ data.tag[2] }}：</span
        ><span
          class="sche_view_room"
          v-for="(roomNo, index) in data.result[2]"
          :key="index"
          >{{ roomNo }}</span
        >
      </div>
    </div>
    <div class="section-block form-set" v-if="isEditor">
      <div class="form-set-name"><label>議事メモ</label></div>
      <div class="form-set-input">
        <textarea rows="3" v-model="data.comment"></textarea>
      </div>
      <div class="btn_kanri" @click="onSave">
        <span>保存</span>
      </div>
    </div>
  </div>
</template>

<script>
import VoteService from "../services/vote.service";
import UserService from "../services/user.service";

export default {
  name: "vote",
  props: [
    "bodID",
    "agendaIndex",
    "titles",
    "isEditor",
    "isDone",
    "members",
    "roomNo",
  ],
  data: function() {
    return {
      selectedRoomNo: "",
      rooms: [],
      data: {
        tag: ["", "", ""],
        result: [[], [], []],
        agendaIndex: this.agendaIndex,
        bod: this.bodID,
        comment: "",
      },
    };
  },
  mounted: function() {
    this.load();
    console.log("++VOTE");
    console.log(this.members);
    // this.load2();
  },
  methods: {
    async onSave() {
      // console.log("save");
      // console.log(this.data.comment);
      const obj = { comment: this.data.comment };
      const res = await VoteService.update(this.data._id, obj);
      // console.log(res);
      this.data = res.data;
    },
    async onVote(value) {
      // console.log(value);
      // console.log(this.selectedRoomNo);
      let obj = { value: value };
      if (this.isEditor) {
        if (this.selectedRoomNo == "") {
          return;
        }
        obj.roomNo = this.selectedRoomNo;
      }
      const res = await VoteService.vote(this.data._id, obj);
      // console.log(res);
      this.data = res.data;
    },
    async load() {
      const res = await VoteService.find({
        bod: this.bodID,
        agendaIndex: this.agendaIndex,
      });
      // console.log("---aaa");
      // console.log(res);
      if (res.data.length > 0) {
        // this.isVisible = true;
        this.data = res.data[0];
      } else {
        // this.isVisible = false;
      }
    },
    async load2() {
      const res = await UserService.findByIndex(110, 100);
      console.log("+++load2");
      console.log(res);
      this.rooms = res.data;
    },
    isMember() {
      return (
        this.isEditor ||
        this.members.some(element => element.roomNo == this.roomNo)
      );
    },
  },
};
</script>

<style></style>
