var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main shoko"},[_c('section',{staticClass:"main-section"},[(_vm.isPermission('Admin'))?_c('p',{staticClass:"btn-item",staticStyle:{"display":"none"}},[_c('router-link',{attrs:{"to":{
          name: 'admin_archive_edit',
          params: { mc_code: _vm.currentUser.mansion._id, id: 'create' },
        }}},[_c('button',{staticClass:"el-button el-button--primary",attrs:{"type":"button"}},[_c('i',{staticClass:"el-icon-circle-plus"}),_c('span',[_vm._v("新規書類追加")])])])],1):_vm._e(),_c('h3',{staticClass:"section-title green-left-head"},[_vm._v("書庫（規約・議事録 他）")]),_vm._m(0)]),(_vm.isPermission('Admin'))?_c('section',{staticClass:"main-section buttons cloumBtns"},[_c('span',{staticClass:"exlinks"},_vm._l((_vm.cstDocumentTypes),function(item,key){return _c('div',{key:key},[(_vm.cstDocuments.indexOf(key) > -1)?_c('p',{staticClass:"section-block center archive"},[_c('router-link',{attrs:{"to":{
              name: 'cst',
              params: { doc_type: key },
            },"target":"_blank"}},[_c('button',{staticClass:"bt bt-blue"},[_vm._v(_vm._s(item.name)+"※")])])],1):_vm._e()])}),0),_vm._l((_vm.categories),function(item,index){return _c('p',{key:index,staticClass:"section-block center",class:("archive" + (item._id))},[_c('router-link',{attrs:{"to":{
          name: 'archive-list',
          params: { category: item._id, mc_code: _vm.currentUser.mansion._id },
        }}},[_c('button',{staticClass:"bt bt-green"},[_vm._v(_vm._s(item.title))])])],1)})],2):_c('section',{staticClass:"main-section buttons cloumBtns"},[_c('span',{staticClass:"exlinks"},_vm._l((_vm.cstDocuments),function(item,key){return _c('div',{key:key},[_c('p',{staticClass:"section-block center archive"},[_c('router-link',{attrs:{"to":{
              name: 'cst',
              params: { doc_type: item },
            },"target":"_blank"}},[_c('button',{staticClass:"bt bt-blue"},[_vm._v(" "+_vm._s(_vm.cstDocumentTypes[item].name)+"※ ")])])],1)])}),0),_vm._l((_vm.norm_categories),function(item,index){return _c('p',{key:index,staticClass:"section-block center",class:("archive" + (item._id))},[_c('router-link',{attrs:{"to":{
          name: 'archive-list',
          params: { category: item._id, mc_code: _vm.currentUser.mansion._id },
        }}},[_c('button',{staticClass:"bt bt-green"},[_vm._v(" "+_vm._s(item.title)+" ")])])],1)})],2),_vm._m(1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pageCaption"},[_vm._v(" マンションに関する書類等を閲覧・ダウンロードできます。"),_c('br'),_vm._v("※印は外部サイトリンクです（別ウインドウが開きます） ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top-back-btn"},[_c('a',{attrs:{"href":"https://iuc-salon.mansion-concierge.com/"}},[_vm._v("トップページへ")])])}]

export { render, staticRenderFns }