<template>
  <div class="mainContentBox">
    <div class="mainContentBoxInner">
      <div class="pageTitleWrapper mypage">
        <h2 class="pageTitle">ユーザー情報</h2>
      </div>

      <div class="pageCaption">
        メールアドレスの登録変更、パスワードの変更が可能です。<br />
        IDは変更できません。予めご了承ください。
      </div>

      <!-- textarea -->
      <div id="textarea">
        <!-- <form
          name="entry_form"
          action="infoconf.php?now_time=20210610183927"
          method="post"
        > -->
        <div id="infochangetable">
          <table
            cellpadding="0"
            cellspacing="2"
            border="0"
            summary="infochange"
            class="infochangeinput"
          >
            <tr>
              <td class="head">ID</td>
              <td class="input">
                <span>インフォメーションカードをご覧ください。</span>
              </td>
            </tr>

            <tr>
              <td class="head">パスワード</td>
              <td class="input">
                <router-link
                  :to="{
                    name: 'mypage-password',
                    params: { mc_code: mcCode },
                  }"
                >
                  <span>パスワードを変更する</span>
                </router-link>
              </td>
            </tr>

            <tr>
              <td class="head">メールアドレス</td>
              <td class="input">
                <span id="mailstr">{{
                  hashEmailAddress(currentUser.email)
                }}</span>
                <router-link
                  :to="{
                    name: 'mypage-email',
                    params: { mc_code: mcCode },
                  }"
                >
                  <span>メールアドレスを変更する</span></router-link
                >
              </td>
            </tr>
          </table>
        </div>
        <!-- </form> -->
      </div>

      <div class="top-back-btn"><a href="./">トップページへ</a></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
/* @import "/i-salon/css/isalon/infochange_layout.css"; */
</style>
