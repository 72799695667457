import axios from "axios";
import authHeader from "./auth-header";

const API_BASE_URL = `${process.env.VUE_APP_API_ENDPOINT}/api/vote`;
const API_RESET_URL = `${API_BASE_URL}/reset`;
const API_FIND_URL = `${API_BASE_URL}/find`;
// console.log(API_RESET_URL);
class VoteService {
  async reset(id, members) {
    // console.log("servece reset 1");
    // console.log(id);
    const res = await axios.post(
      API_RESET_URL,
      { _id: id, users: members },
      { headers: authHeader() },
    );
    return res;
  }
  async find(obj) {
    const res = await axios.post(API_FIND_URL, obj, { headers: authHeader() });
    return res;
  }

  async update(voteID, obj) {
    // console.log("update");
    // console.log(obj);
    const url = `${API_BASE_URL}/${voteID}`;
    const res = await axios.post(url, obj, {
      headers: authHeader(),
    });
    return res;
  }

  async vote(voteID, obj) {
    const url = `${API_BASE_URL}/voting/${voteID}`;
    const res = await axios.post(url, obj, {
      headers: authHeader(),
    });
    return res;
  }
}

export default new VoteService();
