// import Vue from "vue";
// import BoD from "../services/bod.service";
import Vote from "../services/vote.service";
// import authHeader from "../services/auth-header";

const initialState = { values: [] };

export const vote = {
  namespaced: true,
  state: initialState,
  actions: {
    async find({ commit }, obj) {
      // console.log(`find(${obj})`);
      const res = await Vote.find(obj);
      // console.log(res.data);
      commit("setVote", res.data);
    },
    setVote({ commit }, obj) {
      commit("setVote", obj);
    },
  },
  mutations: {
    setVote(state, value) {
      state.values = value;
    },
  },
  getters: {
    values: state => {
      return state.values;
    },
  },
};
