<template>
  <div class="mainContentBox">
    <div class="mainContentBoxInner">
      <div class="pageTitleWrapper">
        <h3 class="section-title green-left-head">
          理事会からのお知らせ
        </h3>
      </div>
      <!-- head only start -->
      <div>
        <section class="kanri-lv1" v-if="isPermission('Chairman')">
          <div class="btn-kanri">
            <router-link
              :to="{
                name: 'admin_bod_information',
                params: { mc_code: currentUser.mansion._id },
              }"
              >→お知らせ登録はこちら</router-link
            >
          </div>
        </section>
        <!-- head only end -->
        <!-- news -->
        <BoDNewsDetailList />
        <!-- /news -->
      </div>
    </div>
  </div>
</template>

<script>
// import NewsDetailList from "@/components/InfoList.vue";
import BoDNewsDetailList from "@/components/BoDNewsList.vue";
// import NewsDetailList from "@/components/NewsDetailList.vue";
// import MeetingSummary from "@/components/MeetingSummary.vue";
// import IconMenu from "@/components/IconMenu.vue";
// import GA from "../../../mixin/gtag";
export default {
  // mixins: [GA],
  components: { BoDNewsDetailList },
};
</script>

<style></style>
