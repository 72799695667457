<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
// import gtag from "../mixin/gtag_public";
export default {
  name: "free-layout",
  // mixins: [gtag],
  async mounted() {
    var routeInstance = this.$route;
    await this.$nextTick();
    this.createTitleDesc(routeInstance);
  },
  watch: {
    // eslint-disable-next-line
    $route(routeInstance, from) {
      this.createTitleDesc(routeInstance);
    },
  },
  methods: {
    createTitleDesc: function(routeInstance) {
      // タイトルを設定
      if (routeInstance.meta.title) {
        var setTitle = routeInstance.meta.title + " | iuc SALON";
        document.title = setTitle;
      } else {
        document.title = "iuc SALON";
      }
      // メタタグのdescription設定処理
      console.log("meta", routeInstance.meta);
      if (routeInstance.meta.desc) {
        console.log("++++OKOKOKO");
        var setDesc = routeInstance.meta.desc + "";
        document
          .querySelector("meta[name='description']")
          .setAttribute("content", setDesc);
      } else {
        document
          .querySelector("meta[name='description']")
          .setAttribute("content", "");
      }
    },
  },
};
</script>

<style></style>
