<template>
  <div id="notfound">
    <br />
    ..
  </div>
</template>

<script>
export default {
  data() {
    return { time: 5000 };
  },
  created: function() {
    setTimeout(() => {
      document.location = "/";
    }, 1000);
  },
};
</script>

<style></style>
