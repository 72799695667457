<template>
  <div id="BOD-list" v-if="bods.length > 0">
    <section class="main-section">
      <h3 class="section-title green-left-head">{{ title }}</h3>
      <div class="box-item">
        <div class="box-item-wrapper">
          <ul class="box-item-list">
            <li
              class="box-item-list-item"
              v-for="(item, index) in bods"
              :key="index"
            >
              <router-link
                :to="{
                  name: 'bodDetail',
                  params: { id: item._id, mc_code: currentUser.mansion._id },
                }"
              >
                <div class="date" v-if="item.date != null">
                  {{ toYYYYMMDD(item.date) }}&nbsp;{{ item.range.start }}〜{{
                    item.range.end
                  }}&nbsp;{{ isOfficialWord(item.isOfficial) }}
                </div>
                <div class="date" v-else>
                  調整中
                </div>
                <div class="text">
                  {{ item.title }}
                </div>
              </router-link>
              <router-link
                v-if="isEditor"
                :to="{
                  name: 'admin_bod_edit',
                  params: { id: item._id, mc_code: currentUser.mansion._id },
                }"
              >
                <span class="btn_kanri">
                  編集
                </span>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <!--/.main-section-->
  </div>
</template>

<script>
import store from "../store";
export default {
  name: "bod-list",
  props: ["title", "mode", "isEditor"],
  async mounted() {
    await this.$nextTick();
    store.dispatch("bod/all").then(
      // eslint-disable-next-line
      docs => {
        //
      },
      // eslint-disable-next-line
      error => {
        //
      },
    );
  },
  computed: {
    bods() {
      let data = this.$store.getters["bod/bods"];
      //
      if (this.mode == "current") {
        const self = this;
        // return data;
        const today = this.currentDate();

        return data.filter(item => {
          const i1 = self.toTIMESTAMP(item.date, item.range.start);
          const i2 = self.toTIMESTAMP(item.date, item.range.end);

          return i1 <= today && i2 >= today && item.isOfficial;
        });
      } else if (this.mode == "next") {
        const self = this;
        // return data;
        const today = this.currentDate();

        return data.filter(item => {
          const i1 = self.toTIMESTAMP(item.date, item.range.start);

          return i1 >= today && item.isOfficial;
        });
      } else if (this.mode == "adjust") {
        // let data = this.$store.getters["bod/bods"];
        //
        //
        // if (data.length > 0) {
        //   return [data[0]];
        // } else {
        //   return [];
        // }
        // const self = this;
        // return data;
        // const today = this.currentDate();
        // //
        return data.filter(item => {
          // const i1 = self.toTIMESTAMP(item.date, item.range.start);
          // //
          return !item.isOfficial;
        });
      } else {
        // let data = this.$store.getters["bod/bods"];
        const self = this;
        // return data;
        const today = this.currentDate();
        // //
        return data.filter(item => {
          const i1 = self.toTIMESTAMP(item.date, item.range.end);
          // //
          return i1 < today && item.isOfficial;
        });
      }
    },
  },
};
</script>

<style></style>
