import axios from "axios";
import authHeader from "./auth-header";
const API_BOD_URL = `${process.env.VUE_APP_API_ENDPOINT}/api/bod`;
const API_URL = `${process.env.VUE_APP_API_ENDPOINT}/api/bod/item/`;
const API_PRIORITY_URL = `${process.env.VUE_APP_API_ENDPOINT}/api/bod/priority/`;
const API_REMOVE = `${process.env.VUE_APP_API_ENDPOINT}/api/bod/remove/`;
// console.log(API_URL);
class BoDService {
  all() {
    return axios
      .get(API_URL + "all", { headers: authHeader() })
      .then(response => {
        return response.data;
      });
  }

  item(id) {
    return axios.get(API_URL + id, { headers: authHeader() }).then(response => {
      return response.data;
    });
  }

  async remove(id) {
    // console.log("servece remove 1");
    const res = await axios.post(
      API_REMOVE,
      { _id: id },
      { headers: authHeader() },
    );
    return res;
    // // console.log("servece remove 2");
    // // console.log(res);
    // return res;
  }

  // remove(id) {
  //   // console.log("servece remove");
  //   return axios
  //     .post(API_REMOVE, { _id: id }, { headers: authHeader() })
  //     .then((response) => {
  //       // console.log(response.data);
  //       return response.data;
  //     });
  // }

  update(bod) {
    // console.log("bod.update");
    // console.log(bod);
    return axios
      .post(API_URL, bod, { headers: authHeader() })
      .then(response => {
        return response.data;
      });
  }

  updatePriority(obj) {
    // const obj = {
    //   uesrID: userID,
    //   BoDID: BoDID,
    //   index: index,
    //   priority: priority,
    // };
    return axios
      .post(API_PRIORITY_URL, obj, { headers: authHeader() })
      .then(response => {
        return response.data;
      });
  }

  addParticipant(_id, roomNo) {
    const obj = {
      roomNo: roomNo,
    };
    return axios
      .post(`${API_BOD_URL}/${_id}/participants`, obj, {
        headers: authHeader(),
      })
      .then(response => {
        return response.data;
      });
  }

  clearParticipant(_id) {
    return axios
      .delete(`${API_BOD_URL}/${_id}/participants`, { headers: authHeader() })
      .then(response => {
        return response.data;
      });
  }

  getParticipants(_id) {
    return axios
      .get(`${API_BOD_URL}/${_id}/participants`, { headers: authHeader() })
      .then(response => {
        return response.data;
      });
  }
}

export default new BoDService();
