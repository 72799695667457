<template>
  <div>
    <FormulateForm v-model="values" :schema="doc.schema" @submit="onSubmit" />
  </div>
</template>

<script>
import axios from "axios";
import authHeader from "../../services/auth-header";
import OrderSchemaService from "../../services/order_schema.service";
import OrderService from "../../services/order.service";
export default {
  data() {
    return {
      values: {},
      doc: {},
    };
  },
  async created() {
    console.log("++++ Order.created");
    console.log(this.$route.params);
    const result = await OrderSchemaService.find({
      isPublished: true,
      mansion: this.$route.params.mc_code,
      directory: this.$route.params.directory,
    });
    console.log("++++ ----");
    console.log(result);
    if (result.data.length > 0) {
      this.doc = result.data[0];
    } else {
      this.$router.push({
        name: "notfound404",
        params: { mc_code: this.$route.params.mc_code },
      });
    }
  },
  methods: {
    async uploadFile(file, progress, error, options) {
      console.log("++++ uploadFile");
      console.log(options);
      try {
        const formData = new FormData();
        formData.append("file", file);
        const result = await axios.post(
          `${process.env.VUE_APP_API_ENDPOINT}/api/file/attach`,
          formData,
          { headers: authHeader() },
        );
        // const result = await fetch(options.uploadUrl, {
        //   method: "POST",
        //   body: formData,
        // });
        progress(100); // (native fetch doesn’t support progress updates)
        // return await result.json();
        console.log(result);
      } catch (err) {
        error("Unable to upload file");
      }
    },
    async onSubmit() {
      console.log("++++ onSubmit");
      console.log(this.values);
      const directory = this.$route.params.directory;
      const result = await OrderService.editByDirectroy(directory, this.values);
      console.log(result);
    },
  },
};
</script>

<style></style>
