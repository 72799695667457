<template>
  <div class="_main-container userregist" id="jumpTo">
    <div class="mainContentBox">
      <div class="mainContentBoxInner" v-if="status == 'edit'">
        <div class="pageTitleWrapper">
          <h2 class="pageTitle">伊藤忠都市開発（株）お客様情報登録</h2>
        </div>
        <div class="pageCaption">
          <span class="pageCaption-caution"
            >下記内容をご入力の上、お進みください。</span
          >
        </div>
        <div class="pp-box">
          <span class="pp-box-heading">プライバシーポリシー</span><br />

          <article id="mainContents">
            <p class="mb30">
              伊藤忠都市開発株式会社（以下「当社」といいます。）は、当社が運営するホームページ及び各書面にてご提供いただいた個人情報の取扱いに関して下記の通り定め、これを遵守いたします
            </p>

            <section class="mb60">
              <h3>1.個人情報の定義</h3>
              <p>
                個人情報とは、個人を識別できる情報のことを指し、ご住所、お名前、ご年齢、お電話番号、メールアドレス、生年月日、性別等、これらのうちの1つ又は複数を組み合わせることにより、特定の個人が識別され、または識別されうると判断される情報といたします。
              </p>
            </section>

            <section class="mb60">
              <h3>2.個人情報の取得について</h3>
              <p>
                当社は、以下に掲げる事業の遂行に必要な範囲で、個人情報を取得します。
              </p>
              <ul>
                <li class="indent03">
                  （1）不動産（土地、分譲マンション、賃貸マンション、学生ドミトリー、戸建て住宅、オフィスビル、ホテル、物流施設等）及び不動産信託受益権の売買、開発、賃貸・運営管理、仲介・コンサルティング
                </li>
                <li class="indent03">（2）第二種金融商品取引業</li>
                <li class="indent03">
                  （3）その他前記（1）（2）に付帯する事業
                </li>
              </ul>
            </section>

            <section class="mb60">
              <h3>3.個人情報の利用目的について</h3>
              <p>
                当社は、ご提供いただいた個人情報を、以下の目的で利用いたします。
              </p>
              <ul>
                <li class="indent03">（1）事業に関する契約の履行</li>
                <li class="indent03">
                  （2）事業上の申込、契約等において必要な各種案内、本人確認および信用調査
                </li>
                <li class="indent03">
                  （3）事業に関しての関係者への説明、各種行政手続等
                </li>
                <li class="indent03">（4）事業に関するアフターサービス等</li>
                <li class="indent03">
                  （5）事業に関する情報・サービスの提供のための広告活動、営業活動、顧客動向分析及びマーケティング活動等
                </li>
              </ul>
            </section>

            <section class="mb60">
              <h3>4.個人情報の第三者提供について</h3>
              <p class="mb20">
                ご提供いただいた個人情報は、当社の取引先その他関係者に当社の利用目的の達成に必要な範囲内において提供させていただきます。<br />当社は、ご提供いただいた個人情報を、法令に基づく場合を除き、ご本人の同意がない限り、第三者へ開示、提供いたしません。
              </p>
              <div class="pl30">
                <section class="mb30">
                  <h4>［1］情報の提供先</h4>
                  <ul class="indentHalf">
                    <li class="indent01">
                      ・資料請求やお問合せをされた物件の売主（共同事業主）及び販売会社に提供する場合。
                    </li>
                    <li class="indent01">
                      ・上記の利用目的の達成に必要な範囲において、当社の業務委託先または業務提携先（施工会社、管理会社、広告代理店、システム会社等）に開示する場合。
                    </li>
                    <li class="indent01">
                      ・上記の利用目的の達成に必要な範囲において、関係会社（金融機関、司法書士、不動産鑑定士等）に開示する場合。
                    </li>
                  </ul>
                </section>
                <section class="mb30">
                  <h4>［2］提供する情報の内容</h4>
                  <p class="indentHalf">
                    住所、氏名、電話番号、メールアドレス等の個人情報及び上記利用目的の達成に必要な範囲内でお客様に開示いただいた情報。
                  </p>
                </section>
                <section class="mb30">
                  <h4>［3］提供手段・方法</h4>
                  <p class="indentHalf">
                    書面の交付、郵便、口頭や電話での伝達、電子データ送信等により、必要な範囲内で行います。
                  </p>
                </section>
                <section>
                  <h4>［4］第三者への提供停止</h4>
                  <p class="indentHalf">
                    ご本人の申し出により、第三者への提供を停止いたします。下記記載のお問合せ窓口にて停止手続きを承ります。但し、提供を停止されたことにより、各種手続きの遂行や各種サービスのご提供に際し、支障をきたす場合がありますことを予めご了承ください。
                  </p>
                </section>
              </div>
            </section>

            <section class="mb60">
              <h3>5.個人情報の共同利用について</h3>
              <p class="mb20">
                当社は法令の規定に基づいて、当社が開発した分譲マンションに関し、当社にて取得したお客様の個人情報を、以下の通り共同利用させて頂く場合がございます。
              </p>
              <div class="pl30">
                <section class="mb30">
                  <h4>［1］共同利用する個人情報の項目</h4>
                  <p class="indentHalf">
                    住所、氏名、連絡先（電話番号、メールアドレス等）、名刺情報、勤務先情報、不動産情報、応対内容、その他各社事業において必要な項目
                  </p>
                </section>
                <section class="mb30">
                  <h4>［2］共同利用する者の範囲</h4>
                  <p class="indentHalf">
                    伊藤忠ハウジング株式会社<br />伊藤忠アーバンコミュニティ株式会社
                  </p>
                </section>
                <section class="mb30">
                  <h4>［3］共同利用する者の利用目的</h4>
                  <ul class="indentHalf">
                    <li class="indent01">
                      ・「3.個人情報の利用目的に関する事項」記載の目的達成のため
                    </li>
                    <li class="indent01">
                      ・「共同利用する者の範囲」に記載された各社が定める利用目的のため<br />
                      <div class="indent01">
                        伊藤忠ハウジング株式会社<br />
                        <a
                          href="https://www.itochu-housing.co.jp/privacy/"
                          target="_blank"
                          rel="noopener"
                          >https://www.itochu-housing.co.jp/privacy/</a
                        >
                      </div>
                      <div class="indent01">
                        伊藤忠アーバンコミュニティ株式会社<br />
                        <a
                          href="https://www.itc-uc.co.jp/privacy/"
                          target="_blank"
                          rel="noopener"
                          >https://www.itc-uc.co.jp/privacy/</a
                        >
                      </div>
                    </li>
                  </ul>
                </section>
                <section class="mb30">
                  <h4>［4］当該個人情報の管理について責任を有する者</h4>
                  <p class="indentHalf">
                    名　称：伊藤忠都市開発株式会社<br />
                    住　所：東京都港区赤坂二丁目９番１１号<br />
                    代表者：取締役社長　松 典男<br />
                  </p>
                </section>
              </div>
            </section>

            <section class="mb60">
              <h3>6.個人情報の管理について</h3>
              <p>
                当社は、個人情報を安全に管理するために、以下のとおり、最大の注意と必要な措置を講じます。
              </p>
              <ul>
                <li>
                  （1）当社内に個人情報管理責任者を置き、個人情報の適切かつ厳重な管理に努めます。
                </li>
                <li>
                  （2）個人情報保護に関する規則等を定め、当社社員に周知徹底を行います。
                </li>
                <li class="indent03">
                  （3）個人情報の取扱いを外部に委託する場合には、個人情報を適正に取り扱っていると認められる者を選定のうえ、個人情報保護のための必要な契約を締結し、その他法令上必要な措置を講じます。
                </li>
              </ul>
            </section>

            <section class="mb60">
              <h3>7.お問合わせ</h3>
              <div class="pl30">
                <section class="mb30">
                  <h4>［1］開示・訂正・提供停止・削除</h4>
                  <p class="indentHalf">
                    以下の窓口で個人情報の開示・訂正・提供停止・削除・第三者提供の停止手続き及び個人情報の第三者提供記録の開示手続きを承ります。手続きに際しては、ご本人確認をさせていただいた上で、個人情報保護法の定めるところにより、適切な対応を行うよう努めさせていただきます。
                  </p>
                  <ul class="mb15 indentHalf">
                    <li class="indent01">
                      ・当ホームページの「お問合せフォーム」
                    </li>
                    <li class="indent01">
                      ・各物件の販売センター及び販売担当者
                    </li>
                    <li class="indent01">・当社お問合せ窓口</li>
                  </ul>
                  <div class="indentHalf">
                    <div class="mb15 greyBox01">
                      <p class="fb">【伊藤忠都市開発株式会社】人事総務部</p>
                      <p>
                        住所：〒107-0052　東京都港区赤坂2-9-11　オリックス赤坂2丁目ビル<br />電話：03-6811-0200（受付時間：平日9:30～17:30、夏季・冬季会社休業日を除く）<br />
                      </p>
                    </div>
                    <div class="mb15 indentHalf">
                      なお、当社が法令に基づき保管義務を負う場合等、訂正・削除手続きに応じられない場合もあります。また、次の場合には、個人情報等の全部または一部及び個人情報の第三者提供記録を開示いたしません。
                      <ul class="indentHalf">
                        <li class="indent01">・ご本人確認ができない場合</li>
                        <li class="indent01">・法令に違反することになる場合</li>
                        <li class="indent01">
                          ・ご本人または第三者の生命、財産、健康等、その他の権利利益を害するおそれがあると判断した場合
                        </li>
                        <li class="indent01">
                          ・当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
                <section>
                  <h4>［2］手数料</h4>
                  <p class="indentHalf">
                    お客様に個人情報等を開示する際に、郵送料等の実費をご負担いただく場合があります。ご負担いただく場合は、別途お知らせいたします。
                  </p>
                </section>
              </div>
            </section>

            <section class="mb60">
              <h3>8.本プライバシーポリシーの改定</h3>
              <p>
                当社は、法令の改正その他必要に応じ、本プライバシーポリシーの内容を改定することがあります。最新の情報は当ホームページ内に掲載いたします。<br /><br />
              </p>
            </section>
          </article>
        </div>
        <section class="mainContent-form">
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">プライバシーポリシーへの同意</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div>
                    <input
                      type="checkbox"
                      id="agree_yes"
                      name=""
                      v-model.trim="$v.agree.$model"
                    />
                    <label for="agree_yes">同意します</label>
                  </div>
                  <div class="error" v-if="!$v.agree.sameAs">
                    同意が必要です
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">マンション名</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id="">{{ mansionName }}</label>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">部屋番号</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id="">{{ $v.values.room.$model }}号室</label>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">区分</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <div class="formTableBox_itemBox forRadioBox">
                  <div>
                    <input
                      type="radio"
                      id="owner_yes"
                      name=""
                      value="2"
                      v-model="$v.values.owner.$model"
                    />
                    <label for="owner_yes">区分所有者</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="owner_no"
                      name=""
                      value="1"
                      v-model="$v.values.owner.$model"
                    />
                    <label for="owner_no">賃借人</label>
                  </div>
                </div>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="L_NAME">本人　氏名（漢字）</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="30"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder="例:伊藤 太郎"
                    name="lastName"
                    v-model.trim="$v.values.nm.$model"
                  />
                  <div
                    class="error"
                    v-if="
                      !$v.values.nm.checkNameWithSpace ||
                        !$v.values.nm.required ||
                        !$v.values.nm.maxLength
                    "
                  >
                    姓と名の間には半角スペースを入力してください。(30文字以内)
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="L_NAME">本人　氏名（カナ）</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="30"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder="例:イトウ タロウ"
                    name="lastName"
                    v-model.trim="$v.values.nmk.$model"
                  />
                  <div
                    class="error"
                    v-if="
                      !$v.values.nmk.checkNameWithSpace ||
                        !$v.values.nmk.required ||
                        !$v.values.nmk.maxLength
                    "
                  >
                    姓と名の間には半角スペースを入力してください。(30文字以内)
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="L_NAME">本人　電話番号</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="20"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder=""
                    name="lastName"
                    v-model.trim="$v.values.tel1.$model"
                  />
                  <div
                    class="error"
                    v-if="!$v.values.tel1.isPhoneNo || !$v.values.tel1.required"
                  >
                    必須です。電話番号を入力してください。
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">本人　携帯電話</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="20"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder=""
                    name="lastName"
                    v-model.trim="$v.values.tel2.$model"
                  />
                  <div class="error" v-if="!$v.values.tel2.isPhoneNo">
                    必須です。携帯電話番号を入力してください。
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="L_NAME">本人　E-mail</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="255"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder=""
                    name="email"
                    v-model.trim="$v.values.email.$model"
                  />
                  <div
                    class="error"
                    v-if="
                      !$v.values.email.required ||
                        !$v.values.email.email ||
                        !$v.values.email.maxLength
                    "
                  >
                    必須です。emailアドレスを入力してください。（255文字以内）
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">共有者名</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="30"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder="例:伊藤 太郎"
                    name="sh_nm"
                    v-model.trim="$v.values.sh_nm.$model"
                  />
                  <div class="error" v-if="!$v.values.sh_nm.maxLength">
                    姓と名の間には半角スペースを入力してください。（30文字以内）
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">共有者名カナ</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="30"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder="例:イトウ タロウ"
                    name="sh_nmk"
                    v-model.trim="$v.values.sh_nmk.$model"
                  />
                  <div class="error" v-if="!$v.values.sh_nmk.maxLength">
                    姓と名の間には半角スペースを入力してください。（３０文字以内）
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">入居日</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="date"
                    id="L_NAME"
                    maxlength="40"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder=""
                    name="oc_date"
                    v-model.trim="$v.values.oc_date.$model"
                  />
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <!-- 入居者 -->
          <div v-for="index in 6" :key="index">
            <dl class="formTable">
              <dt class="formTable_ttl">
                <span class="icoForm-requir" v-if="index == 1">必須</span>
                <label for="L_NAME">入居者{{ index }}の氏名</label>
              </dt>
              <dd class="formTable_data">
                <dl class="formTableBox">
                  <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                    <input
                      type="text"
                      id="L_NAME"
                      maxlength="30"
                      class="m-formBox_input--name u-ime-ja"
                      placeholder="例:伊藤 太郎"
                      name="lastName"
                      v-model.trim="$v.values[`oc_${index}_nm`].$model"
                    />
                    <div
                      class="error"
                      v-if="
                        !$v.values[`oc_${index}_nm`].checkNameWithSpace ||
                          !$v.values[`oc_${index}_nm`].maxLength
                      "
                    >
                      姓と名の間には半角スペースを入力してください。（30文字以内）
                    </div>
                  </dd>
                </dl>
                <span class="formErr_inline"> </span>
              </dd>
            </dl>
            <dl class="formTable">
              <dt class="formTable_ttl">
                <span class="icoForm-requir" v-if="index == 1">必須</span>
                <label for="L_NAME">入居者{{ index }}の氏名（カナ）</label>
              </dt>
              <dd class="formTable_data">
                <dl class="formTableBox">
                  <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                    <input
                      type="text"
                      id="L_NAME"
                      maxlength="30"
                      class="m-formBox_input--name u-ime-ja"
                      placeholder="例:イトウ タロウ"
                      name="lastName"
                      v-model.trim="$v.values[`oc_${index}_nmk`].$model"
                    />
                    <div
                      class="error"
                      v-if="
                        !$v.values[`oc_${index}_nmk`].checkNameWithSpace ||
                          !$v.values[`oc_${index}_nmk`].maxLength
                      "
                    >
                      姓と名の間には半角スペースを入力してください。（30文字以内）
                    </div>
                  </dd>
                </dl>
                <span class="formErr_inline"> </span>
              </dd>
            </dl>
            <dl class="formTable">
              <dt class="formTable_ttl">
                <span class="icoForm-requir" v-if="index == 1">必須</span>
                <label for="L_NAME">入居者{{ index }}の性別</label>
              </dt>
              <dd class="formTable_data">
                <dl class="formTableBox">
                  <div class="formTableBox_itemBox forRadioBox">
                    <div>
                      <input
                        type="radio"
                        :id="`oc_${index}_sex_yes`"
                        name=""
                        value="1"
                        v-model="$v.values[`oc_${index}_sex`].$model"
                      />
                      <label :for="`oc_${index}_sex_yes`">男性</label>
                    </div>
                    <div>
                      <input
                        type="radio"
                        :id="`oc_${index}_sex_no`"
                        name=""
                        value="2"
                        v-model="$v.values[`oc_${index}_sex`].$model"
                      />
                      <label :for="`oc_${index}_sex_no`">女性</label>
                    </div>
                    <div
                      class="error"
                      v-if="
                        $v.values[`oc_${index}_sex`].$model == 0 && index == 1
                      "
                    >
                      必須です
                    </div>
                  </div>
                </dl>
                <span class="formErr_inline"> </span>
              </dd>
            </dl>
            <dl class="formTable">
              <dt class="formTable_ttl">
                <span class="icoForm-requir" v-if="index == 1">必須</span>
                <label for="L_NAME">入居者{{ index }}の生年月日</label>
              </dt>
              <dd class="formTable_data">
                <dl class="formTableBox">
                  <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                    <input
                      type="date"
                      id="L_NAME"
                      maxlength="40"
                      class="m-formBox_input--name u-ime-ja"
                      placeholder=""
                      name="oc_date"
                      v-model.trim="$v.values[`oc_${index}_birth`].$model"
                    />
                  </dd>
                </dl>
                <span
                  class="formErr_inline"
                  v-if="!$v.values[`oc_${index}_birth`].required && index == 1"
                >
                  必須です
                </span>
              </dd>
            </dl>
            <dl class="formTable" v-if="index == 1">
              <dt class="formTable_ttl">
                <span class="icoForm-requir" v-if="index == 1">必須</span>
                <label for="L_NAME">入居者{{ index }}の年収</label>
              </dt>
              <dd class="formTable_data">
                <dl class="formTableBox">
                  <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                    <input
                      type="text"
                      id="L_NAME"
                      maxlength="20"
                      class="m-formBox_input--name u-ime-ja"
                      placeholder=""
                      name="lastName"
                      v-model.trim="$v.values[`oc_${index}_income`].$model"
                    />
                    <div
                      class="error"
                      v-if="
                        !$v.values[`oc_${index}_income`].required ||
                          !$v.values[`oc_${index}_income`].decimal
                      "
                    >
                      必須です。半角数字で入力してください
                    </div>
                  </dd>
                </dl>
                <span class="formErr_inline"> </span>
              </dd>
            </dl>
          </div>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="L_NAME">緊急連絡先 1　氏名・会社等</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="255"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder=""
                    name="lastName"
                    v-model.trim="$v.values.em_1_nm.$model"
                  />
                  <div
                    class="error"
                    v-if="
                      !$v.values.em_1_nm.required ||
                        !$v.values.em_1_nm.maxLength
                    "
                  >
                    必須です。(255文字以内)
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="L_NAME">緊急連絡先 1　ご本人との関係 </label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="255"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder=""
                    name="lastName"
                    v-model.trim="$v.values.em_1_rel.$model"
                  />
                  <div
                    class="error"
                    v-if="
                      !$v.values.em_1_rel.required ||
                        !$v.values.em_1_rel.maxLength
                    "
                  >
                    必須です。（255文字以内）
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="L_NAME">緊急連絡先 1　住所</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="255"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder=""
                    name="lastName"
                    v-model.trim="$v.values.em_1_addr.$model"
                  />
                  <div
                    class="error"
                    v-if="
                      !$v.values.em_1_addr.required ||
                        !$v.values.em_1_addr.maxLength
                    "
                  >
                    必須です。（255文字以内）
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="L_NAME">緊急連絡先 1　電話番号</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="20"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder=""
                    name="lastName"
                    v-model.trim="$v.values.em_1_tel.$model"
                  />
                  <div
                    class="error"
                    v-if="
                      !$v.values.em_1_tel.required ||
                        !$v.values.em_1_tel.isPhoneNo
                    "
                  >
                    必須です。電話番号を入力してください
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">緊急連絡先 2　氏名・会社等</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="255"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder=""
                    name="lastName"
                    v-model.trim="$v.values.em_2_nm.$model"
                  />
                  <div class="error" v-if="!$v.values.em_2_nm.maxLength">
                    （255文字以内）
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">緊急連絡先 2　ご本人との関係 </label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="255"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder=""
                    name="lastName"
                    v-model.trim="$v.values.em_2_rel.$model"
                  />
                  <div class="error" v-if="!$v.values.em_2_rel.maxLength">
                    （255文字以内）
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">緊急連絡先 2　住所</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="255"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder=""
                    name="lastName"
                    v-model.trim="$v.values.em_2_addr.$model"
                  />
                  <div class="error" v-if="!$v.values.em_2_addr.maxLength">
                    （255文字以内）
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">緊急連絡先 2　電話番号</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="20"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder=""
                    name="lastName"
                    v-model.trim="$v.values.em_2_tel.$model"
                  />
                  <div class="error" v-if="!$v.values.em_2_tel.isPhoneNo">
                    電話番号を入力してください
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">資料送付先　氏名・会社等</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="255"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder=""
                    name="lastName"
                    v-model.trim="$v.values.dc_nm.$model"
                  />
                  <div class="error" v-if="!$v.values.dc_nm.maxLength">
                    （255文字以内）
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">資料送付先　ご本人との関係 </label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="255"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder=""
                    name="lastName"
                    v-model.trim="$v.values.dc_rel.$model"
                  />
                  <div class="error" v-if="!$v.values.dc_rel.maxLength">
                    （255文字以内）
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">資料送付先　住所</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="255"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder=""
                    name="lastName"
                    v-model.trim="$v.values.dc_addr.$model"
                  />
                  <div class="error" v-if="!$v.values.dc_addr.maxLength">
                    （255文字以内）
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">資料送付先　電話番号</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="20"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder=""
                    name="lastName"
                    v-model.trim="$v.values.dc_tel.$model"
                  />
                  <div class="error" v-if="!$v.values.dc_tel.isPhoneNo">
                    電話番号を入力してください
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <div class="cn-back-btn">
            <a href="javascript:void(0);" @click="onConfirm">次へ</a>
          </div>
        </section>
      </div>

      <div class="mainContentBoxInner" v-else-if="status == 'confirm'">
        <div class="pageTitleWrapper">
          <h2 class="pageTitle">お客様情報登録確認</h2>
        </div>

        <div class="pageCaption">
          <div class="contactCaptionDetail">
            入力内容をご確認ください。<br />
            よろしければ「送信」ボタンをクリックしてください。
          </div>
        </div>

        <section class="mainContent-form">
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>マンション名</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ mansionName }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>部屋番号</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ $v.values.room.$model }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>区分</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <span v-if="$v.values.owner.$model == 1">占有者</span>
                  <span v-else-if="$v.values.owner.$model == 2"
                    >区分所有者</span
                  >
                  <span v-else>その他</span>
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>本人 氏名（漢字）</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ $v.values.nm.$model }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>本人 氏名（カナ）</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ $v.values.nmk.$model }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>本人 電話番号</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ $v.values.tel1.$model }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>本人 携帯電話</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ $v.values.tel2.$model }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>共有者名</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ $v.values.sh_nm.$model }}
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>共有者名カナ</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ $v.values.sh_nmk.$model }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>入居日</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ formatDispDate($v.values.oc_date.$model) }}
                </div>
              </div>
            </dd>
          </dl>

          <!-- 入居者 -->
          <div v-for="index in 6" :key="index">
            <dl class="formTable">
              <dt class="formTable_ttl">
                <label>入居者{{ index }}の氏名</label>
              </dt>
              <dd class="formTable_data">
                <div class="formTableBox">
                  <div class="formTableBox_itemBox">
                    {{ $v.values[`oc_${index}_nm`].$model }}
                  </div>
                </div>
              </dd>
            </dl>
            <dl class="formTable">
              <dt class="formTable_ttl">
                <label>入居者{{ index }}の氏名（カナ）</label>
              </dt>
              <dd class="formTable_data">
                <div class="formTableBox">
                  <div class="formTableBox_itemBox">
                    {{ $v.values[`oc_${index}_nmk`].$model }}
                  </div>
                </div>
              </dd>
            </dl>
            <dl class="formTable">
              <dt class="formTable_ttl">
                <label>入居者{{ index }}の性別</label>
              </dt>
              <dd class="formTable_data">
                <div class="formTableBox">
                  <div class="formTableBox_itemBox">
                    <span v-if="$v.values[`oc_${index}_sex`].$model == 1"
                      >男性</span
                    >
                    <span v-if="$v.values[`oc_${index}_sex`].$model == 2"
                      >女性</span
                    >
                  </div>
                </div>
              </dd>
            </dl>
            <dl class="formTable">
              <dt class="formTable_ttl">
                <label>入居者{{ index }}の生年月日</label>
              </dt>
              <dd class="formTable_data">
                <div class="formTableBox">
                  <div class="formTableBox_itemBox">
                    {{ formatDispDate($v.values[`oc_${index}_birth`].$model) }}
                  </div>
                </div>
              </dd>
            </dl>
            <dl class="formTable" v-if="index == 1">
              <dt class="formTable_ttl">
                <label>入居者{{ index }}の年収</label>
              </dt>
              <dd class="formTable_data">
                <div class="formTableBox">
                  <div class="formTableBox_itemBox">
                    {{ $v.values[`oc_${index}_income`].$model }}
                  </div>
                </div>
              </dd>
            </dl>
          </div>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>緊急連絡先1 氏名・会社等</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ $v.values.em_1_nm.$model }}
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>緊急連絡先1 御本人との関係</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ $v.values.em_1_rel.$model }}
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>緊急連絡先1 住所</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ $v.values.em_1_addr.$model }}
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>緊急連絡先1 電話番号</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ $v.values.em_1_tel.$model }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>緊急連絡先2 氏名・会社等</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ $v.values.em_2_nm.$model }}
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>緊急連絡先2 御本人との関係</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ $v.values.em_2_rel.$model }}
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>緊急連絡先2 住所</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ $v.values.em_2_addr.$model }}
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>緊急連絡先2 電話番号</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ $v.values.em_2_tel.$model }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>資料送付先 氏名・会社等</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ $v.values.dc_nm.$model }}
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>資料送付先 御本人との関係</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ $v.values.dc_rel.$model }}
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>資料送付先 住所</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ $v.values.dc_addr.$model }}
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>資料送付先 電話番号</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ $v.values.dc_tel.$model }}
                </div>
              </div>
            </dd>
          </dl>

          <div class="cn-back-btn">
            <a href="javascript:void(0);" @click="status = 'edit'">戻る</a>
          </div>
          <div class="cn-back-btn">
            <a href="javascript:void(0);" @click="onPost">送信</a>
          </div>
        </section>
      </div>

      <div class="mainContentBoxInner" v-else-if="status == 'complete'">
        <div class="pageTitleWrapper">
          <h2 class="pageTitle">お客様情報登録完了</h2>
        </div>

        <div class="pageCaption">
          <div class="contactCaptionDetail">ご登録ありがとうございました。</div>
        </div>

        <!-- <section class="mainContent-form">
          <div class="cn-back-btn">
            <router-link
              :to="{
                name: 'top',
                params: { mc_code: mcCode },
              }">→iuc SALON　ログインページへ</router-link>
          </div>
        </section> -->
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import { required, email, decimal, sameAs, maxLength } from "vuelidate/lib/validators";
import { PhoneNumberUtil } from "google-libphonenumber";
import ExtApiService from "../services/ext_api.service";
const clonedeep = require("lodash/cloneDeep");
import UserService from "../services/user.service";
export default {
  name: "user-registration",
  data() {
    return {
      user_id: null,
      status: "edit",
      agree: false,
      mansionName: "",
      values: {
        bldg_nm: "",
        room: "",
        owner: 0,
        nm: "",
        nmk: "",
        tel1: "",
        tel2: "",
        email: "",
        sh_nm: "",
        sh_nmk: "",
        oc_date: null,
        oc_1_nm: "",
        oc_1_nmk: "",
        oc_1_sex: 0,
        oc_1_birth: null,
        oc_1_industry: null,
        oc_1_income: null,
        oc_2_nm: "",
        oc_2_nmk: "",
        oc_2_sex: 0,
        oc_2_birth: null,
        oc_3_nm: "",
        oc_3_nmk: "",
        oc_3_sex: 0,
        oc_3_birth: null,
        oc_4_nm: "",
        oc_4_nmk: "",
        oc_4_sex: 0,
        oc_4_birth: null,
        oc_5_nm: "",
        oc_5_nmk: "",
        oc_5_sex: 0,
        oc_5_birth: null,
        oc_6_nm: "",
        oc_6_nmk: "",
        oc_6_sex: 0,
        oc_6_birth: null,
        em_1_nm: "",
        em_1_rel: "",
        em_1_addr: "",
        em_1_tel: "",
        em_2_nm: "",
        em_2_rel: "",
        em_2_addr: "",
        em_2_tel: "",
        dc_nm: "",
        dc_rel: "",
        dc_addr: "",
        dc_tel: "",
      },
    };
  },
  validations() {
    const checkNameWithSpace = value => {
      if (value) {
        return value.indexOf(" ") > -1 || value.indexOf("　") > -1;
      } else {
        return true;
      }
    };

    const isPhoneNo = value => {
      if (value) {
        const util = PhoneNumberUtil.getInstance();
        try {
          const phoneNumber = util.parseAndKeepRawInput(value, "JP");
          return util.isValidNumber(phoneNumber);
        } catch (err) {
          return false;
        }
      } else {
        return true;
      }
    };

    return {
      agree: { sameAs: sameAs(() => true) },
      values: {
        bldg_nm: {},
        room: {},
        owner: {},
        nm: {
          required,
          checkNameWithSpace,
          maxLength: maxLength(30),
        },
        nmk: {
          required,
          checkNameWithSpace,
          maxLength: maxLength(30),
        },
        tel1: { isPhoneNo, required },
        tel2: { isPhoneNo },
        email: {
          required,
          email,
          maxLength: maxLength(255),
        },
        sh_nm: {
          maxLength: maxLength(30),
        },
        sh_nmk: {
          maxLength: maxLength(30),
        },
        oc_date: {},
        oc_1_nm: {
          checkNameWithSpace,
          maxLength: maxLength(30),
        },
        oc_1_nmk: {
          checkNameWithSpace,
          maxLength: maxLength(30),
        },
        oc_1_sex: {},
        oc_1_birth: { required },
        oc_1_industry: {},
        oc_1_income: { required, decimal },
        oc_2_nm: {
          checkNameWithSpace,
          maxLength: maxLength(30),
        },
        oc_2_nmk: {
          checkNameWithSpace,
          maxLength: maxLength(30),
        },
        oc_2_sex: {},
        oc_2_birth: {},
        oc_3_nm: {
          checkNameWithSpace,
          maxLength: maxLength(30),
        },
        oc_3_nmk: {
          checkNameWithSpace,
          maxLength: maxLength(30),
        },
        oc_3_sex: {},
        oc_3_birth: {},
        oc_4_nm: {
          checkNameWithSpace,
          maxLength: maxLength(30),
        },
        oc_4_nmk: {
          checkNameWithSpace,
          maxLength: maxLength(30),
        },
        oc_4_sex: {},
        oc_4_birth: {},
        oc_5_nm: {
          checkNameWithSpace,
          maxLength: maxLength(30),
        },
        oc_5_nmk: {
          checkNameWithSpace,
          maxLength: maxLength(30),
        },
        oc_5_sex: {},
        oc_5_birth: {},
        oc_6_nm: {
          checkNameWithSpace,
          maxLength: maxLength(30),
        },
        oc_6_nmk: {
          checkNameWithSpace,
          maxLength: maxLength(30),
        },
        oc_6_sex: {},
        oc_6_birth: {},
        em_1_nm: {
          required,
          maxLength: maxLength(255),
        },
        em_1_rel: {
          required,
          maxLength: maxLength(255),
        },
        em_1_addr: {
          required,
          maxLength: maxLength(255),
        },
        em_1_tel: { isPhoneNo, required },
        em_2_nm: {
          maxLength: maxLength(255),
        },
        em_2_rel: {
          maxLength: maxLength(255),
        },
        em_2_addr: {
          maxLength: maxLength(255),
        },
        em_2_tel: { isPhoneNo },
        dc_nm: {
          maxLength: maxLength(255),
        },
        dc_rel: {
          maxLength: maxLength(255),
        },
        dc_addr: {
          maxLength: maxLength(255),
        },
        dc_tel: { isPhoneNo },
      },
    };
  },
  async mounted() {
    this.user_id = this.$route.params.user_id;
    console.log({ user_id: this.user_id });
    const result = await UserService.findMansionByUsetID(this.user_id);
    const user = result.data;
    console.log({ user });
    if (!user) {
      this.$router.push({
        name: "notfound404",
      });
      return;
    }
    this.mansionName = user.mansion.name;
    this.values.bldg_nm = user.mansion.name;
    // this.values.bldg_nm = user.mansion._id;
    this.values.room = user.roomNo;
  },
  methods: {
    formatName: function(date) {
      if (date) {
        return date.replaceAll("　", " ");
      } else {
        return date;
      }
    },
    formatDispDate: function(date) {
      if (date) {
        return date.replaceAll("-", "/");
      } else {
        return date;
      }
    },
    scrollTop: function() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    onConfirm() {
      console.log("onConfirm");
      console.log(this.values);
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log(this.$v.$invalid);
        console.log("ERROR");
      } else {
        console.log("ok");
        this.status = "confirm";
        this.scrollTop();
      }
    },
    async onPost() {
      console.log("onPost");
      console.log(this.values);
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        console.log("OK");

        const postValue = clonedeep(this.values);
        postValue.nm = this.formatName(postValue.nm);
        postValue.nmk = this.formatName(postValue.nmk);
        postValue.oc_1_nm = this.formatName(postValue.oc_1_nm);
        postValue.oc_1_nmk = this.formatName(postValue.oc_1_nmk);
        postValue.oc_2_nm = this.formatName(postValue.oc_2_nm);
        postValue.oc_2_nmk = this.formatName(postValue.oc_2_nmk);
        postValue.oc_3_nm = this.formatName(postValue.oc_3_nm);
        postValue.oc_3_nmk = this.formatName(postValue.oc_3_nmk);
        postValue.oc_4_nm = this.formatName(postValue.oc_4_nm);
        postValue.oc_4_nmk = this.formatName(postValue.oc_4_nmk);
        postValue.oc_5_nm = this.formatName(postValue.oc_5_nm);
        postValue.oc_5_nmk = this.formatName(postValue.oc_5_nmk);
        postValue.oc_6_nm = this.formatName(postValue.oc_6_nm);
        postValue.oc_6_nmk = this.formatName(postValue.oc_6_nmk);
        postValue.oc_date = this.formatDispDate(postValue.oc_date);
        postValue.oc_1_birth = this.formatDispDate(postValue.oc_1_birth);
        postValue.oc_2_birth = this.formatDispDate(postValue.oc_2_birth);
        postValue.oc_3_birth = this.formatDispDate(postValue.oc_3_birth);
        postValue.oc_4_birth = this.formatDispDate(postValue.oc_4_birth);
        postValue.oc_5_birth = this.formatDispDate(postValue.oc_5_birth);
        postValue.oc_6_birth = this.formatDispDate(postValue.oc_6_birth);
        console.log(postValue);
        // (category, sei, mei, mansionName,mansion, roomNo, result)
        try {
          const result = await ExtApiService.icraft(this.values);
          this.status = "complete";
          this.scrollTop();
          console.log(result);
        } catch (error) {
          console.log("ERROR");
          this.status = "complete";
          console.log(error);
          this.scrollTop();
        }
      }
    },
  },
};
</script>

<style>
.flex-wrapper {
  display: flex;
}
</style>
