<template>
  <div class="mainContentBox">
    <div class="mainContentBoxInner">
      <div class="pageTitleWrapper rijitool">
        <h2 class="pageTitle">理事専用ツール</h2>
      </div>

      <div class="pageCaption">
        理事会専用コンテンツです。<br />オンライン理事会への参加およびトークルームをご利用いただけます。<br />
        <span class="caution">※理事・幹事様以外はご利用いただけません。</span>
      </div>

      <div class="article-section">
        <div class="article-section-text">
          <h3 class="blocktitle">オンライン理事会</h3>
          <div class="blocktext">
            <span class="blocktext-text"
              >理事会をオンラインで開催する場合はこちらから参加いただけます。</span
            >
            <div class="blocktext-buttons">
              <span class="blocktext-btn"
                ><a @click="onOpenRijikai">{{ remoteTitle }}</a></span
              >
            </div>
          </div>
        </div>

        <div class="article-section-img">
          <img src="/i-salon/assets/img/common/rijitool_fig01.jpg" alt="" />
        </div>
      </div>

      <div class="article-section">
        <div class="article-section-text">
          <h3 class="blocktitle">トークルーム</h3>
          <div class="blocktext">
            <span class="blocktext-text"
              >理事会専用のトークルームです。日程調整、事前打合せ等にご活用ください。</span
            >
            <div class="blocktext-buttons">
              <span class="blocktext-btn"
                ><a @click="isChatView = true">トークルーム</a></span
              >
            </div>
          </div>
        </div>

        <div class="article-section-img">
          <img src="/i-salon/assets/img/common/rijitool_fig02.jpg" alt="" />
        </div>
      </div>

      <div
        class="article-section"
        v-if="isPermission('Admin') || isPermission('MP')"
      >
        <div class="article-section-text">
          <h3 class="blocktitle">理事会一覧</h3>
          <div class="blocktext">
            <div class="blocktext-buttons">
              <span class="blocktext-btn"
                ><a @click="onBOD">理事会一覧</a></span
              >
            </div>
          </div>
        </div>

        <div class="article-section-img">
          <img src="/assets/img/common/rijitool_fig02.jpg" alt="" />
        </div>
      </div>

      <div class="top-back-btn">
        <a @click="$router.push({ name: 'top' })">トップページへ</a>
      </div>
    </div>

    <div id="chat" v-if="isChatView">
      <!-- <div class="chat-title"> -->
      <!-- <div class="chatclose">閉じる</div> -->
      <h2 class="chat-heading" style="position: relative">トークルーム</h2>
      <div class="chatclose chatopen" @click="isChatView = false">閉じる</div>
      <!-- </div> -->
      <ChatView
        class="messages"
        @allowmessage="onAllowessage"
        :room="currentBOD._id"
        :name="currentUser.roomNo"
        :dispName="currentUser.roomNo"
        :roomName="currentBOD.title"
        mode="user"
      ></ChatView>
      <ChatInput
        allow_status="allow_status"
        :room="currentBOD._id"
        :name="currentUser.roomNo"
        :dispName="currentUser.roomNo"
      ></ChatInput>
    </div>
  </div>
</template>

<script>
import store from "../../store";
import ChatView from "@/components/ChatView.vue";
import ChatInput from "@/components/ChatInput.vue";
import BODService from "../../services/bod.service";
export default {
  components: { ChatView, ChatInput },
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (currentUser && !currentUser.permissions.includes("Director")) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  data() {
    return {
      isChatView: false,
      allow_status: "",
      chatRoomTile: "テスト",
      currentBOD: {},
    };
  },
  computed: {
    remoteTitle() {
      return this.currentBOD.remote ? this.currentBOD.remote.title : "";
    },
  },
  async mounted() {
    console.log("+++ mounted");
    this.currentBOD = await BODService.item("current");
    console.log("this.currentBOD", this.currentBOD);
  },
  methods: {
    onBOD() {
      this.$router.push({
        name: "bod",
      });
    },
    onAllowessage: function(value) {
      // console.log(`onAllowessage=${value}`);
      this.allow_status = value;
    },
    onOpenRijikai() {
      if (this.currentBOD.remote) {
        window.open(this.currentBOD.remote.url, "_blank");
      }
    },
  },
};
</script>

<style></style>
