import axios from "axios";
import authHeader from "./auth-header";

const API_URL = `${process.env.VUE_APP_API_ENDPOINT}/api/archive`;

class ArchiveService {
  list(params) {
    return axios.get(`${API_URL}/list`, {
      headers: authHeader(),
      params: params,
    });
  }

  create(data) {
    return axios.post(`${API_URL}/item`, data, { headers: authHeader() });
  }
  read(id) {
    return axios.get(`${API_URL}/item/${id}`, { headers: authHeader() });
  }

  update(data) {
    const _id = data._id;
    return axios.post(`${API_URL}/item/${_id}`, data, {
      headers: authHeader(),
    });
  }

  delete(id) {
    return axios.delete(`${API_URL}/item/${id}`, { headers: authHeader() });
  }

  count() {
    return axios.get(`${API_URL}/count`, {
      headers: authHeader(),
    });
  }
}

export default new ArchiveService();
