<template>
  <div id="meeting-detail">
    <template v-if="isExistMeeting && isLoaded"
      ><div class="main main-sokai">
        <section class="main-section">
          <h3 class="section-title green-left-head">
            総会
            <span class="btn_to_list">
              <router-link
                :to="{
                  name: 'meeting',
                  params: { mc_code: currentUser.mansion._id },
                }"
                >総会の詳細に戻る</router-link
              ></span
            >
          </h3>
        </section>
      </div>
      <div class="frame-wrapper__video">
        <iframe
          :src="streaming"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
      <div class="frame-wrapper__et">
        <iframe
          src=""
          frameborder="1"
          name="etouhyou"
          style="width:100%;"
          sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
        >
        </iframe>
        <div class="et_reload" @click="onReload">
          <span class="et_reload__inner">再読込</span>
        </div>
      </div>
      <form
        ref="epost"
        name="epost"
        id="epost"
        method="POST"
        action="https://zm.etohyo.com/cmn_sso.aspx"
        target="etouhyou"
      >
        <input
          type="hidden"
          name="UserID"
          :value="md5hex(currentUser.loginID)"
        />
        <!-- <input type="submit" value="e投票iframe" /> -->
      </form>
      <div class="main">
        <section class="main-section buttons">
          <p class="section-block center" v-if="isPermission('Admin')">
            <router-link
              target="_blank"
              :to="{
                name: 'admin-chat',
                params: { id: meeting._id, mc_code: currentUser.mansion._id },
              }"
            >
              <button
                class="bt bt-green chatopenBtn"
                style="display: flex;flex-direction: column;align-items: center;"
              >
                <span style="font-size :1.7rem;">質疑チャット管理</span
                ><span style="font-size :1.0rem;">
                  ※別ウィンドウで開きます。</span
                >
              </button>
            </router-link>
          </p>
          <p class="section-block center" v-else>
            <button class="bt bt-green chatopenBtn" @click="isChatView = true">
              質疑チャット
            </button>
          </p>
          <p class="section-block center">
            <button
              class="bt bt-green bt-green-sub pdfopenBtn"
              @click="onOpen()"
              :disabled="!isExistFile"
            >
              議案の確認
            </button>
          </p>
          <p class="section-block center">
            <button class="bt bt-green chatopenBtn" @click="isTalkRoom = true">
              公開トークルーム
            </button>
          </p>
          <p class="section-block center" v-if="isPermission('Admin')">
            <router-link
              :to="{
                name: 'adminMeetingParticipants',
                params: { id: meeting._id, mc_code: currentUser.mansion._id },
              }"
              target="_blank"
            >
              <button
                class="bt bt-green chatopenBtn"
                style="display: flex;flex-direction: column;align-items: center;"
              >
                <span style="font-size :1.7rem;">参加者</span
                ><span style="font-size :1.0rem;">
                  ※別ウィンドウで開きます。</span
                >
              </button>
            </router-link>
          </p>
          <!--      <p class="section-block center"><button class="bt bt-gray">途中退席</button></p>-->
        </section>
        <!--/.main-section-->
      </div>
      <IconMenu />
      <!--/.main-->
    </template>

    <div id="chat" v-if="isChatView">
      <!-- <div class="chat-title"> -->
      <!-- <div class="chatclose">閉じる</div> -->
      <h2 class="chat-heading" style="position:relative;">
        質疑チャット
      </h2>
      <div class="chatclose chatopen" @click="isChatView = false">閉じる</div>
      <!-- </div> -->
      <ChatView
        class="messages"
        @allowmessage="onAllowessage"
        :room="privateRoom"
        :name="currentUser.roomNo"
        :dispName="currentUser.roomNo"
        roomName="質疑チャット"
        mode="user"
        :filter="currentUser.roomNo"
      ></ChatView>
      <ChatInput
        allow_status="allow_status"
        :room="privateRoom"
        :name="currentUser.roomNo"
        :dispName="currentUser.username"
        roomName="質疑応答チャット"
        to="admin"
      ></ChatInput>
    </div>
    <div id="chat" v-if="isTalkRoom">
      <!-- <div class="chat-title"> -->
      <!-- <div class="chatclose">閉じる</div> -->
      <h2 class="chat-heading" style="position:relative;">
        公開トークルーム
      </h2>
      <div class="chatclose chatopen" @click="isTalkRoom = false">閉じる</div>
      <!-- </div> -->
      <OpenChatView
        class="messages"
        @allowmessage="onAllowessage"
        :room="openRoom"
        :name="currentUser.roomNo"
        :dispName="currentUser.roomNo"
        roomName="公開トークルーム"
        mode="user"
      ></OpenChatView>
      <OpenChatInput
        v-if="isPermission('Admin')"
        allow_status="allow_status"
        :room="openRoom"
        name="admin"
        :dispName="currentUser.username"
        roomName="公開トークルーム"
      ></OpenChatInput>
    </div>
    <div id="pdfContainer" v-show="isPDFView">
      <iframe
        src=""
        type="application/pdf"
        width="100%"
        height="100%"
        style="overflow: auto;"
        id="pdfView"
      ></iframe>
      <div class="chatclose chatopen" @click="isPDFView = false">閉じる</div>
    </div>
  </div>
</template>

<script>
import MeetingService from "../../services/meeting.service";
// eslint-disable-next-line
import FileService from "../../services/file.service";
import ChatView from "@/components/ChatView.vue";
import OpenChatView from "@/components/OpenChatView.vue";
import ChatInput from "@/components/ChatInput.vue";
import OpenChatInput from "@/components/OpenChatInput.vue";
import IconMenu from "@/components/IconMenu.vue";
import meetingService from "../../services/meeting.service";

import store from "../../store";

export default {
  name: "Meeting",
  components: { ChatView, ChatInput, OpenChatView, OpenChatInput, IconMenu },
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (currentUser && !currentUser.permissions.includes("OwnedNonResident")) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  computed: {
    openRoom() {
      console.log("Meeding-detail openRoom");
      console.log(this.meeting._id);
      return `open-room_${this.meeting._id}`;
    },
    privateRoom() {
      console.log("Meeding-detail privateRoom");
      console.log(this.meeting._id);
      return `admin-private_${this.meeting._id}`;
    },
    room() {
      return this.mode == "admin" ? "meeting_src" : "meeting";
    },
    isExistMeeting: function() {
      console.log("isExistMeeting");
      console.log(this.meeting);
      return this.meeting != null;
    },
    isExistFile: function() {
      return (
        this.meeting.files.length > 0 && this.meeting.files[0].name != undefined
      );
    },
    streaming: function() {
      return this.meeting.streaming != "" ? this.meeting.streaming : "";
    },
    mode: function() {
      return this.isPermission("Admin") ? "admin" : "user";
    },
  },
  created() {
    // Attach onpopstate event handler
    const self = this;
    // eslint-disable-next-line
    window.onpopstate = function(event) {
      self.isChatView = false;
      self.isTalkRoom = false;
    };
  },
  watch: {
    isChatView: function(val) {
      if (val) {
        // window.scrollTo(0, 0);
        // history.pushState(null, null, document.URL);
        // document.body.style.overflow = "hidden";
      } else {
        // console.log("-");
        // document.body.style.overflow = "auto";
      }
    },
    isTalkRoom: function(val) {
      if (val) {
        // window.scrollTo(0, 0);
        // document.body.style.overflow = "hidden";
        // history.pushState(null, null, document.URL);
      } else {
        // console.log("-");
        // document.body.style.overflow = "auto";
      }
    },
    isPDFView: function(val) {
      if (val) {
        // window.scrollTo(0, 0);
        // document.body.style.overflow = "hidden";
        // history.pushState(null, null, document.URL);
      } else {
        // console.log("-");
        // document.body.style.overflow = "auto";
      }
    },
  },
  mounted: async function() {
    // console.log("++++ meeting mouted");
    // // console.log(this);
    // document.body.style.overflow = "auto";
    // MeetingService.item("current").then(
    //   (docs) => {
    //     // console.log("MeetingSummary:mounted");
    //     // console.log(docs);
    //     if (docs) {
    //       this.meeting = docs;
    //     }
    //   },
    //   (error) => {
    //     // console.log(error);
    //   }
    // );
    await this.$nextTick();
    this.meeting = await MeetingService.item("current");
    this.isLoaded = true;
    await this.$nextTick();
    // console.log("ref--");
    // console.log(this.$refs.epost);
    if (this.$refs.epost != undefined) {
      this.$refs.epost.submit();
      meetingService.addParticipant(this.meeting._id, this.currentUser.roomNo);
    }
  },
  data: function() {
    return {
      isLoaded: false,
      isChatView: false,
      isPDFView: false,
      isTalkRoom: false,
      allow_status: "",
      meeting: {
        title: "",
        desc: "",
        date: "",
        start_at: "",
        end_at: "",
        location: "",
        streaming: "",
        files: [],
        is_published: false,
      },
    };
  },
  methods: {
    onReload() {
      this.$refs.epost.submit();
    },
    createhtml() {
      var html = `
      <form
        name="form"
        method="post"
        action="https://zm.etohyo.com/cmn_sso.aspx"
      >
        <input
          type="hidden"
          name="UserID"
          value="${this.md5hex(this.currentUser.username)}"
        />
        <input type="submit" value="e投票" />
      </form>
      `;

      var blob = new Blob([html], { type: "text/html" });
      return URL.createObjectURL(blob);
    },
    onAllowessage: function(value) {
      // console.log(`onAllowessage=${value}`);
      this.allow_status = value;
    },
    onOpen() {
      // console.log(this.meeting.files[0].name);
      const self = this;
      if (this.meeting.files.length > 0 && this.meeting.files[0].name) {
        const fileName = this.meeting.files[0].name;
        FileService.download("meeting", this.meeting._id, fileName).then(
          response => {
            // return response.data;
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: "application/pdf" }),
            );
            document.getElementById("pdfView").src =
              "/pdfjs/web/viewer.html?file=" + url;
            self.isPDFView = true;
          },
        );
      }
    },
  },
};
</script>

<style>
#chat {
  width: 100%;
  position: fixed;
  height: 100%;
  background-color: #e7edfd;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  z-index: 2;
}

@media (min-width: 769px) {
  #chat {
    width: 50%;
    right: 0;
    left: auto;
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.2);
    max-width: 460px;
  }

  #chat-view,
  .inputWrapper {
    max-width: 100% !important;
  }
}

#chat {
  -webkit-animation: SlideIn 0.5s backwards;
  animation: SlideIn 0.5s backwards;
}

@-webkit-keyframes SlideIn {
  0% {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes SlideIn {
  0% {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

#pdfContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100%;
}

.messages {
  flex-grow: 1;
}
</style>
