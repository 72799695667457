<template>
  <div class="wrapper">
    <div class="hdr">
      <div class="hdr-wrapper">
        <div class="logo">
          <a href="/"
            ><img src="/i-salon/assets/img/common/logo-main.svg" alt="iuc SALON"
          /></a>
        </div>
      </div>
    </div>
    <!--/.hdr-->

    <div class="main" style="padding-top: 0px;" v-if="status == 'edit'">
      <section id="password-reset" class="main-section">
        <h3 class="section-title green-left-head">パスワード再設定</h3>
        <div class="faq-wrapper">
          <h4>再設定する新しいパスワードを入力してください。</h4>
        </div>

        <section class="mainContent-form">
          <dl
            class="formTable form-group"
            :class="{ 'form-group--error': $v.password.$error }"
          >
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="">パスワード</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <input
                    type="text"
                    class="form__input u-input--w50p u-ime-en"
                    v-model.trim="$v.password.$model"
                    id="pass"
                    maxlength="128"
                    name=""
                    placeholder="8〜20文字 半角英数"
                  />
                </div>
                <div class="error" v-if="!$v.password.required">必須です</div>
                <div class="error" v-if="!$v.password.passwordValidator">
                  8〜20文字 半角英数です
                  {{ $v.password.$params.minLength.min }} letters.
                </div>
              </div>
            </dd>
          </dl>
          <dl
            class="formTable form-group"
            :class="{ 'form-group--error': $v.confirmation.$error }"
          >
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="">確認入力</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <input
                    type="text"
                    class="form__input u-input--w50p u-ime-en"
                    v-model.trim="$v.confirmation.$model"
                    id="reinput"
                    maxlength="128"
                    name=""
                    placeholder="確認入力"
                  />
                </div>
                <div class="error" v-if="!$v.confirmation.sameAsPassword">
                  確認入力の内容が違います
                </div>
              </div>
            </dd>
          </dl>
          <!---->
          <div class="cn-back-btn">
            <a @click="onPost">パスワード設定</a>
          </div>
        </section>

        <!---->
      </section>
    </div>

    <div class="main" v-else>
      <section class="main-section">
        <h3 class="section-title green-left-head">パスワードを変更しました</h3>

        <div class="faq-wrapper">
          <!-- <span class="caution"
            >登録したメールアドレスに届く「パスワード再設定URL」をクリックしパスワードを再設定して、改めてログインください。</span
          > -->
          <br /><br />
          <span class="backLink">
            <router-link
              style="display: block; color: #000562; text-decoration: underline"
              :to="{
                name: 'logout',
              }"
            >
              →iuc SALON ログインページへ</router-link
            ></span
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { required, sameAs, helpers } from "vuelidate/lib/validators";

import UserService from "../services/user.service";
const passwordValidator = helpers.regex("alpha", /^[0-9a-zA-Z\d]{8,20}$/);

export default {
  name: "PasswordReminder",
  data() {
    return {
      status: "edit",
      isExists: false,
      confirmation: "",
      password: "",
    };
  },
  validations: {
    password: {
      required,
      passwordValidator,
    },
    confirmation: {
      sameAsPassword: sameAs("password"),
    },
  },
  mounted: async function() {
    console.log("++++ PasswordReset.mounded");
    const email = this.$route.query.email.replace(/ /g, "+");
    const key = this.$route.query.key;
    console.log(email);
    console.log(key);
    try {
      const result = await UserService.getReminder(email, key);
      console.log(result);
      if (result.status != "200") {
        this.$router.push({
          name: "notfound404",
          params: { mc_code: this.currentUser.mansion._id },
        });
      }
    } catch (error) {
      console.error("えらーやで");
      console.log(error);
      this.$router.push({
        name: "notfound404",
      });
    }
  },
  methods: {
    async onPost() {
      console.log("onPost");
      console.log("submit!");
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        // do your submit logic here
        const email = this.$route.query.email.replace(/ /g, "+");
        const key = this.$route.query.key;
        const result = await UserService.resetPassword(
          email,
          key,
          this.password,
        );
        console.log(result);
        this.status = "comlete";
        if (result.status == "200") {
          console.log("OK");
        } else {
          console.log("ERROR");
        }
        console.log("OK");
      }
    },
  },
};
</script>

<style></style>
