import axios from "axios";
import authHeader from "./auth-header";

const API_URL = `${process.env.VUE_APP_API_ENDPOINT}/api/file/`;
// console.log(API_URL);
class FileService {
  download(category, id, fileName) {
    return axios.get(API_URL + `${category}/${id}/${fileName}`, {
      responseType: "blob",
      dataType: "binary",
      headers: authHeader(),
    });
  }
  download2(category, id, fileName) {
    return axios.get(API_URL + `${category}/${id}/${fileName}`, {
      headers: authHeader(),
    });
  }
}

export default new FileService();
