<template>
  <div id="bod-top" class="main rijitool">
    <p class="btn-item" v-if="isPermission('Chairman')">
      <router-link
        :to="{
          name: 'admin_bod_edit',
          params: { id: 'create', mc_code: currentUser.mansion._id },
        }"
      >
        <button type="button" class="el-button el-button--primary">
          <i class="el-icon-circle-plus"></i><span>新規理事会</span>
        </button></router-link
      >
    </p>
    <BODList
      title="開催中の理事会"
      mode="current"
      :isEditor="isPermission('Admin') || isPermission('MP')"
    ></BODList>

    <BODList
      title="調整中の理事会"
      mode="adjust"
      :isEditor="isPermission('Admin') || isPermission('MP')"
    ></BODList>

    <BODList
      title="開催予定の理事会"
      mode="next"
      :isEditor="isPermission('Admin') || isPermission('MP')"
    ></BODList>

    <BODList
      title="過去の理事会"
      mode="normal"
      :isEditor="isPermission('Admin') || isPermission('MP')"
    ></BODList>

    <!--/.main-section-->
    <IconMenu />
    <!--/.main-section-->
  </div>
</template>

<script>
import BODList from "@/components/BODList";
import IconMenu from "@/components/IconMenu.vue";
import store from "../../store";

export default {
  components: { BODList, IconMenu },
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (currentUser && !currentUser.permissions.includes("Director")) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
};
</script>

<style></style>
