<template>
  <div class="main shoko">
    <section class="main-section">
      <p class="btn-item" v-if="isPermission('Admin')" style="display: none">
        <router-link
          :to="{
            name: 'admin_archive_edit',
            params: { mc_code: currentUser.mansion._id, id: 'create' },
          }"
        >
          <button type="button" class="el-button el-button--primary">
            <i class="el-icon-circle-plus"></i><span>新規書類追加</span>
          </button>
        </router-link>
      </p>

      <h3 class="section-title green-left-head">書庫（規約・議事録 他）</h3>
      <div class="pageCaption">
        マンションに関する書類等を閲覧・ダウンロードできます。<br />※印は外部サイトリンクです（別ウインドウが開きます）
      </div>
    </section>
    <!--/.main-section-->

    <section
      class="main-section buttons cloumBtns"
      v-if="isPermission('Admin')"
    >
      <span class="exlinks">
        <div v-for="(item, key) in cstDocumentTypes" :key="key">
          <p
            class="section-block center archive"
            v-if="cstDocuments.indexOf(key) > -1"
          >
            <router-link
              :to="{
                name: 'cst',
                params: { doc_type: key },
              }"
              target="_blank"
            >
              <button class="bt bt-blue">{{ item.name }}※</button>
            </router-link>
          </p>
        </div>
      </span>
      <p
        class="section-block center"
        v-for="(item, index) in categories"
        :key="index"
        :class="`archive${item._id}`"
      >
        <router-link
          :to="{
            name: 'archive-list',
            params: { category: item._id, mc_code: currentUser.mansion._id },
          }"
        >
          <button class="bt bt-green">{{ item.title }}</button>
        </router-link>
      </p>
    </section>
    <section class="main-section buttons cloumBtns" v-else>
      <span class="exlinks">
        <div v-for="(item, key) in cstDocuments" :key="key">
          <p class="section-block center archive">
            <router-link
              :to="{
                name: 'cst',
                params: { doc_type: item },
              }"
              target="_blank"
            >
              <button class="bt bt-blue">
                {{ cstDocumentTypes[item].name }}※
              </button>
            </router-link>
          </p>
        </div>
      </span>
      <p
        class="section-block center"
        v-for="(item, index) in norm_categories"
        :key="index"
        :class="`archive${item._id}`"
      >
        <router-link
          :to="{
            name: 'archive-list',
            params: { category: item._id, mc_code: currentUser.mansion._id },
          }"
        >
          <button class="bt bt-green">
            {{ item.title }}
          </button>
        </router-link>
      </p>
    </section>
    <!--/.main-section-->

    <!-- <IconMenu /> -->
    <!--/.main-section-->
    <div class="top-back-btn">
      <a href="https://iuc-salon.mansion-concierge.com/">トップページへ</a>
    </div>
  </div>
  <!--/.main-->
</template>

<script>
// import store from "../../store";
import CategoryService from "../../services/category.service";
import CstService from "../../services/cst.service";
import ArchiveService from "../../services/archive.service";
export default {
  name: "archive-index",
  data: function() {
    return {
      categories: [],
      counter: [],
      cstDocumentTypes: [],
      cstDocuments: [],
    };
  },
  computed: {
    norm_categories() {
      return this.categories.filter((elem, index) => {
        return this.counter[index] != 0;
      });
    },
  },
  mounted: async function() {
    try {
      console.log("+++ mountd");
      const count = await ArchiveService.count();
      this.counter = count.data;
      console.log(this.counter);
      const res = await CategoryService.get();
      console.log(res);
      this.categories = res.data;
      const res2 = await CstService.documentTypes();
      this.cstDocumentTypes = res2.data;
      console.log(this.cstDocumentTypes);

      const res3 = await CstService.visibleDocuments();
      this.cstDocuments = res3.data;
      console.log(this.cstDocuments);
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style scoped>
.bt-blue {
  background-color: #76a5b2;

  display: inline-block;
  border: none;
  border-radius: 5px;
  box-sizing: border-box;
  min-width: 100%;
  min-height: 44px;
  color: #fff;
  font-size: 140%;
  font-weight: bold;
}
</style>
