<template>
  <el-main>
    <div class="eventrsv eventdetail">
      <h3 class="section-title green-left-head title-margin">
        イベント予約管理
      </h3>
      <p>
        <el-button type="primary" @click="$router.back()">戻る</el-button>
        <el-button type="warning" @click="onExport">ダウンロード</el-button>
      </p>
      <h3 class="section-title">{{ doc.title }}</h3>
      <div
        class="schedule-table"
        v-for="(schedule, index) in doc.schedules"
        :key="`schedule_${index}`"
      >
        <div>{{ schedule.date | moment("YYYY/MM/DD") }}</div>
        <table>
          <thead>
            <tr>
              <th>No.</th>
              <th>時間</th>
              <th>部屋番号</th>
              <th>名前</th>
              <th>メールアドレス</th>
              <th>電話番号</th>
              <th>備考</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(koma, komaIndex) in schedule.komas">
              <tr
                v-for="roomIndex in koma.capacity"
                :key="`reserve_${index}_${komaIndex}_${roomIndex}`"
                :class="{ bl: roomIndex == koma.capacity }"
              >
                <td>{{ roomIndex }}</td>
                <td>{{ koma.start }}〜{{ koma.end }}</td>
                <template v-if="koma.rooms[roomIndex - 1]">
                  <td class="under-line">
                    <p
                      @click="
                        openReserveEdit(
                          koma.rooms[roomIndex - 1]
                            ? koma.rooms[roomIndex - 1]
                            : null,
                          index,
                          komaIndex,
                        )
                      "
                    >
                      {{
                        koma.rooms[roomIndex - 1]
                          ? koma.rooms[roomIndex - 1].roomNo
                          : "○"
                      }}
                    </p>
                  </td>
                  <td>
                    {{ koma.rooms[roomIndex - 1].sei }}&nbsp;{{
                      koma.rooms[roomIndex - 1].mei
                    }}
                  </td>
                  <td>{{ _.get(koma.rooms[roomIndex - 1], "user.email") }}</td>
                  <td>{{ koma.rooms[roomIndex - 1].phone }}</td>
                  <td class="tenten">{{ koma.rooms[roomIndex - 1].memo }}</td>
                </template>
                <template v-else>
                  <td class="under-line">
                    <p
                      @click="
                        openReserveEdit(
                          koma.rooms[roomIndex - 1]
                            ? koma.rooms[roomIndex - 1]
                            : null,
                          index,
                          komaIndex,
                        )
                      "
                    >
                      {{
                        koma.rooms[roomIndex - 1]
                          ? koma.rooms[roomIndex - 1].roomNo
                          : "○"
                      }}
                    </p>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </template>

                <!-- <td class="tc">{{ koma.capacity - koma.rooms.length }}</td> -->
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <!--  -->
    <el-dialog title="予約内容" :visible.sync="dialogFormVisible">
      <el-form :model="currentKoma" :label-width="formLabelWidth">
        <el-form-item label="部屋番号">
          <!-- <el-row :gutter="20" type="flex"
            ><el-col :span="16"> -->
          <!-- <el-input v-model="currentKoma.roomNo"></el-input> -->
          <el-select
            v-model="currentKoma.roomNo"
            placeholder="Select"
            @change="onSelectedRoom"
          >
            <el-option
              v-for="item in rooms"
              :key="item.roomNo"
              :label="item.roomNo"
              :value="item.roomNo"
            >
            </el-option>
          </el-select>
          <!-- </el-col> -->
          <!-- <el-col :span="4">
              <el-button type="warning" @click="onCheckRoomNo"
                >部屋番号チェック</el-button
              >
            </el-col> -->
          <!-- </el-row> -->
        </el-form-item>
        <el-form-item label="名前">
          <el-row :gutter="20" type="flex">
            <el-col :span="8"
              ><el-input v-model="currentKoma.sei" placeholder="姓"></el-input
            ></el-col>
            <el-col :span="8"
              ><el-input v-model="currentKoma.mei" placeholder="名"></el-input
            ></el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="ふりがな">
          <el-row :gutter="20" type="flex">
            <el-col :span="8"
              ><el-input
                v-model="currentKoma.seiKana"
                placeholder="せい"
              ></el-input
            ></el-col>
            <el-col :span="8"
              ><el-input
                v-model="currentKoma.meiKana"
                placeholder="めい"
              ></el-input
            ></el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="メールアドレス">
          {{ currentKoma.email }}
        </el-form-item>
        <el-form-item label="電話番号">
          <el-input v-model="currentKoma.phone"></el-input>
        </el-form-item>
        <el-form-item label="備考">
          <el-input v-model="currentKoma.memo" type="textarea"></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="onCancel">キャンセル</el-button>
        <el-button
          type="primary"
          @click="onSubmit"
          :disabled="!currentKoma.user"
          >{{ currentKoma._id ? "更新" : "登録" }}</el-button
        >
        <el-button type="warning" @click="onRemoveKoma" v-if="currentKoma._id"
          >削除</el-button
        >
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import store from "../../../store";
import PreviewService from "../../../services/preview.service";
import PreviewReserveService from "../../../services/preview_reserve.service";
import UserService from "../../../services/user.service";
import moment from "moment";
export default {
  name: "previewIndex",
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (
      currentUser &&
      !currentUser.permissions.includes("Admin") &&
      !currentUser.permissions.includes("EventAdmin")
    ) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  data() {
    return {
      dialogFormVisible: false,
      currentPreviewID: null,
      currentScheduleIndex: null,
      currentKomaIndex: null,
      currentKoma: {},
      doc: {},
      formLabelWidth: "120px",
      rooms: [],
    };
  },
  computed: {
    sortCaretAsc: function() {
      return function(prop) {
        if (this.sortProp === prop && this.sortOrder === "asc") {
          return "sort-caret ascending active";
        }
        return "sort-caret ascending";
      };
    },
    sortCaretDesc: function() {
      return function(prop) {
        if (this.sortProp === prop && this.sortOrder === "desc") {
          return "sort-caret descending active";
        }
        return "sort-caret descending";
      };
    },
  },
  async created() {
    console.log("%c++++created", "color:orange");
    console.log(this.$route.params.id);
    if (this.$route.params.id) {
      const res = await PreviewReserveService.findInPreview(
        this.$route.params.id,
      );
      this.doc = res.data;
    }
    console.log("this.doc", this.doc);
    const res2 = await UserService.getRoonNosInMansion(this.doc.mansion);
    this.rooms = res2.data;
    console.log("this.rooms", this.rooms);
  },
  async mounted() {
    await this.$nextTick();
    // this.reloadData();
  },
  methods: {
    async onExport() {
      var a = document.createElement("a");
      var content = this.createDownloadContent();
      var title = this.doc.title;
      var mansion = this.mansion.name;
      console.log("content", content);
      var blob = new Blob([content], { type: "text/plain" });
      var blobUrl = window.URL.createObjectURL(blob);
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = blobUrl;
      a.download = `EVENT_${mansion}_${title}_${moment().format(
        "YYYYMMDD-hhmmss",
      )}.csv`;
      var event = new MouseEvent("click");
      a.dispatchEvent(event);
    },
    createDownloadContent() {
      let temp =
        "日付,No.,開始,終了,部屋番号,姓,名,かな姓,かな名,email,電話番号,備考\n";
      for (let index = 0; index < this.doc.schedules.length; index++) {
        const schedule = this.doc.schedules[index];
        const date = moment(schedule.date).format("YYYY/MM/DD");
        // console.log("schedule", schedule);
        // console.log("schedule.komas", schedule.komas);
        for (
          let komaIndex = 0;
          komaIndex < schedule.komas.length;
          komaIndex++
        ) {
          const koma = schedule.komas[komaIndex];
          console.log("koma", koma);
          console.log("koma.rooms", koma.rooms);
          for (let roomIndex = 0; roomIndex < koma.capacity; roomIndex++) {
            const room = koma.rooms[roomIndex];
            console.log("room", room);
            if (room) {
              temp += `${date},${roomIndex + 1},${koma.start},${koma.end},${
                room.roomNo
              },${room.sei},${room.mei},${room.seiKana},${room.meiKana},${
                room.email
              },${room.phone},${room.memo}\n`;
            } else {
              temp += `${date},${roomIndex + 1},${koma.start},${
                koma.end
              },,,,,,,,\n`;
            }
          }
        }
      }
      return temp;
    },
    onSelectedRoom(roomNo) {
      console.log(roomNo);
      const item = this.rooms.find(e => e.roomNo == roomNo);
      console.log(item);
      this.currentKoma.email = item.email ? item.email : "";
      this.currentKoma.user = item._id ? item._id : "";
    },
    onCancel() {
      this.dialogFormVisible = false;
      this.reloadData();
    },
    async onCheckRoomNo() {
      console.log(this.doc);
      const res = await UserService.findInMansion(
        this.doc.mansion,
        this.currentKoma.roomNo,
      );
      console.log(res);
      if (res.data) {
        this.$set(this.currentKoma, "user", res.data._id);
        this.$set(this.currentKoma, "email", res.data.email);
        // this.currentKoma.user = res.data._id;
        // this.currentKoma.email = res.data.email;
        console.log(this.currentKoma);

        this.$message.success("部屋番号は存在します");
      } else {
        this.$message.error("部屋番号が存在しません");
      }
    },
    async onSubmit() {
      console.log("onSumit", this.currentKoma);
      if (this.currentKoma._id) {
        try {
          const res = await PreviewReserveService.update(this.currentKoma);
          if (res.response) {
            console.log("res", res.response);
            // error
            this.$message.error(res.response.data);
            this.reloadData();
          } else {
            console.log("res", res);
            this.$message.success("更新しました");
            this.dialogFormVisible = false;
            this.reloadData();
          }
        } catch (error) {
          console.log(error);
          this.$message.error(error);
        }
      } else {
        try {
          this.currentKoma.komaIndex = this.currentKomaIndex;
          this.currentKoma.scheduleIndex = this.currentScheduleIndex;
          this.currentKoma.mansion = this.currentUser.mansion._id;
          this.currentKoma.preview = this.$route.params.id;
          const res = await PreviewReserveService.create(this.currentKoma);
          if (res.response) {
            console.log("res", res.response);
            // error
            this.$message.error(res.response.data);
            this.reloadData();
          } else {
            console.log("res", res);
            this.$message.success("登録しました");
            this.dialogFormVisible = false;
            this.reloadData();
          }
        } catch (error) {
          console.log(error);
          this.$message.error(error);
        }
      }
    },
    async onRemoveKoma() {
      if (confirm("削除しても良いですか？")) {
        const res = PreviewReserveService.remove(this.currentKoma._id, true);
        console.log(res);
        this.dialogFormVisible = false;
        this.$message.success("削除しました");
        this.reloadData();
      }
    },
    openReserveEdit(obj, scheduleIndex, komaIndex) {
      console.log(`openReserveEdit`, obj);
      console.log("scheduleIndex", scheduleIndex);
      console.log("komaIndex", komaIndex);
      this.currentScheduleIndex = scheduleIndex;
      this.currentKomaIndex = komaIndex;
      if (obj != null) {
        this.currentKoma = obj;
      } else {
        this.currentKoma = {};
      }
      // this.currentPreviewID = id;
      this.dialogFormVisible = true;
    },
    async getKoma(previewid, scheduleIndex, komaIndex) {
      const result = PreviewReserveService.find({
        q: `{"preview":"${previewid}","scheduleIndex":${scheduleIndex},"komaIndex":${komaIndex}}`,
      });
      console.log("getKoma", result);
      return result;
    },
    async reloadData() {
      const res = await PreviewReserveService.findInPreview(
        this.$route.params.id,
      );
      this.doc = res.data;
    },
    onCreate() {
      this.$router.push({ name: "admin-event-edit", params: { id: null } });
    },
    async sortChange(prop, direction) {
      console.log("sortChange");
      // { field: 'asc', test: -1 }
      const order = {};
      order[prop] = direction;
      console.log(`${prop}/${direction}`);
      await this.find({ page: 1, order, mansion: this.$route.params.mc_code });
    },
    renderHeader(h, e) {
      console.log("renderHeader");
      return h("div", null, [
        h("span", { class: "caret-wrapper" }, [
          h(
            "i",
            {
              class: this.sortCaretAsc(e.column.property),
              on: {
                click: () => this.sortChange(e.column.property, 1),
              },
            },
            null,
          ),
          h(
            "i",
            {
              class: this.sortCaretDesc(e.column.property),
              on: {
                click: () => this.sortChange(e.column.property, -1),
              },
            },
            null,
          ),
        ]),
        e.column.label,
      ]);
    },
    async find(query) {
      console.log("+++++ find");
      console.log(query);
      this.result = await PreviewService.list(query);
      // this.result = res.data;
      console.log(this.result);
    },
    async onCurrentChange(page) {
      // console.log(page);
      this.result = await PreviewService.list({
        mansion: this.$route.params.mc_code,
        page: page,
      });
      console.log(this.result);
    },
    onEdit(id) {
      console.log(id);
      this.$router.push({
        name: "admin-event-edit",
        params: { id: id },
      });
    },
    onManage(id) {
      this.$router.push({
        name: "admin-event-detail",
        params: { id: id },
      });
    },
    async onRemove(id) {
      console.log(id);
      if (!confirm("削除しても良いですか？")) {
        return;
      }
      try {
        const res = await PreviewService.remove(id);
        console.log(res);
        this.$message.success("削除しました");
        this.reloadData();
      } catch (error) {
        console.log(error.message);
        this.$message.error("削除できませんでした");
      }
    },
  },
};
</script>

<style>
.koma {
  display: flex;
  flex-direction: row;
}

.schedule-table {
  font-size: 140%;
  margin-bottom: 10px;
}

.schedule-table table {
  margin: 20px auto;
  width: 96%;
}

.schedule-table table th {
  white-space: nowrap;
  text-align: center;
}

.schedule-table table td {
  padding: 8px 16px;
  text-align: center;
}

.schedule-table table td:last-child {
  text-align: left;
}

.tabale-header {
  display: flex;
  flex-direction: row;
}

.te {
  text-align: end;
}

.tc {
  text-align: center;
}

.under-line {
  text-decoration: underline;
  cursor: pointer;
}

.bl {
  border-bottom: solid 1px black;
  margin-bottom: 10px;
}

.tenten {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}
</style>
