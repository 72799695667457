import axios from "axios";
import authHeader from "./auth-header";

const API_URL = `${process.env.VUE_APP_API_ENDPOINT}/api/sub_category/`;
const API_BOD_URL = `${process.env.VUE_APP_API_ENDPOINT}/api/bod-sub_category/`;

class SubCategoryService {
  get(category_id) {
    return axios.get(API_URL, {
      params: { category_id: category_id },
      headers: authHeader(),
    });
  }
  add(category_id, title) {
    return axios.post(
      API_URL,
      { category_id: category_id, title: title },
      { headers: authHeader() },
    );
  }
  getBoD(category_id) {
    return axios.get(API_BOD_URL, {
      params: { category_id: category_id },
      headers: authHeader(),
    });
  }
  addBoD(category_id, title) {
    return axios.post(
      API_BOD_URL,
      { category_id: category_id, title: title },
      { headers: authHeader() },
    );
  }
}

export default new SubCategoryService();
