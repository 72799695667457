<template>
  <el-main>
    <h3 class="section-title green-left-head title-margin">
      申し込みフォーム管理
    </h3>
    <p>
      <el-button type="primary" icon="el-icon-circle-plus" @click="onEdit()"
        >新規申し込みフォーム</el-button
      >
    </p>
    <el-table :data="result.data.docs" stripe style="width: 100%">
      <el-table-column label="公開" width="80"
        ><template slot-scope="scope">
          <span style="margin-left: 10px">{{
            scope.row.isPublished ? "公開" : "非公開"
          }}</span>
        </template></el-table-column
      >
      <el-table-column prop="name" label="タイトル"> </el-table-column>
      <el-table-column label="フォーム" width="200"
        ><template slot-scope="scope">
          <span>
            {{ scope.row.directory }}
          </span>
        </template></el-table-column
      >
      <!-- <el-table-column prop="start_at" label="開始"> </el-table-column>
      <el-table-column prop="end_at" label="終了"> </el-table-column> -->
      <el-table-column fixed="right" label="操作" fit="true" width="120">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="onEdit(scope.row._id)"
            >編集</el-button
          >
          <!-- <el-button type="text" size="small" @click="onRmove(scope.row._id)"
            >削除</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="result.totalDocs"
      :page-size="result.limit"
      @current-change="onCurrentChange"
    >
    </el-pagination>
  </el-main>
</template>

<script>
import store from "../../../store";
import OrderSchemaService from "../../../services/order_schema.service";
export default {
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (currentUser && !currentUser.permissions.includes("Admin")) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  data() {
    return {
      result: { data: [] },
    };
  },
  async mounted() {
    // await this.$nextTick();
    this.result = await OrderSchemaService.list({
      mansion: this.$route.params.mc_code,
    });
    console.log(this.result.data);
  },
  methods: {
    async onCurrentChange(page) {
      // console.log(page);
      this.result = await OrderSchemaService.list({
        mansion: this.$route.params.mc_code,
        page: page,
      });
    },
    onEdit(id) {
      console.log(id);
      this.$router.push({
        name: "admin_order-schema_edit",
        params: { id: id, mc_code: this.currentUser.mansion._id },
      });
    },
  },
};
</script>

<style></style>
