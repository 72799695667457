var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main kanri-page"},[_vm._m(0),_c('el-col',{attrs:{"span":24}},[_c('section',{staticClass:"main-section gray-top-border"},[_c('el-form',{ref:"form_target",attrs:{"model":_vm.doc,"label-width":"200px"}},[_c('el-form-item',{attrs:{"label":"タイトル","prop":"title"}},[_c('el-input',{attrs:{"type":"text","placeholder":"タイトル"},model:{value:(_vm.doc.title),callback:function ($$v) {_vm.$set(_vm.doc, "title", $$v)},expression:"doc.title"}})],1),_c('el-form-item',{attrs:{"label":"掲載期間","prop":"publishRange"}},[_c('el-date-picker',{attrs:{"type":"daterange","range-separator":"〜","start-placeholder":"掲載開始日","end-placeholder":"掲載終了日"},model:{value:(_vm.doc.publishRange),callback:function ($$v) {_vm.$set(_vm.doc, "publishRange", $$v)},expression:"doc.publishRange"}})],1),_c('el-form-item',{attrs:{"label":"ターゲット"}},[_c('div',{staticClass:"group"},_vm._l((_vm.targets),function(item,index){return _c('el-checkbox',{key:("targets-" + index),style:({
                display: index > _vm.targets.length - 3 ? 'none' : 'inline-block',
              }),model:{value:(item.checked),callback:function ($$v) {_vm.$set(item, "checked", $$v)},expression:"item.checked"}},[_vm._v(_vm._s(item.label))])}),1),_c('el-checkbox',{model:{value:(_vm.targets[_vm.targets.length - 2].checked),callback:function ($$v) {_vm.$set(_vm.targets[_vm.targets.length - 2], "checked", $$v)},expression:"targets[targets.length - 2].checked"}},[_vm._v("賃借人も含める")]),_c('el-checkbox',{model:{value:(_vm.targets[_vm.targets.length - 1].checked),callback:function ($$v) {_vm.$set(_vm.targets[_vm.targets.length - 1], "checked", $$v)},expression:"targets[targets.length - 1].checked"}},[_vm._v("管理員も含める")])],1)],1),_vm._l((_vm.doc.schedules),function(item,index){return _c('el-card',{key:("sch_" + index),staticClass:"box-card"},[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v("イベント実施日の枠設定")]),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(index != 0),expression:"index != 0"}],staticStyle:{"float":"right","padding":"3px 0","color":"red"},attrs:{"type":"text"},on:{"click":function($event){return _vm.onDeleteSchedule(index)}}},[_vm._v("削除")]),_c('el-button',{staticStyle:{"float":"right","padding":"3px 0"},attrs:{"type":"text"},on:{"click":function($event){return _vm.onAddSchedule(index)}}},[_vm._v("コピー")])],1),_c('el-form',{attrs:{"model":_vm.doc,"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"イベント実施日","prop":"value1"}},[_c('el-date-picker',{attrs:{"type":"date","placeholder":"イベント実施日"},model:{value:(item.date),callback:function ($$v) {_vm.$set(item, "date", $$v)},expression:"item.date"}})],1),_vm._l((item.komas),function(item2,index2){return _c('el-form-item',{key:("koma_" + index + "_" + index2),attrs:{"label":("コマ" + (index2 + 1))}},[_c('el-time-select',{staticStyle:{"width":"150px"},attrs:{"placeholder":"開始時間","picker-options":{
                start: '08:00',
                step: '00:15',
                end: '18:00',
              }},model:{value:(item2.start),callback:function ($$v) {_vm.$set(item2, "start", $$v)},expression:"item2.start"}}),_vm._v(" 〜 "),_c('el-time-select',{staticStyle:{"width":"150px"},attrs:{"placeholder":"終了時間","picker-options":{
                start: '08:00',
                step: '00:15',
                end: '18:00',
                minTime: item2.start,
              }},model:{value:(item2.end),callback:function ($$v) {_vm.$set(item2, "end", $$v)},expression:"item2.end"}}),_vm._v(" 枠数: "),_c('el-input-number',{attrs:{"min":1,"max":10,"placeholder":"枠数"},model:{value:(item2.capacity),callback:function ($$v) {_vm.$set(item2, "capacity", $$v)},expression:"item2.capacity"}}),_c('el-button',{directives:[{name:"show",rawName:"v-show",value:(index2 != 0),expression:"index2 != 0"}],staticStyle:{"float":"right"},attrs:{"type":"danger"},on:{"click":function($event){return _vm.onDeleteKoma(index, index2)}}},[_vm._v("削除")])],1)}),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-circle-plus"},on:{"click":function($event){return _vm.onAddKoma(index)}}},[_vm._v("コマ追加")])],1)],2)],1)}),_c('el-form',[_c('el-form-item',[_c('el-button',{attrs:{"type":"primary","icon":"el-icon-circle-plus"},on:{"click":function($event){return _vm.onAddSchedule()}}},[_vm._v("イベント実施日追加")])],1)],1),_c('el-divider'),_c('el-form',{ref:"form_target3",attrs:{"model":_vm.doc,"label-width":"200px"}},[_c('el-form-item',{attrs:{"label":"キャンセル期限","prop":"cancelDay"}},[_c('el-input-number',{attrs:{"min":0,"max":100},model:{value:(_vm.doc.cancelDay),callback:function ($$v) {_vm.$set(_vm.doc, "cancelDay", $$v)},expression:"doc.cancelDay"}}),_vm._v(" 日まで ")],1),_c('el-form-item',{attrs:{"label":"残り枠表示"}},[_c('el-switch',{attrs:{"active-color":"#13ce66","inactive-color":"#ff4949","active-text":"する","inactive-text":"しない"},model:{value:(_vm.doc.visibleRemaining),callback:function ($$v) {_vm.$set(_vm.doc, "visibleRemaining", $$v)},expression:"doc.visibleRemaining"}})],1),_c('el-divider'),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("この内容で登録")]),_c('el-button',{on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("キャンセル")])],1)],1)],2)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',{staticClass:"page-title"},[_c('strong',[_vm._v("イベント登録")])])}]

export { render, staticRenderFns }