<template>
  <div class="mainContentBox">
    <div class="mainContentBoxInner">
      <div class="pageTitleWrapper">
        <h3 class="section-title green-left-head">お知らせ</h3>
      </div>

      <div id="news" class="box-item-wrapper">
        <dl id="" class="box-item-list">
          <dt class="box-item-list-item">
            <span class="date">{{
              info.registration_at | moment("YYYY/MM/DD")
            }}</span>
            <span v-html="createTag(info.label)"></span>
            <span class="text">{{ info.title }}</span>
          </dt>

          <dd class="news-detail-text">
            <div v-html="info.body" class="ck-content info-body"></div>
            <!-- <pre class="news-detail-text">{{ info.body }}</pre> -->
            <br /><br />
            <span class="news-sche-box">
              <span style="font-weight: bold" v-if="info.is_schedule"
                >スケジュール詳細：</span
              >
              <span class="news-detail-sche-detail"
                >{{
                  info.calender_range.start | moment("YYYY/MM/DD HH:mm")
                }}〜{{ info.calender_range.end | moment("YYYY/MM/DD HH:mm") }}
              </span>
            </span>
          </dd>
        </dl>
      </div>

      <div class="top-back-btn" v-if="info.url != ''">
        <a :href="info.url" :target="info.url_target ? '_blank' : ''">
          {{ info.url_title }}
        </a>
      </div>

      <div class="top-back-btn">
        <router-link
          :to="{
            name: 'information-1',
            params: { mc_code: currentUser.mansion._id },
          }"
          >お知らせ一覧へ</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import InfoService from "../../services/info.service";
// import IconMenu from "@/components/IconMenu.vue";
export default {
  // components: { IconMenu },
  name: "info-detail",
  data() {
    return {
      info: {
        target: "",
        target_user: "",
        title: "",
        body: "",
        kakomi: "",
        registration_at: "",
        display_range: { start: "", end: "" },
        url: "",
        url_target: false,
        url_title: "",
        label: "",
        is_top: false,
        is_schedule: false,
        calender_text: "",
        calender_range: { start: "", end: "" },
        is_dispTime: true,
        notification_mail: false,
        notification_push: false,
      },
    };
  },
  async beforeRouteUpdate(to, from, next) {
    try {
      const response = await InfoService.findOne(to.params.id);
      console.log(response);
      if (response.data.length > 0) {
        this.info = response.data[0];
      }
    } catch (error) {
      console.log(error);
      this.$router.push({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    }
    next();
  },
  async mounted() {
    console.log("info datail +++ mounted");
    await this.$nextTick();
    try {
      const response = await InfoService.findOne(this.$route.params.id);
      console.log(response);
      if (response.data.length > 0) {
        this.info = response.data[0];
      }
    } catch (error) {
      console.log(error);
      this.$router.push({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    }
  },
};
</script>

<style></style>
