<template>
  <el-main id="meeting-edit">
    <h3 class="section-title green-left-head title-margin">
      総会&nbsp;{{ param == "create" ? "新規作成" : "編集" }}
    </h3>
    <el-col :span="20">
      <el-form ref="form" :model="meeting" label-width="20%">
        <el-form-item label="公開"
          ><el-switch
            v-model="meeting.is_published"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="公開"
            inactive-text="非公開"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="総会名">
          <el-input v-model="meeting.title"></el-input>
        </el-form-item>
        <el-form-item label="説明">
          <el-input v-model="meeting.desc" type="textarea" :rows="2"></el-input>
        </el-form-item>
        <el-form-item label="日付">
          <el-date-picker
            v-model="meeting.date"
            type="date"
            placeholder="日付選択"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="開始時間">
          <el-col :span="6">
            <el-time-select
              v-model="meeting.start_at"
              :picker-options="{
                start: '00:00',
                step: '00:15',
                end: '23:45',
              }"
              placeholder="開始時間"
            >
            </el-time-select
          ></el-col>
        </el-form-item>
        <el-form-item label="終了時間">
          <el-col :span="6">
            <el-time-select
              v-model="meeting.end_at"
              :picker-options="{
                start: '00:00',
                step: '00:15',
                end: '23:45',
              }"
              placeholder="終了時間"
            >
            </el-time-select>
          </el-col>
        </el-form-item>
        <el-form-item label="場所">
          <el-input v-model="meeting.location"></el-input>
        </el-form-item>
        <el-form-item label="ビデオURL">
          <el-input v-model="meeting.streaming"></el-input>
        </el-form-item>
        <!-- <el-form-item label="議案ファイル">
          <el-input v-model="meeting.file"></el-input>
        </el-form-item> -->
        <el-form-item label="議案ファイル">
          <el-upload
            class="upload-demo"
            :action="uploadApi"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :on-exceed="handleExceed"
            :file-list="meeting.files"
            :on-success="onSuccess"
            :headers="header"
            :multiple="false"
            :data="{ id: $route.params.id, category: 'meeting' }"
            accept=".pdf"
          >
            <el-button size="small" type="primary">ファイル選択</el-button>
            <!-- <div slot="tip" class="el-upload__tip">
              jpg/png files with a size less than 500kb
            </div> -->
          </el-upload>
        </el-form-item>
        <el-form-item label="参加者">
          <ul
            class="box-item-list"
            style="display: inline-block;flex-wrap: wrap;"
          >
            <li
              v-for="(participant, index) in meeting.participants"
              :key="index"
              style="display:flex;"
            >
              <div style="width:150px">
                {{ participant.timestamp | moment("YYYY/MM/DD HH:mm:ss") }}
              </div>
              <div>{{ participant.roomNo }} 号室</div>
            </li>
          </ul>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">登録</el-button>
          <el-button type="warning" @click="onRemove">削除</el-button>
          <el-button
            @click="
              $router.push({
                name: 'admin_meeting_index',
                params: { mc_code: currentUser.mansion._id },
              })
            "
            >キャンセル</el-button
          >
        </el-form-item>
      </el-form>
    </el-col>
  </el-main>
</template>

<script>
import MeetingService from "../../../services/meeting.service";
import authHeader from "../../../services/auth-header";

import store from "../../../store";
export default {
  name: "meeting-edit",
  mounted: async function() {
    console.log("mounted");
    console.log(this.$route.params);
    if (this.$route.params.id != "create") {
      try {
        const result = await MeetingService.item(this.$route.params.id);
        if (result == null) {
          this.$router.push({
            name: "notfound404",
            params: { mc_code: this.currentUser.mansion._id },
          });
        } else {
          this.meeting = result;
        }
      } catch (error) {
        console.log(error);
        this.$router.push({
          name: "notfound404",
          params: { mc_code: this.currentUser.mansion._id },
        });
      }
      // store.dispatch("meeting/item", this.$route.params.id).then(
      //   // eslint-disable-next-line
      //   docs => {
      //     // console.log(docs);
      //     this.meeting = docs;
      //   },
      //   // eslint-disable-next-line
      //   error => {
      //     // console.log(error);
      //   },
      // );
    }
  },
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (currentUser && !currentUser.permissions.includes("Admin")) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  data() {
    return {
      uploadApi: `${process.env.VUE_APP_API_ENDPOINT}/api/file/upload`,
      dialogVisible: false,
      meeting: {
        title: "",
        desc: "",
        date: "",
        start_at: "",
        end_at: "",
        location: "",
        streaming: "",
        files: [],
        is_published: false,
      },
    };
  },
  computed: {
    header: function() {
      return authHeader();
    },
    param: function() {
      return this.$route.params.id;
    },
  },
  methods: {
    onRemove: async function() {
      console.log(`remove=${this.meeting._id}`);
      if (window.confirm("削除します。よろしいですか？か？")) {
        try {
          const result = await MeetingService.remove(this.meeting._id);
          console.log(result);
          this.$router.push({
            name: "admin_meeting_index",
            params: { mc_code: this.currentUser.mansion._id },
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
    onSubmit: function() {
      // console.log("onSubmit");
      // console.log(this.meeting);
      MeetingService.update(this.meeting).then(
        // eslint-disable-next-line
        docs => {
          // console.log(docs);
          this.$router.push({
            name: "admin_meeting_index",
            params: { mc_code: this.currentUser.mansion._id },
          });
        },
        // eslint-disable-next-line
        error => {
          // console.log(error);
          this.$message.error("更新できませんでした");
        },
      );
    },
    // eslint-disable-next-line
    handleRemove(file, fileList) {
      // console.log(file, fileList);
      // console.log("remove");
      // console.log(file);
      // console.log(fileList);
      this.meeting.files = [];
      // console.log(this.meeting.files);
    },
    // eslint-disable-next-line
    handlePreview(file) {
      // console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `The limit is 3, you selected ${
          files.length
        } files this time, add up to ${files.length + fileList.length} totally`,
      );
    },
    // eslint-disable-next-line
    beforeRemove(file, fileList) {
      // eslint-disable-line
      return this.$confirm(`削除します。よろしいですか？`);
    },
    // eslint-disable-next-line
    onSuccess(file, fileList) {
      // console.log("onSuccess");
      // console.log(file);
      // console.log(fileList);
      // console.log(this.meeting.files);
      this.meeting.files = [];
      this.meeting.files.push(fileList);
    },
  },
};
</script>

<style>
input[type="text"] {
  width: 100%;
}
</style>
