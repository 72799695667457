<template>
  <el-main id="bod-edit" class="shoko">
    <h3 class="section-title green-left-head">
      書庫&emsp;ファイル{{ $route.params.id == "create" ? "登録" : "編集" }}
    </h3>
    <el-col :span="24">
      <el-form ref="form" :model="form" label-width="25%" :rules="rules">
        <el-form-item label="表示ページ" prop="category">
          <el-select v-model="form.category" placeholder="表示ページ選択">
            <el-option
              v-for="item in categories"
              :key="item._id"
              :label="item.title"
              :value="item._id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="表示対象">
          <div class="group">
            <el-checkbox
              v-model="item.checked"
              v-for="(item, index) in targets"
              :key="index"
              :style="{
                display: index > targets.length - 4 ? 'none' : 'inline-block',
              }"
              >{{ item.label }}</el-checkbox
            >
          </div>
          <el-checkbox v-model="targets[targets.length - 3].checked"
            >賃借人</el-checkbox
          >
          <el-checkbox v-model="targets[targets.length - 2].checked"
            >管理員</el-checkbox
          ><el-checkbox v-model="targets[targets.length - 1].checked"
            >その他</el-checkbox
          >
          <el-checkbox v-model="form.is_individual">個別</el-checkbox>
        </el-form-item>
        <el-form-item label="個別表示対象部屋番号" v-if="form.is_individual">
          <el-input
            v-model="rooms"
            type="textarea"
            :rows="2"
            placeholder="個別を選択した場合の表示対象部屋番号（例：301,302,507）※カンマ区切り"
          ></el-input>
        </el-form-item>
        <el-form-item label="タイトル">
          <el-input v-model="form.title"></el-input>
        </el-form-item>

        <el-form-item label="サブカテゴリ" prop="sub_category">
          <el-select
            :disabled="form.category == null"
            v-model="form.sub_category"
            filterable
            default-first-option
            placeholder="サブカテゴリ選択"
          >
            <el-option
              v-for="item in sub_categories"
              :key="item._id"
              :label="item.title"
              :value="item._id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-input
                v-model="new_sub_category"
                placeholder="新しいカテゴリを追加"
              ></el-input>
            </el-col>
            <el-col :span="11" :gutter="20">
              <el-button type="primary" @click="onAddSubcateogry"
                >サブカテゴリ追加</el-button
              >
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="表示登録日" prop="resgist_at">
          <el-col :span="15">
            <el-date-picker
              v-model="form.resgist_at"
              type="date"
              placeholder="日付選択"
              style="width: 100%"
            >
            </el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="掲載期間">
          <el-col :span="11">
            <el-date-picker
              v-model="form.release_range.start"
              type="date"
              placeholder="開始日"
              style="width: 100%"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="1" class="line">〜</el-col>
          <el-col :span="11">
            <el-date-picker
              v-model="form.release_range.end"
              type="date"
              placeholder="終了日"
              style="width: 100%"
            >
            </el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="ソートキー">
          <el-col :span="5">
            <el-input v-model="form.sort_order"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="ファイル" prop="files">
          <el-upload
            class="uploader"
            :action="uploadApi"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :on-exceed="handleExceed"
            :file-list="form.files"
            :on-success="onSuccess"
            :before-upload="onBeforeUpload"
            :headers="header"
            :multiple="false"
            :data="{ id: $route.params.id, category: 'archive' }"
            accept=".pdf,.ppt,.pptx,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png"
          >
            <el-button size="small" type="primary">ファイル選択</el-button>
            <!-- <div slot="tip" class="el-upload__tip">
              jpg/png files with a size less than 500kb
            </div> -->
          </el-upload>
        </el-form-item>
        <el-form-item>
          <p>
            アップロードできるファイル形式：pdf,ppt,pptx,doc,docx,xls,xlsx,jpg,jpeg,png
          </p>
          <p>アップロードできるファイル容量：20MBまで</p>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">登録</el-button>
          <el-button type="danger" @click="onDelete" :disabled="isFirst"
            >削除</el-button
          >
          <el-button @click="$router.go(-1)">戻る</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-main>
</template>

<script>
import authHeader from "../../../services/auth-header";
import CategoryService from "../../../services/category.service";
import SubCategoryService from "../../../services/sub_category.service";
import ArchiveService from "../../../services/archive.service";

import store from "../../../store";
export default {
  name: "admin-acrhive-edit",
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (currentUser && !currentUser.permissions.includes("Admin")) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  data: function() {
    const temp = new Date();
    const today = new Date(temp.getFullYear(), temp.getMonth(), temp.getDate());
    return {
      isFileSelecting: false,
      uploadApi: `${process.env.VUE_APP_API_ENDPOINT}/api/file/upload`,
      categories: [],
      sub_categories: [],
      rooms: "",
      new_sub_category: "",
      targets: [
        {
          value: "OwnedResident",
          label: "住居オーナー（区分所有者）",
          checked: false,
        },
        {
          value: "OwnedNonResident",
          label: "外部オーナー（区分所有者）",
          checked: false,
        },
        { value: "Chairman", label: "理事長（区分所有者）", checked: false },
        { value: "Director", label: "理事（区分所有者）", checked: false },
        { value: "Secretary", label: "監事（区分所有者）", checked: false },
        { value: "RentResident", label: "賃借人", checked: false },
        { value: "Manager", label: "管理員", checked: false },
        { value: "Others", label: "その他", checked: false },
        // { value: "ExternalParty", label: "外部関係者", checked: false },
      ],
      form: {
        category: null,
        is_individual: false,
        title: "",
        sub_category: null,
        resgist_at: today,
        release_range: { start: today, end: "" },
        sort_order: "",
        files: [],
        targets: [
          "OwnedResident",
          "OwnedNonResident",
          "Chairman",
          "Director",
          "Secretary",
        ],
      },
      rules: {
        category: [
          {
            required: true,
            message: "表示ページを選択してください",
            trigger: "blur",
          },
        ],
        sub_category: [
          {
            required: true,
            message: "サブカテゴリを選択してください",
            trigger: "blur",
          },
        ],
        files: [
          {
            required: true,
            message: "ファイルを選択してください",
            trigger: "blur",
          },
        ],
        resgist_at: [
          {
            required: true,
            message: "表示登録日を選択してください",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    rooms: function(val) {
      this.form.rooms = val.split(",");
    },
    targets: {
      // eslint-disable-next-line
      handler: function (val, oldVal) {
        console.log("someObj changed");
        const data = [];
        this.targets.forEach(element => {
          if (element.checked) {
            data.push(element.value);
          }
        });
        console.log(data);
        this.form.targets = data;
      },
      deep: true,
    },
    "form.category": function(val) {
      console.log(val);
      this.updateSubcategory(val);
      this.form.sub_category = null;
    },
  },
  computed: {
    header: function() {
      return authHeader();
    },
    isFirst: function() {
      return this.$route.params.id == "create";
    },
  },
  mounted: async function() {
    console.log("++++++++mounted");
    const res = await CategoryService.get();
    this.categories = res.data.map(item => {
      item._id = parseInt(item._id);
      return item;
    });
    console.log("this.categories", this.categories);
    if (this.$route.params.id != "create") {
      const res = await ArchiveService.read(this.$route.params.id);
      console.log("this.form", res.data);
      this.form = res.data;
      // set checkbox.data
      this.updateTagets(this.form.targets);
      // set subcategory
      const sub_category = this.form.sub_category;
      await this.updateSubcategory(this.form.category);
      this.form.sub_category = sub_category;
      // set rooms
      this.rooms = this.form.rooms.join(",");
      // await this.$nextTick();
      // console.log(sub_category);
      // this.form.sub_category = sub_category;
    } else {
      let category_id = parseInt(this.$route.query.category_id);
      category_id = isNaN(category_id) ? null : category_id;
      category_id =
        category_id > 0 && this.categories.find(i => i._id == category_id)
          ? category_id
          : null;
      this.form.category = category_id;
      this.categories.map(i => (i._id = parseInt(i._id)));
      this.updateTagets(this.form.targets);
    }
  },
  methods: {
    onBeforeUpload(file) {
      this.isFileSelecting = true;
      // const isJPG = file.type === "image/jpeg";
      const isLt10M = file.size / 1024 / 1024 < 20;

      // if (!isJPG) {
      //   this.$message.error("Avatar picture must be JPG format!");
      // }
      if (!isLt10M) {
        this.$message.error("アップロードできるファイル容量は20MBまでです");
      }
      // return isJPG && isLt10M;
      return isLt10M;
    },
    updateTagets(array) {
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        const obj = this.targets.find(obj => obj.value == element);
        obj.checked = true;
      }
    },
    onSubmit: async function() {
      // console.log("onSubmit");
      // console.log(this.form);
      const self = this;
      this.$refs["form"].validate(valid => {
        if (valid) {
          self.postData();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async postData() {
      if (this.form._id == undefined) {
        console.log("create");
        try {
          const res = await ArchiveService.create(this.form);
          console.log(res);
          this.$router.go(-1);
          // this.$router.push({ name: "archive" });
        } catch (error) {
          console.log(error);
          this.$message.error("登録できませんでした");
        }
      } else {
        console.log("update");
        try {
          const res = await ArchiveService.update(this.form);
          console.log(res);
          // this.$router.push({ name: "archive" });
          // this.$router.push({ name: "bod" });
          this.$router.go(-1);
        } catch (error) {
          console.log(error);
          this.$message.error("更新できませんでした");
        }
      }
    },
    async updateSubcategory(category_id) {
      console.log(`update:${category_id}`);
      const res = await SubCategoryService.get(category_id);
      console.log(res);
      this.sub_categories = res.data;
    },
    async onAddSubcateogry() {
      console.log("++++onAddSubcateogry");
      const value = this.new_sub_category;
      console.log(value);
      if (value.trim() == "") {
        this.new_sub_category = "";
        return;
      }
      const is_exists = this.sub_categories.some(item => item.title == value);
      if (!is_exists) {
        const res = await SubCategoryService.add(this.form.category, value);
        console.log(res);
        this.sub_categories.push(res.data);
        await this.$nextTick();
        this.form.sub_category = res.data._id;
        this.new_sub_category = "";
      }
    },
    async onChange(value) {
      console.log("++++onChange");
      console.log(value);
      const is_exists = this.sub_categories.some(item => item._id == value);
      if (!is_exists) {
        const res = await SubCategoryService.add(this.form.category, value);
        console.log(res);
        this.sub_categories.push(res.data);
        await this.$nextTick();
        this.form.sub_category = res.data._id;
      }
    },
    async onDelete() {
      if (!confirm("削除しても良いですか？")) {
        return;
      }
      // console.log(this.form);
      // eslint-disable-next-line
      const res = await ArchiveService.delete(this.form._id);
      console.log(res);
      this.$router.go(-1);
      // this.$router.push({ name: "bod" });
    },
    // eslint-disable-next-line
    handleRemove(file, fileList) {
      // console.log(file, fileList);
      // console.log("remove");
      // console.log(file);
      // console.log(fileList);
      this.form.files = [];
      // console.log(this.meeting.files);
    },
    // eslint-disable-next-line
    handlePreview(file) {
      // console.log(file);
    },
    // eslint-disable-next-line
    handleExceed(files, fileList) {
      this.$message.warning(
        `The limit is 3, you selected ${
          files.length
        } files this time, add up to ${files.length + fileList.length} totally`,
      );
    },
    // eslint-disable-next-line
    beforeRemove(file, fileList) {
      if (this.isFileSelecting) {
        this.isFileSelecting = false;
        return true;
      }
      // eslint-disable-line
      return this.$confirm(`削除します。よろしいですか？`);
    },
    // eslint-disable-next-line
    onSuccess(file, fileList) {
      // console.log("onSuccess");
      // console.log(file);
      // console.log(fileList);
      // console.log(this.meeting.files);
      this.form.files = [];
      this.form.files.push(fileList);
    },
  },
};
</script>

<style>
.el-checkbox__label {
  line-height: inherit;
}
</style>
<style scoped>
.group {
  border: solid 1px black;
  padding: 0 10px;
}
</style>
