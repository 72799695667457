<template>
  <div id="message-input" class="inputWrapper" style="position: relative;">
    <!-- <input type="text" v-model="message" /> -->
    <textarea name="" rows="4" cols="40" v-model="message" />

    <p class="section-block center">
      <button
        class="bt bt-green bt-green-sub pdfopenBtn"
        @click.prevent="onSend"
        v-bind:disabled="allow_status_local == 'wait'"
      >
        送信
      </button>
      <!-- <span v-if="allow_status_local == 'wait'">承認待ち</span>
      <span v-if="allow_status_local == 'deny'">非承認</span> -->
    </p>
  </div>
</template>

<script>
import io from "socket.io-client";

// const ENDPOINT = "http://ec2-44-232-167-73.us-west-2.compute.amazonaws.com:5000/";

const ENDPOINT = process.env.VUE_APP_CHAT_SERVER;

let socket;

// console.log("AAAA");

// const name = "user1";
// const room = "room1";
// const disp_name = "user1";

export default {
  name: "chat-input",
  props: ["allow_status", "room", "name", "dispName", "to"],
  data: function() {
    return {
      message: "",
      allow_status_local: this.allow_status,
    };
  },
  mounted: function() {
    // console.log("+++ CHatInput mounted");
    socket = io(ENDPOINT);
    // console.log(this.$route.query);
    // let room = "meeting_src";
    // let name = this.$route.query.name;
    // let disp_name = name;
    // if (this.$route.query.mode == "admin") {
    //   room = "meeting_src"
    // }
    const self = this;
    this.$nextTick(function() {
      // console.log("+++ CHatInput mounted2");
      // console.log(self.room);
      const name = self.name;
      const room = self.room;
      const disp_name = self.dispName;
      let to = self.to != undefined ? self.to : "";
      socket.emit("join", { name, room, disp_name, to }, error => {
        if (error) {
          alert(error);
        } else {
          // console.log("ok");
        }
      });
      // eslint-disable-next-line
      socket.on("private", function(data) {
        // console.log("+++ private");
        // console.log(data);
      });
    });
  },
  beforeDestroy: function() {
    // console.log("+++ CHatInput beforeDestroy");
    socket.disconnect();
  },
  methods: {
    onSend: function() {
      // console.log(`onSend(${this.message})`);
      if (this.message == "") {
        // console.log("empty cancel");
        return;
      }
      // if (this.to == "") {
      // console.log("public");
      // this.allow_status_local = 'wait';
      socket.emit("sendMessage", this.message, () => {
        // console.log("sended");
        this.message = "";
      });
      // } else {
      //   console.log("private");
      //   const self = this;
      //   socket.emit(
      //     "private",
      //     { msg: this.message, to: this.to, from: this.currentUser.roomNo },
      //     () => {
      //       console.log("private+");
      //       self.message = "";
      //     }
      //   );
      // }
    },
  },
};
</script>

<style>
#chat-input {
  /* height: 100px;
  background-color: blue; */
}
</style>
