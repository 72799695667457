import axios from "axios";
import authHeader from "./auth-header";

const API_URL = `${process.env.VUE_APP_API_ENDPOINT}/api/mansion/`;

class MansionService {
  getAll() {
    return axios.get(`${process.env.VUE_APP_API_ENDPOINT}/api/mansions/`, {
      headers: authHeader(),
    });
  }

  findById(id) {
    return axios.get(API_URL + id, {
      headers: authHeader(),
    });
  }
}

export default new MansionService();
