<template>
  <div class="mainContentBox">
    <div class="mainContentBoxInner" v-if="status == 'edit'">
      <div class="pageTitleWrapper contact">
        <h2 class="pageTitle">{{ preview.title }} イベント申込み</h2>
      </div>

      <div class="pageCaption contactCaption">
        <div class="contactCaptionDetail">
          お名前、ご連絡先を入力し、「次へ」ボタンをクリックしてください
        </div>
      </div>
      <section class="mainContent-form">
        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="">日時</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                <label id=""
                  >{{ currentDate }} &nbsp;
                  {{ _.get(currentKoma, "start") }}〜{{
                    _.get(currentKoma, "end")
                  }}</label
                >
              </div>
            </div>
          </dd>
        </dl>
        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="">マンション名</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                <label id="">{{ _.get(mansion, "name") }}</label>
              </div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="">部屋番号</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                <label id="">{{ currentUser.roomNo }}</label>
              </div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <span class="icoForm-requir">必須</span>
            <label for="L_NAME">お名前</label>
          </dt>
          <dd class="formTable_data">
            <dl class="formTableBox">
              <dt class="formTableBox_ttl"><label for="L_NAME">姓</label></dt>
              <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                <input
                  type="text"
                  id="L_NAME"
                  maxlength="40"
                  class="m-formBox_input--name u-ime-ja"
                  placeholder="例:伊藤"
                  name="lastName"
                  v-model.trim="$v.values.sei.$model"
                />
                <div class="error" v-if="!$v.values.sei.required">必須です</div>
              </dd>
              <dt class="formTableBox_ttl"><label for="F_NAME">名</label></dt>
              <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                <input
                  type="text"
                  id="F_NAME"
                  maxlength="40"
                  class="formTableBox_input--name u-ime-ja"
                  placeholder="例:忠太郎"
                  name="firstName"
                  v-model.trim="$v.values.mei.$model"
                />
                <div class="error" v-if="!$v.values.mei.required">必須です</div>
              </dd>
            </dl>

            <span class="formErr_inline"> </span>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <span class="icoForm-requir">必須</span>
            <label for="L_NAME">ふりがな</label>
          </dt>
          <dd class="formTable_data">
            <dl class="formTableBox">
              <dt class="formTableBox_ttl"><label for="L_NAME">せい</label></dt>
              <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                <input
                  type="text"
                  id="L_NAME"
                  maxlength="40"
                  class="m-formBox_input--name u-ime-ja"
                  placeholder="例:いとう"
                  name="lastName"
                  v-model.trim="$v.values.seiKana.$model"
                />
                <div class="error" v-if="!$v.values.seiKana.required">
                  必須です
                </div>
              </dd>
              <dt class="formTableBox_ttl"><label for="F_NAME">めい</label></dt>
              <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                <input
                  type="text"
                  id="F_NAME"
                  maxlength="40"
                  class="formTableBox_input--name u-ime-ja"
                  placeholder="例:ちゅうたろう"
                  name="firstName"
                  v-model.trim="$v.values.meiKana.$model"
                />
                <div class="error" v-if="!$v.values.meiKana.required">
                  必須です
                </div>
              </dd>
            </dl>

            <span class="formErr_inline"> </span>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <span class="icoForm-requir">必須</span>
            <label for="">メールアドレス</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                <label id="">{{ values.email }}</label>
                <label id=""
                  ><input
                    type="hidden"
                    id=""
                    class="u-input--w100p u-ime-en"
                    maxlength="128"
                    name=""
                    v-model.trim="$v.values.email.$model"
                /></label>
                <div class="error" v-if="!$v.values.email.required">
                  必須です
                </div>
                <div class="error" v-if="!$v.values.email.email">
                  Emailアドレスを入力してください
                </div>
              </div>
            </div>
          </dd>
        </dl>
        <dl class="formTable">
          <dt class="formTable_ttl">
            <span class="icoForm-requir">必須</span>
            <label for="">電話番号</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                <label id=""
                  ><input
                    type="text"
                    id=""
                    class="u-input--w100p u-ime-en"
                    maxlength="128"
                    name=""
                    v-model.trim="$v.values.phone.$model"
                /></label>
                <div class="error" v-if="!$v.values.phone.required">
                  必須です
                </div>
                <div class="error" v-if="!$v.values.phone.decimal">
                  半角番号を入力してください
                </div>
              </div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="">その他質問等</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                <textarea
                  rows="8"
                  class="u-input--w100p u-ime-ja"
                  v-model.trim="$v.values.memo.$model"
                ></textarea>
              </div>
            </div>
          </dd>
        </dl>

        <div class="cn-back-btn">
          <a href="javascript:void(0);" @click="onConfirm">次へ</a>
        </div>
      </section>
    </div>
    <div class="mainContentBox" v-if="status == 'confirm'">
      <div class="mainContentBoxInner">
        <div class="pageTitleWrapper contact">
          <h2 class="pageTitle">イベント申し込み</h2>
        </div>

        <div class="pageCaption">
          <div class="contactCaptionDetail">
            入力内容をご確認ください。<br />
            よろしければ「送信」ボタンをクリックしてください。
          </div>
        </div>

        <section class="mainContent-form">
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">マンション名</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id="">{{ mansion.name }}</label>
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">部屋番号</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">{{ currentUser.roomNo }}</div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">お名前</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ values.sei }} {{ values.mei }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">ふりがな</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ values.seiKana }} {{ values.meiKana }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">メールアドレス</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">{{ values.email }}</div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">電話番号</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">{{ values.phone }}</div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">お問い合わせ内容</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <pre>{{ values.memo }}</pre>
                </div>
              </div>
            </dd>
          </dl>
          <div class="cn-back-btn">
            <a href="javascript:void(0);" @click="status = 'edit'">戻る</a>
          </div>
          <div class="cn-back-btn">
            <a href="javascript:void(0);" @click="onPost">送信</a>
          </div>
        </section>
      </div>
    </div>

    <div class="mainContentBoxInner" v-else-if="status == 'complete'">
      <div class="pageTitleWrapper contact">
        <h2 class="pageTitle">イベント申し込み</h2>
      </div>

      <div class="pageCaption">
        <div class="contactCaptionDetail">予約を完了しました</div>
      </div>

      <section class="mainContent-form">
        <div class="cn-back-btn">
          <router-link
            :to="{
              name: 'top',
              params: { mc_code: mcCode },
            }"
            >トップページへ</router-link
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { required, email, decimal } from "vuelidate/lib/validators";
import PreviewService from "../../../services/preview.service";
import PreviewReserveService from "../../../services/preview_reserve.service";
import moment from "moment";
export default {
  name: "contact",
  // mixins: [Navi],
  data() {
    return {
      status: "edit",
      preview: {},
      values: {
        sei: "",
        mei: "",
        seiKana: "",
        meiKana: "",
        email: "",
        phone: "",
        memo: "",
        user: null,
        preview: null,
        scheduleIndex: null,
        komaIndex: null,
      },
      schema: null,
    };
  },
  validations: {
    values: {
      sei: { required },
      mei: { required },
      seiKana: { required },
      meiKana: { required },
      email: { required, email },
      phone: { required, decimal },
      memo: {},
    },
  },

  computed: {
    currentDate: {
      get() {
        const val = this._.get(
          this.preview,
          `schedules[${this.$route.params.scheduleIndex}].date`,
        );
        if (val) {
          return moment(val).format("YYYY/MM/DD (ddd)");
        } else {
          return "";
        }
      },
      set(val) {
        console.log(val);
      },
    },
    currentKoma() {
      return this._.get(
        this.preview,
        `schedules[${this.$route.params.scheduleIndex}].komas[${this.$route.params.komaIndex}]`,
      );
    },
  },
  created: async function() {
    console.log(`created`);
    const previewid = this.$route.params.previewid;
    const scheduleIndex = this.$route.params.scheduleIndex;
    const komaIndex = this.$route.params.komaIndex;
    console.log(`${previewid},${scheduleIndex},${komaIndex}`);

    const res = await PreviewService.findByID(previewid);
    this.preview = res.data;
    console.log("this.preview", this.preview);
    var setTitle =
      this.preview.title + `予約 | ${this.currentUser.mansion.name}`;
    document.title = setTitle;
  },
  mounted: async function() {
    console.log("+++ contact.mounted");
    // const mc_code = this.$route.params.mc_code
    //   ? this.$route.params.mc_code
    //   : "";
    // console.log(mc_code);
    // const result = await FormService.findOne({
    //   category: "contact",
    //   mansion: mc_code,
    // });
    // console.log(result);
    await this.$nextTick();
    this.values.email = this.currentUser.email;
  },
  methods: {
    scrollTop: function() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    onConfirm() {
      console.log("onConfirm");
      console.log(this.values);
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        this.status = "confirm";
        this.scrollTop();
      }
    },
    async onPost() {
      console.log("onConfirm");
      console.log(this.values);
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        console.log("this.currentUser", this.currentUser);
        this.values.user = this.currentUser.id;
        this.values.preview = this.$route.params.previewid;
        this.values.scheduleIndex = this.$route.params.scheduleIndex;
        this.values.komaIndex = this.$route.params.komaIndex;
        this.values.mansion = this.currentUser.mansion.id;
        this.values.roomNo = this.currentUser.roomNo;
        console.log("onPost", this.values);
        const res = await PreviewReserveService.createByUser(this.values);
        console.log(res);
        if (res.response) {
          console.log("res", res.response);
          // error
          const obj = JSON.parse(res.response.data);
          this.$message.error(obj.message);
        } else {
          console.log("ok");
          this.status = "complete";
        }
        // this.status = "complete";
        // (category, sei, mei, mansionName,mansion, roomNo, result)
        // const result = await FormService.private(
        //   "contact",
        //   this.values.sei,
        //   this.values.mei,
        //   this.mansion.name,
        //   this.mansion._id,
        //   this.currentUser.roomNo,
        //   this.values,
        //   false,
        // );
        this.scrollTop();
        // console.log(result);
      }
    },
  },
};
</script>

<style>
/* @import "/i-salon/css/isalon/contact_layout.css"; */
.flex-wrapper {
  display: flex;
}
</style>
