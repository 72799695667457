const crypto = require("crypto");
import moment from "moment";
// import store from "../store";
// import MansionService from "../services/mansion.service"
export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    mcCode() {
      return this.currentUser
        ? this.currentUser.mansion
          ? this.currentUser.mansion._id
          : ""
        : "";
    },
    mansion() {
      // console.log("++ mixin mansion");
      // console.log(this.$store.state.mansion.data);
      return this.$store.state.mansion.data;
    },
    logoutUrl() {
      return process.env.VUE_APP_ISALON_LOGOUT_ENDPOINT;
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   console.log("+++ mixin:beforeRouteEnter");
  //   const currentUser = store.state.auth.user;
  //   console.log(currentUser);
  //   console.log(to);

  //   if (currentUser != null && currentUser.mansion._id != to.params.mc_code) {
  //     console.log(":401");
  //     next({
  //       name: "notfound404",
  //       params: { mc_code: currentUser.mansion._id },
  //     });
  //   } else {
  //     next();
  //   }
  // },
  methods: {
    createTitleDesc: function(routeInstance) {
      // タイトルを設定
      if (routeInstance.meta.title) {
        var setTitle =
          routeInstance.meta.title + ` | ${this.currentUser.mansion.name}`;
        document.title = setTitle;
      } else {
        document.title = this.currentUser.dai_bunrui.name;
      }
      // メタタグのdescription設定処理
      console.log("meta", routeInstance.meta);
      if (routeInstance.meta.desc) {
        var setDesc = routeInstance.meta.desc + "";
        document
          .querySelector("meta[name='description']")
          .setAttribute("content", setDesc);
      } else {
        document
          .querySelector("meta[name='description']")
          .setAttribute("content", "");
      }
    },
    commentDate(createdAt) {
      const date = moment(createdAt).format("YYYY/MM/DD");
      const today = moment().format("YYYY/MM/DD");
      // console.log("commentDate");
      // console.log(createdAt);
      // console.log(date);
      // console.log(today);
      if (date == today) {
        return "";
      } else {
        return moment(createdAt).format("MM/DD");
      }
    },
    commentTime(createdAt) {
      return moment(createdAt).format("HH:mm");
    },
    isPermission(value) {
      return this.$store.getters["auth/isPermission"](value);
    },
    isOfficialWord(value) {
      return value ? "" : "予定";
    },
    md5hex(str /*: string */) {
      const md5 = crypto.createHash("md5");
      return md5.update(str, "binary").digest("hex");
    },
    createTag(tag) {
      const titles = {
        tag01: "作業予定",
        tag02: "重要",
        tag03: "お知らせ",
        tag04: "キャンペーン",
        tag05: "伊藤忠都市開発より",
        tag06: "大規模修繕",
        tag07: "アンケート",
        tag08: "優待のご案内",
      };

      const str = titles[tag] ? titles[tag] : "";
      return `<span class="tag ${tag}">${str}</span>`;
    },
    toYYYYMMDD(str) {
      // console.log("+++ toYYYYMMDD");
      if (str != undefined) {
        return moment(str).format("YYYY/MM/DD");
      } else {
        return "";
      }
    },

    toTIMESTAMP(str, hhmm) {
      // console.log("+++ toYYYYMMDD");
      if (str != undefined) {
        return moment(str).format("YYYY/MM/DD") + ` ${hhmm}`;
      } else {
        return "";
      }
    },
    currentDate() {
      return moment().format("YYYY/MM/DD HH:mm");
    },
    contentsLoader(id, url) {
      var req = new XMLHttpRequest();
      const method = "GET";
      // const file = "/i-salon/docs/kiyaku_pre.html";

      var div = document.getElementById(id);

      req.open(method, url, true);
      req.onreadystatechange = function() {
        if (req.readyState === 4 && req.status === 200) {
          var rest = req.responseText;
          div.innerHTML = rest;
        }
      };
      req.send();
    },
    hashEmailAddress(email) {
      if (!email) return "";
      const temp = email.split("@");
      const len = temp[0].length;
      if (len < 3) {
        return `${temp[0]}@${temp[1]}`;
      } else {
        const maru = "●".repeat(len - 2);
        const pre = temp[0].substr(0, 2);
        return `${pre}${maru}@${temp[1]}`;
      }
    },
    appendFile() {
      console.log("+++ utils.appendFile");
      if (this.currentUser != null && this.currentUser.mansion != undefined) {
        const mansion = this.currentUser.mansion;
        const dai_bunrui = mansion.dai_bunrui;
        const chu_bunrui = mansion.chu_bunrui;
        const mc_code = mansion._id;
        // console.log(this.currentUser);
        this.appendCSS(`/assets/custom/${dai_bunrui}/default.css`);
        this.appendJS(`/assets/custom/${dai_bunrui}/default.js`);
        this.appendCSS(
          `/assets/custom/${dai_bunrui}/${chu_bunrui}/default.css`,
        );
        this.appendJS(`/assets/custom/${dai_bunrui}/${chu_bunrui}/default.js`);
        this.appendCSS(
          `/assets/custom/${dai_bunrui}/${chu_bunrui}/${mc_code}/default.css`,
        );

        if (this.currentUser.status == "contractor") {
          this.appendJS(
            `/assets/custom/${dai_bunrui}/${chu_bunrui}/${mc_code}/default_pre.js`,
          );
        } else {
          this.appendJS(
            `/assets/custom/${dai_bunrui}/${chu_bunrui}/${mc_code}/default.js`,
          );
        }
        document.body.classList.add(dai_bunrui);
        document.body.classList.add(chu_bunrui);
        document.body.classList.add(mc_code);
        document.body.classList.add(this.currentUser.roomNo);
        document.body.classList.add(this.currentUser.status);
        if (mansion.iuc_code) {
          document.body.classList.add(mansion.iuc_code);
        }
        if (mansion.ipd_code) {
          document.body.classList.add(mansion.ipd_code);
        }

        document.body.classList.add(this.currentUser.role);

        this.$cookies.set(
          "m-code",
          mc_code,
          process.env.VUE_APP_COOKIE_EXPIRES,
          "/",
          process.env.VUE_APP_COOKIE_DOMAIN,
          true,
          process.env.VUE_APP_COOKIE_SAMESITE,
        );
        this.$cookies.set(
          "m-status",
          mansion.status,
          process.env.VUE_APP_COOKIE_EXPIRES,
          "/",
          process.env.VUE_APP_COOKIE_DOMAIN,
          true,
          process.env.VUE_APP_COOKIE_SAMESITE,
        );
        this.$cookies.set(
          "u-status",
          this.currentUser.status,
          process.env.VUE_APP_COOKIE_EXPIRES,
          "/",
          process.env.VUE_APP_COOKIE_DOMAIN,
          true,
          process.env.VUE_APP_COOKIE_SAMESITE,
        );
      }
    },
    appendJS(filePath) {
      let file2 = document.createElement("script");
      file2.type = "text/javascript";
      file2.src = filePath;
      document.head.appendChild(file2);
    },
    statusToLabel(status) {
      const statusLabel = {
        contractor: "契約者",
        resident: "入居者",
        others: "その他",
      };
      return statusLabel[status];
    },
  },
};
