<template>
  <div id="meeting-participants-list">
    <section class="main-section">
      <h3 class="section-title green-left-head">参加者</h3>
      <div class="box-item">
        <div class="box-item-wrapper">
          <ul class="box-item-list">
            <li
              v-for="(participant, index) in participants"
              :key="index"
              style="display:flex;"
            >
              <div style="width:200px">
                {{ participant.timestamp | moment("YYYY/MM/DD HH:mm:ss") }}
              </div>
              <div style="width:200px">{{ participant.roomNo }} 号室</div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import MeetingService from "../services/meeting.service";
export default {
  name: "MeetingParticipants",
  props: ["_id"],
  data() {
    return {
      participants: [],
    };
  },
  async mounted() {
    try {
      console.log("+++ MeetingService mounted");
      console.log(this._id);
      const result = await MeetingService.getParticipants(this._id);
      console.log(result);
      this.participants = result;
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style></style>
