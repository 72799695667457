<template>
  <el-main id="admin-meeting-index">
    <h3 class="section-title green-left-head title-margin">総会管理</h3>
    <p>
      <el-button
        type="primary"
        icon="el-icon-circle-plus"
        @click="onEdit('create')"
        >新規総会</el-button
      >
    </p>
    <el-table :data="meetings" stripe style="width: 100%">
      <el-table-column label="公開"
        ><template slot-scope="scope">
          <span style="margin-left: 10px">{{
            scope.row.is_published ? "公開" : "非公開"
          }}</span>
        </template></el-table-column
      >
      <el-table-column prop="title" label="タイトル" width="180">
      </el-table-column>
      <el-table-column label="日付" width="180"
        ><template slot-scope="scope">
          <span style="margin-left: 10px">{{
            scope.row.date | moment("YYYY-MM-DD")
          }}</span>
        </template></el-table-column
      >
      <el-table-column prop="start_at" label="開始"> </el-table-column>
      <el-table-column prop="end_at" label="終了"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="onEdit(scope.row._id)"
            >編集</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </el-main>
</template>

<script>
import store from "../../../store";

export default {
  name: "admin_meeting_index",
  mounted() {
    store.dispatch("meeting/all").then(
      // eslint-disable-next-line
      docs => {
        // console.log(docs);
      },
      // eslint-disable-next-line
      error => {
        // console.log(error);
      },
    );
  },
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (currentUser && !currentUser.permissions.includes("Admin")) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  computed: {
    meetings() {
      return this.$store.getters["meeting/meetings"];
    },
    showAdminBoard() {
      // console.log(this.currentUser.permissions);
      if (this.currentUser && this.currentUser.permissions) {
        return this.currentUser.permissions.includes["Director"];
      }

      return false;
    },
  },
  methods: {
    onEdit: function(id) {
      this.$router.push({
        name: "admin_meeting_edit",
        params: { id, mc_code: this.currentUser.mansion._id },
      });
    },
  },
};
</script>

<style>
input {
  width: unset;
}
</style>
