<template>
  <div id="admin-chat">
    <el-row :gutter="20" style="margin:20px 0;">
      <el-col :span="3"><div style="padding-top: 5px;">ルームNo</div></el-col>
      <el-col :span="12"
        ><el-input
          placeholder="部屋番号ボタンクリックで個別質疑チャットを行います。"
          v-model="roomNo"
          readonly="true"
        ></el-input
      ></el-col>
      <el-col :span="6"
        ><el-button type="primary" @click="roomNo = ''"
          >すべての発言を表示</el-button
        ></el-col
      >
    </el-row>

    <AdminChatView
      style="height:500px"
      class="messages"
      :room="privateRoom"
      :openRoom="openRoom"
      name="admin"
      dispName="admin"
      roomName="質疑チャット"
      mode="user"
      :filter="roomNo"
      @onfilter="onFilter"
    ></AdminChatView>
    <AdminChatInput
      v-if="roomNo != ''"
      :room="privateRoom"
      :openRoom="openRoom"
      name="admin"
      dispName="admin"
      roomName="質疑応答チャット"
      :to="roomNo"
    ></AdminChatInput>
  </div>
</template>

<script>
import AdminChatView from "@/components/AdminChatView";
import AdminChatInput from "@/components/AdminChatInput";
import store from "../../../store";
export default {
  name: "admin-chat",
  components: { AdminChatView, AdminChatInput },
  data: function() {
    return {
      roomNo: "",
    };
  },
  computed: {
    privateRoom() {
      console.log("ChatAdmin privateRoom");
      console.log(this.$route.params.id);
      return `admin-private_${this.$route.params.id}`;
    },
    openRoom() {
      console.log("ChatAdmin privateRoom");
      console.log(this.$route.params.id);
      return `open-room_${this.$route.params.id}`;
    },
  },
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (currentUser && !currentUser.permissions.includes("Admin")) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  methods: {
    onFilter(value) {
      // console.log("onFilter");
      // console.log(value);
      this.roomNo = value;
    },
  },
};
</script>

<style></style>
