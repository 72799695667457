<template>
  <div id="sso">
    ログイン中...
  </div>
</template>

<script>
import firebase from "firebase";
import userService from "../services/user.service";
export default {
  data() {
    return {};
  },
  created() {
    console.log("+++ sso.created");
    if (this.checkQuery()) {
      this.sso();
    } else {
      alert("erro");
    }
  },
  async mounted() {
    console.log("+++ sso.mounted");
  },
  methods: {
    checkQuery() {
      const query = this.$route.query;
      return (
        query["PORTAL"] !== undefined &&
        query["ID"] !== undefined &&
        query["GMT"] !== undefined &&
        query["SHA"] !== undefined
      );
    },
    sso() {
      const query = this.$route.query;
      console.log("---- sso start");
      this.$store.dispatch("auth/sso", query).then(
        () => {
          console.log("sso +++A");
          console.log(this.currentUser);
          let messaging = null;
          if (firebase.messaging.isSupported()) {
            console.log("sso +++B");
            messaging = firebase.messaging();
            messaging
              .getToken({
                vapidKey: `${process.env.VUE_APP_FIREBASE_VAPID_KEY}`,
              })
              .then(currentToken => {
                console.log("+++C");
                if (currentToken) {
                  console.log(`login currentToken=${currentToken}`);
                  userService.setToken(currentToken);
                  console.log("++OK");
                  // if (this.currentUser.email) {
                  this.$router.push({
                    name: "top",
                    params: { mc_code: this.currentUser.mansion._id },
                  });
                  // } else {
                  //   window.history.pushState(
                  //     null,
                  //     "トップ",
                  //     `/${this.currentUser.mansion._id}`,
                  //   );
                  //   this.$router.push({
                  //     name: "email-required",
                  //     params: { mc_code: this.currentUser.mansion._id },
                  //   });
                  // }
                } else {
                  // Show permission request.
                  console.log(
                    "No registration token available. Request permission to generate one.",
                  );
                  // Show permission UI.
                  // updateUIForPushPermissionRequired();
                  // setTokenSentToServer(false);
                  // if (this.currentUser.email) {
                  this.$router.push({
                    name: "top",
                    params: { mc_code: this.currentUser.mansion._id },
                  });
                  // } else {
                  //   window.history.pushState(
                  //     null,
                  //     "トップ",
                  //     `/${this.currentUser.mansion._id}`,
                  //   );
                  //   this.$router.push({
                  //     name: "email-required",
                  //     params: { mc_code: this.currentUser.mansion._id },
                  //   });
                  // }
                }
              })
              .catch(err => {
                console.log("An error occurred while retrieving token. ", err);

                // if (this.currentUser.email) {
                this.$router.push({
                  name: "top",
                  params: { mc_code: this.currentUser.mansion._id },
                });
                // } else {
                //   window.history.pushState(
                //     null,
                //     "トップ",
                //     `/${this.currentUser.mansion._id}`,
                //   );
                //   this.$router.push({
                //     name: "email-required",
                //     params: { mc_code: this.currentUser.mansion._id },
                //   });
                // }
                // showToken("Error retrieving registration token. ", err);
                // setTokenSentToServer(false);
              });
          } else {
            // if (this.currentUser.email) {
            this.$router.push({
              name: "top",
              params: { mc_code: this.currentUser.mansion._id },
            });
            // } else {
            //   window.history.pushState(
            //     null,
            //     "トップ",
            //     `/${this.currentUser.mansion._id}`,
            //   );
            //   this.$router.push({
            //     name: "email-required",
            //     params: { mc_code: this.currentUser.mansion._id },
            //   });
            // }
          }
        },
        error => {
          console.log("ERROR!!!");
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        },
      );
    },
  },
};
</script>

<style></style>
