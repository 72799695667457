<template>
  <div class="_main-container soudan" id="jumpTo">
    <div class="mainContentBox">
      <div class="mainContentBoxInner" v-if="status == 'edit'">
        <div class="pageTitleWrapper">
          <h2 class="pageTitle">マンション売りたい・貸したい</h2>
        </div>

        <div class="pageCaption contactCaption">
          <div class="contactCaptionDetail">
            ご相談の内容によっては、返信・回答までのお時間を要することがございます。<br />
            ご送付いただいてから3営業日が経過しても、担当者より連絡がない場合は、大変お手数ですが、下記まで再度ご相談をお願いいたします。<br /><br />
            <h2>伊藤忠アーバンコミュニティ株式会社 仲介係</h2>
            電話：0120-101-323 平日9時〜17時30分（年末年始を除く）<br />
            メール：<a href="mailto:cs-support00@itc-uc.co.jp"
              >cs-support00@itc-uc.co.jp</a
            ><br /><br />
            個人情報の取扱いに関しては、下記
            <span>プライバシーポリシーをご参照ください。</span><br /><br /><a
              href="http://www.itc-uc.co.jp/privacy/"
              target="_blank"
              >・伊藤忠アーバンコミュニティ株式会社</a
            ><br />
          </div>
        </div>

        <section class="mainContent-form">
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">マンション名</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id="">{{ mansionName }}</label>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">部屋番号</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id="">{{ currentUser.roomNo }}</label>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="L_NAME">お名前</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dt class="formTableBox_ttl"><label for="L_NAME">姓</label></dt>
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="40"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder="例:伊藤"
                    name="lastName"
                    v-model.trim="$v.values.sei.$model"
                  />
                  <div class="error" v-if="!$v.values.sei.required">
                    必須です
                  </div>
                </dd>
                <dt class="formTableBox_ttl"><label for="F_NAME">名</label></dt>
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="F_NAME"
                    maxlength="40"
                    class="formTableBox_input--name u-ime-ja"
                    placeholder="例:忠太郎"
                    name="firstName"
                    v-model.trim="$v.values.mei.$model"
                  />
                  <div class="error" v-if="!$v.values.mei.required">
                    必須です
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="L_NAME">ふりがな</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dt class="formTableBox_ttl">
                  <label for="L_NAME">せい</label>
                </dt>
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="40"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder="例:いとう"
                    name="lastName"
                    v-model.trim="$v.values.kanaSei.$model"
                  />
                  <div class="error" v-if="!$v.values.kanaSei.required">
                    必須です
                  </div>
                </dd>
                <dt class="formTableBox_ttl">
                  <label for="F_NAME">めい</label>
                </dt>
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="F_NAME"
                    maxlength="40"
                    class="formTableBox_input--name u-ime-ja"
                    placeholder="例:ちゅうたろう"
                    name="firstName"
                    v-model.trim="$v.values.kanaMei.$model"
                  />
                  <div class="error" v-if="!$v.values.kanaMei.required">
                    必須です
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">メールアドレス</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id="">{{ values.email }}</label>
                  <div class="error" v-if="!$v.values.email.required">
                    必須です
                  </div>
                  <div class="error" v-if="!$v.values.email.email">
                    Emailアドレスを入力してください
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="">電話番号</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id=""
                    ><input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-en"
                      maxlength="128"
                      name=""
                      v-model.trim="$v.values.phone.$model"
                  /></label>
                  <div class="formTableCautionInner">
                    ※ハイフン（-）なしで数字のみご入力ください。
                  </div>
                  <div class="error" v-if="!$v.values.phone.required">
                    必須です
                  </div>
                  <div class="error" v-if="!$v.values.phone.decimal">
                    半角数字を入力してください
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="">ご相談種別</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label v-for="(item, index) in category_options" :key="index">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      :value="item"
                      v-model="$v.values.category.$model"
                    />
                    {{ item }}
                  </label>
                  <div class="error" v-if="!$v.values.category.required">
                    必須です
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="">無料査定</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div>
                    <input
                      type="radio"
                      id="senyuu_hoshu_yes"
                      name=""
                      value="希望します"
                      v-model.trim="$v.values.satei.$model"
                    />
                    <label for="senyuu_hoshu_yes">希望します</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="senyuu_hoshu_no"
                      name=""
                      value="希望しません"
                      v-model.trim="$v.values.satei.$model"
                    />
                    <label for="senyuu_hoshu_no">希望しません</label>
                  </div>
                  <div class="error" v-if="!$v.values.satei.required">
                    必須です
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="">ご相談内容</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <textarea
                    rows="8"
                    class="u-input--w100p u-ime-ja"
                    v-model.trim="$v.values.text.$model"
                  ></textarea>

                  <div class="error" v-if="!$v.values.text.required">
                    必須です
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <div class="cn-back-btn">
            <a href="javascript:void(0);" @click="onConfirm">次へ</a>
          </div>
        </section>
      </div>

      <div class="mainContentBoxInner" v-else-if="status == 'confirm'">
        <div class="pageTitleWrapper">
          <h2 class="pageTitle">マンション売りたい・貸したい</h2>
        </div>

        <div class="pageCaption">
          <div class="contactCaptionDetail">
            入力内容をご確認ください。<br />
            よろしければ「送信」ボタンをクリックしてください。
          </div>
        </div>

        <section class="mainContent-form">
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>マンション名</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ mansionName }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>部屋番号</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ currentUser.roomNo }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">名前</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ values.sei }} {{ values.mei }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">ふりがな</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ values.kanaSei }} {{ values.kanaMei }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">メールアドレス</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">{{ values.email }}</div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">電話番号</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">{{ values.phone }}</div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">ご相談種別</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div v-for="(item, index) in values.category" :key="index">
                    {{ item }}
                  </div>
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">無料査定</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">{{ values.satei }}</div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">ご相談内容</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <pre>{{ values.text }}</pre>
                </div>
              </div>
            </dd>
          </dl>

          <div class="cn-back-btn">
            <a href="javascript:void(0);" @click="status = 'edit'">戻る</a>
          </div>
          <div class="cn-back-btn">
            <a href="javascript:void(0);" @click="onPost">送信</a>
          </div>
        </section>
      </div>

      <div class="mainContentBoxInner" v-else-if="status == 'complete'">
        <div class="pageTitleWrapper">
          <h2 class="pageTitle">マンション売りたい・貸したい</h2>
        </div>

        <div class="pageCaption">
          <div class="contactCaptionDetail">ご相談内容を受け付けました。</div>
        </div>

        <section class="mainContent-form">
          <div class="cn-back-btn">
            <router-link
              :to="{
                name: 'top',
                params: { mc_code: mcCode },
              }"
              >トップページへ</router-link
            >
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email, decimal } from "vuelidate/lib/validators";
// import FormService from "../../services/form.service";
import OrderService from "../../services/order.service";
import OrderSchemaService from "../../services/order_schema.service";
import store from "../../store";
// import Navi from "../mixin/navi";
export default {
  name: "contact",
  // mixins: [Navi],
  data() {
    return {
      status: "edit",
      values: {
        sei: "",
        mei: "",
        kanaSei: "",
        kanaMei: "",
        email: "",
        phone: "",
        category: [],
        satei: "",
        text: "",
      },
      schema: null,
      category_options: [],
    };
  },
  validations: {
    values: {
      sei: { required },
      mei: { required },
      kanaSei: { required },
      kanaMei: { required },
      email: { required, email },
      phone: { required, decimal },
      category: { required },
      satei: { required },
      text: { required },
    },
  },
  mounted: async function() {
    console.log("+++ contact.mounted");
    await this.$nextTick();
    const user = store.state.auth.user;
    console.log(user);
    const result = await OrderSchemaService.find({
      mansion: user.mansion.id,
      directory: "soudan",
    });
    console.log("result", result);
    this.schema = result.data[0].schema;
    const category = this.schema.find(item => item.name == "category");
    console.log(category);
    this.category_options = category.options;
    console.log(this.category_options);
    this.values.email = user.email;
  },
  computed: {
    mansionName() {
      return this.mansion != null ? this.mansion.name : "";
    },
  },
  methods: {
    scrollTop: function() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    onConfirm() {
      console.log("onConfirm");
      console.log(this.values);
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        this.status = "confirm";
        this.scrollTop();
      }
    },
    async onPost() {
      console.log("onConfirm");
      console.log(this.values);
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        // (category, sei, mei, mansionName,mansion, roomNo, result)
        const result = await OrderService.editByDirectroy(
          "soudan",
          this.values,
        );
        this.status = "complete";
        this.scrollTop();
        console.log(result);
      }
    },
  },
};
</script>

<style>
/* @import "/i-salon/css/isalon/contact_layout.css"; */
.flex-wrapper {
  display: flex;
}
</style>
