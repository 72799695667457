<template>
  <div class="main kanri-page">
    <h2 class="page-title"><strong>イベント登録</strong></h2>
    <el-col :span="24">
      <section class="main-section gray-top-border">
        <!-- <h3 class="section-title">イベント</h3> -->

        <!-- <el-col :span="24"> -->
        <el-form ref="form_target" :model="doc" label-width="200px">
          <el-form-item label="タイトル" prop="title">
            <el-input type="text" placeholder="タイトル" v-model="doc.title">
            </el-input>
          </el-form-item>
          <el-form-item label="掲載期間" prop="publishRange">
            <el-date-picker
              v-model="doc.publishRange"
              type="daterange"
              range-separator="〜"
              start-placeholder="掲載開始日"
              end-placeholder="掲載終了日"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="ターゲット">
            <div class="group">
              <el-checkbox
                v-model="item.checked"
                v-for="(item, index) in targets"
                :key="`targets-${index}`"
                :style="{
                  display: index > targets.length - 3 ? 'none' : 'inline-block',
                }"
                >{{ item.label }}</el-checkbox
              >
            </div>
            <el-checkbox v-model="targets[targets.length - 2].checked"
              >賃借人も含める</el-checkbox
            >
            <el-checkbox v-model="targets[targets.length - 1].checked"
              >管理員も含める</el-checkbox
            >
            <!-- <el-checkbox v-model="information.is_individual">個別</el-checkbox> -->
          </el-form-item>
        </el-form>
        <el-card
          class="box-card"
          v-for="(item, index) in doc.schedules"
          :key="`sch_${index}`"
        >
          <div slot="header">
            <span style="font-size: 14px">イベント実施日の枠設定</span>
            <el-button
              style="float: right; padding: 3px 0; color: red"
              type="text"
              v-show="index != 0"
              @click="onDeleteSchedule(index)"
              >削除</el-button
            >
            <el-button
              style="float: right; padding: 3px 0"
              type="text"
              @click="onAddSchedule(index)"
              >コピー</el-button
            >
          </div>
          <el-form :model="doc" label-width="100px">
            <el-form-item label="イベント実施日" prop="value1">
              <el-date-picker
                v-model="item.date"
                type="date"
                placeholder="イベント実施日"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item
              :label="`コマ${index2 + 1}`"
              v-for="(item2, index2) in item.komas"
              :key="`koma_${index}_${index2}`"
            >
              <el-time-select
                style="width: 150px"
                placeholder="開始時間"
                v-model="item2.start"
                :picker-options="{
                  start: '08:00',
                  step: '00:15',
                  end: '18:00',
                }"
              >
              </el-time-select>
              〜
              <el-time-select
                style="width: 150px"
                placeholder="終了時間"
                v-model="item2.end"
                :picker-options="{
                  start: '08:00',
                  step: '00:15',
                  end: '18:00',
                  minTime: item2.start,
                }"
              >
              </el-time-select>
              &ensp;枠数:
              <el-input-number
                v-model="item2.capacity"
                :min="1"
                :max="10"
                placeholder="枠数"
              ></el-input-number>
              <el-button
                type="danger"
                style="float: right"
                @click="onDeleteKoma(index, index2)"
                v-show="index2 != 0"
                >削除</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="onAddKoma(index)"
                icon="el-icon-circle-plus"
                >コマ追加</el-button
              >
            </el-form-item>
          </el-form>
        </el-card>
        <el-form>
          <el-form-item>
            <el-button
              type="primary"
              @click="onAddSchedule()"
              icon="el-icon-circle-plus"
              >イベント実施日追加</el-button
            >
          </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <el-form ref="form_target3" :model="doc" label-width="200px">
          <el-form-item label="キャンセル期限" prop="cancelDay">
            <el-input-number
              v-model="doc.cancelDay"
              :min="0"
              :max="100"
            ></el-input-number>
            日まで
          </el-form-item>
          <el-form-item label="残り枠表示"
            ><el-switch
              v-model="doc.visibleRemaining"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="する"
              inactive-text="しない"
            >
            </el-switch>
          </el-form-item>
          <el-divider></el-divider>
          <el-form-item>
            <el-button type="primary" @click="onSubmit"
              >この内容で登録</el-button
            >
            <el-button @click="$router.back()">キャンセル</el-button>
          </el-form-item>
        </el-form>
      </section>
    </el-col>
  </div>
</template>

<script>
import store from "../../../store";
const clonedeep = require("lodash/cloneDeep");
import PreviewService from "../../../services/preview.service";
export default {
  data() {
    return {
      value1: "",
      defaultKoma: { start: "", end: "", capacity: 4 },
      defaultSchedule: {
        date: "",
        komas: [{ start: "", end: "", capacity: 4 }],
      },
      doc: {
        title: "",
        publishRange: [],
        targets: [
          "OwnedResident",
          "OwnedNonResident",
          "Chairman",
          "Director",
          "Secretary",
        ],
        schedules: [
          {
            date: "",
            komas: [{ start: "", end: "", capacity: 4 }],
          }, //{date:日付,{range:[start,end],capacity:定員}}
        ],
        cancelDay: 1,
        visibleRemaining: true,
        mansion: null,
      },
      targets: [
        {
          value: "OwnedResident",
          label: "住居オーナー（区分所有者）",
          checked: false,
        },
        {
          value: "OwnedNonResident",
          label: "外部オーナー（区分所有者）",
          checked: false,
        },
        { value: "Chairman", label: "理事長（区分所有者）", checked: false },
        { value: "Director", label: "理事（区分所有者）", checked: false },
        { value: "Secretary", label: "監事（区分所有者）", checked: false },
        { value: "RentResident", label: "賃借人", checked: false },
        { value: "Manager", label: "管理員", checked: false },
        // { value: "ExternalParty", label: "外部関係者", checked: false },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (
      currentUser &&
      !currentUser.permissions.includes("Admin") &&
      !currentUser.permissions.includes("EventAdmin")
    ) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  async created() {
    console.log("%c++++created", "color:orange");
    console.log(this.$route.params.id);
    if (this.$route.params.id) {
      const res = await PreviewService.findByID(this.$route.params.id);
      this.doc = res.data;
    }
    console.log(this.doc);
  },
  mounted() {
    this.updateTagets(this.doc.targets);
  },
  watch: {
    rooms: function(val) {
      this.form.rooms = val.split(",");
    },
    targets: {
      // eslint-disable-next-line
      handler: function (val, oldVal) {
        console.log("someObj changed");
        const data = [];
        this.targets.forEach(element => {
          if (element.checked) {
            data.push(element.value);
          }
        });
        console.log(data);
        this.doc.targets = data;
      },
      deep: true,
    },
  },
  methods: {
    async onSubmit() {
      console.log("onSubmit");
      this.doc.mansion = this.currentUser.mansion.id;
      console.log(this.doc);
      try {
        let res;
        if (this.$route.params.id) {
          console.log("update");
          res = await PreviewService.update(this.doc);
        } else {
          console.log("create");
          res = await PreviewService.create(this.doc);
        }
        if (res.response) {
          console.log("+++ error");
          console.log(res.response);
          console.log(res.response.status);
          console.log(res.response.data);
          this.$message.error(res.response.data);
        } else {
          console.log("+++ successs");
          console.log(res);

          this.$message.success("登録しました");
          // this.$router.go(-1);
          this.$router.push({
            name: "admin_event",
          });
        }
      } catch (error) {
        console.log(error.message);
        this.$message.error("登録できませんでした");
      }
    },
    onAddKoma(index) {
      const prev = this.doc.schedules[index].komas[
        this.doc.schedules[index].komas.length - 1
      ];
      const newKoma = clonedeep(this.defaultKoma);
      console.log(prev);
      if (prev.end != "") {
        newKoma.start = prev.end;
      }
      this.doc.schedules[index].komas.push(newKoma);
    },
    onDeleteKoma(index, index2) {
      if (confirm("削除しても良いですか？")) {
        this.doc.schedules[index].komas.splice(index2, 1);
      }
    },
    onAddSchedule(copyIndex) {
      console.log(`onAddSchedule=${copyIndex}`);
      if (copyIndex != undefined) {
        console.log("copy");
        const newschedule = clonedeep(this.doc.schedules[copyIndex]);
        this.doc.schedules.push(newschedule);
      } else {
        console.log("create");
        const newschedule = clonedeep(this.defaultSchedule);
        this.doc.schedules.push(newschedule);
      }
    },
    onDeleteSchedule(index) {
      if (confirm("削除しても良いですか？")) {
        this.doc.schedules.splice(index, 1);
      }
    },
    updateTagets(array) {
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        const obj = this.targets.find(obj => obj.value == element);
        obj.checked = true;
      }
    },
  },
};
</script>

<style>
.box-card {
  margin-bottom: 20px;
}
</style>
