import axios from "axios";
// import qs from "qs";
import authHeader from "./auth-header";

const END_POINT = `${process.env.VUE_APP_API_ENDPOINT}`;

class OrderSchemaService {
  async list(query) {
    console.log("+++ list");
    console.log(query);
    const result = await axios.get(END_POINT + `/api/order_schemas`, {
      params: query,
      headers: authHeader(),
    });
    return result;
  }

  async find(query) {
    console.log("+++ find");
    console.log(query);
    const result = await axios.post(END_POINT + `/api/order_schemas`, query, {
      headers: authHeader(),
    });
    return result;
  }

  async get(id) {
    const result = await axios.get(END_POINT + `/api/order_schema/${id}`, {
      headers: authHeader(),
    });
    return result;
  }

  async edit(data) {
    const result = await axios.post(
      END_POINT + `/api/order_schema/${data._id || ""}`,
      data,
      {
        headers: authHeader(),
      },
    );
    return result;
  }
}

export default new OrderSchemaService();
