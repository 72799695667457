<template>
  <div id="participants">
    <div class="main">
      <MeetingParticipants :_id="$route.params.id" />
    </div>
  </div>
</template>

<script>
import store from "../../../store";
import MeetingParticipants from "@/components/MeetingParticipants";
export default {
  components: { MeetingParticipants },
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (currentUser && !currentUser.permissions.includes("Admin")) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
};
</script>

<style></style>
