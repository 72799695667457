<template>
  <div id="meeting-summary">
    <section class="main-section" v-if="meeting.title != ''">
      <h3 class="section-title green-left-head">総会のご案内</h3>
      <p class="section-block">{{ meeting.desc }}</p>

      <div class="box-item-sokai">
        <div class="box-item-wrapper">
          <ul class="box-item-list">
            <li class="box-item-list-item">
              <div class="text">
                {{ meeting.date | moment("YYYY年MM月DD日 (dd)") }}&nbsp;{{
                  meeting.start_at
                }}〜{{ meeting.end_at }}<br />{{ meeting.title }}&nbsp;場所：{{
                  meeting.location
                }}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <!--/.main-section-->

    <section
      class="main-section buttons"
      v-if="meeting.title != '' && mode == 'all'"
    >
      <p class="section-block center">
        <router-link
          :to="{
            name: 'etohyo',
            params: { mc_code: currentUser.mansion._id },
          }"
          ><button class="bt bt-green">出欠の提出</button></router-link
        >
      </p>
      <p class="section-block center">
        <button
          class="bt bt-green bt-green-sub pdfopenBtn"
          @click="onOpen()"
          :disabled="!isExistFile"
        >
          議案の確認
        </button>
      </p>
      <p class="section-block center">
        <router-link
          :to="{
            name: 'meeting_detail',
            params: { mc_code: currentUser.mansion._id },
          }"
        >
          <button
            class="bt bt-modalopen"
            :disabled="!isMeetingTime"
            :class="{ 'bt-green': isMeetingTime, 'bt-gray': !isMeetingTime }"
          >
            総会に参加する
          </button>
        </router-link>
      </p>
      <p class="section-block center" v-if="isAdmin">
        <router-link
          target="_blank"
          :to="{
            name: 'admin-chat',
            params: { id: meeting._id, mc_code: currentUser.mansion._id },
          }"
        >
          <button
            class="bt bt-green bt-modalopen"
            style="display: flex;flex-direction: column;align-items: center;"
          >
            <span style="font-size :1.7rem;">質疑チャット管理</span
            ><span style="font-size :1.0rem;"> ※別ウィンドウで開きます。</span>
          </button>
        </router-link>
      </p>
      <p class="section-block center" v-else>
        <button class="bt bt-green bt-modalopen" @click="isChatView = true">
          質疑チャット
        </button>
      </p>
    </section>
    <section
      class="main-section buttons"
      v-if="meeting.title != '' && mode == 'def'"
    >
      <p class="section-block center">
        <router-link
          :to="{
            name: 'meeting',
            params: { mc_code: currentUser.mansion._id },
          }"
        >
          <button class="bt bt-green bt-modalopen">
            詳しくみる
          </button>
        </router-link>
      </p>
    </section>
    <!--/.main-section-->
    <div id="pdfContainer" v-show="isPDFView" style="z-index:100;">
      <iframe
        src=""
        type="application/pdf"
        width="100%"
        height="100%"
        style="overflow: auto;"
        id="pdfView"
      ></iframe>
      <div class="chatclose chatopen" @click="isPDFView = false">閉じる</div>
    </div>
    <div id="chat" v-if="isChatView" style="z-index:100;">
      <!-- <div class="chat-title"> -->
      <!-- <div class="chatclose">閉じる</div> -->
      <h2 class="chat-heading" style="position:relative;">
        質疑チャット
      </h2>
      <div class="chatclose chatopen" @click="isChatView = false">閉じる</div>
      <!-- </div> -->
      <ChatView
        class="messages"
        :room="privateRoom"
        :name="currentUser.roomNo"
        :dispName="currentUser.roomNo"
        roomName="質疑チャット"
        mode="user"
        :filter="currentUser.roomNo"
      ></ChatView>
      <ChatInput
        :room="privateRoom"
        :name="currentUser.roomNo"
        :dispName="currentUser.username"
        roomName="質疑応答チャット"
        to="admin"
      ></ChatInput>
    </div>
  </div>
</template>

<script>
import MeetingService from "../services/meeting.service";
import FileService from "../services/file.service";

import ChatView from "@/components/ChatView.vue";
import ChatInput from "@/components/ChatInput.vue";
import moment from "moment";

// eslint-disable-line

export default {
  props: ["mode", "isAdmin"],
  name: "MeetingSummary",
  components: { ChatView, ChatInput },
  computed: {
    privateRoom() {
      console.log("meetingSummury privateRoom");
      console.log(this.meeting._id);
      return `admin-private_${this.meeting._id}`;
    },
    isExistFile: function() {
      return (
        this.meeting.files.length > 0 && this.meeting.files[0].name != undefined
      );
    },
  },
  watch: {
    isPDFView: function(val) {
      if (val) {
        // window.scrollTo(0, 0);
        // document.body.style.overflow = "hidden";
        // history.pushState(null, null, document.URL);
      } else {
        // console.log("-");
        // document.body.style.overflow = "auto";
      }
    },
    isChatView: function(val) {
      console.log("++opne");
      if (val) {
        // window.scrollTo(0, 0);
        //   document.body.style.overflow = "hidden";
      } else {
        //   console.log("++close");
        //   document.body.style.overflow = "auto";
      }
    },
  },
  mounted: function() {
    MeetingService.item("current").then(
      docs => {
        console.log("MeetingSummary:mounted");
        console.log(docs);
        if (docs) {
          this.meeting = docs;
          this.isMeetingTime = this.checkMeetingTime() || this.isAdmin;
          console.log(`isMeetingTime=${this.isMeetingTime}`);
        }
      },
      // eslint-disable-next-line
      error => {
        // console.log(error);
      },
    );
  },
  data: function() {
    return {
      isPDFView: false,
      isChatView: false,
      isMeetingTime: false,
      meeting: {
        title: "",
        desc: "",
        date: "",
        start_at: "",
        end_at: "",
        location: "",
        streaming: "",
        files: [],
        is_published: false,
      },
    };
  },
  methods: {
    checkMeetingTime() {
      const marginHour = 2;
      // console.log(this.meeting.date);
      const dd = moment(this.meeting.date).format("YYYY/MM/DD");
      // console.log(dd);
      const now = moment().format("YYYY/MM/DD HH:mm");
      const stt = moment(`${dd} ${this.meeting.start_at}`);
      console.log("++++ checkMeetingTime");
      console.log(`stt=${stt}`);
      const st2 = stt.add(-marginHour, "hour");
      console.log(`st2=${st2}`);
      const ett = moment(`${dd} ${this.meeting.end_at}`);
      console.log(`ett=${ett}`);
      const et2 = ett.add(marginHour, "hour");
      console.log(`et2=${et2}`);

      // const st = `${dd} ${this.meeting.start_at}`;
      // const ed = `${dd} ${this.meeting.end_at}`;

      const st = st2.format("YYYY/MM/DD HH:mm");
      const ed = et2.format("YYYY/MM/DD HH:mm");

      console.log(`st =${st}`);
      console.log(`now=${now}`);
      console.log(`ed =${ed}`);
      // console.log(stt2);
      // console.log(ett2);
      const r = st <= now && ed >= now;
      console.log(`r=${r}`);
      return r;
    },
    onOpen() {
      // console.log(this.meeting.files[0].name);
      const self = this;
      if (this.meeting.files.length > 0 && this.meeting.files[0].name) {
        const fileName = this.meeting.files[0].name;
        FileService.download("meeting", this.meeting._id, fileName).then(
          response => {
            // return response.data;
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: "application/pdf" }),
            );
            document.getElementById("pdfView").src =
              "/pdfjs/web/viewer.html?file=" + url;
            self.isPDFView = true;
          },
        );
      }
    },
  },
};
</script>

<style></style>
