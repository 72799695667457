<template>
  <div class="mainContentBox">
    <div class="mainContentBoxInner">
      <div class="pageTitleWrapper">
        <h3 class="section-title green-left-head">お知らせ</h3>
      </div>
      <!-- head only start -->
      <div>
        <section class="kanri-lv1" v-if="isPermission('Admin')">
          <div class="btn-kanri">
            <router-link
              :to="{
                name: 'admin_information',
                params: { mc_code: currentUser.mansion._id },
              }"
              >→お知らせ登録はこちら</router-link
            >
          </div>
        </section>
        <!-- head only end -->
        <!-- news -->
        <GlobalNewsList />
        <NewsDetailList />
        <!-- /news -->
      </div>
    </div>
  </div>
</template>

<script>
// import NewsDetailList from "@/components/InfoList.vue";
import NewsDetailList from "@/components/NewsList.vue";
import GlobalNewsList from "@/components/GlobalNewsList.vue";
// import NewsDetailList from "@/components/NewsDetailList.vue";
// import MeetingSummary from "@/components/MeetingSummary.vue";
// import IconMenu from "@/components/IconMenu.vue";
// import GA from "../../../mixin/gtag";
export default {
  // mixins: [GA],
  components: { NewsDetailList, GlobalNewsList },
};
</script>

<style></style>
