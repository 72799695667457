import axios from "axios";
import authHeader from "./auth-header";
const BASE_API_URL = `${process.env.VUE_APP_API_ENDPOINT}/api/info/`;
// console.log(BASE_API_URL);

class InfoService {
  async find(obj) {
    const res = await axios.post(BASE_API_URL + `find`, obj, {
      headers: authHeader(),
    });
    return res;
  }

  async findOne(_id) {
    const res = await axios.get(BASE_API_URL + _id, {
      headers: authHeader(),
    });
    return res;
  }

  async findByDB(maintionID, page) {
    const result = await axios.get(
      BASE_API_URL + `findbydb?mansion=${maintionID}&page=${page}`,
      {
        headers: authHeader(),
      },
    );
    return result;
  }

  async list(param) {
    const params = param ? param : {};
    const res = await axios.post(BASE_API_URL + `list`, params, {
      headers: authHeader(),
    });
    return res;
  }

  // async remove(id) {
  //   // console.log("servece remove 1");
  //   const res = await axios.post(
  //     API_REMOVE,
  //     { _id: id },
  //     { headers: authHeader() }
  //   );
  //   return res;
  // }

  async remove(_id) {
    return await axios.delete(BASE_API_URL + `${_id}`, {
      headers: authHeader(),
    });
  }

  async update(data) {
    // console.log("bod.update");
    // console.log(data);
    return await axios.post(BASE_API_URL + `item`, data, {
      headers: authHeader(),
    });
  }
}

export default new InfoService();
