import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth.module";
import { meeting } from "./meeting.module";
import { bod } from "./bod.module";
import { vote } from "./vote.module";
import { mansion } from "./mansion.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    meeting,
    bod,
    vote,
    mansion,
  },
});
