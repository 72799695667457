import axios from "axios";
// import qs from "qs";
import authHeader from "./auth-header";

const END_POINT = `${process.env.VUE_APP_API_ENDPOINT}`;

class FormAnswerService {
  async find(query) {
    const result = await axios.get(END_POINT + `/api/answers`, {
      params: query,
      headers: authHeader(),
    });
    return result;
  }

  async get(id) {
    const result = await axios.get(END_POINT + `/api/contact/${id}`, {
      headers: authHeader(),
    });
    return result;
  }

  async edit(data) {
    const result = await axios.post(
      END_POINT + `/api/contact/${data._id || ""}`,
      data,
      {
        headers: authHeader(),
      },
    );
    return result;
  }
}

export default new FormAnswerService();
