<template>
  <div></div>
</template>

<script>
import CstService from "../../services/cst.service";
const _ = require("lodash");
export default {
  async mounted() {
    try {
      const doc_type = this.$route.params.doc_type;
      console.log(doc_type);
      const result = await CstService.url(doc_type);
      console.log({ result });
      const url = _.get(result, "data.url");
      console.log({ url });
      if (url) {
        window.location = url;
      } else {
        this.$router.push({
          name: "notfound404",
        });
      }
    } catch (error) {
      console.log(error);
      this.$router.push({
        name: "notfound404",
      });
    }
  },
};
</script>
