<template>
  <div class="wrapper">
    <div class="hdr">
      <div class="hdr-wrapper">
        <div class="logo">
          <a href="/"
            ><img src="/i-salon/assets/img/common/logo-main.svg" alt="iuc SALON"
          /></a>
        </div>
      </div>
    </div>
    <!--/.hdr-->

    <div class="main" v-if="status == 'edit'">
      <section class="main-section">
        <h3 class="section-title green-left-head">パスワードを忘れた方</h3>

        <div class="faq-wrapper">
          <h4 class="faq-cate section-title">
            ご登録のメールアドレスに、パスワード再設定のご案内をお送りいたします。<br />
            下記手順に従って再設定ください。
          </h4>

          <ul class="stepList">
            <li>
              1.
              ログインID、メールアドレスを入力します。「送信」ボタンをクリックします。
            </li>
            <li>
              2.
              登録したメールアドレスに届く「パスワード再設定URL」をクリックしてパスワードを再設定してください。
            </li>
            <li>3. パスワード再設定後、iuc SALONへログインしてください。</li>
          </ul>
        </div>

        <section class="mainContent-form">
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="">ログインID</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id=""
                    ><input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-en"
                      maxlength="128"
                      name=""
                      v-model.trim="$v.values.loginID.$model"
                  /></label>
                  <div class="error" v-if="!$v.values.loginID.required">
                    ログインIDは必須です
                  </div>
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="">ご登録メールアドレス</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id=""
                    ><input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-en"
                      maxlength="128"
                      name=""
                      v-model.trim="$v.values.email.$model"
                  /></label>
                  <div class="error" v-if="!$v.values.email.required">
                    ご登録メールアドレスは必須です
                  </div>
                  <div class="error" v-if="!$v.values.email.email">
                    正しいメールアドレスを入力してください
                  </div>
                </div>
              </div>
            </dd>
          </dl>

          <div class="formTableCaution">
            ※ログインIDが誤っている場合、またはご登録メールアドレス以外のメールアドレスを入力した場合は、ご案内メールは送信されません。
          </div>

          <div class="cn-back-btn">
            <a href="javascript:void(0);" @click="onPost">送信</a>
          </div>
        </section>
      </section>
    </div>
    <!--/.main-->

    <div class="main" v-else>
      <section class="main-section">
        <h3 class="section-title green-left-head">パスワードを忘れた方</h3>

        <div class="faq-wrapper">
          <span class="caution"
            >登録したメールアドレスに届く「パスワード再設定URL」をクリックしパスワードを再設定して、改めてログインください。</span
          >
          <br /><br />
          <span class="backLink" style="display:none;"
            ><a
              href="/"
              style="display: block; color: #000562; text-decoration: underline"
              >→iuc SALON ログインページへ</a
            ></span
          >
        </div>
      </section>
    </div>
    <!--/.main-->

    <div class="ftr">
      <div class="ftr-top-wrapper">
        <ul class="ftr-link">
          <li class="ftr-link-item">
            <a href="/i-salon/docs/kiyaku.html">利用規約</a>
          </li>
          <li class="ftr-link-item">
            <a href="/i-salon/docs/faq.html">よくある質問</a>
          </li>
        </ul>
      </div>
      <div class="ftr-bottom-wrapper">
        <div class="copyright">
          &copy; 2023 ITOCHU Urban Community
        </div>
      </div>
    </div>
    <!--/.ftr-->
  </div>
  <!--/.wrapper-->
</template>

<script>
import { required, email } from "vuelidate/lib/validators";

import UserService from "../services/user.service";

export default {
  name: "PasswordReminder",
  data() {
    return {
      status: "edit",
      values: {
        email: "",
        loginID: "",
      },
    };
  },
  validations: {
    values: {
      email: {
        required,
        email,
      },
      loginID: {
        required,
      },
    },
  },
  mounted: async function() {},
  methods: {
    async onPost() {
      console.log("onPost");
      // console.log("submit!");
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        // do your submit logic here

        const result = await UserService.prePasswordReset(this.values);
        this.status = "complete";
        console.log(result);
        if (result.status == "200") {
          console.log("OK");
        } else {
          console.log("ERROR");
        }
        console.log("OK");
      }
    },
  },
};
</script>

<style></style>
