<template>
  <div class="mainContentBox">
    <div class="mainContentBoxInner eventrsv eventdetail">
      <div class="pageTitleWrapper">
        <h3 class="section-title green-left-head">イベント予約</h3>
      </div>
      <div class="preview-index">
        <div class="reserve" v-if="!isReserved">
          <div
            v-for="(preview, index) in previews"
            :key="`preview_${index}`"
            class="collapse"
          >
            <div class="eventtitle">{{ preview.title }}</div>
            <ul class="event">
              <li
                class="day"
                v-for="(schedule, sindex) in preview.schedules"
                :key="`schedule_${index}_${sindex}`"
              >
                <p class="date">
                  {{ schedule.date | moment("YYYY/MM/DD (ddd)") }}
                </p>
                <ul class="koma-parent">
                  <li
                    v-for="(koma, kindex) in schedule.komas"
                    :key="`koma_${index}_${sindex}_${kindex}`"
                  >
                    <div
                      class="koma-item"
                      :class="{
                        disabled: koma.capacity - koma.rooms.length == 0,
                      }"
                      @click="
                        onReserve(
                          preview._id,
                          sindex,
                          kindex,
                          koma.capacity - koma.rooms.length,
                        )
                      "
                    >
                      <p>{{ koma.start }}〜{{ koma.end }}</p>
                      <p v-if="preview.visibleRemaining">
                        残り{{ koma.capacity - koma.rooms.length }}
                      </p>
                    </div>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="reserved" v-else>
          <div class="eventtitle">{{ reservedData.title }}</div>
          <div class="date">
            {{ reservedData.date | moment("YYYY/MM/DD(ddd)") }}
          </div>
          <div class="time">
            {{ reservedData.start }}〜{{ reservedData.end }}
          </div>
          <div>上記時間帯で予約済みです。</div>
          <div class="btns" v-if="canCancel">
            <div @click="onCancel">キャンセル</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PreviewSrevice from "../../../services/preview.service";
import PreviewReserveService from "../../../services/preview_reserve.service";
import moment from "moment";
export default {
  data() {
    return {
      isReserved: false,
      previews: [],
      reservedData: {},
      canCancel: true,
    };
  },
  async created() {
    console.log("+created");
    await this.reloadData();
  },
  mounted() {
    // var setTitle =
    //   this.previews[0].title + ` | ${this.currentUser.mansion.name}`;
    // document.title = setTitle;
    // console.log("mouted",this.previews);
  },
  methods: {
    async reloadData() {
      console.log("reloadData");
      this.isExistReserve();
      const res = await PreviewSrevice.findWithReserve(
        this.$route.params.previewid,
      );
      console.log(res.data);
      this.previews = res.data;
      var setTitle =
        this.previews[0].title + `予約 | ${this.currentUser.mansion.name}`;
      document.title = setTitle;

      // console.log(this.reservedData);
      const dd = moment(this.reservedData.date).subtract(
        this.reservedData.cancelDay,
        "d",
      );
      // console.log(dd.format());

      this.canCancel = moment().isBefore(dd);
    },
    onUpdate() {},
    async onCancel() {
      if (confirm("キャンセルしても良いですか？")) {
        console.log("onCancel");

        const res = await PreviewReserveService.remove(this.reservedData._id);
        console.log(res);
        this.$message.success("キャンセルしました");
        this.reloadData();
      }
    },
    async isExistReserve() {
      const res = await PreviewReserveService.find({
        q: `{"mansion":"${this.currentUser.mansion.id}","roomNo":"${this.currentUser.roomNo}"}`,
      });
      console.log("isExistReserve", res);
      this.isReserved = res.data.length > 0;
      if (this.isReserved) {
        this.reservedData = {};
        const previewReserve = res.data[0];
        console.log("previewReserve", previewReserve);
        this.reservedData.cancelDay = previewReserve.preview.cancelDay;
        const preview = res.data[0].preview;
        this.reservedData.title = preview.title;
        this.reservedData.date =
          preview.schedules[previewReserve.scheduleIndex].date;
        this.reservedData.start =
          preview.schedules[previewReserve.scheduleIndex].komas[
            previewReserve.komaIndex
          ].start;
        this.reservedData.end =
          preview.schedules[previewReserve.scheduleIndex].komas[
            previewReserve.komaIndex
          ].end;
        this.reservedData._id = previewReserve._id;
      }
    },
    onReserve(previewid, scheduleIndex, komaIndex, nokori) {
      console.log(
        `onReserve_${previewid}_${scheduleIndex},${komaIndex}_${nokori}`,
      );
      if (nokori > 0) {
        this.$router.push({
          name: "isalon-event-reserve",
          params: { previewid, scheduleIndex, komaIndex },
        });
      }
    },
  },
};
</script>

<style scoped>
.preview-index {
  font-size: 14px;
}

.koma-parent {
  margin-left: 20px;
}

.koma-item {
  display: flex;
  flex-direction: row;
}
</style>
