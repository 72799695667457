import axios from "axios";
// import qs from "qs";
import authHeader from "./auth-header";

const END_POINT = `${process.env.VUE_APP_API_ENDPOINT}`;

class OrderService {
  async list(query) {
    console.log("+++ Order.list");
    console.log(query);
    const result = await axios.get(END_POINT + `/api/orders`, {
      params: query,
      headers: authHeader(),
    });
    return result;
  }

  async find(query) {
    console.log("+++ find");
    console.log(query);
    const result = await axios.post(END_POINT + `/api/orders`, query, {
      headers: authHeader(),
    });
    return result;
  }

  async get(id) {
    const result = await axios.get(END_POINT + `/api/order/${id}`, {
      headers: authHeader(),
    });
    return result;
  }

  async edit(data) {
    const result = await axios.post(
      END_POINT + `/api/order/${data._id || ""}`,
      data,
      {
        headers: authHeader(),
      },
    );
    return result;
  }

  async editByDirectroy(directory, data) {
    const result = await axios.post(
      END_POINT + `/api/orderbydirectory/${directory}`,
      data,
      {
        headers: authHeader(),
      },
    );
    return result;
  }

  download(mansion) {
    return axios.get(
      `${process.env.VUE_APP_API_ENDPOINT}/api/download/order/${mansion}`,
      {
        responseType: "blob",
        dataType: "binary",
        headers: authHeader(),
      },
    );
  }
}

export default new OrderService();
