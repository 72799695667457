import axios from "axios";
import authHeader from "./auth-header";
// import _ from "lodash";

console.log("VUE_APP_API_ENDPOINT", process.env.VUE_APP_API_ENDPOINT);

// console.log(API_URL);

class GlobalInfoService {
  async findSchedule() {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_ENDPOINT}/api/gim-schedule`,
        {
          headers: authHeader(),
        },
      );
      if (!(response instanceof Error)) {
        return response.data;
      } else {
        throw response;
      }
    } catch (error) {
      console.log(error);
      console.log(`ERROR!! occurred in Backend.!`);
      throw error;
    }
  }

  async findCalender(obj) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_ENDPOINT}/api/gim-calender/${obj.year}/${obj.month}`,
        {
          headers: authHeader(),
        },
      );
      if (!(response instanceof Error)) {
        return response.data;
      } else {
        throw response;
      }
    } catch (error) {
      console.log(error);
      console.log(`ERROR!! occurred in Backend.!`);
      throw error;
    }
  }

  async findByID(id) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_API_ENDPOINT}/api/global-info-master/${id}`,
        {
          headers: authHeader(),
        },
      );
      if (!(response instanceof Error)) {
        return response.data;
      } else {
        throw response;
      }
    } catch (error) {
      console.log(error);
      console.log(`ERROR!! occurred in Backend.!`);
      throw error;
    }
  }
}

export default new GlobalInfoService();
