<template>
  <div id="meeting-top">
    <div class="main">
      <!-- 総会のご案内 -->
      <div>
        <MeetingSummary mode="all" :isAdmin="isPermission('Admin')" />
      </div>
      <section class="main-section kanri-lv1" v-if="isPermission('Admin')">
        <div class="btn_kanri">
          <router-link
            :to="{
              name: 'admin_meeting',
              params: { mc_code: currentUser.mansion._id },
            }"
            >総会管理</router-link
          >
        </div>
      </section>

      <div style="margin-top:40px;"></div>
      <!--/.main-section-->
      <IconMenu />

      <!--/.main-section-->
    </div>
    <!--/.main-->
  </div>
</template>

<script>
import MeetingSummary from "@/components/MeetingSummary.vue";
import IconMenu from "@/components/IconMenu.vue";

import store from "../../store";
export default {
  title: "トップ",
  components: { MeetingSummary, IconMenu },
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (currentUser && !currentUser.permissions.includes("OwnedNonResident")) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
};
</script>

<style></style>
