import axios from "axios";
import authHeader from "./auth-header";

const API_URL = `${process.env.VUE_APP_API_ENDPOINT}/api/meeting/`;
// console.log(API_URL);
class MeetingService {
  all() {
    return axios
      .get(API_URL + "all", { headers: authHeader() })
      .then(response => {
        return response.data;
      });
  }

  item(id) {
    return axios.get(API_URL + id, { headers: authHeader() }).then(response => {
      return response.data;
    });
  }
  update(meeting) {
    return axios
      .post(API_URL, meeting, { headers: authHeader() })
      .then(response => {
        return response.data;
      });
  }

  remove(_id) {
    return axios
      .delete(API_URL + _id, { headers: authHeader() })
      .then(response => {
        return response.data;
      });
  }

  addParticipant(_id, roomNo) {
    const obj = {
      roomNo: roomNo,
    };
    return axios
      .post(`${API_URL}${_id}/participants`, obj, {
        headers: authHeader(),
      })
      .then(response => {
        return response.data;
      });
  }

  clearParticipant(_id) {
    return axios
      .delete(`${API_URL}${_id}/participants`, { headers: authHeader() })
      .then(response => {
        return response.data;
      });
  }

  getParticipants(_id) {
    return axios
      .get(`${API_URL}${_id}/participants`, { headers: authHeader() })
      .then(response => {
        return response.data;
      });
  }
}

export default new MeetingService();
