import axios from "axios";
import authHeader from "./auth-header";

const API_URL = `${process.env.VUE_APP_API_ENDPOINT}/api/test/`;
const API_BASE_URL = `${process.env.VUE_APP_API_ENDPOINT}/api/user/`;
const API_OPTION_URL = `${process.env.VUE_APP_API_ENDPOINT}/api/user-options/`;
const API_SETTINGS_URL = `${process.env.VUE_APP_API_ENDPOINT}/api/user-settings/`;

class UserService {
  getPublicContent() {
    return axios.get(API_URL + "all");
  }

  getUserBoard() {
    return axios.get(API_URL + "user", { headers: authHeader() });
  }

  getModeratorBoard() {
    return axios.get(API_URL + "mod", { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + "admin", { headers: authHeader() });
  }

  async findMansionByUsetID(user_id) {
    const url = `${process.env.VUE_APP_API_ENDPOINT}/api/mansion/user/${user_id}`;
    const result = await axios.get(url);
    return result;
  }
  async findInMansion(mansionid, roomNo) {
    const url = API_BASE_URL + `mansion/${mansionid}/${roomNo}`;
    console.log(url);
    const result = await axios.get(url, {
      headers: authHeader(),
    });
    return result;
  }

  async findByIndex(lte, gte) {
    const result = await axios.get(API_BASE_URL + `findbyindex/${lte}/${gte}`, {
      headers: authHeader(),
    });
    return result;
  }

  async setToken(token) {
    const result = await axios.post(
      API_BASE_URL + `token`,
      { token: token },
      {
        headers: authHeader(),
      },
    );
    return result;
  }

  async findByDB(maintionID, page) {
    const result = await axios.get(
      API_BASE_URL + `findbydb?mansion=${maintionID}&page=${page}`,
      {
        headers: authHeader(),
      },
    );
    return result;
  }

  async findByID(_id) {
    const result = await axios.get(API_BASE_URL + `find/${_id}`, {
      headers: authHeader(),
    });
    return result;
  }

  async edit(user) {
    console.log("edit");
    console.log(user);
    return await axios.post(
      API_BASE_URL + `${user._id}`,
      {
        loginID: user.loginID,
        username: user.username,
        email: user.email,
        mansion: user.mansion,
        roomNo: user.roomNo,
        role: user.role,
        needToSendEmail: user.needToSendEmail,
        status: user.status,
        contractorToResidentDate: user.contractorToResidentDate,
      },
      { headers: authHeader() },
    );
  }
  async editOptions(params) {
    console.log("editOptions", params);
    return await axios.post(API_OPTION_URL + `${params._id}`, params, {
      headers: authHeader(),
    });
  }

  async update(id, query) {
    console.log("++ update");
    return await axios.post(API_BASE_URL + `${id}`, query, {
      headers: authHeader(),
    });
  }

  async updateUsersStatus(users, query) {
    console.log("++ updateUsersStatus");
    return await axios.post(
      API_BASE_URL + `status`,
      {
        users,
        status: query.status,
        contractorToResidentDate: query.contractorToResidentDate,
      },
      {
        headers: authHeader(),
      },
    );
  }

  async remove(_id) {
    console.log(`remove=${_id}`);
    return await axios.delete(API_BASE_URL + `${_id}`, {
      headers: authHeader(),
    });
  }

  async messaging(title, body, tokens, clickAction) {
    console.log(`messagin`);
    const paload = {
      title: title,
      body: body,
      tokens: tokens,
      clickAction: clickAction,
    };
    return await axios.post(API_BASE_URL + `messaging`, paload, {
      headers: authHeader(),
    });
  }

  async sendmail(payload) {
    console.log(`sendmail`);
    return await axios.post(API_BASE_URL + `sendmail`, payload, {
      headers: authHeader(),
    });
  }

  async changePassword(newPassword) {
    console.log("++++ changePassword");
    try {
      const response = await axios.post(
        API_BASE_URL + "password",
        {
          password: newPassword,
        },
        {
          headers: authHeader(),
        },
      );
      console.log(response);
      return response;
    } catch (error) {
      console.log("++++error");
      console.log(error);
      // alert(error);
      console.log("ERROR!! occurred in Backend.!");
      throw error;
    }
  }

  async changeEmail(newEmail) {
    console.log("++++ changeEmail");
    try {
      const response = await axios.post(
        API_BASE_URL + "email",
        {
          email: newEmail,
        },
        {
          headers: authHeader(),
        },
      );
      console.log(response);
      return response;
    } catch (error) {
      console.log("++++error");
      console.log(error);
      // alert(error);
      console.log("ERROR!! occurred in Backend.!");
      throw error;
    }
  }

  async getReminder(email, key) {
    const API = `${process.env.VUE_APP_API_ENDPOINT}/api/password-reminder/`;
    const result = await axios.get(API, {
      params: {
        email,
        key,
      },
    });
    return result;
  }

  // {
  //     email,
  //     loginID,
  //   }
  async prePasswordReset(params) {
    console.log("++++ prePasswordReset");
    console.log(params);
    const API = `${process.env.VUE_APP_API_ENDPOINT}/api/pre-password-reset/`;
    const result = await axios.post(API, params);
    return result;
  }

  async resetPassword(email, key, password) {
    const API = `${process.env.VUE_APP_API_ENDPOINT}/api/reset-password/`;
    const result = await axios.post(API, {
      email,
      key,
      password,
    });
    return result;
  }

  async preVerifyEmail(newEmail, loginID) {
    console.log("++++ pre-verify-email");
    try {
      const response = await axios.post(
        `${process.env.VUE_APP_API_ENDPOINT}/api/pre-verify-email/`,
        {
          email: newEmail,
          loginID,
        },
        {
          headers: authHeader(),
        },
      );
      console.log(response);
      return response;
    } catch (error) {
      console.log("++++error");
      console.log(error);
      // alert(error);
      console.log("ERROR!! occurred in Backend.!");
      throw error;
    }
  }

  async verifyEmail(email, key) {
    const API = `${process.env.VUE_APP_API_ENDPOINT}/api/verify-email/`;
    const result = await axios.post(API, {
      email,
      key,
    });
    return result;
  }

  async getRoonNosInMansion(mansionid) {
    const result = await axios.get(
      `${process.env.VUE_APP_API_ENDPOINT}/api/roomnos/${mansionid}`,
      {
        headers: authHeader(),
      },
    );
    return result;
  }

  async getSettings() {
    const result = await axios.get(API_SETTINGS_URL, {
      headers: authHeader(),
    });
    return result;
  }

  async updateSettings(payload) {
    console.log(`updateSettings`, payload);
    return await axios.post(API_SETTINGS_URL, payload, {
      headers: authHeader(),
    });
  }

  async getUsers(query) {
    console.log(`getUsers`);
    const result = await axios.get(
      `${process.env.VUE_APP_API_ENDPOINT}/api/current-mansion/users`,
      {
        headers: authHeader(),
        query,
      },
    );
    return result;
  }

  async preCheck(loginID) {
    console.log(`preCheck`);
    const result = await axios.get(
      `${process.env.VUE_APP_API_ENDPOINT}/api/v1/user-precheck`,
      {
        params: { loginID },
      },
    );
    return result;
  }
}

export default new UserService();
