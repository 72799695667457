import MeetingService from "../services/meeting.service";
const initialState = { meetings: [], current: {} };

export const meeting = {
  namespaced: true,
  state: initialState,
  actions: {
    all({ commit }) {
      return MeetingService.all().then(
        docs => {
          commit("meetings", docs);
          return Promise.resolve(docs);
        },
        error => {
          commit("meetings", []);
          return Promise.reject(error);
        },
      );
    },
    item({ commit }, id) {
      return MeetingService.item(id).then(
        docs => {
          commit("current", docs);
          return Promise.resolve(docs);
        },
        error => {
          commit("current", {});
          return Promise.reject(error);
        },
      );
    },
  },
  mutations: {
    meetings(state, docs) {
      state.meetings = docs;
    },
    current(state, docs) {
      state.current = docs;
    },
  },
  getters: {
    meetings: state => {
      return state.meetings;
    },
  },
};
