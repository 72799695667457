var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-main',{attrs:{"id":"meeting-edit"}},[_c('h3',{staticClass:"section-title green-left-head title-margin"},[_vm._v(" 総会 "+_vm._s(_vm.param == "create" ? "新規作成" : "編集")+" ")]),_c('el-col',{attrs:{"span":20}},[_c('el-form',{ref:"form",attrs:{"model":_vm.meeting,"label-width":"20%"}},[_c('el-form-item',{attrs:{"label":"公開"}},[_c('el-switch',{attrs:{"active-color":"#13ce66","inactive-color":"#ff4949","active-text":"公開","inactive-text":"非公開"},model:{value:(_vm.meeting.is_published),callback:function ($$v) {_vm.$set(_vm.meeting, "is_published", $$v)},expression:"meeting.is_published"}})],1),_c('el-form-item',{attrs:{"label":"総会名"}},[_c('el-input',{model:{value:(_vm.meeting.title),callback:function ($$v) {_vm.$set(_vm.meeting, "title", $$v)},expression:"meeting.title"}})],1),_c('el-form-item',{attrs:{"label":"説明"}},[_c('el-input',{attrs:{"type":"textarea","rows":2},model:{value:(_vm.meeting.desc),callback:function ($$v) {_vm.$set(_vm.meeting, "desc", $$v)},expression:"meeting.desc"}})],1),_c('el-form-item',{attrs:{"label":"日付"}},[_c('el-date-picker',{attrs:{"type":"date","placeholder":"日付選択"},model:{value:(_vm.meeting.date),callback:function ($$v) {_vm.$set(_vm.meeting, "date", $$v)},expression:"meeting.date"}})],1),_c('el-form-item',{attrs:{"label":"開始時間"}},[_c('el-col',{attrs:{"span":6}},[_c('el-time-select',{attrs:{"picker-options":{
              start: '00:00',
              step: '00:15',
              end: '23:45',
            },"placeholder":"開始時間"},model:{value:(_vm.meeting.start_at),callback:function ($$v) {_vm.$set(_vm.meeting, "start_at", $$v)},expression:"meeting.start_at"}})],1)],1),_c('el-form-item',{attrs:{"label":"終了時間"}},[_c('el-col',{attrs:{"span":6}},[_c('el-time-select',{attrs:{"picker-options":{
              start: '00:00',
              step: '00:15',
              end: '23:45',
            },"placeholder":"終了時間"},model:{value:(_vm.meeting.end_at),callback:function ($$v) {_vm.$set(_vm.meeting, "end_at", $$v)},expression:"meeting.end_at"}})],1)],1),_c('el-form-item',{attrs:{"label":"場所"}},[_c('el-input',{model:{value:(_vm.meeting.location),callback:function ($$v) {_vm.$set(_vm.meeting, "location", $$v)},expression:"meeting.location"}})],1),_c('el-form-item',{attrs:{"label":"ビデオURL"}},[_c('el-input',{model:{value:(_vm.meeting.streaming),callback:function ($$v) {_vm.$set(_vm.meeting, "streaming", $$v)},expression:"meeting.streaming"}})],1),_c('el-form-item',{attrs:{"label":"議案ファイル"}},[_c('el-upload',{staticClass:"upload-demo",attrs:{"action":_vm.uploadApi,"on-preview":_vm.handlePreview,"on-remove":_vm.handleRemove,"before-remove":_vm.beforeRemove,"on-exceed":_vm.handleExceed,"file-list":_vm.meeting.files,"on-success":_vm.onSuccess,"headers":_vm.header,"multiple":false,"data":{ id: _vm.$route.params.id, category: 'meeting' },"accept":".pdf"}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("ファイル選択")])],1)],1),_c('el-form-item',{attrs:{"label":"参加者"}},[_c('ul',{staticClass:"box-item-list",staticStyle:{"display":"inline-block","flex-wrap":"wrap"}},_vm._l((_vm.meeting.participants),function(participant,index){return _c('li',{key:index,staticStyle:{"display":"flex"}},[_c('div',{staticStyle:{"width":"150px"}},[_vm._v(" "+_vm._s(_vm._f("moment")(participant.timestamp,"YYYY/MM/DD HH:mm:ss"))+" ")]),_c('div',[_vm._v(_vm._s(participant.roomNo)+" 号室")])])}),0)]),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v("登録")]),_c('el-button',{attrs:{"type":"warning"},on:{"click":_vm.onRemove}},[_vm._v("削除")]),_c('el-button',{on:{"click":function($event){return _vm.$router.push({
              name: 'admin_meeting_index',
              params: { mc_code: _vm.currentUser.mansion._id },
            })}}},[_vm._v("キャンセル")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }