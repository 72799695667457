<template>
  <footer class="footer">
    <div class="_footer-inner">
      <div class="_footer-navi-list">
        <div class="_footer-navi-item">
          <a href="https://www.itc-uc.co.jp/privacy/" target="_blank"
            >個人情報保護方針</a
          >
        </div>
        <div class="_footer-navi-item">
          <a href="/i-salon/docs/kiyaku.html" target="_blank">ご利用規約</a>
        </div>
        <div class="_footer-navi-item">
          <router-link
            :to="{
              name: 'in-contact',
              params: { mc_code: mcCode },
            }"
            >お問い合わせ</router-link
          >
        </div>
        <div class="_footer-navi-item">
          <router-link
            :to="{
              name: 'mypage',
              params: { mc_code: mcCode },
            }"
          >
            ユーザー情報
          </router-link>
        </div>
      </div>
      <!-- / _footer-navi-list -->
      <h2 class="_footer-logo">
        <img
          src="/i-salon/assets/img/common/logo-itochu_urban_community.png"
          alt="伊藤忠アーバンコミュニティ"
          class="_footer-logo-3"
        />
      </h2>
      <p class="_footer-copyrights">
        &copy; 2023 ITOCHU Urban Community
      </p>
      <!-- / _footer-copyrights -->
    </div>
    <!-- / _footer-inner -->
  </footer>
</template>

<script>
export default {};
</script>

<style></style>
