<template>
  <div class="mainContentBox">
    <div class="mainContentBoxInner eventrsv eventindex">
      <div class="pageTitleWrapper">
        <h3 class="section-title green-left-head">イベント一覧</h3>
      </div>
      <div class="preview-index">
        <div class="reserve">
          <div
            v-for="(preview, index) in previews"
            :key="`preview_${index}`"
            class="collapse"
          >
            <div
              class="title"
              @click="
                $router.push({
                  name: 'isalon-event-detail',
                  params: { previewid: preview._id },
                })
              "
            >
              <div class="rsvtitle">{{ preview.title }}</div>
              <div class="totalcapacity">
                {{
                  preview.totalCapacity - preview.reserved > 0
                    ? "  （○空きあり）"
                    : "  （✕空きなし）"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PreviewSrevice from "../../../services/preview.service";
import PreviewReserveService from "../../../services/preview_reserve.service";
export default {
  data() {
    return {
      isReserved: false,
      previews: [],
      reservedData: {},
    };
  },
  async created() {
    await this.reloadData();
  },
  methods: {
    async reloadData() {
      this.isExistReserve();
      const res = await PreviewSrevice.findWithReserve("");
      console.log(res.data);
      this.previews = res.data;
    },
    onUpdate() {},
    async onCancel() {
      if (confirm("キャンセルしても良いですか？")) {
        console.log("onCancel");
        const res = await PreviewReserveService.remove(this.reservedData._id);
        console.log(res);
        this.$message.success("キャンセルしました");
        this.reloadData();
      }
    },
    async isExistReserve() {
      const res = await PreviewReserveService.find({
        q: `{"mansion":"${this.currentUser.mansion.id}","roomNo":"${this.currentUser.roomNo}"}`,
      });
      console.log("isExistReserve", res);
      this.isReserved = res.data.length > 0;
      if (this.isReserved) {
        this.reservedData = {};
        const previewReserve = res.data[0];
        console.log("previewReserve", previewReserve);
        const preview = res.data[0].preview;
        this.reservedData.title = preview.title;
        this.reservedData.date =
          preview.schedules[previewReserve.scheduleIndex].date;
        this.reservedData.start =
          preview.schedules[previewReserve.scheduleIndex].komas[
            previewReserve.komaIndex
          ].start;
        this.reservedData.end =
          preview.schedules[previewReserve.scheduleIndex].komas[
            previewReserve.komaIndex
          ].end;
        this.reservedData._id = previewReserve._id;
      }
    },
    onReserve(previewid, scheduleIndex, komaIndex, nokori) {
      console.log(
        `onReserve_${previewid}_${scheduleIndex},${komaIndex}_${nokori}`,
      );
      if (nokori > 0) {
        this.$router.push({
          name: "isalon-preview-reserve",
          params: { previewid, scheduleIndex, komaIndex },
        });
      }
    },
  },
};
</script>

<style scoped>
.preview-index {
  font-size: 14px;
}
.koma-parent {
  margin-left: 20px;
}
.koma-item {
  display: flex;
  flex-direction: row;
}
</style>
