<template>
  <el-col :span="24">
    <section class="main-section gray-top-border">
      <h3 class="section-title">{{ title }}</h3>
      <el-form ref="form_target" :model="doc" label-width="200px">
        <el-form-item>
          <el-checkbox v-model="statusContractor">契約者</el-checkbox>
          <el-checkbox v-model="statusResident">入居者</el-checkbox>
        </el-form-item>
        <el-form-item>
          <div class="group">
            <el-checkbox
              v-model="item.checked"
              v-for="(item, index) in targets"
              :key="`targets-${index}`"
              :style="{
                display: index > targets.length - 5 ? 'none' : 'inline-block',
              }"
              >{{ item.label }}</el-checkbox
            >
          </div>
          <el-checkbox v-model="targets[targets.length - 4].checked"
            >賃借人</el-checkbox
          >
          <el-checkbox v-model="targets[targets.length - 3].checked"
            >管理員</el-checkbox
          >
          <el-checkbox v-model="targets[targets.length - 2].checked"
            >MP</el-checkbox
          >
          <el-checkbox v-model="targets[targets.length - 1].checked"
            >その他</el-checkbox
          >
          <el-checkbox v-model="doc.is_individual">個別</el-checkbox>
        </el-form-item>
        <el-form-item
          label="個別お知らせ対象部屋番号"
          v-show="doc.is_individual"
        >
          <el-input
            type="textarea"
            :rows="4"
            placeholder="個別お知らせを選択した場合の表示対象部屋番号（例：301,302,507）※カンマ区切り"
            v-model="rooms"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <!-- </el-col> -->
    </section>
  </el-col>
  <!--/.main-section-->
</template>

<script>
import CstService from "../services/cst.service";
const clonedeep = require("lodash/cloneDeep");
export default {
  name: "TargetSelection",
  props: {
    doc_type: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    const defaultDoc = {
      _id: null,
      doc_type: this.doc_type,
      targets: [],
      is_individual: false,
      rooms: [],
      status_targets: {
        contractor: false,
        resident: false,
        others: false,
      },
    };
    return {
      defaultDoc,
      doc: defaultDoc,
      targets: [
        {
          value: "OwnedResident",
          label: "住居オーナー（区分所有者）",
          checked: false,
        },
        {
          value: "OwnedNonResident",
          label: "外部オーナー（区分所有者）",
          checked: false,
        },
        { value: "Chairman", label: "理事長（区分所有者）", checked: false },
        { value: "Director", label: "理事（区分所有者）", checked: false },
        { value: "Secretary", label: "監事（区分所有者）", checked: false },
        { value: "RentResident", label: "賃借人", checked: false },
        { value: "Manager", label: "管理員", checked: false },
        { value: "MP", label: "MP", checked: false },
        { value: "Others", label: "その他", checked: false },
        // { value: "ExternalParty", label: "外部関係者", checked: false },
      ],
      rooms: "",
    };
  },
  watch: {
    rooms: function(val) {
      this.doc.rooms = val.split(",");
    },
    targets: {
      // eslint-disable-next-line
      handler: function (val, oldVal) {
        console.log("someObj changed");
        const data = [];
        this.targets.forEach(element => {
          if (element.checked) {
            data.push(element.value);
          }
        });
        console.log(data);
        this.doc.targets = data;
      },
      deep: true,
    },
  },
  computed: {
    statusContractor: {
      get() {
        return this.doc.status_targets.contractor;
      },
      set(value) {
        this.doc.status_targets.contractor = value;
      },
    },
    statusResident: {
      get() {
        return this.doc.status_targets.resident;
      },
      set(value) {
        this.doc.status_targets.resident = value;
      },
    },
    statusOthers: {
      get() {
        return this.doc.status_targets.others;
      },
      set(value) {
        this.doc.status_targets.others = value;
      },
    },
  },
  async mounted() {
    console.log("TargetSelection mounted", this.doc_type);
    const res = await CstService.find({ doc_type: this.doc_type });
    if (res.data.length > 0) {
      this.doc = clonedeep(res.data[0]);
    }
    this.updateTagets(this.doc.targets);
    this.rooms = this.doc.rooms.join(",");
    console.log("+++this.doc", this.doc);
  },
  methods: {
    updateTagets(array) {
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        const obj = this.targets.find(obj => obj.value == element);
        obj.checked = true;
      }
    },
    async onUpdate() {
      console.log("+onUpdate", this.doc);
      try {
        if (this.doc._id) {
          const res = await CstService.update(this.doc);
          console.log(res);
          // this.doc = res.data;
        } else {
          const res = await CstService.create(this.doc);
          console.log(res);
          // this.doc = res.data;
        }
        this.$message({
          message: `${this.title}を更新しました`,
          type: "success",
        });
      } catch (error) {
        this.$message.error(`${this.title}の更新に失敗しました`);
      }
    },
  },
};
</script>

<style scoped>
.group {
  border-width: thin;
  padding: 10px;
}
</style>
