<template>
  <el-main id="admin-user">
    <h3 class="section-title green-left-head title-margin">ユーザ管理</h3>

    <p v-if="currentUser.permissions.includes('Admin')">
      <el-upload
        class="upload-demo"
        ref="upload"
        :action="uploadCSV"
        :auto-upload="false"
        :on-success="onSuccess"
        :headers="header"
      >
        <el-button slot="trigger" size="small" type="primary"
          >User.csvファイル選択</el-button
        >
        <el-button
          style="margin-left: 10px;"
          size="small"
          type="success"
          @click="submitUpload"
          >アップロード</el-button
        >
        <div class="el-upload__tip" slot="tip">
          csv files with a size less than 500kb
        </div>
      </el-upload>
    </p>
    <p v-if="currentUser.permissions.includes('Admin')">
      <el-upload
        class="upload-demo"
        ref="upload3"
        :action="uploadCSVForUpdate"
        :auto-upload="false"
        :on-success="onSuccess"
        :headers="header"
      >
        <el-button slot="trigger" size="small" type="primary"
          >更新.csvファイル選択</el-button
        >
        <el-button
          style="margin-left: 10px;"
          size="small"
          type="success"
          @click="submitUpload3"
          >更新アップロード</el-button
        >
        <div class="el-upload__tip" slot="tip">
          csv files with a size less than 500kb
        </div>
      </el-upload>
    </p>
    <el-table :data="result.docs" stripe style="width: 100%">
      <el-table-column prop="loginID" label="ID" width="180"> </el-table-column>
      <el-table-column prop="mansion.name" label="マンション名">
      </el-table-column>
      <el-table-column prop="roomNo" label="部屋番号"> </el-table-column>
      <el-table-column prop="email" label="Email"> </el-table-column>
      <el-table-column label="お知らせメール">
        <template slot-scope="scope">
          <span style="margin-left: 10px">{{
            scope.row.needToSendEmail ? "送る" : "送らない"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="role.dispName" label="権限"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="120">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="onEdit(scope.row._id)"
            >編集</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="result.totalDocs"
      :page-size="result.limit"
      @current-change="onCurrentChange"
    >
    </el-pagination>
  </el-main>
</template>

<script>
import authHeader from "../../../services/auth-header";
// import MansionService from "../../../services/mansion.service";
import UserService from "../../../services/user.service";

import store from "../../../store";

export default {
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (
      currentUser &&
      !currentUser.permissions.includes("Admin") &&
      !currentUser.permissions.includes("EventAdmin")
    ) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  data() {
    return {
      uploadCSV: `${process.env.VUE_APP_API_ENDPOINT}/api/user/upload`,
      uploadCSVdb: `${process.env.VUE_APP_API_ENDPOINT}/api/user/uploaddb`,
      uploadCSVForUpdate: `${process.env.VUE_APP_API_ENDPOINT}/api/user/upload3`,
      // mansions: [],
      // mansionsDef: [{ code: "", name: "すべて", _id: "" }],
      result: { docs: [] },
    };
  },
  computed: {
    header: function() {
      return authHeader();
    },
  },
  async mounted() {
    await this.$nextTick();
    this.findByDB(this.currentUser.mansion._id, 1);
  },
  methods: {
    onSuccess(obj) {
      console.log(obj);
      this.$message({
        message: obj.message,
        type: "success",
      });
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    onEdit(id) {
      console.log(`edit=${id}`);
      this.$router.push({
        name: "admin_user_edit",
        params: { id, mc_code: this.currentUser.mansion._id },
      });
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    submitUpload3() {
      this.$refs.upload3.submit();
    },
    submitUploaddb() {
      this.$refs.uploaddb.submit();
    },
    async findByDB(maintionID, page) {
      console.log("+++++ findByDB");
      const res = await UserService.findByDB(maintionID, page);
      this.result = res.data;
      console.log(this.result);
    },
    async onCurrentChange(page) {
      // console.log(page);
      this.findByDB(this.currentUser.mansion._id, page);
    },
  },
};
</script>

<style></style>
