<template>
  <div class="_main-container friend" id="jumpTo">
    <div class="mainContentBox">
      <div class="mainContentBoxInner" v-if="status == 'edit'">
        <div class="pageTitleWrapper">
          <h2 class="pageTitle">クレヴィアお友達紹介</h2>
        </div>
        <div class="pageCaption">
          お友達やお知り合いに伊藤忠都市開発の新築分譲マンション「クレヴィア」をご紹介ください。<br />マンションの情報はトップページにございます「伊藤忠の住まい」よりご確認お願いいたします。<span
            class="pageCaption-caution"
            >※所有者様限定のサービスとなっております。</span
          >
        </div>
        <div class="mvBox">
          <img
            src="/i-salon/assets/img/friend/fig01.png"
            alt="お友達紹介特典"
          />
        </div>
        <div class="stepBox">
          <ul>
            <li>
              <img src="/i-salon/assets/img/friend/step1.png" alt="STEP1" />
            </li>
            <li>
              <img src="/i-salon/assets/img/friend/step2.png" alt="STEP2" />
            </li>
            <li>
              <img src="/i-salon/assets/img/friend/step3.png" alt="STEP3" />
            </li>
          </ul>
        </div>
        <div class="cautionBox">
          <ul class="caution">
            <li>※現在販売中の新築物件に限ります。</li>
            <li>
              ※ご購入者は、初回来場時にお友達紹介を受けていることを販売担当にお伝えください。
            </li>
            <li>※他の割引との併用はできません。</li>
            <li>※ご紹介特典はお友達の入居後に発送させていただきます。</li>
          </ul>
        </div>
        <section class="block">
          <h2 class="blocktitle">お友達情報</h2>
          <span class="itemText"
            >お友達（購入者）の情報を入力してください。</span
          >
        </section>
        <section class="mainContent-form">
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="">希望物件</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id=""
                    ><input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-ja"
                      maxlength="512"
                      name=""
                      v-model.trim="$v.values.bukken.$model"
                  /></label>
                  <div class="error" v-if="!$v.values.bukken.required">
                    必須です
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="L_NAME">お名前</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dt class="formTableBox_ttl"><label for="L_NAME">姓</label></dt>
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="40"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder="例:伊藤"
                    name="lastName"
                    v-model.trim="$v.values.sei.$model"
                  />
                  <div class="error" v-if="!$v.values.sei.required">
                    必須です
                  </div>
                </dd>
                <dt class="formTableBox_ttl"><label for="F_NAME">名</label></dt>
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="F_NAME"
                    maxlength="40"
                    class="formTableBox_input--name u-ime-ja"
                    placeholder="例:忠太郎"
                    name="firstName"
                    v-model.trim="$v.values.mei.$model"
                  />
                  <div class="error" v-if="!$v.values.mei.required">
                    必須です
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="L_NAME">ふりがな</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dt class="formTableBox_ttl">
                  <label for="L_NAME">せい</label>
                </dt>
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="40"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder="例:いとう"
                    name="lastName"
                    v-model.trim="$v.values.kanaSei.$model"
                  />
                  <div class="error" v-if="!$v.values.kanaSei.required">
                    必須です
                  </div>
                </dd>
                <dt class="formTableBox_ttl">
                  <label for="F_NAME">めい</label>
                </dt>
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="F_NAME"
                    maxlength="40"
                    class="formTableBox_input--name u-ime-ja"
                    placeholder="例:ちゅうたろう"
                    name="firstName"
                    v-model.trim="$v.values.kanaMei.$model"
                  />
                  <div class="error" v-if="!$v.values.kanaMei.required">
                    必須です
                  </div>
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="">住所</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id=""
                    ><input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-ja"
                      maxlength="512"
                      name=""
                      v-model.trim="$v.values.address.$model"
                  /></label>
                  <div class="error" v-if="!$v.values.address.required">
                    必須です
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="">メールアドレス</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id=""
                    ><input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-en"
                      maxlength="128"
                      name=""
                      v-model.trim="$v.values.email.$model"
                  /></label>
                  <div class="error" v-if="!$v.values.email.required">
                    必須です
                  </div>
                  <div class="error" v-if="!$v.values.email.email">
                    Emailアドレスを入力してください
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <div class="cn-back-btn">
            <a href="javascript:void(0);" @click="onConfirm">次へ</a>
          </div>
        </section>
      </div>

      <div class="mainContentBoxInner" v-else-if="status == 'confirm'">
        <div class="pageTitleWrapper">
          <h2 class="pageTitle">クレヴィアお友達紹介</h2>
        </div>

        <div class="pageCaption">
          <div class="contactCaptionDetail">
            入力内容をご確認ください。<br />
            よろしければ「送信」ボタンをクリックしてください。
          </div>
        </div>

        <section class="mainContent-form">
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>希望物件</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ values.bukken }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">名前</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ values.sei }} {{ values.mei }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">ふりがな</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ values.kanaSei }} {{ values.kanaMei }}
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">住所</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ values.address }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">メールアドレス</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">{{ values.email }}</div>
              </div>
            </dd>
          </dl>

          <div class="cn-back-btn">
            <a href="javascript:void(0);" @click="status = 'edit'">戻る</a>
          </div>
          <div class="cn-back-btn">
            <a href="javascript:void(0);" @click="onPost">送信</a>
          </div>
        </section>
      </div>

      <div class="mainContentBoxInner" v-else-if="status == 'complete'">
        <div class="pageTitleWrapper">
          <h2 class="pageTitle">クレヴィアお友達紹介</h2>
        </div>

        <div class="pageCaption">
          <div class="contactCaptionDetail">
            お友達をご紹介いただきありがとうございます。紹介内容を受け付けました。<br />
            ご紹介特典はお友達の入居後に発送させていただきます。
          </div>
        </div>

        <section class="mainContent-form">
          <div class="cn-back-btn">
            <router-link
              :to="{
                name: 'top',
                params: { mc_code: mcCode },
              }"
              >トップページへ</router-link
            >
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
// import FormService from "../../services/form.service";
import OrderService from "../../services/order.service";
// import Navi from "../mixin/navi";
export default {
  name: "contact",
  // mixins: [Navi],
  data() {
    return {
      status: "edit",
      values: {
        bukken: "",
        sei: "",
        mei: "",
        kanaSei: "",
        kanaMei: "",
        email: "",
        address: "",
      },
      schema: null,
    };
  },
  validations: {
    values: {
      bukken: { required },
      sei: { required },
      mei: { required },
      kanaSei: { required },
      kanaMei: { required },
      email: { required, email },
      address: { required },
    },
  },
  mounted: async function() {
    console.log("+++ contact.mounted");
    // const mc_code = this.$route.params.mc_code
    //   ? this.$route.params.mc_code
    //   : "";
    // console.log(mc_code);
    // const result = await FormService.findOne({
    //   category: "contact",
    //   mansion: mc_code,
    // });
    // console.log(result);
    // await this.$nextTick();
    // this.schema = result.data.schema;
    // console.log(this.schema);
    // this.$emit("changeMansion", this.mansion.name);
  },
  methods: {
    scrollTop: function() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    onConfirm() {
      console.log("onConfirm");
      console.log(this.values);
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        this.status = "confirm";
        this.scrollTop();
      }
    },
    async onPost() {
      console.log("onConfirm");
      console.log(this.values);
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        // (category, sei, mei, mansionName,mansion, roomNo, result)
        const result = await OrderService.editByDirectroy(
          "friend",
          this.values,
        );
        this.status = "complete";
        this.scrollTop();
        console.log(result);
      }
    },
  },
};
</script>

<style>
/* @import "/i-salon/css/isalon/contact_layout.css"; */
.flex-wrapper {
  display: flex;
}
</style>
