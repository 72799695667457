<template>
  <div id="profile">
    <el-main>
      <el-card class="box-card" v-if="currentUser">
        <header class="jumbotron">
          <h3>
            <strong>{{ currentUser.username }}</strong>
          </h3>
        </header>
        <p>
          <strong>Token:</strong>
          {{ currentUser.accessToken.substring(0, 20) }} ...
          {{
            currentUser.accessToken.substr(currentUser.accessToken.length - 20)
          }}
        </p>
        <p>
          <strong>Id:</strong>
          {{ currentUser.id }}
        </p>
        <p>
          <strong>Email:</strong>
          {{ currentUser.email }}
        </p>
        <strong>Authorities:</strong>
        <p>
          <strong>Role:</strong>
          {{ currentUser.role }}
        </p>
      </el-card>
    </el-main>
  </div>
</template>

<script>
export default {
  name: "Profile",
};
</script>

<style></style>
