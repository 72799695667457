<template>
  <el-main id="admin-user">
    <h3 class="section-title green-left-head title-margin">ユーザ移行管理</h3>
    <el-form :inline="true" :model="params" ref="ruleForm">
      <el-form-item label="区分">
        <el-select v-model="params.status" placeholder="区分">
          <el-option label="変更なし" value="none"></el-option>
          <el-option label="契約者" value="contractor"></el-option>
          <el-option label="入居者" value="resident"></el-option>
          <el-option label="その他" value="others"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="移行日">
        <el-tooltip
          class="item"
          content="区分が契約者の場合のみ使用されます"
          placement="top"
        >
          <el-date-picker
            v-model="params.contractorToResidentDate"
            type="date"
            placeholder="日付"
            style="width: 100%"
          >
          </el-date-picker>
        </el-tooltip>
      </el-form-item>
      <el-button
        type="primary"
        @click="handleRegister('ruleForm')"
        :disabled="!isUpdatable"
        >更新</el-button
      >
    </el-form>
    <el-table
      :data="result.docs"
      stripe
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column prop="loginID" label="ID" width="180"> </el-table-column>
      <el-table-column prop="mansion.name" label="マンション名">
      </el-table-column>
      <el-table-column prop="roomNo" label="部屋番号" sortable>
      </el-table-column>
      <el-table-column prop="role.dispName" label="権限" sortable>
      </el-table-column>
      <el-table-column prop="status" label="区分" sortable>
        <template slot-scope="scope">
          {{
            scope.row.status != null
              ? statusToLabel(scope.row.status)
              : `${statusToLabel(currentUser.mansion.status)}(マンション準拠)`
          }}
        </template>
      </el-table-column>
      <el-table-column prop="contractorToResidentDate" label="移行日" sortable>
        <template slot-scope="scope">
          {{ scope.row.contractorToResidentDate | moment("YYYY/MM/DD") }}
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-pagination
      background
      layout="prev, pager, next"
      :total="result.totalDocs"
      :page-size="result.limit"
      @current-change="onCurrentChange"
    >
    </el-pagination> -->
  </el-main>
</template>

<script>
import authHeader from "../../../services/auth-header";
// import MansionService from "../../../services/mansion.service";
import UserService from "../../../services/user.service";

import store from "../../../store";

export default {
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (
      currentUser &&
      !currentUser.permissions.includes("UserMigrationManagement")
    ) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  data() {
    return {
      uploadCSV: `${process.env.VUE_APP_API_ENDPOINT}/api/user/upload`,
      uploadCSVdb: `${process.env.VUE_APP_API_ENDPOINT}/api/user/uploaddb`,
      uploadCSVForUpdate: `${process.env.VUE_APP_API_ENDPOINT}/api/user/upload3`,
      // mansions: [],
      // mansionsDef: [{ code: "", name: "すべて", _id: "" }],
      result: { docs: [] },
      params: {
        status: "none",
        contractorToResidentDate: null,
      },
      multipleSelection: [],
    };
  },
  computed: {
    header: function() {
      return authHeader();
    },
    isUpdatable() {
      return (
        this.multipleSelection.length > 0 &&
        (this.params.status != "none" ||
          this.params.contractorToResidentDate != null)
      );
    },
  },
  async mounted() {
    await this.$nextTick();
    this.findByDB();
  },
  methods: {
    // eslint-disable-next-line
    async handleRegister(ref) {
      console.log("handleRegister");
      let users = [];
      for (let index = 0; index < this.multipleSelection.length; index++) {
        const element = this.multipleSelection[index];
        users.push(element._id);
      }
      console.log(users);
      console.log(this.params);
      try {
        const res = await UserService.updateUsersStatus(users, this.params);
        console.log(res);
        this.findByDB();
        // this.$router.go(-1);
        this.$message({
          message: "データを更新しました",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$message.error("データを更新できませんでした");
      }
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val;

      // this.$emit("users", users);
      // this.$store.dispatch('search/setMansions', mansions)
    },
    onSetting() {
      console.log("onSetting");
      this.submitUpload();
    },
    onSuccess(obj) {
      console.log(obj);
      this.$message({
        message: obj.message,
        type: "success",
      });
      this.$router.go({ path: this.$router.currentRoute.path, force: true });
    },
    onEdit(id) {
      console.log(`edit=${id}`);
      this.$router.push({
        name: "admin_user_edit",
        params: { id, mc_code: this.currentUser.mansion._id },
      });
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    submitUpload3() {
      this.$refs.upload3.submit();
    },
    submitUploaddb() {
      this.$refs.uploaddb.submit();
    },
    async findByDB() {
      console.log("+++++ findByDB");
      const res = await UserService.getUsers();
      this.result = res.data;
      console.log(this.result);
    },
    async onCurrentChange() {
      // console.log(page);
      this.findByDB();
    },
  },
};
</script>

<style></style>
