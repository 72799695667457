<template>
  <div class="_newinfo bodnews">
    <div class="_newinfo-inner">
      <h2 class="_newinfo-ttl">
        理事会からのお知らせ
        <div class="-more">
          <router-link
            class="-more-link"
            :to="{
              name: 'bod-information-1',
              params: { mc_code: mcCode },
            }"
            ><img src="/i-salon/assets/img/main/ic-more.png" alt="MORE"
          /></router-link>
        </div>
      </h2>
      <div class="_newinfo-list" v-if="news.length > 0">
        <!-- whats new start -->
        <p class="_newinfo-item -mq" v-for="(item, index) in news" :key="index">
          <router-link
            class="-more-link"
            :to="{
              name: 'bod-information-detail',
              params: { mc_code: mcCode, id: item._id },
            }"
          >
            {{ item.registration_at | moment("YYYY.MM.DD") }}
            <span
              class="tag"
              :class="{
                tag01: item.label == 'tag01',
                tag02: item.label == 'tag02',
                tag03: item.label == 'tag03',
                tag04: item.label == 'tag04',
                tag05: item.label == 'tag05',
                tag06: item.label == 'tag06',
                tag07: item.label == 'tag07',
                tag08: item.label == 'tag08',
              }"
              >{{ statusText(item.label) }}</span
            >
            {{ item.title }}
          </router-link>
        </p>
        <!-- whats new end -->

        <!-- / _newinfo-item -->
      </div>
      <div class="_newinfo-list" v-else>
        <p>現在、理事会からのお知らせはありません。</p>
      </div>
      <!-- / _newinfo-list -->
    </div>
    <!-- / _newinfo-inner -->
  </div>

  <!-- <div class="main-section" id="info-list">

    <h3 class="section-title green-left-head">
      お知らせ
    </h3>
    <div class="box-item">
      <div class="box-item-wrapper" v-if="news.length > 0">
        <ul class="box-item-list">
          <li
            class="box-item-list-item"
            v-for="(item, index) in news"
            :key="index"
          >
            <a
              :href="item.url"
              v-if="item.url != ''"
              :target="item.url_target ? '_blank' : ''"
              ><div class="date">
                {{ item.registration_at | moment("YYYY/MM/DD")
                }}<span v-html="createTag(item.label)"></span>
              </div>
              <div class="text">{{ item.title }}</div></a
            >
            <router-link
              :to="{
                name: 'information-detail',
                params: { id: item._id, mc_code: currentUser.mansion._id },
              }"
              v-else
            >
              <div class="date">
                {{ item.registration_at | moment("YYYY/MM/DD")
                }}<span v-html="createTag(item.label)"></span>
              </div>
              <div class="text">{{ item.title }}</div>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="box-item-wrapper" v-else>
        現在、お知らせはありません。
      </div>
    </div>
  </div> -->
</template>

<script>
import BoDInfoService from "../services/bod-info.service";

export default {
  name: "InfoList",
  props: ["mode"],
  data: function() {
    return {
      news: [],
    };
  },
  async mounted() {
    // console.log("list --");
    // console.log(response.data.docs);
    await this.$nextTick();
    await this.$nextTick();
    const response = await BoDInfoService.list({ is_top: true, limit: 100 });
    if (response.data.docs != undefined) {
      this.news = response.data.docs;
    } else {
      this.news = [];
    }
  },
  methods: {
    statusText(tag) {
      const titles = {
        tag01: "作業予定",
        tag02: "重要",
        tag03: "お知らせ",
        tag04: "キャンペーン",
        tag05: "伊藤忠都市開発より",
        tag06: "大規模修繕",
        tag07: "アンケート",
        tag08: "優待のご案内",
      };
      return titles[tag] ? titles[tag] : "";
    },
    createTag(tag) {
      const titles = {
        tag01: "作業予定",
        tag02: "重要",
        tag03: "お知らせ",
        tag04: "キャンペーン",
        tag05: "伊藤忠都市開発より",
        tag06: "大規模修繕",
        tag07: "アンケート",
        tag08: "優待のご案内",
      };
      const str = titles[tag] ? titles[tag] : "";
      return `<span class="tag ${tag}">${str}</span>`;
    },
  },
};
</script>

<style></style>
