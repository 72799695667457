<template>
  <div class="main kanri-page" id="admin-info-edit">
    <h2 class="page-title"><strong>カテゴリー別表示対象</strong></h2>
    <el-col :span="24" v-for="(doc, key) in docs" :key="key">
      <TargetSelection
        :ref="`ts_${key}`"
        :title="documentTypes[key].name"
        :doc_type="key"
      ></TargetSelection>
    </el-col>
    <el-col :span="24">
      <section class="main-section gray-top-border">
        <el-button @click="onUpdate" type="primary">更新</el-button>
      </section>
      <!-- <el-button @click="onTest">OK</el-button> -->
    </el-col>
  </div>
</template>

<script>
import CstService from "../../../services/cst.service";
import store from "../../../store";
import TargetSelection from "../../../components/TargetSelection.vue";
const clonedeep = require("lodash/cloneDeep");

export default {
  name: "admin_cst_index",
  components: { TargetSelection },
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (currentUser && !currentUser.permissions.includes("Admin")) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  async mounted() {
    console.log("+++++mounted");
    console.log(this);
    const res = await CstService.documentTypes();
    // console.log(res.data);
    this.documentTypes = res.data;
    console.log(this.documentTypes);
    const res2 = await CstService.find();
    this.docs = await this.normalizeDocs(res2.data);
    console.log("this.docs", this.docs);
    let targets = {};
    for (const key in this.documentTypes) {
      console.log(key);
      targets[key] = clonedeep(this.defalutTargets);
    }
    this.targets = targets;
    console.log("this.targets", this.targets);
    // this.result = response.data;
  },
  data() {
    console.log("++data");
    const defaultDoc = {
      doc_type: "",
      mansion: "",
      targets: [
        "OwnedResident",
        "OwnedNonResident",
        "Chairman",
        "Director",
        "Secretary",
      ],
      is_individual: false,
      rooms: [],
      status_targets: {
        contractor: true,
        resident: true,
        others: false,
      },
    };

    const defalutTargets = [
      {
        value: "OwnedResident",
        label: "住居オーナー（区分所有者）",
        checked: false,
      },
      {
        value: "OwnedNonResident",
        label: "外部オーナー（区分所有者）",
        checked: false,
      },
      { value: "Chairman", label: "理事長（区分所有者）", checked: false },
      { value: "Director", label: "理事（区分所有者）", checked: false },
      { value: "Secretary", label: "監事（区分所有者）", checked: false },
      { value: "RentResident", label: "賃借人", checked: false },
      { value: "Manager", label: "管理員", checked: false },
      { value: "Others", label: "その他", checked: false },
      // { value: "ExternalParty", label: "外部関係者", checked: false },
    ];

    return {
      documentTypes: {},
      docs: {},
      defaultDoc,
      targets: {},
      rooms: {},
      defalutTargets,
    };
  },
  methods: {
    async normalizeDocs(data) {
      console.log("normalizeDocs", data);
      let docs = {};
      for (const key in this.documentTypes) {
        if (Object.hasOwnProperty.call(this.documentTypes, key)) {
          // const element = this.documentTypes[key];
          const value = await data.find(item => item.doc_type === key);
          if (value) {
            docs[key] = value;
          } else {
            const doc = clonedeep(this.defaultDoc);
            doc.doc_type = key;
            docs[key] = doc;
          }
        }
      }
      return docs;
    },
    getRooms(key) {
      console.log("getRooms", key);
      if (this.docs[key].rooms) {
        return this.docs[key].rooms.join(",");
      }
      return "";
    },
    onUpdate() {
      console.log("onUpdate");
      console.log(this.$refs);
      for (const key in this.documentTypes) {
        if (Object.hasOwnProperty.call(this.documentTypes, key)) {
          this.$refs[`ts_${key}`][0].onUpdate();
        }
      }
    },
    async onTest() {
      // const res = await CstService.visibleDocuments();
      // console.log(res.data);
      const res = await CstService.url("P05");
      console.log(res.data);
    },
  },
};
</script>
