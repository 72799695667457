<template>
  <div class="wrapper">
    <div class="hdr">
      <div class="hdr-wrapper">
        <div class="logo">
          <router-link :to="{ name: 'top', params: { mc_code: mcCode } }"
            ><img src="/i-salon/assets/img/common/logo-main.svg" alt="iuc SALON"
          /></router-link>
        </div>
      </div>
    </div>
    <!--/.hdr-->

    <div class="main">
      <section class="main-section">
        <h3 class="section-title green-left-head" style="display:none;">
          メールアドレス登録完了
        </h3>
        <div class="faq-wrapper">
          <h4 class="faq-cate section-title">
            メールアドレスの登録が完了しました。
          </h4>

          <div class="moreinfobox" v-if="canRegistUser">
            <span class="moreinfobox-msg"
              >引き続き、お客様情報をご登録頂いた場合には、今後伊藤忠都市開発（株）よりご登録者様限定で暮らしに役立つ情報等をご提供したり、いち早く新築分譲マンションの販売情報をお届けさせて頂きます。是非ご登録を宜しくお願い致します。</span
            >
            <span class="backLink"
              ><a
                href="javascript:void(0);"
                @click="onRegistration"
                style="
    border: 1px solid #565656;
    padding: 10px 20px;
    margin: 20px auto;
    display: inline-block;
    background: #000562;
    color: #fff;
    border-radius: 100vh;
    "
              >
                →伊藤忠都市開発（株）お客様情報入力フォームへ</a
              ></span
            >
          </div>
          <div
            class="cn-back-btn"
            style="
    max-width: 920px;
    font-size: 90%;
    "
          >
            <a href="javascript:void(0);" @click="onTop">ログイン画面へ</a>
          </div>
          <div style="color: #f33;max-width: 920px;te;text-align: center;">
            ※再度ID・パスワードを入力し、ログインしてください。
          </div>
        </div>
      </section>
    </div>
    <!--/.main-->

    <div class="ftr">
      <div class="ftr-top-wrapper">
        <ul class="ftr-link">
          <li class="ftr-link-item">
            <a href="/i-salon/docs/kiyaku.html">ご利用規約</a>
          </li>

          <li class="ftr-link-item">
            <a href="/i-salon/docs/faq.html">よくあるご質問</a>
          </li>
        </ul>
      </div>
      <div class="ftr-bottom-wrapper">
        <div class="copyright">
          &copy; 2023 ITOCHU Urban Community
        </div>
      </div>
    </div>
    <!--/.ftr-->
  </div>
</template>

<script>
import UserService from "../services/user.service";
import store from "../store";
const REGISTER_DAIBUNRUI_LIST = ["cat001", "cat002"]; // ユーザ登録できる大分類
export default {
  name: "verifyMail",
  data() {
    return {
      canRegistUser: false,
      user_id: null,
    };
  },
  mounted: async function() {
    await this.$nextTick();
    store.dispatch("auth/logout");
    console.log("++++ mounded");
    const email = this.$route.query.email.replace(/ /g, "+");
    const key = this.$route.query.key;
    console.log(email);
    console.log(key);
    try {
      const result = await UserService.verifyEmail(email, key);
      console.log("result", result);
      console.log(result.status);
      if (result.status != "200") {
        console.log("error");
        this.$router.push({
          name: "notfound404",
        });
      } else {
        console.log("success");
        const dai_bunrui = this._.get(result, "data.mansion.dai_bunrui");
        console.log({ dai_bunrui });
        console.log(this.currentUser);
        const mansionStatus = this._.get(result, "data.status");
        // const mansionStatus = this.currentUser.status;
        console.log({ mansionStatus });
        this.canRegistUser =
          REGISTER_DAIBUNRUI_LIST.indexOf(dai_bunrui) >= 0 &&
          mansionStatus == "resident"; // 入居者のみ表示
        console.log("this.canRegistUser", this.canRegistUser);
        this.user_id = this._.get(result, "data._id");
        console.log({ user_id: this.user_id });
      }
    } catch (error) {
      console.log(error);
      this.$router.push({
        name: "notfound404",
      });
    }
  },
  methods: {
    onTop() {
      const email = this.$route.query.email.replace(/ /g, "+");
      if (this.currentUser) {
        this.currentUser.email = email;
      }
      this.$router.push({
        name: "top",
        params: { mc_code: this.mcCode },
      });
    },
    onRegistration() {
      this.$router.push({
        name: "user-registration",
        params: { user_id: this.user_id },
      });
    },
  },
};
</script>

<style>
/* @import "/i-salon/docs/css/layout.css"; */
.display_none {
  display: none;
}
</style>
