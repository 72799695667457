<template>
  <div id="vote-gian">
    <div class="gian-wrapper">
      <h4 class="gian-heading" @click="isVisible = !isVisible">
        {{ agendaIndex + 1 }}.{{ agenda.title }}
      </h4>
      <span class="gian-text" :class="{ gianopen: isVisible }">{{
        agenda.body
      }}</span>
      <Vote
        v-if="isOfficial"
        :bodID="bodID"
        :agendaIndex="agendaIndex"
        :titles="['賛成', '反対']"
        :isEditor="isEditor"
        :isDone="isDone"
        :members="members"
        :roomNo="roomNo"
      ></Vote>
    </div>
  </div>
</template>

<script>
import VoteService from "../services/vote.service";
import Vote from "./Vote";

export default {
  name: "vote-gian",
  props: [
    "bodID",
    "agenda",
    "agendaIndex",
    "isEditor",
    "isOfficial",
    "isDone",
    "members",
    "roomNo",
  ],
  components: { Vote },
  data: function() {
    return {
      isVisible: false,
      data: {
        tag: ["", "", ""],
        result: [[], [], []],
        agendaIndex: this.agendaIndex,
        bod: this.bodID,
      },
    };
  },
  mounted: function() {
    this.load();
    console.log("++VoteGain");
    console.log(this.members);
  },
  methods: {
    async onVote(value) {
      const res = await VoteService.vote(this.data._id, value);
      // console.log(res);
      this.data = res.data;
    },
    async load() {
      const res = await VoteService.find({
        bod: this.bodID,
        agendaIndex: this.agendaIndex,
      });
      // console.log("---aaa");
      // console.log(res);
      if (res.data.length > 0) {
        // this.isVisible = true;
        this.data = res.data[0];
      } else {
        // this.isVisible = false;
      }
    },
  },
};
</script>

<style></style>
