var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"icon-menu"}},[_c('section',{staticClass:"main-section"},[_c('ul',{staticClass:"top-btns"},[_c('li',{staticClass:"top-btns-item top-btns-item-01"},[_c('router-link',{attrs:{"to":{
            name: 'information-1',
            params: { mc_code: _vm.mcCode },
          }}},[_c('span',{staticClass:"top-btns-item-img"}),_c('span',{staticClass:"top-btns-item-text"},[_vm._v("お知らせ")])])],1),(_vm.isPermission('OwnedNonResident'))?_c('li',{staticClass:"top-btns-item top-btns-item-04"},[_c('router-link',{attrs:{"to":{
            name: 'meeting',
            params: { mc_code: _vm.mcCode },
          }}},[_c('span',{staticClass:"top-btns-item-img"}),_c('span',{staticClass:"top-btns-item-text"},[_vm._v("総会")])])],1):_vm._e(),(_vm.isPermission('Director'))?_c('li',{staticClass:"top-btns-item top-btns-item-05"},[_c('router-link',{attrs:{"to":{
            name: 'bod',
            params: { mc_code: _vm.mcCode },
          }}},[_c('span',{staticClass:"top-btns-item-img"}),_c('span',{staticClass:"top-btns-item-text"},[_vm._v("理事会")])])],1):_vm._e(),_c('li',{staticClass:"top-btns-item top-btns-item-02"},[_c('router-link',{attrs:{"to":{
            name: 'archive',
            params: { mc_code: _vm.mcCode },
          }}},[_c('span',{staticClass:"top-btns-item-img"}),_c('span',{staticClass:"top-btns-item-text"},[_vm._v("マンション書庫")])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }