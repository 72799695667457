<template>
  <div class="_main-container saigai" id="jumpTo">
    <div class="mainContentBox">
      <div class="mainContentBoxInner" v-if="status == 'edit'">
        <div class="pageTitleWrapper">
          <h2 class="pageTitle">被害状況共有</h2>
        </div>

        <div class="pageCaption contactCaption">
          <div class="contactCaptionDetail">
            災害の規模により、共有いただきました内容の確認、復旧にお時間がかかる場合がございます。ご了承ください。<br />
            尚、緊急対応が必要な場合は、アイフロント24（0120-987-550）にご連絡をお願いいたします。<br />
            （停電・システム障害等により電話が繋がらない場合がございます。）
            <br /><br />
            個人情報の取扱いに関しては、下記
            <span>プライバシーポリシーをご参照ください。</span><br /><br /><a
              href="http://www.itc-uc.co.jp/privacy/"
              target="_blank"
              >・伊藤忠アーバンコミュニティ株式会社</a
            ><br />
          </div>
        </div>

        <section class="mainContent-form">
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">マンション名</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id="">{{ mansionName }}</label>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">部屋番号</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id="">{{ currentUser.roomNo }}</label>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">お名前</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dt class="formTableBox_ttl"><label for="L_NAME">姓</label></dt>
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="40"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder="例:伊藤"
                    name="lastName"
                    v-model.trim="$v.values.sei.$model"
                  />
                </dd>
                <dt class="formTableBox_ttl"><label for="F_NAME">名</label></dt>
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="F_NAME"
                    maxlength="40"
                    class="formTableBox_input--name u-ime-ja"
                    placeholder="例:忠太郎"
                    name="firstName"
                    v-model.trim="$v.values.mei.$model"
                  />
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">ふりがな</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dt class="formTableBox_ttl">
                  <label for="L_NAME">せい</label>
                </dt>
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="40"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder="例:いとう"
                    name="lastName"
                    v-model.trim="$v.values.kanaSei.$model"
                  />
                </dd>
                <dt class="formTableBox_ttl">
                  <label for="F_NAME">めい</label>
                </dt>
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="F_NAME"
                    maxlength="40"
                    class="formTableBox_input--name u-ime-ja"
                    placeholder="例:ちゅうたろう"
                    name="firstName"
                    v-model.trim="$v.values.kanaMei.$model"
                  />
                </dd>
              </dl>
              <span class="formErr_inline"> </span>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">メールアドレス</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id="">{{ values.email }}</label>
                  <div class="error" v-if="!$v.values.email.required">
                    必須です
                  </div>
                  <div class="error" v-if="!$v.values.email.email">
                    Emailアドレスを入力してください
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="">種別</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <select
                    class="select-box"
                    v-model.trim="$v.values.category.$model"
                  >
                    <option value="" selected="">選択してください</option>
                    <option
                      v-for="(item, index) in category_options"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <div class="error" v-if="!$v.values.category.required">
                    必須です
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="">詳細</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <textarea
                    rows="8"
                    class="u-input--w100p u-ime-ja"
                    v-model.trim="$v.values.text.$model"
                  ></textarea>

                  <div class="error" v-if="!$v.values.text.required">
                    必須です
                  </div>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">画像添付</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div class="img-list">
                    <div
                      v-for="(file, index) in files"
                      :key="index"
                      class="img-margin"
                    >
                      <img :src="file.blob" width="50" height="50" />
                    </div>
                  </div>
                  <div class="btn-container">
                    <file-upload
                      ref="upload"
                      :post-action="uploadApi"
                      :headers="header"
                      :size="1024 * 1024 * 10"
                      v-model="files"
                      @input-file="inputFile"
                      @input-filter="inputFilter"
                      class="upload-btn"
                      extensions="gif,jpg,jpeg,png,webp"
                      accept="image/png,image/gif,image/jpeg,image/webp"
                      :multiple="true"
                      :maximum="3"
                      >ファイル選択(最大3枚まで)</file-upload
                    >
                    <!-- <button
                      v-show="!$refs.upload || !$refs.upload.active"
                      @click.prevent="$refs.upload.active = true"
                      type="button"
                    >
                      アップロード
                    </button> -->
                    <div
                      @click.prevent="clearAllImage"
                      type="button"
                      class="clear-btn"
                    >
                      画像クリア
                    </div>
                  </div>
                </div>
              </div>
            </dd>
          </dl>

          <div class="cn-back-btn">
            <a href="javascript:void(0);" @click="onConfirm">次へ</a>
          </div>
        </section>
      </div>

      <div class="mainContentBoxInner" v-else-if="status == 'confirm'">
        <div class="pageTitleWrapper">
          <h2 class="pageTitle">被害状況共有</h2>
        </div>

        <div class="pageCaption">
          <div class="contactCaptionDetail">
            入力内容をご確認ください。<br />
            よろしければ「送信」ボタンをクリックしてください。
          </div>
        </div>

        <section class="mainContent-form">
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>マンション名</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ mansionName }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label>部屋番号</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ currentUser.roomNo }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">名前</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ values.sei }} {{ values.mei }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="L_NAME">ふりがな</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  {{ values.kanaSei }} {{ values.kanaMei }}
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">メールアドレス</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">{{ values.email }}</div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">種別</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">{{ values.category }}</div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">詳細</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <pre>{{ values.text }}</pre>
                </div>
              </div>
            </dd>
          </dl>
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">画像</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <div class="img-list">
                    <div
                      v-for="(file, index) in files"
                      :key="index"
                      class="img-margin"
                    >
                      <img :src="file.blob" width="50" height="50" />
                    </div>
                    <file-upload
                      ref="upload"
                      :post-action="uploadApi"
                      :headers="header"
                      :size="1024 * 1024 * 10"
                      v-model="files"
                      @input-file="inputFile"
                      @input-filter="inputFilter"
                      extensions="gif,jpg,jpeg,png,webp"
                      accept="image/png,image/gif,image/jpeg,image/webp"
                      :multiple="true"
                      :maximum="3"
                    ></file-upload>
                  </div>
                </div>
              </div>
            </dd>
          </dl>

          <div class="cn-back-btn">
            <a href="javascript:void(0);" @click="status = 'edit'">戻る</a>
          </div>
          <div class="cn-back-btn">
            <a href="javascript:void(0);" @click="onPost">送信</a>
          </div>
        </section>
      </div>

      <div class="mainContentBoxInner" v-else-if="status == 'complete'">
        <div class="pageTitleWrapper">
          <h2 class="pageTitle">被害状況共有</h2>
        </div>

        <div class="pageCaption">
          <div class="contactCaptionDetail">被害状況共有を受け付けました。</div>
        </div>

        <section class="mainContent-form">
          <div class="cn-back-btn">
            <router-link
              :to="{
                name: 'top',
                params: { mc_code: mcCode },
              }"
              >トップページへ</router-link
            >
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import authHeader from "../../services/auth-header";
import { required, email } from "vuelidate/lib/validators";
// import FormService from "../../services/form.service";
import OrderService from "../../services/order.service";
import OrderSchemaService from "../../services/order_schema.service";
// import FileService from "../../services/file.service";
import store from "../../store";
// import Navi from "../mixin/navi";
export default {
  name: "contact",
  // mixins: [Navi],
  data() {
    return {
      files: [],
      isFileSelecting: false,
      uploadApi: `${process.env.VUE_APP_API_ENDPOINT}/api/file/attach`,
      status: "edit",
      uploadedCounter: 0,
      values: {
        sei: "",
        mei: "",
        kanaSei: "",
        kanaMei: "",
        email: "",
        category: [],
        text: "",
        images: [],
      },
      schema: null,
      category_options: [],
    };
  },
  validations: {
    values: {
      sei: {},
      mei: {},
      kanaSei: {},
      kanaMei: {},
      email: { required, email },
      category: { required },
      text: { required },
    },
  },
  mounted: async function() {
    console.log("+++ contact.mounted");
    await this.$nextTick();
    const user = store.state.auth.user;
    console.log(user);
    const result = await OrderSchemaService.find({
      mansion: user.mansion.id,
      directory: "saigai",
    });
    console.log("result", result);
    this.schema = result.data[0].schema;
    const category = this.schema.find(item => item.name == "category");
    console.log(category);
    this.category_options = category.options;
    console.log(this.category_options);
    this.values.email = user.email;
  },
  // watch: {
  //   isUploaded() {
  //     return this.$refs.upload && this.$refs.upload.uploaded;
  //   },
  // },
  computed: {
    header: function() {
      return authHeader();
    },
    mansionName() {
      return this.mansion != null ? this.mansion.name : "";
    },
  },
  methods: {
    clearAllImage() {
      this.files = [];
    },
    updatetValue(value) {
      this.files = value;
      // this.$refs.upload.active = true;
    },
    /**
     * Has changed
     * @param  Object|undefined   newFile   Read only
     * @param  Object|undefined   oldFile   Read only
     * @return undefined
     */
    inputFile: function(newFile, oldFile) {
      if (newFile && oldFile && !newFile.active && oldFile.active) {
        // Get response data
        console.log("response", newFile.response);
        this.values.images.push(newFile.response.url);
        if (this.values.images.length == this.files.length) {
          this.uploadData();
        }
        if (newFile.xhr) {
          //  Get the response status code
          console.log("status", newFile.xhr.status);
        }
      }
    },
    /**
     * Pretreatment
     * @param  Object|undefined   newFile   Read and write
     * @param  Object|undefined   oldFile   Read only
     * @param  Function           prevent   Prevent changing
     * @return undefined
     */
    inputFilter: function(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        if (!/\.(jpeg|jpe|jpg|gif|png|webp)$/i.test(newFile.name)) {
          return prevent();
        }
      }

      // Create a blob field
      newFile.blob = "";
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file);
      }
    },
    async uploadData() {
      console.log(this.values);
      // (category, sei, mei, mansionName,mansion, roomNo, result)
      const result = await OrderService.editByDirectroy("saigai", this.values);
      this.status = "complete";
      this.scrollTop();
      console.log(result);
    },
    scrollTop: function() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    onConfirm() {
      console.log("onConfirm");
      console.log(this.values);
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        this.status = "confirm";
        this.scrollTop();
      }
    },
    onPost() {
      console.log("onConfirm");
      console.log(this.values);
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        if (this.files.length > 0) {
          this.$refs.upload.active = true;
        } else {
          this.uploadData();
        }
      }
    },
  },
};
</script>

<style scoped>
.flex-wrapper {
  display: flex;
}
.img-list {
  display: flex;
}
.img-margin {
  margin: 5px;
}

.btn-container {
  display: flex;
}
.upload-btn {
  width: 200px;
  height: 30px;
  line-height: 30px;
  border: solid 1px black;
  margin: 0 5px;
}

.clear-btn {
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: solid 1px black;
  margin: 0 5px;
}
</style>

<style>
/* @import "/i-salon/css/isalon/contact_layout.css"; */
</style>
