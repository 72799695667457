<template>
  <div class="wrapper">
    <div class="hdr">
      <div class="hdr-wrapper">
        <div class="logo">
          <router-link :to="{ name: 'top', params: { mc_code: mcCode } }"
            ><img src="/i-salon/assets/img/common/logo-main.svg" alt="iuc SALON"
          /></router-link>
        </div>
      </div>
    </div>
    <!--/.hdr-->

    <div class="main" v-if="status == 'edit'">
      <section class="main-section">
        <h3 class="section-title green-left-head">メールアドレス登録</h3>

        <div class="faq-wrapper">
          <h4 class="faq-cate section-title">
            iuc SALONのご利用に当たってはメールアドレスの登録が必要です。<br />
            下記手順に従ってご登録ください。
          </h4>

          <ul class="stepList">
            <li>
              1. メールアドレスを入力します。
            </li>
            <li>
              2.
              利用規約、個人情報保護方針に同意いただき「登録」ボタンをクリックします。
            </li>
            <li>
              3.
              登録したメールアドレスに届く「メールアドレス登録認証URL」をクリックして登録完了です。
            </li>
          </ul>
        </div>
        <div class="mainContent-form">
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="">メールアドレス</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id="">
                    <input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-en"
                      maxlength="128"
                      name=""
                      v-model.trim="$v.values.email.$model"
                    />
                  </label>
                </div>
                <div class="error" v-if="!$v.values.email.required">
                  必須です
                </div>
                <div class="error" v-if="!$v.values.email.email">
                  Emailアドレスを入力してください
                </div>
              </div>
            </dd>
          </dl>

          <!--//
          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label>更新の通知を受け取る</label>
            </dt>

            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label>
//-->

          <input
            type="hidden"
            id="notice"
            class=""
            name="notice"
            value="true"
            v-model="values.needToSendEmail"
          />

          <!--//
                    <input
                      type="radio"
                      id="notice"
                      class=""
                      name="notice"
                      value="true"
                      v-model="values.needToSendEmail"
                    />
                    &nbsp;受け取る&nbsp;</label
                  ><label>
                    <input
                      type="radio"
                      id="notice"
                      class=""
                      name="notice"
                      value="false"
                      v-model="values.needToSendEmail"
                    />
                    &nbsp;受け取らない&nbsp;</label
                  >
                </div>
                <div class="error">必須です</div>
                <div class="error">
                  更新の通知を受け取るか選択してください。
                </div>
              </div>
            </dd>
          </dl>
//-->
        </div>

        <div
          class="cMsg"
          style="
    border: 2px #f33 solid;
    padding: 10px 10px;
    color: #f33;
    margin-top: 20px;
    font-size: 85%;
    "
        >
          <span
            style="
    padding-left: 1em;
    text-indent: -1em;
    display: block;
    "
            >※メールアドレスの変更・廃止、迷惑メール設定、その他エラー等でメールがお届けできない状態が続く場合、ご登録されているメールアドレスを削除させていただくことがあります。
            <br />その際はお手数ですが、改めて有効なメールアドレスのご登録をお願いいたします。予めご了承をお願いいたします。</span
          >
        </div>

        <div class="kiyakuBox">
          <h3 class="section-title green-left-head">利用規約</h3>
          <br />
          <div class="kiyakuBixInner">
            <div class="kiyaku-wrapper" id="kiyakuLoad"></div>
          </div>
        </div>

        <div class="ppBox">
          <h4>各社の個人情報保護方針を確認ください。</h4>
          <ul class="ppList">
            <li>
              <a href="https://www.itc-uc.co.jp/privacy/" target="_blank"
                >→伊藤忠アーバンコミュニティ 個人情報保護方針</a
              >
            </li>
          </ul>
        </div>

        <div class="reqCaption">
          <span class="reqCaption"
            ><input
              type="checkbox"
              name="check"
              id="check"
              v-model.trim="$v.values.confirm.$model"
              @change="$v.values.confirm.$touch()"/><label for="check"
              >ご利用規約および各社個人情報保護方針に同意する</label
            ><br
          /></span>

          <div class="error" v-if="!$v.values.confirm.sameAs">
            同意が必要です
          </div>
        </div>

        <div class="cn-back-btn">
          <a href="javascript:void(0);" @click="onPost">登録</a>
        </div>
        <div class="logoutTrg"><a :href="logoutUrl">キャンセル</a></div>
      </section>
    </div>

    <div class="main" v-else-if="status == 'complete'">
      <section class="main-section">
        <h3 class="section-title caution-em">
          メールアドレス登録はまだ完了していません。
        </h3>

        <div class="faq-wrapper" style="border: 1px solid #ccc;padding: 20px; ">
          <span class="caution"
            >登録をしたメールアドレス宛てにURL付メールが届きます。</span
          >
          <span class="caution-em" style="display: block;"
            >メールをご確認ください。</span
          >
          <br />
          <a
            href="/i-salon/docs/faq.html"
            target="_blank"
            style="text-decoration: underline;color: #000562;"
            >→メールが届かない場合はこちらをご確認ください。</a
          >
        </div>
      </section>
    </div>

    <!--/.main-->

    <div class="ftr">
      <div class="ftr-top-wrapper">
        <ul class="ftr-link">
          <li class="ftr-link-item">
            <a href="/i-salon/docs/kiyaku.html">利用規約</a>
          </li>
          <li class="ftr-link-item">
            <a href="/i-salon/docs/faq.html">よくある質問</a>
          </li>
        </ul>
      </div>
      <div class="ftr-bottom-wrapper">
        <div class="copyright">
          &copy; 2023 ITOCHU Urban Community
        </div>
      </div>
    </div>
    <!--/.ftr-->
  </div>
  <!--/.wrapper-->
</template>

<script>
import { required, email, sameAs } from "vuelidate/lib/validators";
import UserService from "../../services/user.service";
export default {
  data() {
    return {
      values: {
        email: "",
        confirm: false,
        needToSendEmail: true,
      },
      status: "edit",
    };
  },
  validations: {
    values: {
      email: {
        required,
        email,
      },
      confirm: {
        sameAs: sameAs(() => true),
      },
      needToSendEmail: {
        required,
      },
    },
  },
  async mounted() {
    await this.$nextTick();
    this.contentsLoader("kiyakuLoad", "/i-salon/docs/kiyaku_pre.html");
  },
  methods: {
    async onPost() {
      console.log("++++ onPost");
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        console.log("OK");
        try {
          const result = await UserService.preVerifyEmail(
            this.values.email,
            this.currentUser.loginID,
          );
          console.log(result);
          const res2 = await UserService.update(this.currentUser.id, {
            needToSendEmail: this.values.needToSendEmail,
          });
          console.log(res2);
          this.status = "complete";
          console.log("ok");
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style></style>
