<template>
  <div class="main shoko">
    <section class="main-section">
      <p class="btn-item" v-if="isPermission('Admin')">
        <router-link
          :to="{
            name: 'admin-archive-edit',
            params: { id: 'create', mc_code: currentUser.mansion._id },
            query: {
              category_id: $route.params.category,
            },
          }"
        >
          <button type="button" class="el-button el-button--primary">
            <i class="el-icon-circle-plus"></i><span>新規書類追加</span>
          </button>
        </router-link>
      </p>

      <h3 class="section-title green-left-head">
        {{ category.title }}
        <span class="btn_to_list">
          <router-link
            :to="{
              name: 'top',
              params: { mc_code: currentUser.mansion._id },
            }"
            >戻る</router-link
          ></span
        >
      </h3>
      <div v-for="(item, index) in archives" :key="index">
        <h4 class="shoko-title">{{ item.title }}</h4>
        <div class="box-item">
          <div class="box-item-wrapper">
            <ul class="box-item-list" v-if="item.is_show_all">
              <li
                class="box-item-list-item"
                v-for="(item2, index2) in item.archives"
                :key="index2"
              >
                <div
                  :class="{
                    'type-pdf': isFileType(item2.files, ['pdf']),
                    pdfopenBtn: isFileType(item2.files, ['pdf']),
                    'type-ppt': isFileType(item2.files, ['ppt', 'pptx']),
                    'type-excel': isFileType(item2.files, ['xlsx']),
                    'type-image': isFileType(item2.files, [
                      'png',
                      'jpg',
                      'jpeg',
                    ]),
                    'type-word': isFileType(item2.files, ['doc', 'docx']),
                  }"
                >
                  <a
                    href="javascript:void(0);"
                    @click="
                      onOpen(file(item2.files), item2._id);
                      return false;
                    "
                    >{{ item2.title
                    }}<span class="regdate"
                      >（{{ item2.resgist_at | moment("YYYY.MM.DD") }}）</span
                    ></a
                  >
                </div>
                <span class="btn-kanri">
                  <router-link
                    v-if="isPermission('Admin')"
                    :to="{
                      name: 'admin-archive-edit',
                      params: {
                        id: item2._id,
                        mc_code: currentUser.mansion._id,
                      },
                    }"
                  >
                    編集
                  </router-link>
                </span>
              </li>
            </ul>
            <ul class="box-item-list" v-else>
              <li
                class="box-item-list-item"
                v-for="(item2, index2) in item.archives.slice(
                  0,
                  default_show_items,
                )"
                :key="index2"
              >
                <div
                  :class="{
                    'type-pdf': isFileType(item2.files, ['pdf']),
                    pdfopenBtn: isFileType(item2.files, ['pdf']),
                    'type-ppt': isFileType(item2.files, ['ppt', 'pptx']),
                    'type-excel': isFileType(item2.files, ['xlsx', 'xls']),
                    'type-image': isFileType(item2.files, [
                      'png',
                      'jpg',
                      'jpeg',
                    ]),
                    'type-word': isFileType(item2.files, ['doc', 'docx']),
                  }"
                >
                  <a
                    href="javascript:void(0);"
                    @click="
                      onOpen(file(item2.files), item2._id);
                      return false;
                    "
                    >{{ item2.title
                    }}<span class="regdate"
                      >（{{ item2.resgist_at | moment("YYYY.MM.DD") }}）</span
                    ></a
                  >
                </div>
                <span class="btn-kanri">
                  <router-link
                    v-if="isPermission('Admin')"
                    :to="{
                      name: 'admin-archive-edit',
                      params: {
                        id: item2._id,
                        mc_code: currentUser.mansion._id,
                      },
                    }"
                  >
                    編集
                  </router-link>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div
          class="btn-more-wrapper"
          v-if="item.archives.length > default_show_items"
          @click="item.is_show_all = !item.is_show_all"
        >
          <span class="btn-more" v-if="item.is_show_all">閉じる</span>
          <span class="btn-more" v-else>もっとみる</span>
        </div>
      </div>
      <div class="top-back-btn">
        <a href="javascript:history.back();">戻る</a>
      </div>
    </section>
    <!--/.main-section-->
    <div id="pdfContainer" v-show="isPDFView" style="z-index: 100">
      <iframe
        src=""
        type="application/pdf"
        width="100%"
        height="100%"
        style="overflow: auto"
        id="pdfView"
      ></iframe>
      <div class="chatclose chatopen" @click="isPDFView = false">閉じる</div>
    </div>
    <!-- <IconMenu /> -->
  </div>
</template>

<script>
// import store from "../../store";
import ArchiveService from "../../services/archive.service";
import FileService from "../../services/file.service";
import CategoryService from "../../services/category.service";
// import IconMenu from "@/components/IconMenu.vue";
export default {
  name: "archive-list",
  // components: { IconMenu },
  // beforeRouteEnter(to, from, next) {
  //   const currentUser = store.state.auth.user;
  //   console.log(currentUser);
  //   if (currentUser && !currentUser.permissions.includes("Director")) {
  //     console.log(":401");
  //     next({ name: "notfound404" });
  //   } else {
  //     next();
  //   }
  // },
  data: function() {
    return {
      isPDFView: false,
      archives: [],
      category: { title: "" },
      default_show_items: 12,
    };
  },
  mounted: async function() {
    const category_id = this.$route.params.category;
    console.log(category_id);
    try {
      const res = await CategoryService.find(category_id);
      if (res.data) {
        this.category = res.data;
      } else {
        this.$router.push({
          name: "notfound404",
          params: { mc_code: this.currentUser.mansion._id },
        });
      }
    } catch (error) {
      console.log(error);
    }
    const result = await ArchiveService.list({ category_id: category_id });
    console.log(result);
    this.groupingData(result.data);
  },
  methods: {
    async onOpen(file, _id) {
      console.log("onOpen");
      console.log(file);
      console.log(_id);
      // console.log(this.meeting.files[0].name);
      const self = this;
      if (this.extention(file.name) == "pdf") {
        FileService.download("archive", _id, file.name).then(response => {
          // return response.data;
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" }),
          );
          document.getElementById("pdfView").src =
            "/pdfjs/web/viewer.html?file=" + url;
          self.isPDFView = true;
        });
      } else {
        if (!confirm("ファイルをダウンロードします。よろしいですか？")) {
          return;
        }
        const blob = await FileService.download("archive", _id, file.name);
        // console.log("++++ aaaa");
        // console.log(blob.type);
        if (navigator.appVersion.toString().indexOf(".NET") > 0) {
          // IE 10+
          // IEだけはこれじゃないとダウンロードできない
          window.navigator.msSaveBlob(new Blob([blob.data]), file.name);
        } else {
          // aタグの生成
          var a = document.createElement("a");
          // レスポンスからBlobオブジェクト＆URLの生成
          var blobUrl = window.URL.createObjectURL(new Blob([blob.data]));
          document.body.appendChild(a);
          a.style = "display: none";
          // 生成したURLをセット
          a.href = blobUrl;
          // ダウンロードの時にファイル名として表示される
          a.download = file.name;
          // クリックイベント発火
          // a.click();
          var event = new MouseEvent("click");
          a.dispatchEvent(event);
        }
        return false;
      }
    },
    isFileType(files, types) {
      if (files.length == 0) {
        return false;
      }
      const file = this.file(files);
      const ext = this.extention(file.name);
      return types.indexOf(ext) != -1;
    },
    file(files) {
      if (files.length == 0) {
        return "";
      } else {
        return files[0];
      }
    },

    extention(str) {
      // console.log("++extention");
      // console.log(str);
      return str
        .split(".")
        .slice(-1)[0]
        .toLowerCase();
    },

    async groupingData(data) {
      console.log("+++ groupingData");
      console.log(data);
      let res = [];
      await data.forEach(element => {
        // console.log(element.sub_category._id);
        if (element.sub_category != null) {
          const isExist = res.find(item => {
            return item._id == element.sub_category._id;
          });
          if (isExist == undefined) {
            res.push({
              _id: element.sub_category._id,
              title: element.sub_category.title,
              updatedAt: element.sub_category.updatedAt,
              archives: [element],
              is_show_all: false,
            });
          } else {
            isExist.archives.push(element);
          }
        }
      });
      console.log("++++++  ");
      res.sort((a, b) => {
        return a.updatedAt > b.updatedAt ? -1 : 1;
      });
      console.log(res);
      console.log("sort2");
      for (let index = 0; index < res.length; index++) {
        const element = res[index];
        console.log(element.archives);
        const nan_order = -999;
        element.archives.sort((a, b) => {
          // console.log("---- touroku ");
          // console.log(a);
          // console.log(a.resgist_at);
          const a1 = parseInt(a.sort_order);
          const a2 = isNaN(a1) ? nan_order : a1;
          const b1 = parseInt(b.sort_order);
          const b2 = isNaN(b1) ? nan_order : b1;
          if (a2 !== b2) {
            if (a2 > b2) return 1;
            if (a2 < b2) return -1;
          }
          if (a.resgist_at !== b.resgist_at) {
            if (a.resgist_at > b.resgist_at) return -1;
            if (a.resgist_at < b.resgist_at) return 1;
          }
          return 0;
        });
      }
      const res2 = res.sort((a, b) => {
        if (a.archives[0].resgist_at !== b.archives[0].resgist_at) {
          if (a.archives[0].resgist_at > b.archives[0].resgist_at) return -1;
          if (a.archives[0].resgist_at < b.archives[0].resgist_at) return 1;
        }
      });
      console.log("res2", res2);
      this.archives = res2;
    },
  },
};
</script>

<style></style>
