<template>
  <div id="participants">
    Participants
    <BODParticipants :_id="$route.params.id" />
  </div>
</template>

<script>
import BODParticipants from "@/components/BODParticipants";
import store from "../../store";
export default {
  components: { BODParticipants },
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (currentUser && !currentUser.permissions.includes("Director")) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
};
</script>

<style></style>
