<template>
  <div class="mainContentBox">
    <div class="mainContentBoxInner" v-if="state == 'edit'">
      <div class="pageTitleWrapper mypage">
        <h2 class="pageTitle">パスワード変更</h2>
      </div>

      <div class="pageCaption">
        <div class="contactCaptionDetail">
          新しいパスワードを入力してください。<br />
        </div>
      </div>

      <section class="mainContent-form">
        <dl class="formTable">
          <dt class="formTable_ttl">
            <span class="icoForm-requir">必須</span>
            <label for="">パスワード</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                <label id=""
                  ><input
                    type="text"
                    id=""
                    class="u-input--w100p u-ime-en"
                    maxlength="128"
                    name=""
                    v-model.trim="$v.password.$model"
                    placeholder="8〜20文字 半角英数"
                /></label>
              </div>
              <div class="error" v-if="!$v.password.required">必須です。</div>
              <div class="error" v-if="!$v.password.passwordValidator">
                パスワードは8〜20文字 半角英数
              </div>
            </div>
          </dd>
        </dl>

        <div class="cn-back-btn">
          <a href="javascript:void(0);" @click="onConfirm">変更</a>
        </div>
      </section>
    </div>
    <div class="mainContentBoxInner" v-else-if="state == 'confirm'">
      <div class="pageTitleWrapper mypage">
        <h2 class="pageTitle">パスワード変更確認</h2>
      </div>

      <div class="pageCaption">
        <div class="contactCaptionDetail">
          パスワードを変更します。よろしければ「変更」ボタンをクリックしてください。
        </div>
      </div>

      <section class="mainContent-form">
        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="">パスワード</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">{{ password }}</div>
            </div>
          </dd>
        </dl>

        <div class="cn-back-btn">
          <a href="javascript:void(0);" @click="onUpdate">変更</a>
        </div>
        <div class="cn-back-btn">
          <a href="javascript:void(0);" @click="state = 'edit'">戻る</a>
        </div>
      </section>
    </div>
    <div class="mainContentBoxInner" v-if="state == 'complete'">
      <div class="pageTitleWrapper mypage">
        <h2 class="pageTitle">パスワード変更完了</h2>
      </div>

      <div class="pageCaption">
        <div class="contactCaptionDetail">
          <span class="caution">パスワード変更が完了しました。</span>
        </div>
      </div>

      <section class="mainContent-form">
        <div class="cn-back-btn">
          <router-link
            :to="{
              name: 'top',
              params: { mc_code: mcCode },
            }"
            >トップページへ</router-link
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { required, helpers } from "vuelidate/lib/validators";
import UserService from "../../../../services/user.service";
const passwordValidator = helpers.regex("alpha", /^[0-9a-zA-Z\d]{8,20}$/);

export default {
  name: "password-edit",
  data() {
    return {
      password: "",
      state: "edit",
    };
  },
  validations: {
    password: {
      required,
      passwordValidator,
    },
  },
  methods: {
    onConfirm() {
      console.log("onConfirm");
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        this.state = "confirm";
      }
    },
    async onUpdate() {
      console.log("onUpdate");
      try {
        const result = await UserService.changePassword(this.password);
        console.log(result);
        this.state = "complete";
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style>
/* @import "/i-salon/css/isalon/contact_layout.css"; */
</style>
