<template>
  <div id="participants-list">
    list
  </div>
</template>

<script>
import BODService from "../services/bod.service";
export default {
  props: ["_id"],
  data() {
    return {
      data: [],
    };
  },
  async mounted() {
    try {
      console.log("+++ bod mounted");
      console.log(this._id);
      const result = await BODService.getParticipants(this._id);
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style></style>
