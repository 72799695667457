import axios from "axios";
import authHeader from "./auth-header";

const API_URL = `${process.env.VUE_APP_API_ENDPOINT}/api/category`;
const API_BOD_URL = `${process.env.VUE_APP_API_ENDPOINT}/api/bod-category`;

class CategoryService {
  get() {
    return axios.get(`${API_URL}/list`, {
      headers: authHeader(),
    });
  }
  find(id) {
    return axios.get(`${API_URL}/item/${id}`, {
      headers: authHeader(),
    });
  }
  getBoD() {
    return axios.get(`${API_BOD_URL}/list`, {
      headers: authHeader(),
    });
  }
  findBoD(id) {
    return axios.get(`${API_BOD_URL}/item/${id}`, {
      headers: authHeader(),
    });
  }
}

export default new CategoryService();
