import axios from "axios";
// import authHeader from "./auth-header";

const API_URL = `${process.env.VUE_APP_API_ENDPOINT}/api/role/`;

class RoleService {
  getAll() {
    return axios.get(API_URL + "all");
  }
}

export default new RoleService();
