import Vue from "vue";
import BoD from "../services/bod.service";
// import authHeader from "../services/auth-header";

const initialState = { bods: [], current: {}, isDeleted: false };

export const bod = {
  namespaced: true,
  state: initialState,
  actions: {
    // eslint-disable-next-line
    setDeleted: function({ commit }, value) {
      commit("setDeleted", value);
    },
    async remove({ commit }, _id) {
      // // console.log("action remove");
      // let res = await BoD.remove(_id);
      // // console.log(res);
      // // console.log("aaaa");
      // // console.log(res);
      await BoD.remove(_id).then(
        docs => {
          // console.log("ok");
          commit("setDeleted", true);
          return Promise.resolve(docs);
        },
        error => {
          // console.log("error");
          // commit("bods", []);
          return Promise.reject(error);
        },
      );
    },
    all({ commit }) {
      return BoD.all().then(
        docs => {
          commit("bods", docs);
          return Promise.resolve(docs);
        },
        error => {
          commit("bods", []);
          return Promise.reject(error);
        },
      );
    },
    async item2({ commit, dispatch }, id) {
      commit("current", {});
      let docs = await dispatch("item", id);
      // console.log("item2");
      // console.log(docs);
      if (docs == null) docs = {};
      commit("current", docs);
    },

    priority({ commit }, obj) {
      commit("priority", obj);
      BoD.updatePriority(obj).then(
        // eslint-disable-next-line
        docs => {
          // console.log(docs);
          // commit("current", docs);
          // return Promise.resolve(docs);
        },
        // eslint-disable-next-line
        error => {
          // console.log(error);
          // commit("current", {});
          // return Promise.reject(error);
        },
      );
    },
    item({ commit }, id) {
      return BoD.item(id).then(
        docs => {
          commit("current", docs);
          return Promise.resolve(docs);
        },
        error => {
          commit("current", {});
          return Promise.reject(error);
        },
      );
    },
  },
  mutations: {
    setDeleted(state, value) {
      state.isDeleted = value;
    },
    priority(state, obj) {
      // console.log("priority");
      // // console.log(obj);

      // // console.log(state.current.schedules[obj.index]);
      const schedule = state.current.schedules[obj.index];
      Vue.set(
        schedule.results,
        0,
        schedule.results[0].filter(uid => {
          uid != obj.uid;
        }),
      );
      Vue.set(
        schedule.results,
        1,
        schedule.results[1].filter(uid => {
          uid != obj.uid;
        }),
      );
      Vue.set(
        schedule.results,
        2,
        schedule.results[2].filter(uid => {
          uid != obj.uid;
        }),
      );

      state.current.schedules[obj.index].results[obj.priority].push(obj.uid);
    },
    bods(state, docs) {
      state.bods = docs;
    },
    current(state, docs) {
      state.current = docs;
    },
  },
  getters: {
    current: state => {
      return state.current;
    },
    bods: state => {
      return state.bods;
    },
  },
};
