<template>
  <div id="app">
    <component v-bind:is="layout">
      <router-view></router-view>
    </component>
  </div>
</template>

<script>
// import GlobalNavi from "@/components/GlobalNavi.vue";
function getStyleSheet(href) {
  for (var i = 0; i < document.styleSheets.length; i++) {
    var sheet = document.styleSheets[i];
    // console.log(sheet);
    if (sheet.href != null && sheet.href.indexOf(href) > -1) {
      return sheet;
    }
  }
}
export default {
  // components: { GlobalNavi },

  name: "app",
  async mounted() {},
  computed: {
    layout() {
      const commonCss = getStyleSheet("/i-salon/assets/css/common.css");
      const layoutCss = getStyleSheet("/i-salon/docs/css/layout.css");

      // console.log(sheet1);
      if (["free", "full"].includes(this.$route.meta.layout)) {
        commonCss.disabled = true;
        layoutCss.disabled = false;
      } else {
        commonCss.disabled = false;
        layoutCss.disabled = true;
      }
      return (this.$route.meta.layout || this.defaultLayout) + "-layout";
    },
  },
  data() {
    return {
      defaultLayout: "default",
      defaultIndex: "/",
    };
  },
};
</script>

<style>
@import "/i-salon/css/isalon/contact_layout.css";
@import "/i-salon/css/isalon/infochange_layout.css";
@import "/i-salon/css/isalon/newslist_layout.css";

.el-menu--horizontal > .el-menu-item.dock-right {
  float: right;
}
.title-margin {
  margin: 55px 0 20px;
}

._calender-wrap .vc-container [role="button"],
.vc-container button {
  cursor: default;
}

._calender-wrap .vc-day-content {
  cursor: default;
}
</style>
