import axios from "axios";
// import authHeader from "./auth-header";

class RSVService {
  async getToken(mansionCode, roomNo, roleCode) {
    const params = {
      API_KEY: process.env.VUE_APP_RSV_TOKEN_API_KEY,
      mansionCode,
      roomNo,
      roleCode,
    };
    return await axios.post(process.env.VUE_APP_RSV_TOKEN_API_ENDPOINT, params);
  }
}

export default new RSVService();
