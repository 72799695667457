<template>
  <div>
    <div class="_main-mv">
      <picture>
        <source :srcset="mainImageUrl" media="(max-width: 768px)" />
        <img :src="mainImageUrl" alt="" />
      </picture>
    </div>
    <GlobalNewsList />
    <NewsList />
    <BoDNewsList v-if="mansionStatus == 'resident'" />
    <!-- 総会のご案内 -->
    <!-- <MeetingSummary mode="def" v-if="isPermission('OwnedNonResident')" /> -->

    <!-- マンションのお知らせ -->

    <div></div>

    <div class="_newinfo enq"></div>

    <!-- mainmenu start -->

    <div class="menucolumn01 _mainmenu-items">
      <div class="_mainmenu-inner">
        <h2 class="_mainmenu-ttl"></h2>

        <div class="_mainmenu-list">
          <!-- mainmenu roop start -->

          <div class="_mainmenu-item -mq menu01">
            <a
              href="https://www.mansion-concierge.com/i-salon/album.php"
              class="_mainmenu-item-link -bt"
              ontouchstart=""
              >施工レポート</a
            >
          </div>
          <!-- mainmenu roop start -->

          <div class="_mainmenu-item -mq menu02">
            <a
              href="https://www.mansion-concierge.com/i-salon/episode.php"
              class="_mainmenu-item-link -bt"
              ontouchstart=""
              >クレヴィアができるまで</a
            >
          </div>
          <!-- mainmenu roop end -->
        </div>
      </div>
    </div>
    <!-- mainmenu start -->

    <div class="menucolumn02 _mainmenu-items">
      <div class="_mainmenu-inner">
        <h2 class="_mainmenu-ttl"></h2>

        <div class="_mainmenu-list">
          <!-- mainmenu roop start -->

          <div class="_mainmenu-item -mq menu01">
            <a
              href="https://www.mansion-concierge.com/i-salon/option.php"
              class="_mainmenu-item-link -bt"
              ontouchstart=""
              >セレクト／各種オプションのご案内</a
            >
          </div>

          <div class="_mainmenu-item -mq menu02">
            <a
              href="https://www.mansion-concierge.com/i-salon/premium.php"
              class="_mainmenu-item-link -bt"
              ontouchstart=""
              >プレミアムサポート<span
                class="_mainmenu-item-link-sub"
                style="font-size: 87%"
                >（有償）</span
              ></a
            >
          </div>

          <div class="_mainmenu-item -mq menu03">
            <router-link
              class="_mainmenu-item-link -bt"
              :to="{ name: 'friend', params: { mc_code: mcCode } }"
            >
              クレヴィアお友達紹介
            </router-link>
          </div>
          <!-- mainmenu roop start -->

          <div class="_mainmenu-item -mq menu04">
            <a
              href="https://www.mansion-concierge.com/i-salon/service_pre.php"
              class="_mainmenu-item-link -bt"
              ontouchstart=""
              >各種優待サービス案内</a
            >
          </div>
          <!-- mainmenu roop end -->
        </div>
      </div>
    </div>
    <!-- mainmenu start -->

    <div class="menucolumn03 _mainmenu-items">
      <div class="_mainmenu-inner">
        <h2 class="_mainmenu-ttl"></h2>

        <div class="_mainmenu-list">
          <!-- mainmenu roop start -->

          <div class="_mainmenu-item -mq menu01">
            <a
              href="https://www.mansion-concierge.com/i-salon/schedule.php"
              class="_mainmenu-item-link -bt"
              ontouchstart=""
              >ご入居までのスケジュール</a
            >
          </div>
          <!-- mainmenu roop start -->

          <div class="_mainmenu-item -mq menu02">
            <a
              href="https://www.mansion-concierge.com/i-salon/procedure.php"
              class="_mainmenu-item-link -bt"
              ontouchstart=""
              >引越し前後のお手続き</a
            >
          </div>
          <!-- mainmenu roop start -->

          <div class="_mainmenu-item -mq menu03">
            <router-link
              class="_mainmenu-item-link -bt"
              :to="{
                name: 'archive-list',
                params: { mc_code: mcCode, category: 1 },
              }"
              >図面／ご入居関連資料
            </router-link>
          </div>
          <!-- mainmenu roop end -->
        </div>
      </div>
    </div>
    <!-- mainmenu start -->

    <div class="menucolumn04 _mainmenu-items">
      <div class="_mainmenu-inner">
        <h2 class="_mainmenu-ttl"></h2>

        <div class="_mainmenu-list">
          <!-- mainmenu roop start -->

          <div class="_mainmenu-item -mq menu01">
            <a
              href="https://www.mansion-concierge.com/i-salon/areainfo.php"
              class="_mainmenu-item-link -bt"
              ontouchstart=""
              >地域情報</a
            >
          </div>
          <!-- mainmenu roop start -->

          <div class="_mainmenu-item -mq menu02">
            <a
              href="https://www.mansion-concierge.com/i-salon/qa.php"
              class="_mainmenu-item-link -bt"
              ontouchstart=""
              >Q&A</a
            >
          </div>
          <!-- mainmenu roop start -->

          <div class="_mainmenu-item -mq menu03">
            <a
              href="https://www.mansion-concierge.com/i-salon/kurashicolumn.php"
              class="_mainmenu-item-link -bt"
              ontouchstart=""
              >暮らしのコラム</a
            >
          </div>
          <!-- mainmenu roop end -->
        </div>
      </div>
    </div>
    <!-- mainmenu end -->

    <div class="admin-menubox" v-if="currentUser.permissions.includes('Admin')">
      <h5>管理メニュー</h5>
      <ul>
        <li class="kanri-menu-01">
          <router-link :to="{ name: 'admin_user' }">ユーザー管理</router-link>
        </li>
        <li class="kanri-menu-02">
          <router-link :to="{ name: 'admin_order' }">申し込み管理</router-link>
        </li>
        <li class="kanri-menu-03">
          <router-link :to="{ name: 'admin_order-schema' }"
            >オーダースキーマ管理（zoom専用）</router-link
          >
        </li>
        <li class="kanri-menu-04">
          <router-link :to="{ name: 'admin_event' }"
            >イベント予約管理</router-link
          >
        </li>
        <li class="kanri-menu-05">
          <a href="https://www.mansion-concierge.com/i-salon/survey_list.php"
            >アンケート一覧</a
          >
        </li>
        <li class="kanri-menu-04">
          <router-link :to="{ name: 'admin-cst-index' }"
            >INETボタン設定</router-link
          >
        </li>
      </ul>
    </div>
    <div
      class="admin-menubox"
      v-if="
        !currentUser.permissions.includes('Admin') &&
          currentUser.permissions.includes('EventAdmin')
      "
    >
      <h5>管理メニュー</h5>
      <ul>
        <li class="kanri-menu-01">
          <router-link :to="{ name: 'admin_user' }">ユーザー管理</router-link>
        </li>
        <li>
          <router-link :to="{ name: 'admin_event' }"
            >イベント予約管理</router-link
          >
        </li>
      </ul>
    </div>
    <div
      class="admin-menubox"
      v-if="
        !currentUser.permissions.includes('Admin') &&
          currentUser.permissions.includes('Chairman')
      "
    >
      <h5>管理メニュー</h5>
      <ul>
        <li>
          <a href="https://www.mansion-concierge.com/i-salon/survey_list.php"
            >アンケート一覧</a
          >
        </li>
      </ul>
    </div>
    <!-- <div class="admin-menubox" v-if="currentUser.permissions.includes('Admin')">
      <h5>管理メニュー</h5>
      <ul>
        <li><router-link :to="{ name: 'admin-cst-index' }">INETボタン設定</router-link></li>
      </ul>
    </div> -->
  </div>
</template>

<script>
// import InfoList from "@/components/InfoList.vue";
import NewsList from "@/components/NewsList.vue";
import GlobalNewsList from "@/components/GlobalNewsList.vue";
import BoDNewsList from "@/components/BoDNewsList.vue";
// import MeetingSummary from "@/components/MeetingSummary.vue";
export default {
  components: { NewsList, BoDNewsList, GlobalNewsList },
  async mounted() {
    console.log("++++ isalon top");
    await this.$nextTick();
    window.initH2();
    if (window.menuInit) {
      window.menuInit();
    }
  },
  computed: {
    mansionStatus() {
      console.log("++++ mansionStatus", this.currentUser.status);
      if (this.mansion) {
        return this.currentUser.status;
      } else {
        return "";
      }
    },
    mainImageUrl() {
      return `/assets/custom/${this.currentUser.mansion.dai_bunrui}/${this.currentUser.mansion.chu_bunrui}/${this.currentUser.mansion._id}/img/main_top.jpg`;
    },
  },
};
</script>

<style></style>
