<template>
  <div id="app" class="wrapper">
    <header class="header">
      <div class="_header-ttl">
        <h1 class="_hader-logo">
          <router-link
            :to="{
              name: 'top',
              params: { mc_code: mcCode },
            }"
          >
            <img
              src="/i-salon/assets/img/common/logo-main.svg"
              alt="iuc SALON"
            />
          </router-link>
        </h1>
        <!-- / _hader-logo -->
        <h2 class="_header-property">
          <router-link
            :to="{
              name: 'top',
              params: { mc_code: mcCode },
            }"
            v-if="this.mansion != null"
          >
            {{ this.mansion.name }}
          </router-link>
        </h2>
        <!-- / _header-property -->
      </div>
      <!-- / _header-ttl -->
      <div class="_header-user">
        <div class="_user-avatar">
          <router-link
            :to="{
              name: 'mypage',
              params: { mc_code: mcCode },
            }"
          >
            <img src="/i-salon/assets/img/common/ic-avatar.png" alt="" />
          </router-link>
        </div>
        <p class="_user-room">{{ roomNo }}号室</p>
        <p class="_user-resident" id="_user-resident"></p>
      </div>
      <!-- / _header-user -->

      <a class="drawr-handle">
        <span></span>
        <span></span>
        <span></span>
      </a>
      <!-- / drawr-handle -->
    </header>

    <main class="main">
      <div class="_main-sidebar">
        <div class="_sidebar-inner">
          <NaviListContractor
            v-if="currentUser.status == 'contractor'"
          ></NaviListContractor>
          <NaviListResident v-else></NaviListResident>

          <h2 class="_calender-ttl">
            <span class="-cal -b">{{ currentMonth.month }}</span
            >月の行事一覧
          </h2>

          <!-- / _calender-ttl -->
          <div class="_calender-wrap">
            <vc-calendar
              ref="calendar"
              is-expanded
              :masks="masks"
              :firstDayOfWeek="1"
              @dayclick="onDayClick"
              @update:from-page="onFromPage"
              @update:to-page="onToPage"
              :attributes="attrs"
              :v-model="currentDate"
              timezone="Asia/Pyongyang"
              locale="ja"
            ></vc-calendar>

            <div class="calnews">
              <ul>
                <!-- schedule roop start -->

                <li v-for="(item, index) in news" :key="index">
                  <a
                    :href="item.url"
                    v-if="item.url != ''"
                    :target="item.url_target ? '_blank' : ''"
                    ><div class="date">
                      {{ dispDate(item) }}
                    </div>
                    <span v-html="createTag(item.label)"></span>
                    <div class="text">{{ item.calender_text }}</div></a
                  >
                  <div v-else>
                    <router-link
                      v-if="item.type == 'normal'"
                      :to="{
                        name: 'information-detail',
                        params: {
                          id: item._id,
                          mc_code: currentUser.mansion._id,
                        },
                      }"
                    >
                      <div class="date">
                        {{ dispDate(item) }}
                      </div>
                      <span v-html="createTag(item.label)"></span>
                      <div class="text">{{ item.calender_text }}</div>
                    </router-link>
                    <router-link
                      v-else-if="item.type == 'global'"
                      :to="{
                        name: 'global-information-detail',
                        params: {
                          id: item._id,
                          mc_code: currentUser.mansion._id,
                        },
                      }"
                    >
                      <div class="date">
                        {{ dispDate(item) }}
                      </div>
                      <span v-html="createTag(item.label)"></span>
                      <div class="text">{{ item.calender_text }}</div>
                    </router-link>
                    <router-link
                      v-else
                      :to="{
                        name: 'bod-information-detail',
                        params: {
                          id: item._id,
                          mc_code: currentUser.mansion._id,
                        },
                      }"
                    >
                      <div class="date">
                        {{ dispDate(item) }}
                      </div>
                      <span v-html="createTag(item.label)"></span>
                      <div class="text">{{ item.calender_text }}</div>
                    </router-link>
                  </div>
                </li>
                <!-- schedule roop end -->
              </ul>
            </div>
          </div>
          <!-- / _calender-wrap -->
          <!-- / _calender -->

          <!-- banner -->
          <!--//
          <div class="banner">
            <div class="bnr_item">
              <a href="https://gcv-yurakucho.com/" target="_blank">
                <img src="/i-salon/assets/img/common/bn_01.png" />
                <p>ギャラリークレヴィア有楽町イトシア</p>
              </a>
            </div>

            <div class="bnr_item">
              <a href="https://gcv-shinjuku.com/" target="_blank">
                <img src="/i-salon/assets/img/common/bn_02.png" />
                <p>ギャラリークレヴィア新宿</p>
              </a>
            </div>
          </div>
          //-->
          <!-- /banner -->
        </div>
        <!-- / _sidebar-inner -->
      </div>
      <!-- / _main-sidebar -->

      <!--//SP 号室表示//-->
      <div class="_main-container">
        <div class="_main-user-sp">
          <div class="_user-sp-inner">
            <p class="_user-sp-room">{{ roomNo }}号室</p>
            <!-- / _user-sp-room -->
            <p class="_user-sp-resident"></p>
            <!-- / _user-sp-resident -->
          </div>
          <!-- / _user-sp-inner" -->
        </div>
        <!-- / _main-user -->

        <router-view></router-view>

        <!--//SP カレンダー表示//-->
        <div class="_main-calender-sp">
          <div class="_cal-sp-inner">
            <vc-calendar
              ref="calendar"
              is-expanded
              :masks="masks"
              :firstDayOfWeek="2"
              @dayclick="onDayClick"
              @update:from-page="onFromPage"
              @update:to-page="onToPage"
              :attributes="attrs"
              :v-model="currentDate"
              timezone="Asia/Pyongyang"
              locale="ja"
            ></vc-calendar>

            <div class="calnews">
              <ul>
                <!-- schedule roop start -->

                <li v-for="(item, index) in news" :key="index">
                  <a
                    :href="item.url"
                    v-if="item.url != ''"
                    :target="item.url_target ? '_blank' : ''"
                    ><div class="date">
                      {{ dispDate(item) }}
                    </div>
                    <span v-html="createTag(item.label)"></span>
                    <div class="text">{{ item.calender_text }}</div></a
                  >
                  <div v-else>
                    <router-link
                      v-if="item.type == 'normal'"
                      :to="{
                        name: 'information-detail',
                        params: {
                          id: item._id,
                          mc_code: currentUser.mansion._id,
                        },
                      }"
                    >
                      <div class="date">
                        {{ dispDate(item) }}
                      </div>
                      <span v-html="createTag(item.label)"></span>
                      <div class="text">{{ item.calender_text }}</div>
                    </router-link>
                    <router-link
                      v-else
                      :to="{
                        name: 'bod-information-detail',
                        params: {
                          id: item._id,
                          mc_code: currentUser.mansion._id,
                        },
                      }"
                    >
                      <div class="date">
                        {{ dispDate(item) }}
                      </div>
                      <span v-html="createTag(item.label)"></span>
                      <div class="text">{{ item.calender_text }}</div>
                    </router-link>
                  </div>
                </li>
                <!-- schedule roop end -->
              </ul>
            </div>
          </div>
        </div>

        <!-- banner -->
        <!--//
        <div class="banner">
          <div class="bnr_item">
            <a href="https://gcv-yurakucho.com/" target="_blank"
              ><img src="/i-salon/assets/img/common/bn_01.png" />
              <p>ギャラリークレヴィア有楽町イトシア</p></a
            >
          </div>

          <div class="bnr_item">
            <a href="https://gcv-shinjuku.com/" target="_blank"
              ><img src="/isalon/assets/img/common/bn_02.png" />
              <p>ギャラリークレヴィア新宿</p></a
            >
          </div>
        </div>
//-->
        <!-- /banner -->
      </div>
      <!-- / _main-container -->
    </main>
    <!-- / main -->
    <Footer></Footer>
    <!-- / footer -->
    <div class="_page-top" ontouchstart="" style="">
      <img src="/i-salon/assets/img/common/ic-bt-pagetop.png" alt="" />
    </div>

    <!-- sp navigation start -->
    <!--//SP ナビ表示//-->

    <div class="drawr-overlay"></div>
    <!-- / drawr-overlay -->

    <div class="drawr-menu">
      <div class="_drawr-user">
        <div class="_drawr-user-avatar">
          <router-link
            :to="{
              name: 'mypage',
              params: { mc_code: mcCode },
            }"
            ><img src="/i-salon/assets/img/common/ic-avatar_sp.png" alt=""
          /></router-link>
        </div>
        <!-- / _drawr-user-avatar -->
        <div class="_drawr-user-info">
          <p class="_drawr-user-room">{{ roomNo }}号室</p>
          <!-- / _drawr-user-room -->
          <p class="_drawr-user-resident"></p>
          <!-- / _drawr-user-resident -->
        </div>
        <!-- / _drawr-user-info -->
      </div>
      <!-- / _drawr-user -->

      <div class="_drawr-navi">
        <NaviListMobileContractor
          v-if="currentUser.status == 'contractor'"
        ></NaviListMobileContractor>
        <NaviListMobileResident v-else></NaviListMobileResident>
      </div>
      <!-- / _drawr-navi -->
    </div>
    <!-- / drawr-menu -->
    <!-- / sp navigation end -->

    <!-- <div class="ftr">
      <div class="ftr-top-wrapper">
        <ul class="ftr-link">
          <li class="ftr-link-item">
            <a href="/docs/policy.html" target="_blank">個人情報保護方針</a>
          </li>
          <li class="ftr-link-item">
            <a href="/docs/kiyaku.html" target="_blank">ご利用規約</a>
          </li>
        </ul>
      </div>
      <div class="ftr-bottom-wrapper">
        <div class="copyright">
          Copyright &copy; 2020 Zoom Communications, Inc.
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
// import gtag from "../mixin/gtag";
import moment from "moment";
import InfoService from "../services/info.service";
import BoDInfoService from "../services/bod-info.service";
import GlobalInfoService from "../services/global-info.service";
import store from "../store";
import Footer from "../components/Footer.vue";
import NaviListMobileContractor from "../components/NaviListMobileContractor.vue";
import NaviListMobileResident from "../components/NaviListMobileResident.vue";
import NaviListContractor from "../components/NaviListContractor.vue";
import NaviListResident from "../components/NaviListResident.vue";
// import $ from "jquery";
export default {
  components: {
    Footer,
    NaviListMobileContractor,
    NaviListMobileResident,
    NaviListResident,
    NaviListContractor,
  },
  // mixins: [gtag],
  name: "default-layout",
  async mounted() {
    var routeInstance = this.$route;
    //
    await this.$nextTick();
    this.createTitleDesc(routeInstance);
    this.appendFile();

    window.initMenu();
  },
  beforeUpdate() {
    //
    const currentUser = store.state.auth.user;

    if (
      currentUser != null &&
      currentUser.mansion._id != this.$route.params.mc_code
    ) {
      this.$router.push({
        name: "notfound404",
      });
    }
    if (!currentUser.email) {
      this.$router.push({
        name: "email-required",
        params: { mc_code: currentUser.mansion._id },
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    //
    const currentUser = store.state.auth.user;

    this.setGtag();

    if (currentUser != null && currentUser.mansion._id != to.params.mc_code) {
      next({
        name: "notfound404",
        params: { mc_code: currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  beforeRouteUpdate(to, from, next) {
    //
    next();
  },
  beforeRouteLeave(to, from, next) {
    //
    next();
  },
  computed: {
    roomNo() {
      return this.currentUser ? this.currentUser.roomNo : "";
    },
    isShow() {
      const ignorePaths = [
        "login",
        "password-reminder",
        "notfound404",
        "verify-email",
      ];
      //
      //
      return (
        ignorePaths.indexOf(this.$route.name) == -1 && this.currentUser != null
      );
    },
    mansionName() {
      //
      //
      if (this.mansion) {
        return this.mansion.name ? this.mansion.name : "";
      } else {
        return "";
      }
    },
    user() {
      return this.currentUser;
    },
    showAdminBoard() {
      return true;
      // if (this.currentUser && this.currentUser.role) {
      //   // eslint-disable-next-line
      //
      //   return this.currentUser.role == "Admin";
      // }

      // return false;
    },
    showModeratorBoard() {
      return true;
      // if (this.currentUser && this.currentUser.roles) {
      //   return this.currentUser.roles.includes("ROLE_MODERATOR");
      // }

      // return false;
    },
  },
  watch: {
    // eslint-disable-next-line
    $route(routeInstance, from) {
      this.createTitleDesc(routeInstance);
    },
  },
  data() {
    return {
      currentMonth: { year: 0, month: 0 },
      news: [],
      defaultIndex: "/",
      masks: {
        title: "YYYY MM",
        weekdays: "W",
        navMonths: "MMM",
        input: ["L", "YYYY-MM-DD", "YYYY/MM/DD"],
        dayPopover: "WWW, MMM D, YYYY",
        data: ["L", "YYYY-MM-DD", "YYYY/MM/DD"],
      },
      attrs: [
        {
          key: "today",
          highlight: {
            color: "orange",
            fillMode: "light",
          },
          dates: new Date(),
        },
        {
          key: "schedule",
          dot: true,
          dates: new Date(),
          customData: { _id: "abc" },
        },
        {
          key: "schedule",
          highlight: {
            start: { fillMode: "outline" },
            base: { fillMode: "light" },
            end: { fillMode: "outline" },
          },
          dates: { start: new Date(2021, 5, 14), end: new Date(2021, 5, 20) },
          customData: { _id: "qwe" },
        },
        {
          key: "schedule2",
          highlight: {
            start: { fillMode: "outline" },
            base: { fillMode: "light" },
            end: { fillMode: "outline" },
          },
          dates: { start: new Date(2021, 5, 16), end: new Date(2021, 5, 25) },
          customData: { _id: "asd" },
        },
      ],
    };
  },
  // 'http://localhost:8080/assets/custom/dai_1/defalut.css'
  methods: {
    dispDate(obj) {
      let startDate, endDate, startTime;
      let endTime = moment(obj.calender_range.end).format("HH:mm");
      if (obj.type == "global") {
        startDate = moment(obj.calender_range[0]).format("MM.DD");
        endDate = moment(obj.calender_range[1]).format("MM.DD");
        startTime = moment(obj.calender_range[0]).format("HH:mm");
        endTime = moment(obj.calender_range[1]).format("HH:mm");
      } else {
        startDate = moment(obj.calender_range.start).format("MM.DD");
        endDate = moment(obj.calender_range.end).format("MM.DD");
        startTime = moment(obj.calender_range.start).format("HH:mm");
        endTime = moment(obj.calender_range.end).format("HH:mm");
      }

      let result = "";
      if (obj.is_dispTime) {
        if (startDate != endDate) {
          result = `${startDate} ${startTime} 〜 ${endDate} ${endTime}`;
        } else if (startTime != endTime) {
          result = `${startDate} ${startTime} 〜 ${endTime}`;
        } else {
          result = `${startDate} ${startTime} 〜`;
        }
      } else {
        if (startDate != endDate) {
          result = `${startDate} 〜 ${endDate}`;
        } else {
          result = `${startDate}`;
        }
      }

      return result;
    },
    // eslint-disable-next-line
    onDayClick(obj) {
      //
      //
    },
    // eslint-disable-next-line
    onFromPage(obj) {
      //
      //
    },
    onToPage: async function(obj) {
      this.currentMonth = obj;
      //
      obj.is_schedule = true;
      obj.limit = 100;
      const response = await InfoService.list(obj);

      this.news = [];
      if (response.data.docs != undefined) {
        const docs1 = response.data.docs;

        for (let index = 0; index < docs1.length; index++) {
          const element = docs1[index];
          element.type = "normal";
          this.news.push(element);
        }
      }

      const response2 = await BoDInfoService.list(obj);
      //
      if (response2.data.docs != undefined) {
        const docs2 = response2.data.docs;

        for (let index = 0; index < docs2.length; index++) {
          const element = docs2[index];
          element.type = "bod";
          this.news.push(element);
        }
      }

      const responseGlobal = await GlobalInfoService.findCalender(obj);

      if (responseGlobal.length > 0) {
        for (let index = 0; index < responseGlobal.length; index++) {
          const element = responseGlobal[index];
          element.type = "global";
          this.news.push(element);
        }
      }

      this.news = Array.from(
        new Map(this.news.map(item => [item._id, item])).values(),
      );

      //

      this.attrs = [];

      for (let index = 0; index < this.news.length; index++) {
        const element = this.news[index];
        if (element.type == "global") {
          this.attrs.push({
            key: element._id,
            highlight: {
              start: { fillMode: "outline" },
              base: { fillMode: "light" },
              end: { fillMode: "outline" },
            },
            dates: {
              start: element.calender_range[0],
              end: element.calender_range[1],
            },
          });
        } else {
          this.attrs.push({
            key: element._id,
            highlight: {
              start: { fillMode: "outline" },
              base: { fillMode: "light" },
              end: { fillMode: "outline" },
            },
            dates: {
              start: element.calender_range.start,
              end: element.calender_range.end,
            },
          });
        }
      }

      this.attrs.push({
        key: "today",
        highlight: {
          color: "orange",
          fillMode: "light",
        },
        dates: new Date(),
      });
    },
    onChangeMansion(value) {
      this.mansionName = value;
    },

    appendCSS(filePath) {
      let file = document.createElement("link");
      file.rel = "stylesheet";
      file.href = filePath;
      document.head.appendChild(file);
    },
    role2Ja: function(role) {
      const roleNames = {
        Admin: "管理者",
        ExternalParty: "外部関係者",
        Chairman: "理事長",
        Director: "理事",
        OwnedResident: "区分所有者",
        OwnedNonResident: "外部オーナー",
        RentResident: "賃借人",
        Others: "その他",
      };
      return roleNames[role];
    },
    logOut() {
      // this.$store.dispatch("auth/logout");
      // this.$router.push("/login");
      // window.location = "/login";
      window.location = process.env.VUE_APP_ISALON_LOGOUT_ENDPOINT;
    },
  },
};
</script>

<style>
#app {
  /*  font-family: "Avenir", Helvetica, Arial, sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*text-align: center;*/
  /*  color: #2c3e50;*/
  /* margin-top: 60px; */
}

.el-menu--horizontal > .el-menu-item.dock-right {
  float: right;
}
.title-margin {
  margin: 55px 0 20px;
}
</style>
