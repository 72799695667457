<template>
  <div id="bod-detail">
    <div class="main">
      <section class="main-section">
        <h3 class="section-title green-left-head">
          理事会詳細<span class="btn_to_list">
            <router-link
              :to="{
                name: 'bod',
                params: {
                  mc_code: currentUser.mansion._id,
                },
              }"
              >一覧に戻る</router-link
            ></span
          >
        </h3>
        <div class="box-item news-detail">
          <div class="box-item-wrapper">
            <ul class="box-item-list">
              <li class="box-item-list-item">
                <!-- <a href="#"> -->
                <div class="date" v-if="current.date != null">
                  {{ current.date | moment("YYYY/MM/DD") }}
                  {{ current.range.start }}〜{{ current.range.end }}&nbsp;{{
                    isOfficialWord(current.isOfficial)
                  }}
                </div>
                <div class="date" v-else>
                  調整中
                </div>
                <div class="text">
                  {{ current.title }}
                </div>
                <!-- </a> -->
              </li>
            </ul>

            <p class="section-block center">
              <button
                class="bt bt-talk chatopenBtn bt-green"
                @click="onOpenDocument"
                :class="{
                  'bt-gray': current.files ? current.files.length == 0 : false,
                }"
                :disabled="current.files ? current.files.length == 0 : true"
              >
                議事録の確認
              </button>
            </p>
          </div>
        </div>
      </section>
      <!--/.main-section-->

      <section class="main-section" v-if="!current.isOfficial">
        <h3 class="section-title cr-left-head">
          スケジュール調整<span class="riji-status status01">調整中</span>
        </h3>
        <div class="box-item">
          <div class="box-item-wrapper">
            <ul class="box-item-list">
              <li class="box-item-list-item">
                <div class="text" v-if="isMember()">
                  {{
                    current.title
                  }}のスケジュール候補です。ご都合を入力してください。
                </div>
                <div v-else>
                  本理事会の作成時に理事ではなかったのでスケジュール調整権限はありません。
                </div>

                <div class="text text_border sche_view">
                  <div
                    class="sche_view sche_view_child"
                    v-for="(item, index) in current.schedules"
                    :key="index"
                  >
                    <template v-if="item.date != null">
                      <span class="sche_view_date"
                        >{{ item.date | moment("MM/DD(ddd)") }}&emsp;{{
                          item.range.start
                        }}〜{{ item.range.end }}</span
                      ><span
                        class="sche_view_select"
                        :class="{ selected: hasUser(item.results[0]) }"
                        @click="onSelect(index, 0)"
                        v-if="isMember()"
                        >×</span
                      ><span
                        class="sche_view_select"
                        :class="{ selected: hasUser(item.results[1]) }"
                        @click="onSelect(index, 1)"
                        v-if="isMember()"
                        >△</span
                      ><span
                        class="sche_view_select"
                        :class="{ selected: hasUser(item.results[2]) }"
                        @click="onSelect(index, 2)"
                        v-if="isMember()"
                        >○</span
                      >
                    </template>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <!--/.main-section-->

      <section class="main-section" v-if="current.isOfficial">
        <h3 class="section-title cr-left-head">出欠確認・開催情報</h3>
        <div class="box-item">
          <div class="box-item-wrapper">
            <ul class="box-item-list">
              <li class="box-item-list-item" v-if="current.isOfficial">
                <div class="text">
                  {{ current.title }}（{{
                    current.date | moment("YYYY/MM/DD")
                  }}&emsp;{{ current.range.start }}〜{{
                    current.range.end
                  }}）の出欠予定、およびWeb会議のURLです。出欠を選択してください。当日は時間になりましたら会議に参加ください。
                </div>
                <Vote
                  :bodID="$route.params.id"
                  agendaIndex="-1"
                  :titles="['出席', '欠席']"
                  :isEditor="isPermission('Admin')"
                  :isDone="isDone"
                  :members="current.members"
                  :roomNo="currentUser.roomNo"
                ></Vote>
                <div class="text text_link" v-if="current.remote.title != ''">
                  <a :href="current.remote.url" target="_blank">{{
                    current.remote.title
                  }}</a>
                </div>
              </li>
              <li class="box-item-list-item" v-else>
                未設定
              </li>
            </ul>
          </div>
        </div>
      </section>
      <!--/.main-section-->

      <section class="main-section">
        <h3 class="section-title cr-left-head">議案</h3>
        <div class="box-item">
          <div class="box-item-wrapper">
            <ul
              class="box-item-list"
              v-if="
                current.agendas &&
                  current.agendas.length > 0 &&
                  current.agendas[0].title != ''
              "
            >
              <li
                class="box-item-list-item"
                v-for="(agenda, index) in current.agendas"
                :key="index"
              >
                <VoteGian
                  :isEditor="isPermission('Admin')"
                  :bodID="$route.params.id"
                  :agenda="agenda"
                  :agendaIndex="index"
                  :isOfficial="current.isOfficial"
                  :isDone="isDone"
                  :members="current.members"
                  :roomNo="currentUser.roomNo"
                ></VoteGian>
                <!-- </div> -->
              </li>
            </ul>

            <ul class="box-item-list" v-else>
              <li class="box-item-list-item">
                未設定
              </li>
            </ul>
          </div>
        </div>
      </section>
      <!--/.main-section-->

      <section class="main-section">
        <h3 class="section-title cr-left-head">理事会トークルーム</h3>
        <div class="box-item">
          <div class="box-item-wrapper">
            <ul class="box-item-list">
              <li class="box-item-list-item">
                <!-- <div class="date">
                  {{ current.date | moment("YYYY/MM/DD")
                  }}
                  <span class="tag tag-update">update</span>
                </div> -->
                <div class="text">{{ current.title }}トークルーム</div>
                <div class="talk-data">
                  <span>返信数： {{ numOfComments.count }}</span>
                </div>
                <p class="section-block center">
                  <button class="bt bt-talk chatopenBtn" @click="onOpenChat">
                    トークルームを開く
                  </button>
                </p>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <!--/.main-section-->
      <IconMenu />
      <!--/.main-section-->
    </div>
    <!--/.main-->
    <div id="chat" v-if="isChatView">
      <!-- <div class="chat-title"> -->
      <!-- <div class="chatclose">閉じる</div> -->
      <h2 class="chat-heading" style="position:relative;">
        {{ current.title }}トークルーム
      </h2>
      <div class="chatclose chatopen" @click="isChatView = false">閉じる</div>
      <!-- </div> -->
      <ChatView
        class="messages"
        @allowmessage="onAllowessage"
        :room="this.$route.params.id"
        :name="currentUser.roomNo"
        :dispName="currentUser.roomNo"
        :roomName="current.title"
        mode="user"
      ></ChatView>
      <ChatInput
        allow_status="allow_status"
        :room="this.$route.params.id"
        :name="currentUser.roomNo"
        :dispName="currentUser.roomNo"
      ></ChatInput>
    </div>
    <div id="pdfContainer" v-show="isPDFView" style="z-index:100;">
      <iframe
        src=""
        type="application/pdf"
        width="100%"
        height="100%"
        style="overflow: auto;"
        id="pdfView"
      ></iframe>
      <div class="chatclose chatopen" @click="isPDFView = false">閉じる</div>
    </div>
  </div>
</template>

<script>
import store from "../../store";
import Vote from "../../components/Vote";
import VoteGian from "../../components/VoteGian";
import ChatView from "@/components/ChatView.vue";
import ChatInput from "@/components/ChatInput.vue";
import IconMenu from "@/components/IconMenu.vue";
import ChatServce from "../../services/chat.service";
import FileService from "../../services/file.service";

export default {
  name: "",
  components: { Vote, VoteGian, ChatView, ChatInput, IconMenu },
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (currentUser && !currentUser.permissions.includes("Director")) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  created() {
    // Attach onpopstate event handler
    const self = this;
    // eslint-disable-next-line
    window.onpopstate = function(event) {
      self.isChatView = false;
    };
  },
  mounted: async function() {
    console.log("mounted");
    console.log(this.current);
    // console.log(this.$route.params.id);
    await this.$nextTick();
    await store.dispatch("bod/item2", this.$route.params.id);
    console.log("mounted2");
    console.log(this.current);
    if (this.current.title == undefined) {
      this.$router.push({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
      return;
    }
    console.log(this.current.files.length);
    const rr = await ChatServce.count(this.$route.params.id);
    this.numOfComments = rr.data;

    const today = this.currentDate();
    console.log(`today=${today}`);
    const tstart = this.toTIMESTAMP(
      this.current.date,
      this.current.range.start,
    );
    const tend = this.toTIMESTAMP(this.current.date, this.current.range.end);
    console.log(`tstart=${tstart}-${tend}`);
    console.log(tstart <= today && tend >= today && this.current.isOfficial);

    // console.log("----aaa");
    // console.log(this.numOfComments);
  },
  data: function() {
    return {
      isPDFView: false,
      isLoadFinish: false,
      allow_status: "",
      isChatView: false,
      numOfComments: 0,
    };
  },
  watch: {
    isChatView: function(val) {
      if (val) {
        // window.scrollTo(0, 0);
        // history.pushState(null, null, document.URL);
        // document.body.style.overflow = "hidden";
      } else {
        // console.log("-");
        // document.body.style.overflow = "auto";
      }
    },
  },
  computed: {
    memberRooms() {
      console.log("!members");
      console.log(this.current.members);
      const strs = [];
      for (let index = 0; index < this.current.members.length; index++) {
        const element = this.current.members[index];
        strs.push(element.roomNo);
      }
      return strs;
    },
    isBefore() {
      const today = this.currentDate();
      console.log("isBefore");
      console.log(`today=${today}`);
      const tstart = this.toTIMESTAMP(
        this.current.date,
        this.current.range.start,
      );
      const tend = this.toTIMESTAMP(this.current.date, this.current.range.end);
      console.log(`tstart=${tstart}-${tend}`);
      console.log(tstart <= today && tend >= today && this.current.isOfficial);
      const isbef = tstart >= today && this.current.isOfficial;
      console.log(isbef);
      console.log("----ll");
      return isbef;
    },
    isDone() {
      const today = this.currentDate();
      console.log("isDone");
      console.log(`today=${today}`);
      const tstart = this.toTIMESTAMP(
        this.current.date,
        this.current.range.start,
      );
      const tend = this.toTIMESTAMP(this.current.date, this.current.range.end);
      console.log(`tstart=${tstart}-${tend}`);
      console.log(tstart <= today && tend >= today && this.current.isOfficial);
      console.log(tend < today && this.current.isOfficial);
      console.log("----ll");
      return tend < today && this.current.isOfficial;
    },
    current: function() {
      const doc = this.$store.getters["bod/current"];
      // console.log("++ cal");
      // console.log(doc);
      return doc;
    },
  },
  methods: {
    onOpenDocument() {
      const self = this;
      if (this.current.files.length > 0 && this.current.files[0].name) {
        const fileName = this.current.files[0].name;
        FileService.download("bod", this.$route.params.id, fileName).then(
          response => {
            // return response.data;
            const url = window.URL.createObjectURL(
              new Blob([response.data], { type: "application/pdf" }),
            );
            document.getElementById("pdfView").src =
              "/pdfjs/web/viewer.html?file=" + url;
            self.isPDFView = true;
          },
        );
      }
    },
    onAllowessage: function(value) {
      // console.log(`onAllowessage=${value}`);
      this.allow_status = value;
    },
    onOpenChat() {
      // console.log("onOpenChat");
      this.isChatView = true;
    },
    hasUser(array) {
      // console.log(array);
      if (typeof array != "object") {
        return false;
      }
      const uid = this.currentUser.id;
      // console.log(uid);
      return array.indexOf(uid) != -1;
    },
    onSelect(index, priority) {
      // console.log(`index=${index},${priority}`);
      this.$store.dispatch("bod/priority", {
        index: index,
        priority: priority,
        uid: this.currentUser.id,
        BoDID: this.$route.params.id,
      });
    },
    isMember() {
      return (
        this.isPermission("Admin") ||
        this.current.members.some(
          element => element.roomNo == this.currentUser.roomNo,
        )
      );
    },
  },
};
</script>

<style>
#chat {
  width: 100%;
  position: fixed;
  height: 100%;
  background-color: #e7edfd;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  z-index: 2;
}

@media (min-width: 769px) {
  #chat {
    width: 50%;
    right: 0;
    left: auto;
    box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.2);
    max-width: 460px;
  }

  #chat-view,
  .inputWrapper {
    max-width: 100% !important;
  }
}

#chat {
  -webkit-animation: SlideIn 0.5s backwards;
  animation: SlideIn 0.5s backwards;
}

@-webkit-keyframes SlideIn {
  0% {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes SlideIn {
  0% {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

#pdfContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100%;
}
.messages {
  flex-grow: 1;
}
</style>
