import axios from "axios";
import authHeader from "./auth-header";

const BASE_API_URL = `${process.env.VUE_APP_API_ENDPOINT}/api/chat/`;
const CHAT_SERVER = process.env.VUE_APP_CHAT_SERVER;
// console.log(BASE_API_URL);
class ChatService {
  async find(obj) {
    const res = await axios.post(BASE_API_URL + `find`, obj, {
      headers: authHeader(),
    });
    return res;
  }

  async count(room) {
    const res = await axios.get(CHAT_SERVER + `/room/${room}`, {
      headers: authHeader(),
    });
    return res;
  }
}

export default new ChatService();
