<template>
  <el-main>
    <h3 class="section-title green-left-head title-margin">イベント予約管理</h3>
    <p>
      <el-button type="primary" icon="el-icon-circle-plus" @click="onCreate()"
        >新規イベント予約</el-button
      >
    </p>
    <el-table :data="result.data.docs" stripe style="width: 100%">
      <el-table-column
        prop="status"
        label="公開"
        width="140"
        :render-header="renderHeader"
      >
      </el-table-column>
      <el-table-column
        prop="title"
        label="タイトル"
        :render-header="renderHeader"
      ></el-table-column>
      <el-table-column
        prop="publishRange[0]"
        label="掲載開始日"
        width="100"
        :render-header="renderHeader"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.publishRange[0] | moment("YYYY.MM.DD") }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="publishRange[1]"
        label="掲載終了日"
        width="100"
        :render-header="renderHeader"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.publishRange[1] | moment("YYYY.MM.DD") }}</span>
        </template>
      </el-table-column>
      <!-- <el-table-column
        prop="directory"
        label="フォーム名"
        width="200"
        :render-header="renderHeader"
        ><template slot-scope="scope">
          <span>
            {{ scope.row.orderSchema.directory }}
          </span>
        </template></el-table-column
      > -->

      <!-- <el-table-column prop="start_at" label="開始"> </el-table-column>
      <el-table-column prop="end_at" label="終了"> </el-table-column> -->
      <el-table-column fixed="right" label="操作" fit="true" width="120">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="onEdit(scope.row._id)"
            >編集</el-button
          >
          <el-button type="text" size="small" @click="onManage(scope.row._id)"
            >管理</el-button
          >
          <el-button type="text" size="small" @click="onRemove(scope.row._id)"
            >削除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="result.data.totalDocs"
      :page-size="result.data.limit"
      @current-change="onCurrentChange"
    >
    </el-pagination>
  </el-main>
</template>

<script>
import store from "../../../store";
import PreviewService from "../../../services/preview.service";
export default {
  name: "previewIndex",
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (
      currentUser &&
      !currentUser.permissions.includes("Admin") &&
      !currentUser.permissions.includes("EventAdmin")
    ) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  data() {
    return {
      result: { data: { docs: [] } },
      order: { updatedAt: -1 },
    };
  },
  computed: {
    sortCaretAsc: function() {
      return function(prop) {
        if (this.sortProp === prop && this.sortOrder === "asc") {
          return "sort-caret ascending active";
        }
        return "sort-caret ascending";
      };
    },
    sortCaretDesc: function() {
      return function(prop) {
        if (this.sortProp === prop && this.sortOrder === "desc") {
          return "sort-caret descending active";
        }
        return "sort-caret descending";
      };
    },
  },
  async mounted() {
    console.log("%cpreview.mounted", "background-color:green;color:white");
    await this.$nextTick();
    this.reloadData();
  },
  methods: {
    async reloadData() {
      // const order = { updatedAt: -1 };
      console.log("+++reloadData");
      console.log(this.order);
      const res = await PreviewService.find({
        page: 1,
        order: this.order,
        mansion: this.$route.params.mc_code,
      });
      console.log("res", res);
      this.result = res;
      console.log("result", this.result);
    },
    onCreate() {
      this.$router.push({ name: "admin-event-edit", params: { id: null } });
    },
    async sortChange(prop, direction) {
      console.log("sortChange");
      // { field: 'asc', test: -1 }
      this.order = {};
      this.order[prop] = direction;
      console.log(`${prop}/${direction}`);
      await this.find({
        page: 1,
        order: this.order,
        mansion: this.$route.params.mc_code,
      });
    },
    renderHeader(h, e) {
      console.log("renderHeader");
      return h("div", null, [
        h("span", { class: "caret-wrapper" }, [
          h(
            "i",
            {
              class: this.sortCaretAsc(e.column.property),
              on: {
                click: () => this.sortChange(e.column.property, 1),
              },
            },
            null,
          ),
          h(
            "i",
            {
              class: this.sortCaretDesc(e.column.property),
              on: {
                click: () => this.sortChange(e.column.property, -1),
              },
            },
            null,
          ),
        ]),
        e.column.label,
      ]);
    },
    async find(query) {
      console.log("+++++ find");
      console.log(query);
      this.result = await PreviewService.find(query);
      // this.result = res.data;
      console.log(this.result);
    },
    async onCurrentChange(page) {
      // console.log(page);
      this.result = await PreviewService.find({
        order: this.order,
        mansion: this.$route.params.mc_code,
        page: page,
      });
      console.log(this.result);
    },
    onEdit(id) {
      console.log(id);
      this.$router.push({
        name: "admin-event-edit",
        params: { id: id },
      });
    },
    onManage(id) {
      this.$router.push({
        name: "admin-event-detail",
        params: { id: id },
      });
    },
    async onRemove(id) {
      console.log(id);
      if (!confirm("削除しても良いですか？")) {
        return;
      }
      try {
        const res = await PreviewService.remove(id);
        console.log(res);
        this.$message.success("削除しました");
        this.reloadData();
      } catch (error) {
        console.log(error.message);
        this.$message.error("削除できませんでした");
      }
    },
  },
};
</script>

<style></style>
