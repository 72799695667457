<template>
  <div class="mainContentBox">
    <div class="mainContentBoxInner" v-if="state == 'edit'">
      <div class="pageTitleWrapper mypage">
        <h2 class="pageTitle">メールアドレス変更</h2>
      </div>

      <div class="pageCaption">
        <div class="contactCaptionDetail">
          メールアドレスを変更します。

          <ul>
            <li>
              1. メールアドレスを入力します。「変更」ボタンをクリックします。
            </li>
            <li>
              2.
              変更したメールアドレスに届く「メールアドレス登録認証URL」をクリックして変更完了です。
            </li>
          </ul>
        </div>
      </div>

      <section class="mainContent-form">
        <dl class="formTable">
          <dt class="formTable_ttl">
            <span class="icoForm-requir">必須</span>
            <label for="">メールアドレス</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                <label id=""
                  ><input
                    type="text"
                    id=""
                    class="u-input--w100p u-ime-en"
                    maxlength="128"
                    name=""
                    v-model.trim="$v.email.$model"
                /></label>
              </div>
              <div class="error" v-if="!$v.email.required">必須です。</div>
              <div class="error" v-if="!$v.email.email">
                Emailアドレスを入力してください
              </div>
            </div>
          </dd>
        </dl>
        <dl class="formTable">
          <dt class="formTable_ttl">
            <span class="icoForm-requir">必須</span>
            <label>お知らせ通知</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                <span v-for="(item, index) in labels" :key="index">
                  <label>
                    <input
                      type="radio"
                      id="notice"
                      class=""
                      name="notice"
                      v-model="needToSendEmail"
                      :value="item.value"
                    />
                    &nbsp;{{ item.label }}&nbsp;</label
                  >
                </span>
              </div>
              <div class="error"></div>
              <div class="error">
                お知らせが更新された際、メールにて通知を受け取るか受け取らないかの選択をしてください。
              </div>
            </div>
          </dd>
        </dl>

        <div class="cn-back-btn">
          <a href="javascript:void(0);" @click="onPreChange">変更</a>
        </div>
      </section>
    </div>
    <div class="mainContentBoxInner" v-else>
      <div class="pageTitleWrapper mypage">
        <h2 class="pageTitle">メールアドレス変更</h2>
      </div>

      <div class="pageCaption">
        <div class="contactCaptionDetail">
          <span class="caution"
            ><span class="caution-em error"
              >メールアドレス変更はまだ完了していません。</span
            ><br />
            登録したメールアドレスに届く「メールアドレス登録認証URL」をクリックして変更完了となりますので、<br />メールをご確認ください。<br /><br /><a
              href="/i-salon/docs/faq.html"
              target="_blank"
              >→メールが届かない場合はこちらをご確認ください。</a
            ></span
          >
        </div>
      </div>

      <section class="mainContent-form">
        <div class="cn-back-btn">
          <!-- <router-link
            :to="{
              name: 'mypage',
              params: { mc_code: mcCode },
            }"
            >ユーザー情報へ</router-link
          > -->
          <!--//
          <span class="backLink">
            <a href="https://www.mansion-concierge.com/iuc-salon/logout.php">
            >//-->
          <!--//<a
            //  href="javascript:void(0);"
            //  @click="$router.push({ name: 'logout' })"
            >//-->
          <!--//
              →ログインページへ</a
            >
          </span>
//-->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import UserService from "../../../../services/user.service";
export default {
  data() {
    return {
      email: "",
      needToSendEmail: false,
      state: "edit",
      labels: [
        { value: true, label: "受け取る" },
        { value: false, label: "受け取らない" },
      ],
    };
  },
  validations: {
    email: {
      required,
      email,
    },
    needToSendEmail: {
      required,
    },
  },
  async mounted() {
    await this.$nextTick();
    console.log(this.currentUser);
    // this.email = this.currentUser.email;
    this.email = "";
    this.needToSendEmail =
      this.currentUser.needToSendEmail != null
        ? this.currentUser.needToSendEmail
        : true;
  },
  methods: {
    async onPreChange() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        try {
          const result = await UserService.preVerifyEmail(
            this.email,
            this.currentUser.loginID,
          );
          console.log(result);
          const res2 = await UserService.update(this.currentUser.id, {
            needToSendEmail: this.needToSendEmail,
          });
          console.log(res2);
          this.state = "confirm";
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style></style>
