<template>
  <el-main id="bod-edit" class="shoko"
    ><h3 class="section-title green-left-head">申し込み確認</h3>
    <el-col :span="24">
      <el-form ref="form" :model="doc" label-width="20%">
        <el-form-item label="タイトル">
          {{ doc.orderSchema ? doc.orderSchema.name : "" }}</el-form-item
        >
        <el-form-item label="ステータス">
          <el-select v-model="doc.status" placeholder="Select">
            <el-option
              v-for="item in statuses"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="名前"> {{ doc.sei }} {{ doc.mei }} </el-form-item>
        <el-form-item label="部屋番号"> {{ doc.roomNo }}</el-form-item>
        <el-form-item label="メモ">
          <el-input
            type="textarea"
            placeholder=""
            :autosize="{ minRows: 4 }"
            v-model="doc.memo"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="申し受け日">
          {{ doc.createdAt | moment("YYYY/MM/DD hh:mm:ss") }}</el-form-item
        >
        <el-form-item label="更新日">
          {{ doc.updatedAt | moment("YYYY/MM/DD hh:mm:ss") }}</el-form-item
        >
        <el-divider></el-divider>
        <el-form-item
          :label="resultLabels[index]"
          v-for="(item, index) in doc.result"
          :key="index"
        >
          <div v-if="Array.isArray(item)">
            <div v-for="(item2, index2) in item" :key="index2">
              <p v-html="autoLink(item2)"></p>
            </div>
          </div>
          <div v-html="autoLink(item)" v-else></div>
        </el-form-item>
        <el-divider></el-divider>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">保存</el-button>
          <!-- <el-button type="danger" @click="onDelete" :disabled="isFirst"
            >削除</el-button
          > -->
          <el-button @click="$router.go(-1)">戻る</el-button>
        </el-form-item>
      </el-form>
    </el-col>
    <!-- <h3 class="section-title green-left-head">
      申し込みフォーム{{ $route.params.id ? "編集" : "登録" }}
    </h3>
    <el-col :span="24">
      <el-form ref="form" :model="doc" label-width="30%" :rules="rules">
        <el-form-item label="公開">
          <el-switch
            v-model="doc.isPublished"
            active-text="公開"
            inactive-text="非公開"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="公開URL">
          <span>/{{ doc.mansion }}/order/{{ doc.directory }}</span>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input type="text" placeholder="名称" v-model="doc.name">
          </el-input>
        </el-form-item>
        <el-form-item label="マンションコード">
          <el-input
            type="text"
            placeholder="MC CODE"
            v-model="doc.mansion"
            disabled
          >
          </el-input>
        </el-form-item>
        <el-form-item label="ディレクトリ" prop="directory">
          <el-tooltip class="item" effect="light" placement="top-start">
            <div slot="content">
              /[mc code]/order/[ディレクトリ名]として使用されます。<br />同じ[mc
              code]内でユニークである必要があります。
            </div>
            <el-input
              type="text"
              placeholder="ディレクトリ名"
              v-model="doc.directory"
            >
            </el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="Toアドレス">
          <el-input
            type="text"
            placeholder="Toで使用するアドレスを入力してください。複数ある場合にはカンマで区切ってください"
            v-model="doc.toEmailAddress"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="BCCアドレス">
          <el-input
            type="text"
            placeholder="BCCで使用するアドレスを入力してください。複数ある場合にはカンマで区切ってください"
            v-model="doc.bccEmailAddress"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="Toにユーザのアドレスを挿入する">
          <el-switch
            v-model="doc.useUserAddressInTo"
            active-text="する"
            inactive-text="しない"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="BCCにユーザのアドレスを挿入する">
          <el-switch
            v-model="doc.useUserAddressInBCC"
            active-text="する"
            inactive-text="しない"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="フォームスキーマ">
          <vue-json-editor
            v-model="doc.schema"
            :show-btns="false"
            :expandedOnStart="true"
            mode="code"
            @json-change="onJsonChange"
            @has-error="onJsonError"
          ></vue-json-editor>
          <div class="el-form-item__error" v-if="!isSafeJSON">
            JSONフォーマットを確認してください
          </div>
        </el-form-item>
        <el-form-item label="メールSubjectテンプレート">
          <el-input
            type="text"
            placeholder="メールのサブジェクトのテンプレートを入力してください"
            v-model="doc.subjectTemplate"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="メール本文テンプレート" prop="emailTemplate">
          <el-input
            type="textarea"
            placeholder=""
            :autosize="{ minRows: 10 }"
            v-model="doc.emailTemplate"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">登録</el-button>
          <el-button @click="$router.go(-1)">戻る</el-button>
        </el-form-item>
      </el-form>
    </el-col> -->
  </el-main>
</template>

<script>
// import vueJsonEditor from "vue-json-editor";
import store from "../../../store";
import OrderService from "../../../services/order.service";
export default {
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (currentUser && !currentUser.permissions.includes("Admin")) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  data() {
    return {
      statuses: ["未処理", "対応中", "処理済"],
      resultLabels: {},
      doc: {
        status: "",
        sei: "",
        mei: "",
        mansionName: "",
        roomNo: "",
        result: {},
        memo: "",
      },
    };
  },
  async mounted() {
    await this.$nextTick();
    console.log(this.$route.params.id);
    if (this.$route.params.id) {
      const result = await OrderService.get(this.$route.params.id);
      // console.log(result);
      this.doc = result.data;
      this.resultLabels = {};
      console.log(this.doc);
      this.getLabels(this.doc.orderSchema.schema, this.resultLabels);
      console.log(this.resultLabels);
      if (!this.doc.schema) {
        this.doc.schema = {};
      }
    } else {
      this.doc.mansion = this.currentUser.mansion._id;
    }
  },
  methods: {
    autoLink(text) {
      console.log(typeof text);
      if (typeof text != "string") {
        if (typeof text == "boolean") {
          return text ? "はい" : "いいえ";
        }
        return text;
      }

      return text.replace(
        /(https?:\/\/[^\s]*)/g,
        "<a href='$1' target='_blank'>$1</a>",
      );
    },
    onJsonError(error) {
      console.log(error);
      this.isSafeJSON = false;
    },
    onJsonChange(value) {
      console.log("value:", value);
      this.isSafeJSON = true;
    },
    async onSubmit() {
      console.log("+++ onSubmit");
      this.postData();
    },
    async postData() {
      try {
        const param = {
          _id: this.doc._id,
          memo: this.doc.memo,
          status: this.doc.status,
        };
        const res = await OrderService.edit(param);
        if (res.response) {
          console.log("+++ error");
          console.log(res.response);
          console.log(res.response.status);
          console.log(res.response.data);
          this.$message.error(res.response.data);
        } else {
          console.log("+++ successs");
          console.log(res);
          this.$router.go(-1);
        }
        // this.$router.push({ name: "archive" });
      } catch (error) {
        console.log(error.message);
        this.$message.error("登録できませんでした");
      }
    },
    onDelete() {
      console.log("+++ onDelete");
    },
    getLabels(obj, resultObj) {
      if (Array.isArray(obj)) {
        for (let index = 0; index < obj.length; index++) {
          const element = obj[index];
          if (Array.isArray(element.children)) {
            this.getLabels(element.children, resultObj);
          } else {
            if (element.label != undefined) {
              resultObj[element.name] = element.label;
            }
          }
        }
      } else {
        if (obj.label != undefined) {
          resultObj[obj.name] = obj.label;
        }
      }

      return resultObj;
    },
  },
};
</script>

<style></style>
