<template>
  <el-main id="admin-meeting-index">
    <h3 class="section-title green-left-head title-margin">
      理事会からのお知らせ管理
    </h3>
    <p>
      <el-button
        type="primary"
        icon="el-icon-circle-plus"
        @click="onEdit('create')"
        >新規お知らせ</el-button
      >
    </p>
    <el-table :data="result.docs" stripe style="width: 100%">
      <el-table-column label="公開" width="80"
        ><template slot-scope="scope">
          <span style="margin-left: 10px">{{
            scope.row.is_top ? "公開" : "非公開"
          }}</span>
        </template></el-table-column
      >
      <el-table-column prop="title" label="タイトル"> </el-table-column>
      <el-table-column label="掲載開始日" width="120"
        ><template slot-scope="scope">
          <span style="margin-left: 10px">{{
            scope.row.display_range.start | moment("YYYY-MM-DD")
          }}</span>
        </template></el-table-column
      >
      <el-table-column label="掲載終了日" width="120"
        ><template slot-scope="scope">
          <span style="margin-left: 10px">{{
            scope.row.display_range.end | moment("YYYY-MM-DD")
          }}</span>
        </template></el-table-column
      >
      <!-- <el-table-column prop="start_at" label="開始"> </el-table-column>
      <el-table-column prop="end_at" label="終了"> </el-table-column> -->
      <el-table-column fixed="right" label="操作" fit="true" width="120">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="onEdit(scope.row._id)"
            >編集</el-button
          >
          <el-button type="text" size="small" @click="onRmove(scope.row._id)"
            >削除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="result.totalDocs"
      :page-size="result.limit"
      @current-change="onCurrentChange"
    >
    </el-pagination>
  </el-main>
</template>

<script>
// import store from "../../../store";
import BoDInfoService from "../../../../services/bod-info.service";
import store from "../../../../store";

export default {
  name: "admin_info_index",
  async mounted() {
    const response = await BoDInfoService.findByDB("", 1);
    await this.$nextTick();
    await this.$nextTick();
    console.log("+++++mounted");
    console.log(response.data);
    this.result = response.data;
  },
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (currentUser && !currentUser.permissions.includes("Chairman")) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  data: function() {
    return {
      result: { docs: [] },
    };
  },
  computed: {
    showAdminBoard() {
      // console.log(this.currentUser.permissions);
      if (this.currentUser && this.currentUser.permissions) {
        return this.currentUser.permissions.includes["Director"];
      }

      return false;
    },
  },
  methods: {
    async onRmove(_id) {
      if (!confirm("削除しても良いですか？")) {
        return;
      }
      // eslint-disable-next-line
      const result = await BoDInfoService.remove(_id);
      const res = await BoDInfoService.findByDB("", 1);
      console.log(this.result.page);
      console.log(res);
      this.result = res.data;
    },
    async findByDB(maintionID, page) {
      const res = await BoDInfoService.findByDB("", page);
      this.result = res.data;
    },
    async onCurrentChange(page) {
      // console.log(page);
      this.findByDB("", page);
    },
    onEdit: function(id) {
      this.$router.push({
        name: "admin_bod_information_edit",
        params: { id, mc_code: this.currentUser.mansion._id },
      });
    },
  },
};
</script>

<style>
.main {
  text-align: left;
}
input {
  width: unset;
}
</style>
