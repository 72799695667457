import MansionService from "../services/mansion.service";
const initialState = { data: null };

export const mansion = {
  namespaced: true,
  state: initialState,
  actions: {
    async reset(context, id) {
      if (context.state.data == null || context.state.data._id != id) {
        const result = await MansionService.findById(id);
        context.commit("setMansion", result.data);
      }
    },
  },
  mutations: {
    setMansion(state, docs) {
      state.data = docs;
    },
  },
  getters: {
    mansion: state => {
      return state.data;
    },
  },
};
