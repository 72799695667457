<template>
  <div id="e-tohyo" class="main">
    <section class="main-section">
      <h3 class="section-title green-left-head">
        出欠の提出
        <span class="btn_to_list">
          <router-link
            :to="{
              name: 'meeting',
              params: { mc_code: currentUser.mansion._id },
            }"
            >総会の詳細に戻る</router-link
          >
        </span>
      </h3>
      <p class="section-block">
        出欠の提出をお願いいたします。欠席の場合は事前の議決権行使を行なってください。<br />
        画面下部「e投票メニュー」の「総会」に表示されている開催予定総会を選択し、以下に従い選択ください。<br />
        ・総会当日出席する場合は「出席通知」を選択してください。<br />
        ・総会当日欠席する場合は「委任」を選択してください。<br />
        ・事前に議決権行使する場合は「議決権行使」を選択してください。
      </p>
    </section>
    <!--/.main-section-->
    <!--/.main-->

    <div class="frame-wrapper__et">
      <iframe
        id="etohyo"
        name="etouhyou"
        src="/node_modules/etouhyou.html"
        frameborder="0"
        allowfullscreen
      ></iframe>
      <!-- <div class="frame-wrapper__video"> -->
      <div class="et_reload" @click="onReload">
        <span class="et_reload__inner">再読込</span>
      </div>
      <!-- </div> -->
      <form
        ref="epost"
        name="epost"
        id="epost"
        method="POST"
        action="https://zm.etohyo.com/cmn_sso.aspx"
        target="etouhyou"
      >
        <input
          type="hidden"
          name="UserID"
          :value="md5hex(currentUser.loginID)"
        />
        <!-- <input type="submit" value="e投票iframe" /> -->
      </form>
    </div>
    <IconMenu />
  </div>
</template>

<script>
import IconMenu from "@/components/IconMenu.vue";
import store from "../store";

export default {
  components: { IconMenu },
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (currentUser && !currentUser.permissions.includes("OwnedNonResident")) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  mounted: async function() {
    await this.$nextTick();
    // await this.$nextTick();
    this.$refs.epost.submit();
  },
  methods: {
    onReload() {
      this.$refs.epost.submit();
    },
  },
};
</script>

<style></style>
