export default class User {
  constructor(
    id,
    username,
    email,
    password,
    passwordConfirm,
    mansion,
    roomNo,
    role,
  ) {
    this.id = id;
    this.username = username;
    this.email = email;
    this.password = password;
    this.passwordConfirm = passwordConfirm;
    this.roomNo = roomNo;
    this.role = role;
    this.mansion = mansion;
    this.tokens = [];
    this.needToSendEmail = true;
  }
}
