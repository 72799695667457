<template>
  <el-main id="bod-edit" class="shoko">
    <h3 class="section-title green-left-head">
      申し込みフォーム{{ $route.params.id ? "編集" : "登録" }}
    </h3>
    <el-col :span="24">
      <el-form ref="form" :model="doc" label-width="30%" :rules="rules">
        <el-form-item label="公開">
          <el-switch
            v-model="doc.isPublished"
            active-text="公開"
            inactive-text="非公開"
          >
          </el-switch>
        </el-form-item>
        <!-- <el-form-item label="公開URL">
          <span>/{{ doc.mansion }}/order/{{ doc.directory }}</span>
        </el-form-item> -->
        <el-form-item label="名称" prop="name">
          <el-input type="text" placeholder="名称" v-model="doc.name">
          </el-input>
        </el-form-item>
        <el-form-item label="マンションコード">
          <el-input
            type="text"
            placeholder="MC CODE"
            v-model="doc.mansion"
            :disabled="doc._id != ''"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="フォーム名" prop="directory">
          <el-tooltip class="item" effect="light" placement="top-start">
            <div slot="content">
              同じ[mc_code]内でユニークである必要があります。<br />
              あとから変更できますがデータの整合性が取れなくなる場合があります
            </div>
            <!-- <div slot="content">
              /[mc code]/order/[フォーム名]として使用されます。<br />同じ[mc
              code]内でユニークである必要があります。
            </div> -->
            <el-input
              type="text"
              placeholder="フォーム名"
              v-model="doc.directory"
            >
            </el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="Toアドレス" prop="toEmailAddress">
          <el-input
            type="text"
            placeholder="Toで使用するアドレスを入力してください。複数ある場合にはカンマで区切ってください"
            v-model="doc.toEmailAddress"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="BCCアドレス">
          <el-input
            type="text"
            placeholder="BCCで使用するアドレスを入力してください。複数ある場合にはカンマで区切ってください"
            v-model="doc.bccEmailAddress"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="Toにユーザのアドレスを挿入する">
          <el-switch
            v-model="doc.useUserAddressInTo"
            active-text="する"
            inactive-text="しない"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="BCCにユーザのアドレスを挿入する">
          <el-switch
            v-model="doc.useUserAddressInBCC"
            active-text="する"
            inactive-text="しない"
          >
          </el-switch>
        </el-form-item>
        <el-form-item label="フォームスキーマ">
          <vue-json-editor
            v-model="doc.schema"
            :show-btns="false"
            :expandedOnStart="true"
            mode="code"
            @json-change="onJsonChange"
            @has-error="onJsonError"
          ></vue-json-editor>
          <div class="el-form-item__error" v-if="!isSafeJSON">
            JSONフォーマットを確認してください
          </div>
        </el-form-item>
        <el-form-item label="メールSubjectテンプレート">
          <el-input
            type="text"
            placeholder="メールのサブジェクトのテンプレートを入力してください"
            v-model="doc.subjectTemplate"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="メール本文テンプレート" prop="emailTemplate">
          <el-input
            type="textarea"
            placeholder=""
            :autosize="{ minRows: 10 }"
            v-model="doc.emailTemplate"
          >
          </el-input>
        </el-form-item>
        <el-divider>予備メール</el-divider>
        <el-form-item>
          <div class="tip">
            Toアドレスが存在する場合に上記の申し込みとは別にメールを送信します
          </div>
        </el-form-item>
        <el-form-item label="Toアドレス" prop="rsvToEmailAddress">
          <el-input
            type="text"
            placeholder="予備メールのToで使用するアドレスを入力してください。複数ある場合にはカンマで区切ってください"
            v-model="doc.rsvToEmailAddress"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label="メールSubjectテンプレート"
          prop="rsvSubjectTemplate"
        >
          <el-input
            type="text"
            placeholder="予備メールのサブジェクトのテンプレートを入力してください"
            v-model="doc.rsvSubjectTemplate"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="メール本文テンプレート" prop="rsvEmailTemplate">
          <el-input
            type="textarea"
            placeholder=""
            :autosize="{ minRows: 10 }"
            v-model="doc.rsvEmailTemplate"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">登録</el-button>
          <el-button type="info" @click="onCopy">複製</el-button>
          <el-button
            @click="
              $router.push({
                name: 'admin_order-schema',
                params: { mc_code: currentUser.mansion._id },
              })
            "
            >戻る</el-button
          >
        </el-form-item>
      </el-form>
    </el-col>
  </el-main>
</template>

<script>
import vueJsonEditor from "vue-json-editor";
import store from "../../../store";
import OrderSchemaService from "../../../services/order_schema.service";
export default {
  components: { vueJsonEditor },
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (currentUser && !currentUser.permissions.includes("Admin")) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  data() {
    var toEmailAddress = (rule, value, callback) => {
      if (value === "" && !this.doc.useUserAddressInTo) {
        callback(
          new Error(
            "Toにユーザのアドレスを挿入しない場合Toアドレスを入力してください",
          ),
        );
      }
      callback();
    };
    var validateJson = (rule, value, callback) => {
      try {
        JSON.parse(value);
        callback();
      } catch (error) {
        callback(new Error("jsonを記入してください"));
      }
    };
    var validateRsv = (rule, value, callback) => {
      console.log("this.doc.rsvToEmailAddress", this.doc.rsvToEmailAddress);
      console.log("value", value);
      if (this.doc.rsvToEmailAddress && !value) {
        callback(
          new Error("予備メールのToアドレスが存在する場合には入力してください"),
        );
      }
      callback();
    };

    var validateEmails = (rule, value, callback) => {
      // console.log("validateEmails", value);
      if (!value) {
        callback();
      }
      try {
        var emails = value.replace(/\s/g, "").split(",");
        var valid = true;
        var regex = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        for (var i = 0; i < emails.length; i++) {
          if (emails[i] == "" || !regex.test(emails[i])) {
            valid = false;
          }
        }
        if (valid) {
          callback();
        } else {
          callback(new Error("Eメールアドレスを入力してください"));
        }
      } catch (error) {
        callback(new Error("Eメールアドレスを入力してください"));
      }
    };
    return {
      isFirst: true,
      isSafeJSON: true,
      doc: {
        _id: "",
        isPublished: true, // 公開中か
        name: "",
        mansion: "",
        directory: "", // ディレクトリ名 /:mansion._id/order/:directory でユニーク
        schema: {},
        toEmailAddress: "isalon@itc-uc.co.jp", // toに追加するアドレス
        bccEmailAddress: "", // bccに追加するアドレス
        useUserAddressInTo: false, // ユーザアドレスをToに含めるか
        useUserAddressInBCC: false, // ユーザアドレスをBCCに含めるか
        subjectTemplate: "", // subjectテンプレート
        emailTemplate: "", // メールテンプレート
        rsvToEmailAddress: "", // 予備toに追加するアドレス
        rsvSubjectTemplate: "", // 予備メールサブジェクト
        rsvEmailTemplate: "", // 予備メールテンプレート
      },
      rules: {
        name: [
          {
            required: true,
            message: "フォーム名を入力してください",
            trigger: "blur",
          },
        ],
        toEmailAddress: [
          {
            validator: toEmailAddress,
            trigger: "blur",
          },
        ],
        directory: [
          {
            required: true,
            message: "フォーム名を入力してください",
            trigger: "blur",
          },
        ],
        schema: [{ validator: validateJson, trigger: "blur" }],
        emailTemplate: [
          {
            required: true,
            message: "Eメールのテンプレートを入力してください",
            trigger: "blur",
          },
        ],
        rsvToEmailAddress: [
          {
            validator: validateEmails,
            trigger: ["blur", "change"],
          },
        ],
        rsvSubjectTemplate: [{ validator: validateRsv, trigger: "blur" }],
        rsvEmailTemplate: [{ validator: validateRsv, trigger: "blur" }],
      },
    };
  },
  watch: {
    $route: function() {
      console.log("++++ watch");
      console.log(this.$route.params.id);
      delete this.doc._id;
    },
  },
  async mounted() {
    await this.$nextTick();
    console.log(this.$route.params.id);
    if (this.$route.params.id) {
      const result = await OrderSchemaService.get(this.$route.params.id);
      console.log("result", result.data);
      this.doc = result.data;
      if (!this.doc.schema) {
        this.doc.schema = {};
      }
    } else {
      this.doc.mansion = this.currentUser.mansion._id;
    }
  },
  methods: {
    onCopy() {
      // localStorage.setItem("orderSchema", this.doc);
      this.$router.push({
        name: "admin_order-schema_edit",
        params: { mc_code: this.currentUser.mansion._id },
      });
    },
    onJsonError(error) {
      console.log(error);
      this.isSafeJSON = false;
    },
    onJsonChange(value) {
      console.log("value:", value);
      this.isSafeJSON = true;
    },
    async onSubmit() {
      console.log("+++ onSubmit");
      if (!this.isSafeJSON) return;
      const self = this;
      this.$refs["form"].validate(valid => {
        if (valid) {
          console.log("submit ok");
          self.postData();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async postData() {
      console.log(this.doc);
      try {
        const res = await OrderSchemaService.edit(this.doc);
        if (res.response) {
          console.log("+++ error");
          console.log(res.response);
          console.log(res.response.status);
          console.log(res.response.data);
          this.$message.error(res.response.data);
        } else {
          console.log("+++ successs");
          console.log(res);
          // this.$router.go(-1);
          this.$router.push({
            name: "admin_order-schema",
            params: { mc_code: this.currentUser.mansion._id },
          });
        }
      } catch (error) {
        console.log(error.message);
        this.$message.error("登録できませんでした");
      }
    },
    onDelete() {
      console.log("+++ onDelete");
    },
  },
};
</script>

<style scoped>
.tip {
  padding: 8px 16px;
  background-color: #fff6f7;
  border-radius: 4px;
  border-left: 5px solid #fe6c6f;
  margin: 20px 0;
}
</style>
