import axios from "axios";
import authHeader from "./auth-header";

const API_URL = `${process.env.VUE_APP_API_ENDPOINT}/api/cst`;

class CstService {
  url(doc_type) {
    return axios.get(`${API_URL}/url/${doc_type}`, {
      headers: authHeader(),
    });
  }

  documentTypes() {
    return axios.get(`${API_URL}/document-types`, {
      headers: authHeader(),
    });
  }

  find(params) {
    console.log("find");
    return axios.get(`${API_URL}/inets`, {
      params,
      headers: authHeader(),
    });
  }

  create(params) {
    console.log("create");
    return axios.post(`${API_URL}/inet`, params, {
      headers: authHeader(),
    });
  }

  update(params) {
    console.log("update=", `${API_URL}/inet/${params._id}`);
    return axios.put(`${API_URL}/inet/${params._id}`, params, {
      headers: authHeader(),
    });
  }

  visibleDocuments() {
    return axios.get(`${API_URL}/visible-documents`, {
      headers: authHeader(),
    });
  }
}

export default new CstService();
