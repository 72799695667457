<template>
  <el-main id="bod-edit" class="shoko"
    ><h3 class="section-title green-left-head">
      申し込み確認
    </h3>
    <el-col :span="24">
      <el-form ref="form" :model="doc" label-width="20%">
        <el-form-item label="タイトル">
          {{ doc.orderSchema ? doc.orderSchema.name : "" }}</el-form-item
        >
        <el-form-item label="ステータス">
          <el-select v-model="doc.status" placeholder="Select">
            <el-option
              v-for="item in statuses"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="名前"> {{ doc.sei }} {{ doc.mei }} </el-form-item>
        <el-form-item label="部屋番号"> {{ doc.roomNo }}</el-form-item>
        <el-form-item label="メモ">
          <el-input
            type="textarea"
            placeholder=""
            :autosize="{ minRows: 4 }"
            v-model="doc.memo"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="申し受け日">
          {{ doc.createdAt | moment("YYYY/MM/DD hh:mm:ss") }}</el-form-item
        >
        <el-form-item label="更新日">
          {{ doc.updatedAt | moment("YYYY/MM/DD hh:mm:ss") }}</el-form-item
        >
        <el-divider></el-divider>
        <el-form-item
          :label="resultLabels[index]"
          v-for="(item, index) in doc.result"
          :key="index"
        >
          {{ item }}
        </el-form-item>
        <el-divider></el-divider>

        <el-form-item>
          <el-button type="primary" @click="onSubmit">保存</el-button>
          <!-- <el-button type="danger" @click="onDelete" :disabled="isFirst"
            >削除</el-button
          > -->
          <el-button @click="$router.go(-1)">戻る</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-main>
</template>

<script>
// import vueJsonEditor from "vue-json-editor";
import store from "../../../store";
// import OrderService from "../../../services/order.service";

import FormAnswerService from "../../../services/form_answer.service";

export default {
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (currentUser && !currentUser.permissions.includes("Admin")) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  data() {
    return {
      statuses: ["未処理", "対応中", "処理済"],
      doc: {
        status: "",
        sei: "",
        mei: "",
        mansionName: "",
        roomNo: "",
        result: {},
        memo: "",
      },
      resultLabels: {
        sei: "姓",
        mei: "名",
        kanaSei: "せい",
        kanaMei: "めい",
        email: "メールアドレス",
        address: "住所",
        category: "お問い合わせ種別",
        text: "お問い合わせ内容",
        phone: "電話番号",
      },
    };
  },
  async mounted() {
    console.log("+++ Contact.edit.mounted");
    await this.$nextTick();
    console.log(this.$route.params.id);
    console.log("+++ 1");
    if (this.$route.params.id) {
      const result = await FormAnswerService.get(this.$route.params.id);
      console.log("++++ ---- ");
      console.log(result);
      this.doc = result.data;
      if (!this.doc.schema) {
        this.doc.schema = {};
      }
    } else {
      this.doc.mansion = this.currentUser.mansion._id;
    }
  },
  methods: {
    onJsonError(error) {
      console.log(error);
      this.isSafeJSON = false;
    },
    onJsonChange(value) {
      console.log("value:", value);
      this.isSafeJSON = true;
    },
    async onSubmit() {
      console.log("+++ onSubmit");
      this.postData();
    },
    async postData() {
      try {
        const param = {
          _id: this.doc._id,
          memo: this.doc.memo,
          status: this.doc.status,
        };
        const res = await FormAnswerService.edit(param);
        if (res.response) {
          console.log("+++ error");
          console.log(res.response);
          console.log(res.response.status);
          console.log(res.response.data);
          this.$message.error(res.response.data);
        } else {
          console.log("+++ successs");
          console.log(res);
          this.$router.go(-1);
        }
        // this.$router.push({ name: "archive" });
      } catch (error) {
        console.log(error.message);
        this.$message.error("登録できませんでした");
      }
    },
    onDelete() {
      console.log("+++ onDelete");
    },
    getLabels(obj, resultObj) {
      if (Array.isArray(obj)) {
        for (let index = 0; index < obj.length; index++) {
          const element = obj[index];
          if (Array.isArray(element.children)) {
            this.getLabels(element.children, resultObj);
          } else {
            if (element.label != undefined) {
              resultObj[element.name] = element.label;
            }
          }
        }
      } else {
        if (obj.label != undefined) {
          resultObj[obj.name] = obj.label;
        }
      }

      return resultObj;
    },
  },
};
</script>

<style></style>
