import axios from "axios";
import qs from "qs";
import authHeader from "./auth-header";

const END_POINT = `${process.env.VUE_APP_API_ENDPOINT}`;

class FormService {
  get(id) {
    return axios.get(`${END_POINT}/api/form/${id}`);
  }

  find(query) {
    return axios.post(`${END_POINT}/api/forms`, { query });
  }
  findOne(query) {
    const paramsSerializer = query => qs.stringify(query);
    return axios.get(`${END_POINT}/api/form`, { query, paramsSerializer });
  }

  answer(category, sei, mei, mansionName, mansion, roomNo, result, isPublic) {
    return axios.post(`${END_POINT}/api/answer/public`, {
      category,
      sei,
      mei,
      mansionName,
      mansion,
      roomNo,
      result,
      isPublic,
    });
  }
  private(category, sei, mei, mansionName, mansion, roomNo, result, isPublic) {
    return axios.post(
      `${END_POINT}/api/answer/private`,
      {
        category,
        sei,
        mei,
        mansionName,
        mansion,
        roomNo,
        result,
        isPublic,
      },
      {
        headers: authHeader(),
      },
    );
  }
}

export default new FormService();
