<template>
  <div class="main kanri-page" id="admin-info-edit">
    <h2 class="page-title"><strong>お知らせ登録</strong></h2>

    <!-- <section class="main-section error-message">
      <i class="ico-error"></i>
      <p>エラーメッセージが入りますエラーメッセージが入ります。</p>
      <p>
        エラーメッセージが入りますエラーメッセージが入りますエラーメッセージが入ります。
      </p>
    </section> -->
    <el-col :span="24">
      <section class="main-section gray-top-border">
        <h3 class="section-title">お知らせ対象</h3>

        <!-- <el-col :span="24"> -->
        <el-form ref="form_target" :model="information" label-width="200px">
          <el-form-item>
            <el-checkbox v-model="statusContractor">契約者</el-checkbox>
            <el-checkbox v-model="statusResident">入居者</el-checkbox>
          </el-form-item>
          <el-form-item>
            <div class="group">
              <el-checkbox
                v-model="item.checked"
                v-for="(item, index) in targets"
                :key="`targets-${index}`"
                :style="{
                  display: index > targets.length - 4 ? 'none' : 'inline-block',
                }"
                >{{ item.label }}</el-checkbox
              >
            </div>
            <el-checkbox v-model="targets[targets.length - 3].checked"
              >賃借人</el-checkbox
            >
            <el-checkbox v-model="targets[targets.length - 2].checked"
              >管理員</el-checkbox
            ><el-checkbox v-model="targets[targets.length - 1].checked"
              >その他</el-checkbox
            >
            <el-checkbox v-model="information.is_individual">個別</el-checkbox>
          </el-form-item>
          <el-form-item
            label="個別お知らせ対象部屋番号"
            v-show="information.is_individual"
          >
            <el-input
              type="textarea"
              :rows="4"
              placeholder="個別お知らせを選択した場合の表示対象部屋番号（例：301,302,507）※カンマ区切り"
              v-model="rooms"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <!-- </el-col> -->
      </section>
      <!--/.main-section-->

      <section class="main-section gray-top-border">
        <h3 class="section-title">基本情報</h3>

        <!-- <el-col :span="24"> -->
        <el-form
          ref="form"
          :model="information"
          label-width="200px"
          :rules="rules"
        >
          <el-form-item label="タイトル" prop="title">
            <el-input
              type="text"
              placeholder="タイトル"
              v-model="information.title"
            >
            </el-input>
          </el-form-item>
          <div class="ck-content info-body">
            <div style="font-size: 90%; padding-bottom: 4px">
              <span class="required-label">本文</span>
            </div>
            <ckeditor
              ref="body_editor"
              :editor="editor"
              v-model="information.body"
              :config="editorConfig"
              @blur="validate"
            ></ckeditor>
            <div class="el-form-item__error" v-if="!isExistBody">
              本文を入力してください
            </div>
          </div>
          <el-form-item label="メール通知文">
            <el-input
              type="textarea"
              :rows="4"
              placeholder="メール通知文"
              v-model="information.mail_body"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="掲載表示日付">
            <el-col :span="11">
              <el-date-picker
                v-model="information.registration_at"
                type="date"
                placeholder="掲載表示日付"
                style="width: 100%"
              >
              </el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="掲載期間">
            <el-col :span="11">
              <el-date-picker
                type="date"
                placeholder="掲載開始"
                v-model="information.display_range.start"
                style="width: 100%"
                @change="onChange"
                :picker-options="pickerOptions2"
              ></el-date-picker>
            </el-col>
            <el-col class="line" :span="1">〜</el-col>
            <el-col :span="11">
              <el-date-picker
                type="date"
                placeholder="掲載終了"
                v-model="information.display_range.end"
                style="width: 100%"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <!-- <el-form-item label="掲載終了">
            <el-date-picker
              type="date"
              placeholder="掲載終了"
              v-model="fromDate"
              style="width: 100%"
              :picker-options="pickerOptions"
            ></el-date-picker>
          </el-form-item> -->
          <el-form-item label="リンク">
            <el-input type="text" placeholder="URL" v-model="information.url">
            </el-input>
          </el-form-item>
          <el-form-item label="リンクタイトル">
            <el-input
              type="text"
              placeholder="リンクタイトル"
              v-model="information.url_title"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="リンクターゲット">
            <el-switch
              v-model="information.url_target"
              active-text="別ウインドウ"
              inactive-text="同じウインドウ"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="ラベル" prop="label">
            <el-select v-model="information.label" placeholder="Select">
              <el-option
                v-for="item in labels"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="トップページに表示">
            <el-switch
              v-model="information.is_top"
              active-text="表示"
              inactive-text="非表示"
            >
            </el-switch>
          </el-form-item>
        </el-form>
        <!-- </el-col> -->
      </section>
      <!--/.main-section-->

      <section class="main-section gray-top-border">
        <h3 class="section-title">カレンダー</h3>

        <!-- <el-col :span="24"> -->
        <el-form
          ref="form_schedule"
          :model="information"
          label-width="200px"
          :rules="rules"
        >
          <el-form-item label="カレンダー機能">
            <el-switch
              v-model="information.is_schedule"
              active-text="あり"
              inactive-text="なし"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="カレンダー表示テキスト">
            <el-input
              type="text"
              placeholder="カレンダー表示テキスト"
              v-model="information.calender_text"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="期間" prop="calender_range">
            <el-col :span="11">
              <el-date-picker
                type="datetime"
                placeholder="開始"
                v-model="information.calender_range.start"
                style="width: 100%"
                format="yyyy-MM-dd HH:mm"
                :picker-options="pickerOptionsCalS"
              ></el-date-picker>
            </el-col>
            <el-col class="line" :span="1">～</el-col>
            <el-col :span="11">
              <el-date-picker
                type="datetime"
                placeholder="終了"
                v-model="information.calender_range.end"
                style="width: 100%"
                format="yyyy-MM-dd HH:mm"
                :picker-options="pickerOptionsCalE"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="時間を表示">
            <el-switch
              v-model="information.is_dispTime"
              active-text="する"
              inactive-text="しない"
            >
            </el-switch>
          </el-form-item>
        </el-form>
        <!-- </el-col> -->
      </section>
      <!--/.main-section-->

      <section class="main-section gray-top-border">
        <h3 class="section-title">通知</h3>

        <!-- <el-col :span="24"> -->
        <el-form ref="form_email" :model="information" label-width="200px">
          <el-form-item label="対象にメール">
            <el-switch
              v-model="information.notification_mail"
              active-text="する"
              inactive-text="しない"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="対象にPUSH通知">
            <el-switch
              v-model="information.notification_push"
              active-text="する"
              inactive-text="しない"
            >
            </el-switch>
          </el-form-item>
        </el-form>
        <!-- </el-col> -->
      </section>
      <!--/.main-section-->
    </el-col>
    <section class="main-section gray-top-border buttons">
      <button class="el-button el-button--primary" @click="onUpdate">
        登録
      </button>
      <button
        class="el-button el-button--default"
        @click="
          $router.push({
            name: 'admin_information',
            params: { mc_code: currentUser.mansion._id },
          })
        "
      >
        キャンセル
      </button>
    </section>
    <!--/.main-section-->
  </div>
  <!--/.main-->
</template>

<script>
import InfoService from "../../../services/info.service";
// eslint-disable-next-line
import UserService from "../../../services/user.service";
import store from "../../../store";
// eslint-disable-next-line
import moment from "moment";

import authHeader from "../../../services/auth-header";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import ClassicEditor from "../../../libs/ckeditor5-build-classic";

export default {
  name: "admin-info-edit",
  components: {
    // Use the <ckeditor> component in this view.
    ckeditor: CKEditor.component,
  },
  data: function() {
    console.log("+++ data");
    const temp = new Date();
    const today = new Date(temp.getFullYear(), temp.getMonth(), temp.getDate());
    const today2 = new Date(
      temp.getFullYear(),
      temp.getMonth(),
      temp.getDate(),
      temp.getHours(),
    );
    console.log(today);
    console.log("today2", today2);

    const rangeValidator = (rule, value, callback) => {
      console.log("!!! calender_range:validator");
      if (this.information.is_schedule) {
        if (
          value.start == null ||
          value.end == null ||
          String(value.start) == "" ||
          String(value.end) == ""
        ) {
          console.log("!!! calender_range:ERROR");
          return callback(new Error("期間を指定してください"));
        }
      }
      console.log("!!! calender_range:OK");
      callback();
    };
    const dafaultInfo = {
      targets: [
        "OwnedResident",
        "OwnedNonResident",
        "Chairman",
        "Director",
        "Secretary",
        "RentResident",
        "Manager",
      ],
      is_individual: false,
      rooms: [],
      status_targets: {
        contractor: false,
        resident: true,
        others: false,
      },
      title: "",
      body: "",
      mail_body: "",
      kakomi: "",
      registration_at: today,
      display_range: { start: today, end: today },
      url: "",
      url_target: false,
      url_title: "",
      label: "",
      is_top: true,
      is_schedule: false,
      calender_text: "",
      is_dispTime: true,
      calender_range: { start: today2, end: "" },

      notification_mail: false,
      notification_push: false,
    };
    console.log("dafaultInfo", dafaultInfo);
    return {
      pickerOptions: {
        disabledDate: this.disabledDate,
        // onPick: this.pick,
      },
      pickerOptions2: {
        disabledDate: this.disabledDate2,
        // onPick: this.pick,
      },
      pickerOptionsCalS: {
        disabledDate: this.disabledDateCalS,
        // onPick: this.pick,
      },
      pickerOptionsCalE: {
        disabledDate: this.disabledDateCalE,
        // onPick: this.pick,
      },
      isExistBody: true,
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        fontFamily: {
          options: [
            "default",
            "Helvetica Neue,Arial,Hiragino Kaku Gothic ProN,Hiragino Sans,Meiryo,sans-serif",
            "Times New Roman, YuMincho, Hiragino Mincho ProN, Yu Mincho, MS PMincho, serif",
            'UD デジタル 教科書体 NK-R", "Noto Sans JP", 游ゴシック体, YuGothic, "游ゴシック Medium", "Yu Gothic Medium", 游ゴシック, "Yu Gothic", メイリオ, sans-serif',
          ],
        },
        toolbar: {
          items: [
            // "heading",
            // "|",
            "bold",
            "italic",
            "underline",
            // "strikethrough",
            // "code",
            // "subscript",
            // "superscript",
            "|",
            "fontSize",
            "fontFamily",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "alignment",
            "link",
            "bulletedList",
            "numberedList",
            "imageUpload",
            "blockQuote",
            // "insertTable",
            // "mediaEmbed",
            "undo",
            "redo",
            "|",
            "sourceEditing",
          ],
        },
        simpleUpload: {
          // The URL that the images are uploaded to.
          uploadUrl: `${process.env.VUE_APP_API_ENDPOINT}/api/file/info`,

          // Enable the XMLHttpRequest.withCredentials property.
          withCredentials: false,

          // Headers sent along with the XMLHttpRequest to the upload server.
          headers: authHeader(),
        },
      },
      rooms: "",
      dafaultInfo: dafaultInfo,
      taishou: "",
      rules: {
        title: [
          {
            required: true,
            message: "タイトルを入力してください",
            trigger: "blur",
          },
        ],
        body: [
          {
            required: true,
            message: "本文を入力してください",
            trigger: "blur",
          },
        ],
        label: [
          {
            required: true,
            message: "ラベルを選択してください",
            trigger: ["blur", "change"],
          },
        ],
        display_end: [
          {
            required: true,
            // min:this.information.display_range.start,
            message: "掲載開始日以降にしてください",
            trigger: ["blur", "change"],
          },
        ],
        calender_range: [
          {
            required: true,
            trigger: "blur",
            validator: rangeValidator,
          },
        ],
      },
      labels: [
        {
          value: "tag01",
          label: "作業予定",
        },
        {
          value: "tag02",
          label: "重要",
        },
        {
          value: "tag03",
          label: "お知らせ",
        },
        {
          value: "tag04",
          label: "キャンペーン",
        },
        {
          value: "tag05",
          label: "伊藤忠都市開発より",
        },
        {
          value: "tag06",
          label: "大規模修繕",
        },
        {
          value: "tag07",
          label: "アンケート",
        },
        {
          value: "tag08",
          label: "優待のご案内",
        },
      ],
      information: dafaultInfo,
      targets: [
        {
          value: "OwnedResident",
          label: "住居オーナー（区分所有者）",
          checked: false,
        },
        {
          value: "OwnedNonResident",
          label: "外部オーナー（区分所有者）",
          checked: false,
        },
        { value: "Chairman", label: "理事長（区分所有者）", checked: false },
        { value: "Director", label: "理事（区分所有者）", checked: false },
        { value: "Secretary", label: "監事（区分所有者）", checked: false },
        { value: "RentResident", label: "賃借人", checked: false },
        { value: "Manager", label: "管理員", checked: false },
        { value: "Others", label: "その他", checked: false },
        // { value: "ExternalParty", label: "外部関係者", checked: false },
      ],
    };
  },
  watch: {
    rooms: function(val) {
      this.information.rooms = val.split(",");
    },
    targets: {
      // eslint-disable-next-line
      handler: function (val, oldVal) {
        console.log("someObj changed");
        const data = [];
        this.targets.forEach(element => {
          if (element.checked) {
            data.push(element.value);
          }
        });
        console.log(data);
        this.information.targets = data;
      },
      deep: true,
    },
  },
  computed: {
    statusContractor: {
      get() {
        return this.information.status_targets.contractor;
      },
      set(value) {
        this.information.status_targets.contractor = value;
      },
    },
    statusResident: {
      get() {
        return this.information.status_targets.resident;
      },
      set(value) {
        this.information.status_targets.resident = value;
      },
    },
    statusOthers: {
      get() {
        return this.information.status_targets.others;
      },
      set(value) {
        this.information.status_targets.others = value;
      },
    },
  },
  async mounted() {
    await this.$nextTick();
    if (this.$route.params.id == "create") {
      this.updateTagets(this.information.targets);
      return;
    }
    try {
      const response = await InfoService.findOne(this.$route.params.id);
      console.log("++mounted");
      console.log(response);
      // console.log(response.data.docs.length);
      if (response.data.length > 0) {
        await this.$nextTick();
        this.information = response.data[0];
        this.updateTagets(this.information.targets);
        this.rooms = this.information.rooms.join(",");
      } else {
        this.information = this.dafaultInfo;
        this.updateTagets(this.information.targets);
      }
      console.log(this.information);
    } catch (error) {
      console.log(error);
      this.$router.push({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    }
  },
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (currentUser && !currentUser.permissions.includes("Admin")) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  methods: {
    pick({ maxDate, minDate }) {
      console.log("maxDate", maxDate);
      console.log("minDate", minDate);
      this.fromDate = minDate;
    },
    disabledDate(date) {
      const sdate = moment(date).format("YYYY/MM/DD");
      const sstart = moment(this.information.display_range.start).format(
        "YYYY/MM/DD",
      );
      // console.log("date", sdate);
      // console.log("disabledDate", sstart);
      if (this.information.display_range.start) {
        return sstart > sdate;
      }
      return false;
    },
    disabledDate2(date) {
      const sdate = moment(date).format("YYYY/MM/DD");
      const sstart = moment(this.information.display_range.end).format(
        "YYYY/MM/DD",
      );
      // console.log("date", sdate);
      // console.log("disabledDate", sstart);
      if (this.information.display_range.end) {
        return sstart < sdate;
      }
      return false;
    },
    disabledDateCalE(date) {
      const sdate = moment(date).format("YYYY/MM/DD hh:mm:ss");
      const sstart = moment(this.information.calender_range.start).format(
        "YYYY/MM/DD hh:mm:ss",
      );
      // console.log("date", sdate);
      // console.log("disabledDate", sstart);
      if (this.information.calender_range.start) {
        return sstart > sdate;
      }
      return false;
    },
    disabledDateCalS(date) {
      const sdate = moment(date).format("YYYY/MM/DD hh:mm:ss");
      const sstart = moment(this.information.calender_range.end).format(
        "YYYY/MM/DD hh:mm:ss",
      );
      // console.log("date", sdate);
      // console.log("disabledDate", sstart);
      if (this.information.calender_range.end) {
        return sstart < sdate;
      }
      return false;
    },
    async validate() {
      this.isExistBody = this.information.body != "";
      this.$refs["form"].validate(valid => {
        console.log(valid);
      });
    },
    updateTagets(array) {
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        const obj = this.targets.find(obj => obj.value == element);
        obj.checked = true;
      }
    },
    onChange(obj) {
      console.log(obj);
      console.log(this.information.display_range.start);
    },
    async onUpdate() {
      console.log(this.information);

      const self = this;
      this.$refs["form"].validate(valid => {
        console.log("valid", valid);
        if (valid) {
          self.$refs["form_schedule"].validate(valid2 => {
            if (valid2) {
              console.log("OK");
              if (
                self.information.notification_push ||
                self.information.notification_mail
              ) {
                if (
                  !confirm("メール・PUSH通知が実行されます。よろしいですか。")
                ) {
                  return;
                }
              }

              self.postData();
            } else {
              self.isExistBody = self.information.body != "";
              console.log("error submit!!");
              return false;
            }
          });
        } else {
          self.isExistBody = this.information.body != "";
          console.log("error submit!!");
          // eslint-disable-next-line
          self.$refs["form_schedule"].validate((valid) => { });
          return false;
        }
      });

      return;
    },
    async postData() {
      const result = await InfoService.update(this.information);
      console.log("----- postData");
      console.log(result);
      console.log(process.env);

      if (this.information.notification_push) {
        const title = this.information.title;
        const body = this.information.mail_body;
        const clickAction = `${process.env.VUE_APP_BASE_URL}/information/${result.data._id}`;
        console.log(clickAction);
        // return;
        // eslint-disable-next-line
        const doc = await UserService.messaging(
          title,
          body,
          result.data.tokens,
          clickAction,
        );
      }
      if (this.information.notification_mail) {
        console.log("---");

        const payload = {
          emails: result.data.emails,
          date: moment().format("YYYY年MM月DD日"),
          title: this.information.title,
          mail_body: this.information.mail_body,
          url: `${process.env.VUE_APP_BASE_URL}/${this.currentUser.mansion.id}/information/${result.data._id}`,
        };
        //         // eslint-disable-next-line
        const doc = await UserService.sendmail(payload);
        console.log(doc);
      }
      this.$router.push({
        name: "admin_information",
        params: { mc_code: this.currentUser.mansion._id },
      });
    },
  },
};
</script>

<style scoped>
.group {
  border: solid 1px black;
  padding: 0 10px;
}
</style>
