<template>
  <div>
    <!-- RSV
    <a :href="url">RSV</a> -->
  </div>
</template>

<script>
import RSVService from "../../services/rsv.service";
// import store from "../../store";

export default {
  data() {
    return {
      url: "",
    };
  },
  async mounted() {
    const mansionCode = this.mcCode;
    const roomNo = this.currentUser.roomNo;
    const roleCode = this.roleCode;
    console.log(mansionCode, roomNo, roleCode);
    try {
      const result = await RSVService.getToken(mansionCode, roomNo, roleCode);
      console.log(result);
      const token = result.data.token;
      console.log(token);
      this.url = `${process.env.VUE_APP_RSV_LOGIN_URL}${token}`;
      console.log(this.url);
      window.location.href = this.url;
    } catch (error) {
      console.warn(error);
    }
  },
};
</script>
