<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
// import GlobalNavi from "@/components/GlobalNavi.vue";
// import gtag from "../mixin/gtag";
import moment from "moment";
import InfoService from "../services/info.service";
import store from "../store";
export default {
  // components: { GlobalNavi },

  name: "full-layout",
  // mixins: [gtag],
  async mounted() {
    var routeInstance = this.$route;
    console.log("+++++app mount");
    await this.$nextTick();
    this.createTitleDesc(routeInstance);
    this.appendFile();
    // await this.$nextTick();
    // if (this.currentUser != null && this.currentUser.mansion) {
    //   this.mansionName =
    //     this.currentUser.mansion.name != undefined ? this.currentUser.mansion.name : "";
    // } else {
    //   this.mansionName = "";
    // }
  },
  beforeRouteEnter(to, from, next) {
    console.log("+++ mixin:beforeRouteEnter");
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    console.log(to);

    if (currentUser != null && currentUser.mansion._id != to.params.mc_code) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  computed: {
    isShow() {
      const ignorePaths = [
        "login",
        "password-reminder",
        "notfound404",
        "verify-email",
      ];
      console.log(this.$route.path);
      console.log(this.currentUser);
      return (
        ignorePaths.indexOf(this.$route.name) == -1 && this.currentUser != null
      );
    },
    mansionName() {
      console.log("mansionName");
      console.log(this.mansion);
      if (this.mansion) {
        return this.mansion.name ? this.mansion.name : "";
      } else {
        return "";
      }
    },
    user() {
      return this.currentUser;
    },
    showAdminBoard() {
      return true;
      // if (this.currentUser && this.currentUser.role) {
      //   // eslint-disable-next-line
      //   console.log(this.currentUser);
      //   return this.currentUser.role == "Admin";
      // }

      // return false;
    },
    showModeratorBoard() {
      return true;
      // if (this.currentUser && this.currentUser.roles) {
      //   return this.currentUser.roles.includes("ROLE_MODERATOR");
      // }

      // return false;
    },
  },
  watch: {
    // eslint-disable-next-line
    $route(routeInstance, from) {
      this.createTitleDesc(routeInstance);
    },
  },
  data() {
    return {
      news: [],
      defaultIndex: "/",
      masks: {
        title: "YYYY MM",
        weekdays: "W",
        navMonths: "MMM",
        input: ["L", "YYYY-MM-DD", "YYYY/MM/DD"],
        dayPopover: "WWW, MMM D, YYYY",
        data: ["L", "YYYY-MM-DD", "YYYY/MM/DD"],
      },
      attrs: [
        {
          key: "today",
          highlight: {
            color: "orange",
            fillMode: "light",
          },
          dates: new Date(),
        },
        {
          key: "schedule",
          dot: true,
          dates: new Date(),
          customData: { _id: "abc" },
        },
        {
          key: "schedule",
          highlight: {
            start: { fillMode: "outline" },
            base: { fillMode: "light" },
            end: { fillMode: "outline" },
          },
          dates: { start: new Date(2021, 5, 14), end: new Date(2021, 5, 20) },
          customData: { _id: "qwe" },
        },
        {
          key: "schedule2",
          highlight: {
            start: { fillMode: "outline" },
            base: { fillMode: "light" },
            end: { fillMode: "outline" },
          },
          dates: { start: new Date(2021, 5, 16), end: new Date(2021, 5, 25) },
          customData: { _id: "asd" },
        },
      ],
    };
  },
  // 'http://localhost:8080/assets/custom/dai_1/defalut.css'
  methods: {
    dispDate(obj) {
      const startDate = moment(obj.calender_range.start).format("MM/DD");
      const endDate = moment(obj.calender_range.end).format("MM/DD");
      const startTime = moment(obj.calender_range.start).format("hh:mm");
      const endTime = moment(obj.calender_range.end).format("hh:mm");
      let result = "";
      if (obj.is_dispTime) {
        if (startDate != endDate) {
          result = `${startDate} ${startTime} 〜 ${endDate} ${endTime}`;
        } else {
          result = `${startDate} ${startTime} 〜 ${endTime}`;
        }
      } else {
        if (startDate != endDate) {
          result = `${startDate} 〜 ${endDate}`;
        } else {
          result = `${startDate}`;
        }
      }

      return result;
    },
    onDayClick(obj) {
      console.log("+++ onDayClick");
      console.log(obj);
    },
    onFromPage(obj) {
      console.log("+++ onFromPage");
      console.log(obj);
    },
    onToPage: async function(obj) {
      // console.log("+++ onToPage");
      // console.log(obj);
      // console.log("aaa+++");
      obj.limit = 100;
      const response = await InfoService.list(obj);
      console.log(response);
      if (response.data.docs != undefined) {
        this.news = response.data.docs;
      } else {
        this.news = [];
      }

      this.attrs = [
        {
          key: "today",
          highlight: {
            color: "orange",
            fillMode: "light",
          },
          dates: new Date(),
        },
      ];

      for (let index = 0; index < this.news.length; index++) {
        const element = this.news[index];
        this.attrs.push({
          key: element._id,
          highlight: {
            start: { fillMode: "outline" },
            base: { fillMode: "light" },
            end: { fillMode: "outline" },
          },
          dates: {
            start: element.calender_range.start,
            end: element.calender_range.end,
          },
        });
      }
    },
    onChangeMansion(value) {
      // console.log("onChangeMansion");
      // console.log(value);
      this.mansionName = value;
    },
    appendFile() {
      // console.log("+++ appendFile");
      if (this.currentUser != null && this.currentUser.mansion != undefined) {
        const mansion = this.currentUser.mansion;
        // console.log(mansion);
        let file = document.createElement("link");
        file.rel = "stylesheet";
        file.href = `/assets/custom/${mansion.dai_bunrui}/default.css`;
        document.head.appendChild(file);
        let file2 = document.createElement("script");
        file2.type = "text/javascript";
        file2.src = `/assets/custom/${mansion.dai_bunrui}/default.js`;
        document.head.appendChild(file2);
      }
    },
    role2Ja: function(role) {
      const roleNames = {
        Admin: "管理者",
        ExternalParty: "外部関係者",
        Chairman: "理事長",
        Director: "理事",
        OwnedResident: "区分所有者",
        OwnedNonResident: "外部オーナー",
        RentResident: "賃借人",
        Others: "その他",
      };
      return roleNames[role];
    },
    logOut() {
      // this.$store.dispatch("auth/logout");
      // this.$router.push("/login");
      // window.location = "/login";
      window.location = process.env.VUE_APP_ISALON_LOGOUT_ENDPOINT;
    },
  },
};
</script>

<style>
#app {
  /*font-family: "Avenir", Helvetica, Arial, sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  /*text-align: center;*/
  /* color: #2c3e50;*/
  /* margin-top: 60px; */
}

.el-menu--horizontal > .el-menu-item.dock-right {
  float: right;
}
.title-margin {
  margin: 55px 0 20px;
}
</style>
