import Vue from "vue";
import VueRouter from "vue-router";
import VueGtag from "vue-gtag";
import Login from "../views/Login2.vue";
import Logout from "../views/Logout.vue";
import Profile from "../views/Profile.vue";
// import MyPage from "../views/MyPage.vue";
import MyPageIndex from "../views/isalon/MyPage/Index.vue";
import RoomManagement from "../views/RoomManagement.vue";
import Information from "../views/isalon/information/Index.vue";
import InformationDetail from "../views/information/Detail.vue";
import BoDInformation from "../views/BoardOfDirectors/Information/Index.vue";
import BoDInformationDetail from "../views/BoardOfDirectors/Information/Detail.vue";
import MeetingTop from "../views/Meeting/Index.vue";
import MeetingDetail from "../views/Meeting/Detail.vue";
import AdminMeetingParticipants from "../views/admin/meeting/Participants.vue";
import BODIndex from "../views/BoardOfDirectors/Index.vue";
// import BODAchives from "../views/BoardOfDirectors/Archives.vue";
// import Achives from "../views/Archives.vue";
import ArchiveIndex from "../views/Archive/Index.vue";
import ArchiveList from "../views/Archive/List.vue";
import ManagerArchiveIndex from "../views/BoardOfDirectors/Archive/Index.vue";
import ManagerArchiveList from "../views/BoardOfDirectors/Archive/List.vue";
// import BODBBS from "../views/BoardOfDirectors/BBS.vue";
import BODDetail from "../views/BoardOfDirectors/Detail.vue";
import BODParticipants from "../views/BoardOfDirectors/Participants.vue";
import AdminBODEdit from "../views/admin/bod/Edit.vue";
import NotFound from "../views/NotFound.vue";
import AdminBoDInformation from "../views/admin/bod/Information/Index.vue";
import AdminBoDInformationEdit from "../views/admin/bod/Information/Edit.vue";
import AdminInformation from "../views/admin/information/Index.vue";
import AdminInformationEdit from "../views/admin/information/Edit.vue";
import AdminMeetingindex from "../views/admin/meeting/Index.vue";
import AdminMeetingEdit from "../views/admin/meeting/Edit.vue";
import ETohyo from "../views/ETohyo.vue";
import ChatAdmin from "../views/admin/meeting/ChatAdmin.vue";
import AdminUserIndex from "../views/admin/user/Index.vue";
import AdminUserEdit from "../views/admin/user/Edit.vue";
import AdminUserStatusIndex from "../views/admin/user_status/Index.vue";
import AdminUserStatusEdit from "../views/admin/user_status/Edit.vue";
import AdminArchiveEdit from "../views/admin/archive/Edit.vue";
import AdminManagerArchiveEdit from "../views/admin/bod/archive/Edit.vue";
import Sso from "../views/Sso.vue";
import PasswodReminder from "../views/PasswordReminder.vue";
import PasswordReset from "../views/PasswordReset.vue";
import VerifyEmail from "../views/VerifyEmail.vue";
import Contact from "../views/Contact.vue";
import IsalonFriend from "../views/isalon/Friend.vue";
import IsalonContact from "../views/isalon/Contact.vue";
import IsalonTop from "../views/isalon/Top.vue";
import IsalonSoudan from "../views/isalon/Soudan.vue";
import IsalonAfter from "../views/isalon/After.vue";
import IsalonAfterFuteiki from "../views/isalon/AfterFuteiki.vue";
import IsalonSaigai from "../views/isalon/Saigai.vue";
import IsalonMyPagePasswordEdit from "../views/isalon/MyPage/Password/Edit.vue";
import IsalonMyPageEmailEdit from "../views/isalon/MyPage/Email/Edit.vue";
import IsalonMyPageSettingsNotification from "../views/isalon/MyPage/Settings/Notification.vue";
import IsalonFriendEdit from "../views/isalon/Friend.vue";
import EmailRequired from "../views/email/Required.vue";
import AdminContactIndex from "../views/admin/contact/Index.vue";
import AdminContactEdit from "../views/admin/contact/Edit.vue";
import AdminOrderSchemaIndex from "../views/admin/order_schema/Index.vue";
import AdminOrderSchemaEdit from "../views/admin/order_schema/Edit.vue";
import AdminOrderIndex from "../views/admin/order/Index.vue";
import AdminOrderEdit from "../views/admin/order/Edit.vue";
import Order from "../views/isalon/Order.vue";
import AdminEventIndex from "../views/admin/event/Index.vue";
import AdminEventEdit from "../views/admin/event/Edit.vue";
import AdminEventDetail from "../views/admin/event/Detail.vue";
import IsalonEventIndex from "../views/isalon/event/Index.vue";
import IsalonEventDetail from "../views/isalon/event/Detail.vue";
import IsalonEventReserve from "../views/isalon/event/Reserve.vue";

// import Information from "../views/isalon/globalInformation/Index.vue";
import GlobalInformationDetail from "../views/globalInformation/Detail.vue";
import BODTools from "../views/BoardOfDirectors/Tools.vue";
import UserRegistration from "../views/UserRegistration.vue";
import Cst from "../views/isalon/Cst.vue";
import AdminCstIndex from "../views/admin/cst/Index.vue";
import RSV from "../views/isalon/RSV.vue";

// import Test from "../views/isalon/Test.vue";

// import Viewer from "../../public/pdfjs/web/viewer.html";
import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/login" },
  {
    path: "/verify-email",
    name: "verify-email",
    component: VerifyEmail,
    meta: {
      title: "メール確認",
      layout: "free",
      desc:
        "伊藤忠アーバンコミュニティが管理するマンションのご入居者様向け会員サイトです。皆さまの生活をより豊かにしていただくために、マンションに関するお知らせや各種ご優待などを掲載させていただいております。",
    },
  },
  {
    path: "/contact/",
    name: "contact",
    component: Contact,
    meta: { title: "問い合わせ", layout: "free" },
  },
  {
    path: "/registration",
    name: "user-registration",
    component: UserRegistration,
    meta: { title: "ユーザ登録", layout: "free" },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      layout: "free",
      title: "ログイン",
      desc:
        "伊藤忠アーバンコミュニティが管理するマンションのご入居者様向け会員サイトです。皆さまの生活をより豊かにしていただくために、マンションに関するお知らせや各種ご優待などを掲載させていただいております。",
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: { layout: "free" },
  },
  {
    path: "/rsv",
    name: "rsv",
    component: RSV,
    meta: { title: "RSV", desc: "", layout: "full" },
  },
  {
    path: "/:mc_code/logout",
    name: "mc-logout",
    component: Logout,
    meta: { layout: "free" },
  },
  {
    path: "/sso",
    name: "sso",
    component: Sso,
    meta: { layout: "free" },
  },
  {
    path: "/password-reminder",
    name: "password-reminder",
    component: PasswodReminder,
    meta: { title: "パスワードリマインダー", layout: "full" },
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: PasswordReset,
    meta: { title: "パスワード再設定", layout: "full" },
  },
  {
    path: "/:mc_code",
    name: "top",
    component: IsalonTop,
    meta: { title: "トップ", desc: "トップ" },
  },
  {
    path: "/:mc_code/mypage",
    name: "mypage",
    component: MyPageIndex,
    meta: { title: "マイページ", desc: "マイページ" },
  },

  {
    path: "/:mc_code/mypage/password",
    name: "mypage-password",
    component: IsalonMyPagePasswordEdit,
    meta: { title: "マイページ", desc: "マイページ" },
  },
  {
    path: "/:mc_code/mypage/email",
    name: "mypage-email",
    component: IsalonMyPageEmailEdit,
    meta: { title: "マイページ", desc: "マイページ" },
  },
  {
    path: "/:mc_code/email-required",
    name: "email-required",
    component: EmailRequired,
    meta: { title: "メールアドレス登録", layout: "full" },
  },
  {
    path: "/:mc_code/mypage/settings/notification",
    name: "mypage-settings-notification",
    component: IsalonMyPageSettingsNotification,
    meta: { title: "マイページ", desc: "マイページ" },
  },
  {
    path: "/:mc_code/etohyo",
    name: "etohyo",
    component: ETohyo,
    meta: { title: "出欠の提出", desc: "マイページ" },
  },
  {
    path: "/:mc_code/roommanagement",
    name: "roommanagement",
    component: RoomManagement,
  },
  {
    path: "/:mc_code/information/:id",
    name: "information-detail",
    component: InformationDetail,
    meta: { title: "お知らせ", desc: "" },
  },
  {
    path: "/:mc_code/information",
    name: "information-1",
    component: Information,
    alias: "/information/-1",
    meta: { title: "お知らせ", desc: "" },
  },
  {
    path: "/:mc_code/global-information/:id",
    name: "global-information-detail",
    component: GlobalInformationDetail,
    meta: { title: "お知らせ", desc: "" },
  },
  {
    path: "/:mc_code/bod/information/:id",
    name: "bod-information-detail",
    component: BoDInformationDetail,
    meta: { title: "理事会からのお知らせ", desc: "" },
  },
  {
    path: "/:mc_code/bod/information",
    name: "bod-information-1",
    component: BoDInformation,
    alias: "/bod/information/-1",
    meta: { title: "理事会からのお知らせ", desc: "" },
  },
  {
    path: "/:mc_code/bod/tools",
    name: "bod-tools",
    component: BODTools,
    alias: "/bod/tools",
    meta: { title: "理事会からのお知らせ", desc: "" },
  },
  {
    path: "/:mc_code/friend",
    name: "friend",
    component: IsalonFriendEdit,
    meta: { title: "クレヴィアお友達紹介", desc: "" },
  },
  {
    path: "/:mc_code/archive",
    name: "archive",
    component: ArchiveIndex,
    meta: { title: "マンション書庫", desc: "" },
  },
  {
    path: "/:mc_code/archive/:category",
    name: "archive-list",
    component: ArchiveList,
    meta: { title: "マンション書庫 一覧", desc: "" },
  },
  {
    path: "/:mc_code/admin/archive/edit/:id",
    name: "admin-archive-edit",
    component: AdminArchiveEdit,
    meta: {
      title: "[管理]マンション書庫 ファイル登録",
      desc: "",
      layout: "admin",
    },
  },
  {
    path: "/:mc_code/bod/archive",
    name: "bod-archive",
    component: ManagerArchiveIndex,
    meta: { title: "管理組合書庫", desc: "" },
  },
  {
    path: "/:mc_code/bod/archive/:category",
    name: "bod-archive-list",
    component: ManagerArchiveList,
    meta: { title: "マンション書庫 一覧", desc: "" },
  },
  {
    path: "/:mc_code/admin/bod/archive/edit/:id",
    name: "admin-bod-archive-edit",
    component: AdminManagerArchiveEdit,
    meta: {
      title: "[管理]管理組合書庫 ファイル登録",
      desc: "",
      layout: "admin",
    },
  },
  {
    path: "/:mc_code/admin/information",
    name: "admin_information",
    component: AdminInformation,
    meta: { title: "[管理]お知らせ", desc: "", layout: "admin" },
  },
  {
    path: "/:mc_code/admin/bod/information/edit/:id",
    name: "admin_bod_information_edit",
    component: AdminBoDInformationEdit,
    meta: {
      title: "[管理]理事会からのお知らせ編集",
      desc: "",
      layout: "admin",
    },
  },
  {
    path: "/:mc_code/admin/bod/information",
    name: "admin_bod_information",
    component: AdminBoDInformation,
    meta: { title: "[管理]理事会からのお知らせ", desc: "", layout: "admin" },
  },
  {
    path: "/:mc_code/admin/information/edit/:id",
    name: "admin_information_edit",
    component: AdminInformationEdit,
    meta: { title: "[管理]お知らせ編集", desc: "", layout: "admin" },
  },
  {
    path: "/:mc_code/admin/orderschema",
    name: "admin_order-schema",
    component: AdminOrderSchemaIndex,
    meta: { title: "[管理]申し込みフォーム一覧", desc: "", layout: "admin" },
  },
  {
    path: "/:mc_code/admin/orderschema/edit/:id?",
    name: "admin_order-schema_edit",
    component: AdminOrderSchemaEdit,
    meta: { title: "[管理]申し込みフォーム編集", desc: "", layout: "admin" },
  },
  {
    path: "/:mc_code/admin/order",
    name: "admin_order",
    component: AdminOrderIndex,
    meta: { title: "[管理]申し込み一覧", desc: "", layout: "admin" },
  },
  // {
  //   path: "/:mc_code/test",
  //   name: "test",
  //   component: Test,
  //   meta: { title: "テスト", desc: "" },
  // },
  {
    path: "/:mc_code/admin/order/edit/:id?",
    name: "admin_order_edit",
    component: AdminOrderEdit,
    meta: { title: "[管理]申し込み確認", desc: "", layout: "admin" },
  },

  {
    path: "/:mc_code/meeting",
    name: "meeting",
    component: MeetingTop,
    meta: { title: "総会", desc: "" },
  },
  {
    path: "/:mc_code/meeting/:id",
    name: "meetingDetail",
    component: MeetingDetail,
    meta: { title: "総会詳細", desc: "" },
  },
  {
    path: "/:mc_code/admin/meeting/participants/:id",
    name: "adminMeetingParticipants",
    component: AdminMeetingParticipants,
    meta: { title: "[管理]総会参加者", desc: "", layout: "admin" },
  },
  {
    path: "/:mc_code/admin/meeting",
    name: "admin_meeting_index",
    component: AdminMeetingindex,
    meta: { title: "[管理]総会", desc: "", layout: "admin" },
  },
  {
    path: "/:mc_code/admin/meeting/edit/:id",
    name: "admin_meeting_edit",
    component: AdminMeetingEdit,
    meta: { title: "[管理]総会編集", desc: "", layout: "admin" },
  },
  {
    path: "/:mc_code/bod",
    name: "bod",
    component: BODIndex,
    meta: { title: "理事会", desc: "" },
  },
  {
    path: "/:mc_code/bod/detail/:id",
    name: "bodDetail",
    component: BODDetail,
    meta: { title: "理事会詳細", desc: "" },
  },
  {
    path: "/:mc_code/bod/participants/:id",
    name: "bod-Participants",
    component: BODParticipants,
    meta: { title: "理事会", desc: "" },
  },
  // {
  //   path: "/:mc_code/bod/archives",
  //   name: "bodArchives",
  //   component: BODAchives,
  //   meta: { title: "理事会書類", desc: "" },
  // },
  {
    path: "/:mc_code/order/:directory",
    name: "order",
    component: Order,
    meta: { title: "申し込み", desc: "" },
  },
  // {
  //   path: "/bod/bbs",
  //   name: "bbs",
  //   component: BODBBS,
  // },
  {
    path: "/:mc_code/admin/bod/edit/:id",
    name: "admin_bod_edit",
    component: AdminBODEdit,
    meta: { title: "[管理]理事会編集", desc: "", layout: "admin" },
  },
  {
    path: "/:mc_code/admin/user",
    name: "admin_user",
    component: AdminUserIndex,
    meta: { title: "[管理]ユーザ管理", desc: "", layout: "admin" },
  },
  {
    path: "/:mc_code/admin/user/edit/:id",
    name: "admin_user_edit",
    component: AdminUserEdit,
    meta: { title: "[管理]ユーザ編集", desc: "", layout: "admin" },
  },

  {
    path: "/:mc_code/admin/user_status",
    name: "admin_user_status",
    component: AdminUserStatusIndex,
    meta: { title: "[管理]ユーザ移行管理", desc: "", layout: "admin" },
  },
  {
    path: "/:mc_code/admin/user_status/edit/:id",
    name: "admin_user_edit",
    component: AdminUserStatusEdit,
    meta: { title: "[管理]ユーザ移行編集", desc: "", layout: "admin" },
  },
  {
    path: "/:mc_code/profile",
    name: "profile",
    component: Profile,
  },
  {
    path: "/:mc_code/admin/chat/:id",
    name: "admin-chat",
    component: ChatAdmin,
    meta: { title: "[管理]チャット管理", desc: "", layout: "admin" },
  },
  {
    path: "/:mc_code/friend/:id?",
    name: "in-friend",
    component: IsalonFriend,
    meta: { title: "クレヴィアお友達紹介" },
  },
  {
    path: "/:mc_code/soudan/:id?",
    name: "isalon-soudan",
    component: IsalonSoudan,
    meta: { title: "マンション売りたい・貸したい相談" },
  },
  {
    path: "/:mc_code/after/:id?",
    name: "isalon-after",
    component: IsalonAfter,
    meta: { title: "アフター申し込み" },
  },
  {
    path: "/:mc_code/after-futeiki/:id?",
    name: "isalon-after-futeki",
    component: IsalonAfterFuteiki,
    meta: { title: "アフター申し込み(不定期)" },
  },
  {
    path: "/:mc_code/saigai/:id?",
    name: "isalon-saigai",
    component: IsalonSaigai,
    meta: { title: "被害状況共有" },
  },
  {
    path: "/:mc_code/contact/:id?",
    name: "in-contact",
    component: IsalonContact,
    meta: { title: "お問い合わせ" },
  },
  {
    path: "/:mc_code/admin/contact",
    name: "admin-contact",
    component: AdminContactIndex,
    meta: { title: "問い合わせ一覧", desc: "マイページ", layout: "admin" },
  },
  {
    path: "/:mc_code/admin/contact/edit/:id",
    name: "admin-contact-edit",
    component: AdminContactEdit,
    meta: { title: "問い合わせ確認", desc: "マイページ", layout: "admin" },
  },
  {
    path: "/:mc_code/admin/event",
    name: "admin_event",
    component: AdminEventIndex,
    meta: { title: "イベント予約一覧", desc: "イベント予約", layout: "admin" },
  },
  {
    path: "/:mc_code/admin/event/edit/:id?",
    name: "admin-event-edit",
    component: AdminEventEdit,
    meta: { title: "イベント予約編集", desc: "イベント予約", layout: "admin" },
  },
  {
    path: "/:mc_code/admin/event/detail/:id",
    name: "admin-event-detail",
    component: AdminEventDetail,
    meta: { title: "イベント予約管理", desc: "イベント予約", layout: "admin" },
  },
  {
    path: "/:mc_code/event",
    name: "isalon-event-index",
    component: IsalonEventIndex,
    meta: { title: "イベント予約一覧", desc: "イベント予約" },
  },
  {
    path: "/:mc_code/event/:previewid",
    name: "isalon-event-detail",
    component: IsalonEventDetail,
    meta: { title: "イベント予約", desc: "イベント予約" },
  },
  {
    path: "/:mc_code/event/reserve/:previewid/:scheduleIndex/:komaIndex",
    name: "isalon-event-reserve",
    component: IsalonEventReserve,
    meta: { title: "イベント予約", desc: "イベント予約" },
  },
  {
    path: "/:mc_code/cst/:doc_type",
    name: "cst",
    component: Cst,
    meta: { title: "CST", desc: "cst", layout: "full" },
  },
  {
    path: "/:mc_code/admin/cst",
    name: "admin-cst-index",
    component: AdminCstIndex,
    meta: { title: "[管理]CSテクノI'NET設定", desc: "", layout: "admin" },
  },
  {
    path: "/:mc_code/rsv",
    name: "rsv-index",
    component: RSV,
    meta: { title: "RSV", desc: "", layout: "full" },
  },
  // {
  //   test: /\.(html)$/,
  //   exclude: /(node_modules)/,
  //   use: {
  //     loader: "html-loader",
  //   },
  // },
  // {
  //   path: "/static*",
  // },
  {
    path: "404",
    name: "notfound404",
    component: NotFound,
    meta: { layout: "free" },
  },
  // {
  //   path: "/404",
  //   name: "notfound404",
  //   component: NotFound,
  // },
  {
    path: "*",
    name: "notfound",
    component: NotFound,
    meta: { layout: "free" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line
  scrollBehavior: async (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }

    const findEl = async (hash, x) => {
      return (
        document.querySelector(hash) ||
        // eslint-disable-next-line
        new Promise((resolve, reject) => {
          if (x > 50) {
            return resolve();
          }
          setTimeout(() => {
            resolve(findEl(hash, ++x || 1));
          }, 100);
        })
      );
    };

    if (to.hash) {
      const el = await findEl(to.hash);

      if ("scrollBehavior" in document.documentElement.style) {
        return window.scrollTo({ top: el.offsetTop, behavior: "smooth" });
      } else {
        return window.scrollTo(0, el.offsetTop);
      }
    }

    return { x: 0, y: 0 };
  },
});

Vue.use(
  VueGtag,
  {
    config: { id: `${process.env.VUE_APP_FIREBASE_MEASURMENT_ID}` },
    params: {
      send_page_view: false,
    },
    // pageTrackerTemplate(to) {
    //   return {
    //     page_title: to.title,
    //     page_path: `/hoge/${to.path}`,
    //     hoge: "abc",
    //   };
    // },
  },
  router,
);

export default router;
