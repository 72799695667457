<template>
  <el-main id="admin-user-edit">
    <!-- <el-main> -->
    <el-form
      :model="user"
      :rules="rules"
      ref="ruleForm"
      label-width="240px"
      @submit.native.prevent="handleRegister('ruleForm')"
    >
      <el-form-item label="ログインID" prop="loginID">
        <el-input
          v-model="user.loginID"
          :disabled="!hasEditPermission"
        ></el-input>
      </el-form-item>
      <el-form-item label="名前" prop="username">
        <el-input
          v-model="user.username"
          :disabled="!hasEditPermission"
        ></el-input>
      </el-form-item>
      <el-form-item label="email" prop="email">
        <el-input
          v-model="user.email"
          :disabled="!hasEditPermission"
        ></el-input>
      </el-form-item>

      <el-form-item label="マンション" prop="mansion">
        <el-select v-model="user.mansion" placeholder="Select" disabled>
          <el-option
            v-for="item in mansions"
            :key="item.name"
            :label="item.name"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="部屋番号" prop="roomNo">
        <el-input
          v-model="user.roomNo"
          :disabled="!hasEditPermission"
        ></el-input>
      </el-form-item>

      <el-form-item label="権限">
        <el-select
          v-model="user.role"
          placeholder="Select"
          :disabled="!hasEditPermission"
        >
          <el-option
            v-for="item in roles"
            :key="item.name"
            :label="item.dispName"
            :value="item._id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="お知らせメール送信">
        <el-switch
          v-model="user.needToSendEmail"
          active-text="する"
          inactive-text="しない"
          :disabled="!hasEditPermission"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="PushToken">
        <p
          v-for="(token, index) in user.tokens"
          :key="`token-${index}`"
          class="token"
        >
          {{ token }}
        </p>
      </el-form-item>
      <el-form-item label="区分">
        <el-select v-model="user.status" placeholder="Select">
          <el-option
            v-for="item in statusOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="契約者から入居者への自動移行日">
        <el-col>
          <el-tooltip
            class="item"
            content="区分が契約者の場合のみ使用されます"
            placement="top"
          >
            <el-date-picker
              v-model="user.contractorToResidentDate"
              type="date"
              placeholder="日付"
              style="width: 100%"
            >
            </el-date-picker>
          </el-tooltip>
        </el-col>
      </el-form-item>
      <el-form-item>
        <el-alert
          :title="message"
          :type="successful ? 'success' : 'error'"
          v-if="message != ''"
        ></el-alert>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" native-type="submit">保存</el-button>
        <el-button
          type="warning"
          @click="onRemove"
          :disabled="!hasEditPermission"
          >削除</el-button
        >
        <el-button @click="$router.go(-1)">キャンセル</el-button>
        <el-button
          @click="visibleNewPassword = true"
          :disabled="!hasEditPermission"
          >パスワード変更</el-button
        >
        <el-button @click="onResetTokens" :disabled="!hasEditPermission"
          >push tokenクリア</el-button
        >
      </el-form-item>
    </el-form>
    <el-dialog title="パスワード変更" :visible.sync="visibleNewPassword">
      <el-form :model="form">
        <el-form-item label="new password">
          <el-input v-model="newPassword" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visibleNewPassword = false">Cancel</el-button>
        <el-button type="primary" @click="onChangePassword">Confirm</el-button>
      </span>
    </el-dialog>
  </el-main>
  <!-- </div> -->
</template>

<script>
import User from "../../../models/user";
import RoleService from "../../../services/role.service";
import MansionService from "../../../services/mansion.service";
import UserService from "../../../services/user.service";

import store from "../../../store";

export default {
  name: "AdminUserEdit",
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (
      currentUser &&
      !currentUser.permissions.includes("Admin") &&
      !currentUser.permissions.includes("EventAdmin")
    ) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please input the password"));
      } else {
        if (this.passwordConfirm !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      // // console.log(value);
      if (value === "") {
        callback(new Error("Please input the password again"));
      } else if (value !== this.user.password) {
        callback(new Error("Two inputs don't match!"));
      } else {
        callback();
      }
    };
    return {
      hasEditPermission: false,
      user: new User("", "", "", "", "", "", "", ""),
      passwordConfirm: "",
      form: {},
      submitted: false,
      successful: false,
      message: "",
      roles: [],
      mansions: [],
      visibleNewPassword: false,
      newPassword: "",
      rules: {
        loginID: [
          {
            required: true,
            message: "loginIDは必須です",
            trigger: "blur",
          },
          {
            min: 1,
            max: 20,
            message: "Length should be 1 to 20",
            trigger: "blur",
          },
        ],
        email: [
          {
            type: "email",
            required: false,
            message: "Please email address",
            trigger: "change",
          },
        ],
        password: [
          {
            validator: validatePass,
            trigger: "blur",
          },
        ],
        passwordConfirm: [
          {
            validator: validatePass2,
            trigger: "blur",
          },
        ],
        mansion: [
          {
            required: true,
            message: "マンションは必須です",
            trigger: "blur",
          },
        ],
        contractorToResidentDate: [],
      },
      statusOptions: [
        { value: "contractor", label: "契約者" },
        { value: "resident", label: "入居者" },
      ],
    };
  },
  async mounted() {
    const result = await UserService.findByID(this.$route.params.id);
    console.log(result);
    await this.$nextTick();
    // const self = this;
    // this.$nextTick(() => {
    this.user = result.data;
    // });
    RoleService.getAll().then(
      response => {
        this.roles = response.data;
        // console.log(this.roles);
      },
      // eslint-disable-next-line
      (error) => {
        // console.log(error);
        // this.roles =
        //   (error.response &&
        //     error.response.data &&
        //     error.response.data.message) ||
        //   error.message ||
        //   error.toString();
      },
    );
    MansionService.getAll().then(
      response => {
        this.mansions = response.data;
        // console.log(this.roles);
      },
      // eslint-disable-next-line
      (error) => {
        // console.log(error);
        // this.roles =
        //   (error.response &&
        //     error.response.data &&
        //     error.response.data.message) ||
        //   error.message ||
        //   error.toString();
      },
    );
    this.hasEditPermission = this.currentUser.permissions.includes("Admin");
  },
  methods: {
    onChangePassword: async function() {
      const params = { _id: this.user._id, password: this.newPassword };
      try {
        const result = await UserService.editOptions(params);
        console.log(result);
        // this.$router.go(-1);
        this.$message({
          message: "パスワードを変更しました",
          type: "success",
        });
        this.visibleNewPassword = false;
      } catch (error) {
        console.log(error);
        this.$message.error("パスワードを変更できませんでした");
        this.visibleNewPassword = false;
      }
    },
    onResetTokens: async function() {
      if (confirm("Push Tokenをクリアしても良いですか？")) {
        const params = { _id: this.user._id, tokens: [] };
        try {
          const result = await UserService.editOptions(params);
          console.log(result);
          // this.$router.go(-1);
          this.$message({
            message: "push tokenをクリアしました",
            type: "success",
          });
          this.user.tokens = [];
          this.visibleNewPassword = false;
        } catch (error) {
          console.log(error);
          this.$message.error("push tokenをクリアできませんでした");
          this.visibleNewPassword = false;
        }
      }
    },
    onRemove: async function() {
      console.log(`remove=${this.user._id}`);
      if (window.confirm("削除します。よろしいですか？か？")) {
        try {
          const result = await UserService.remove(this.user._id);
          console.log(result);
          // this.$router.go(-1);
          this.$router.push({
            name: "admin_user",
            params: { mc_code: this.currentUser.mansion._id },
          });
        } catch (error) {
          console.log(error);
        }
      }
    },
    onSubmit: function() {
      alert("ok");
      // console.log("submit!");
    },
    async handleRegister(formName) {
      // console.log("handleRegister");
      this.message = "";
      this.submitted = true;
      console.log("this.user", this.user);
      this.$refs[formName].validate(async valid => {
        if (valid) {
          const res = await UserService.edit(this.user);
          console.log(res);
          if (res.response) {
            this.$message.error(res.response.data);
          } else {
            this.$router.push({
              name: "admin_user",
              params: { mc_code: this.currentUser.mansion._id },
            });
          }
        } else {
          // console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style>
.el-main {
  text-align: left;
}

.token {
  overflow: hidden;
  width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
