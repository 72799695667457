var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"meeting-summary"}},[(_vm.meeting.title != '')?_c('section',{staticClass:"main-section"},[_c('h3',{staticClass:"section-title green-left-head"},[_vm._v("総会のご案内")]),_c('p',{staticClass:"section-block"},[_vm._v(_vm._s(_vm.meeting.desc))]),_c('div',{staticClass:"box-item-sokai"},[_c('div',{staticClass:"box-item-wrapper"},[_c('ul',{staticClass:"box-item-list"},[_c('li',{staticClass:"box-item-list-item"},[_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.meeting.date,"YYYY年MM月DD日 (dd)"))+" "+_vm._s(_vm.meeting.start_at)+"〜"+_vm._s(_vm.meeting.end_at)),_c('br'),_vm._v(_vm._s(_vm.meeting.title)+" 場所："+_vm._s(_vm.meeting.location)+" ")])])])])])]):_vm._e(),(_vm.meeting.title != '' && _vm.mode == 'all')?_c('section',{staticClass:"main-section buttons"},[_c('p',{staticClass:"section-block center"},[_c('router-link',{attrs:{"to":{
          name: 'etohyo',
          params: { mc_code: _vm.currentUser.mansion._id },
        }}},[_c('button',{staticClass:"bt bt-green"},[_vm._v("出欠の提出")])])],1),_c('p',{staticClass:"section-block center"},[_c('button',{staticClass:"bt bt-green bt-green-sub pdfopenBtn",attrs:{"disabled":!_vm.isExistFile},on:{"click":function($event){return _vm.onOpen()}}},[_vm._v(" 議案の確認 ")])]),_c('p',{staticClass:"section-block center"},[_c('router-link',{attrs:{"to":{
          name: 'meeting_detail',
          params: { mc_code: _vm.currentUser.mansion._id },
        }}},[_c('button',{staticClass:"bt bt-modalopen",class:{ 'bt-green': _vm.isMeetingTime, 'bt-gray': !_vm.isMeetingTime },attrs:{"disabled":!_vm.isMeetingTime}},[_vm._v(" 総会に参加する ")])])],1),(_vm.isAdmin)?_c('p',{staticClass:"section-block center"},[_c('router-link',{attrs:{"target":"_blank","to":{
          name: 'admin-chat',
          params: { id: _vm.meeting._id, mc_code: _vm.currentUser.mansion._id },
        }}},[_c('button',{staticClass:"bt bt-green bt-modalopen",staticStyle:{"display":"flex","flex-direction":"column","align-items":"center"}},[_c('span',{staticStyle:{"font-size":"1.7rem"}},[_vm._v("質疑チャット管理")]),_c('span',{staticStyle:{"font-size":"1.0rem"}},[_vm._v(" ※別ウィンドウで開きます。")])])])],1):_c('p',{staticClass:"section-block center"},[_c('button',{staticClass:"bt bt-green bt-modalopen",on:{"click":function($event){_vm.isChatView = true}}},[_vm._v(" 質疑チャット ")])])]):_vm._e(),(_vm.meeting.title != '' && _vm.mode == 'def')?_c('section',{staticClass:"main-section buttons"},[_c('p',{staticClass:"section-block center"},[_c('router-link',{attrs:{"to":{
          name: 'meeting',
          params: { mc_code: _vm.currentUser.mansion._id },
        }}},[_c('button',{staticClass:"bt bt-green bt-modalopen"},[_vm._v(" 詳しくみる ")])])],1)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPDFView),expression:"isPDFView"}],staticStyle:{"z-index":"100"},attrs:{"id":"pdfContainer"}},[_c('iframe',{staticStyle:{"overflow":"auto"},attrs:{"src":"","type":"application/pdf","width":"100%","height":"100%","id":"pdfView"}}),_c('div',{staticClass:"chatclose chatopen",on:{"click":function($event){_vm.isPDFView = false}}},[_vm._v("閉じる")])]),(_vm.isChatView)?_c('div',{staticStyle:{"z-index":"100"},attrs:{"id":"chat"}},[_c('h2',{staticClass:"chat-heading",staticStyle:{"position":"relative"}},[_vm._v(" 質疑チャット ")]),_c('div',{staticClass:"chatclose chatopen",on:{"click":function($event){_vm.isChatView = false}}},[_vm._v("閉じる")]),_c('ChatView',{staticClass:"messages",attrs:{"room":_vm.privateRoom,"name":_vm.currentUser.roomNo,"dispName":_vm.currentUser.roomNo,"roomName":"質疑チャット","mode":"user","filter":_vm.currentUser.roomNo}}),_c('ChatInput',{attrs:{"room":_vm.privateRoom,"name":_vm.currentUser.roomNo,"dispName":_vm.currentUser.username,"roomName":"質疑応答チャット","to":"admin"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }