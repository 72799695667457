<template>
  <div class="_navi-list">
    <!-- menu -->

    <div class="_navi-item">
      <router-link
        class="_navi-link -ic nav-toppage"
        :to="{
          name: 'top',
          params: { mc_code: mcCode },
        }"
      >
        トップページ
      </router-link>
    </div>
    <!-- / _navi-item -->

    <div class="_navi-item">
      <router-link
        :to="{
          name: 'mypage',
          params: { mc_code: mcCode },
        }"
        class="_navi-link -ic nav-mypage"
      >
        ユーザー情報
      </router-link>
    </div>
    <!-- / _navi-item -->

    <div class="_navi-item">
      <router-link
        class="_navi-link -ic nav-newinfo"
        :to="{
          name: 'information-1',
          params: { mc_code: mcCode },
        }"
      >
        お知らせ
      </router-link>
    </div>
    <!-- / _navi-item -->

    <!-- category start -->

    <div class="_navi-item">
      <span class="_nav-toggle -ic nav-menucolumn00" id="menucolumn00"></span>

      <div class="_navi-item-sub">
        <!-- menucolumn start -->
        <router-link
          :to="{
            name: 'top',
            params: { mc_code: mcCode },
          }"
        >
          トップページ
        </router-link>
        <!-- menucolumn start -->
        <router-link
          class="_navi-link -ic nav-information"
          :to="{
            name: 'in-information',
            params: { mc_code: mcCode },
          }"
          >お知らせ
        </router-link>

        <!-- menucolumn start -->

        <a
          href="https://www.mansion-concierge.com/i-salon/survey_list.php"
          class="_navi-link"
          ontouchstart=""
          >アンケート</a
        >
        <!-- menucolumn end -->
      </div>
    </div>
    <!-- category start -->

    <div class="_navi-item _navi-item01">
      <span class="_nav-toggle -ic nav-menucolumn01" id="menucolumn01"></span>

      <div class="_navi-item-sub">
        <!-- menucolumn start -->

        <a
          href="https://www.mansion-concierge.com/i-salon/album.php"
          class="menu01 _navi-link"
          ontouchstart=""
          >施工レポート</a
        >
        <!-- menucolumn start -->

        <a
          href="https://www.mansion-concierge.com/i-salon/episode.php"
          class="menu02 _navi-link"
          ontouchstart=""
          >クレヴィアができるまで</a
        >
        <!-- menucolumn end -->
      </div>
    </div>
    <!-- category start -->

    <div class="_navi-item _navi-item02">
      <span class="_nav-toggle -ic nav-menucolumn02" id="menucolumn02"></span>

      <div class="_navi-item-sub">
        <!-- menucolumn start -->

        <a
          href="https://www.mansion-concierge.com/i-salon/option.php"
          class="menu01 _navi-link"
          ontouchstart=""
          >セレクト／各種オプションのご案内</a
        >
        <!-- menucolumn start -->
        <!--////-->
        <a
          href="https://www.mansion-concierge.com/i-salon/premium.php"
          class="menu02 _navi-link"
          ontouchstart=""
          >プレミアムサポート<span class="_navi-link-sub" style="font-size:87%"
            >（有償）</span
          ></a
        >
        <!--////-->
        <!-- menucolumn start -->
        <router-link
          class="menu03 _navi-link"
          :to="{ name: 'friend', params: { mc_code: mcCode } }"
        >
          クレヴィアお友達紹介</router-link
        >
        <!-- menucolumn start -->

        <a
          href="https://www.mansion-concierge.com/i-salon/service_pre.php"
          class="menu04 _navi-link"
          ontouchstart=""
          >各種優待サービス案内</a
        >
        <!-- menucolumn end -->
      </div>
    </div>
    <!-- category start -->

    <div class="_navi-item _navi-item03">
      <span class="_nav-toggle -ic nav-menucolumn03" id="menucolumn03"></span>

      <div class="_navi-item-sub">
        <!-- menucolumn start -->

        <a
          href="https://www.mansion-concierge.com/i-salon/schedule.php"
          class="menu01 _navi-link"
          ontouchstart=""
          >ご入居までのスケジュール</a
        >
        <!-- menucolumn start -->

        <a
          href="https://www.mansion-concierge.com/i-salon/procedure.php"
          class="menu02 _navi-link"
          ontouchstart=""
          >引越し前後のお手続き</a
        >
        <!-- menucolumn start -->
        <router-link
          class="menu03 _navi-link"
          :to="{
            name: 'archive-list',
            params: { mc_code: mcCode, category: 1 },
          }"
        >
          図面／ご入居関連資料</router-link
        >
        <!-- menucolumn end -->
      </div>
    </div>
    <!-- category start -->

    <div class="_navi-item _navi-item04">
      <span class="_nav-toggle -ic nav-menucolumn04" id="menucolumn04"></span>

      <div class="_navi-item-sub">
        <!-- menucolumn start -->

        <a
          href="https://www.mansion-concierge.com/i-salon/areainfo.php"
          class="menu01 _navi-link"
          ontouchstart=""
          >地域情報</a
        >
        <!-- menucolumn start -->

        <a
          href="https://www.mansion-concierge.com/i-salon/qa.php"
          class="menu02 _navi-link"
          ontouchstart=""
          >Q&A</a
        >
        <!-- menucolumn start -->

        <a
          href="https://www.mansion-concierge.com/i-salon/kurashicolumn.php"
          class="menu03 _navi-link"
          ontouchstart=""
          >暮らしのコラム</a
        >
        <!-- menucolumn end -->
      </div>
    </div>
    <!-- category start -->

    <div class="_navi-item _navi-item05">
      <span class="_nav-toggle -ic nav-menucolumn05" id="menucolumn05"></span>

      <div class="_navi-item-sub">
        <!-- menucolumn start -->

        <a
          href="https://www.mansion-concierge.com/i-salon/areainfo.php"
          class="menu01 _navi-link"
          ontouchstart=""
          >地域情報</a
        >
        <!-- menucolumn start -->

        <a
          href="https://www.mansion-concierge.com/i-salon/qa.php"
          class="menu02 _navi-link"
          ontouchstart=""
          >Q&A</a
        >
        <!-- menucolumn start -->

        <a
          href="https://www.mansion-concierge.com/i-salon/kurashicolumn.php"
          class="menu03 _navi-link"
          ontouchstart=""
          >暮らしのコラム</a
        >
        <!-- menucolumn end -->
      </div>
    </div>
    <!-- category end -->

    <div class="_navi-item _navi-item06">
      <span class="_nav-toggle -ic nav-menucolumn06" id="menucolumn06"></span>

      <div class="_navi-item-sub"></div>
    </div>
    <!-- category end -->

    <div class="_navi-item">
      <router-link
        class="_navi-link -ic nav-contact"
        :to="{
          name: 'in-contact',
          params: { mc_code: mcCode },
        }"
      >
        お問い合わせ
      </router-link>
    </div>
    <!-- / _navi-item -->

    <div class="_navi-item">
      <a :href="logoutUrl" class="_navi-link -ic nav-logout" ontouchstart="">
        <!--//<a
//                @click="logOut"
//                class="_navi-link -ic nav-logout"
//                ontouchstart=""
              >//-->
        ログアウト
      </a>
    </div>
    <!-- / _navi-item -->

    <!-- /menu -->
  </div>
  <!-- / _navi-list -->
</template>

<script>
export default {};
</script>

<style></style>
