<template>
  <div class="wrapper">
    <div class="hdr">
      <div class="hdr-wrapper">
        <div class="logo">
          <a href="top.html"
            ><img src="/i-salon/assets/img/common/logo-main.svg" alt="iuc SALON"
          /></a>
        </div>
      </div>
    </div>
    <!--/.hdr-->

    <div class="main" v-if="status == 'edit'">
      <section class="main-section">
        <h3 class="section-title green-left-head">お問い合わせ</h3>

        <div class="faq-wrapper">
          <div class="contactCaptionDetail">
            下記フォームに必要事項をご入力お願いいたします。
            <br />担当者より１週間以内にご連絡をさせていただきます。
            <br />
            <br />別途［よくあるご質問］をご用意しております。ぜひご覧ください。
            <br />
            <a
              href="https://iuc-salon.mansion-concierge.com/i-salon/docs/faq.html"
              target="_blank"
              style="color: #000562;text-decoration: underline;"
              >→よくあるご質問</a
            >
            <br />
            <br />個人情報の取扱いに関しては、プライバシーポリシーをご参照ください。
            <br />
            <br />・<a href="http://www.itc-uc.co.jp/privacy/" target="_blank"
              >伊藤忠アーバンコミュニティ株式会社</a
            >
            <br />
          </div>
          <br /><br />
        </div>

        <div class="mainContent-form">
          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">マンション名</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id=""
                    ><input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-ja"
                      maxlength="512"
                      name=""
                      v-model.trim="$v.values.mansionName.$model"
                  /></label>
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">部屋番号</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id=""
                    ><input
                      type="text"
                      id=""
                      class="u-input--w50p u-ime-en"
                      maxlength="512"
                      name=""
                      v-model.trim="$v.values.roomNo.$model"
                  /></label>
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="L_NAME">お名前</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dt class="formTableBox_ttl"><label for="L_NAME">姓</label></dt>
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="40"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder="例:伊藤"
                    name="lastName"
                    v-model.trim="$v.values.sei.$model"
                  />
                  <div class="error" v-if="!$v.values.sei.required">
                    必須です
                  </div>
                </dd>
                <dt class="formTableBox_ttl"><label for="F_NAME">名</label></dt>
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="F_NAME"
                    maxlength="40"
                    class="formTableBox_input--name u-ime-ja"
                    placeholder="例:忠太郎"
                    name="firstName"
                    v-model.trim="$v.values.mei.$model"
                  />
                  <div class="error" v-if="!$v.values.mei.required">
                    必須です
                  </div>
                </dd>
              </dl>

              <span class="formErr_inline"> </span>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="L_NAME">ふりがな</label>
            </dt>
            <dd class="formTable_data">
              <dl class="formTableBox">
                <dt class="formTableBox_ttl">
                  <label for="L_NAME">せい</label>
                </dt>
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="L_NAME"
                    maxlength="40"
                    class="m-formBox_input--name u-ime-ja"
                    placeholder="例:いとう"
                    name="lastName"
                    v-model.trim="$v.values.kanaSei.$model"
                  />
                  <div class="error" v-if="!$v.values.kanaSei.required">
                    必須です
                  </div>
                </dd>
                <dt class="formTableBox_ttl">
                  <label for="F_NAME">めい</label>
                </dt>
                <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                  <input
                    type="text"
                    id="F_NAME"
                    maxlength="40"
                    class="formTableBox_input--name u-ime-ja"
                    placeholder="例:ちゅうたろう"
                    name="firstName"
                    v-model.trim="$v.values.kanaMei.$model"
                  />
                  <div class="error" v-if="!$v.values.kanaMei.required">
                    必須です
                  </div>
                </dd>
              </dl>

              <span class="formErr_inline"> </span>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="">ご登録メールアドレス</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id=""
                    ><input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-en"
                      maxlength="128"
                      name=""
                      v-model.trim="$v.values.email.$model"
                  /></label>
                  <div class="error" v-if="!$v.values.email.required">
                    必須です
                  </div>
                  <div class="error" v-if="!$v.values.email.email">
                    正しいメールアドレスを入力してください
                  </div>
                  <div class="formTableCautionInner">
                    ※メールアドレス未登録の場合は、受信可能なメールアドレスを入力してください。
                  </div>
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <label for="">電話番号</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id=""
                    ><input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-en"
                      maxlength="128"
                      name=""
                      v-model.trim="$v.values.phone.$model"
                  /></label>
                  <div class="error" v-if="!$v.values.phone.decimal">
                    半角数字を入力してください
                  </div>
                  <div class="formTableCautionInner">
                    ※ハイフン（-）なしで数字のみご入力ください。
                  </div>
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable" style="display: none">
            <dt class="formTable_ttl">
              <label for="">書類送付先等住所</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <label id=""
                    ><input
                      type="text"
                      id=""
                      class="u-input--w100p u-ime-ja"
                      maxlength="512"
                      name=""
                      v-model.trim="$v.values.address.$model"
                  /></label>
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="">お問い合わせ種別</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <select
                    class="select-box"
                    v-model.trim="$v.values.category.$model"
                  >
                    <option value="" selected="">選択してください</option>
                    <option value="ログインIDがわからない">
                      ログインIDがわからない
                    </option>
                    <option value="ログインIDとパスワードがわからない">
                      ログインIDとパスワードがわからない
                    </option>
                    <option value="その他">その他</option>
                  </select>
                  <div class="error" v-if="!$v.values.category.required">
                    必須です
                  </div>
                </div>
              </div>
            </dd>
          </dl>

          <dl class="formTable">
            <dt class="formTable_ttl">
              <span class="icoForm-requir">必須</span>
              <label for="">お問い合わせ内容</label>
            </dt>
            <dd class="formTable_data">
              <div class="formTableBox">
                <div class="formTableBox_itemBox">
                  <textarea
                    rows="8"
                    class="u-input--w100p u-ime-ja"
                    v-model.trim="$v.values.text.$model"
                  ></textarea>
                  <div class="error" v-if="!$v.values.text.required">
                    必須です
                  </div>
                </div>
              </div>
            </dd>
          </dl>

          <div class="reqCaption">
            <span class="reqCaption"
              ><input
                type="checkbox"
                name="check"
                id="check"
                v-model.trim="$v.values.confirm.$model"
                @change="$v.values.confirm.$touch()"
              /><label for="check">個人情報保護方針に同意する</label></span
            >
            <div class="error" v-if="!$v.values.confirm.sameAs">
              同意が必要です
            </div>
          </div>
          <div class="cn-back-btn">
            <a href="javascript:void(0);" @click="onConfirm">次へ</a>
          </div>
        </div>
      </section>
    </div>
    <!--/.main-->
    <div class="main" v-else-if="status == 'confirm'">
      <section class="main-section mainContent-form">
        <h3 class="section-title green-left-head">お問い合わせ 入力内容確認</h3>

        <div class="pageCaption">
          <div class="contactCaptionDetail">
            入力内容をご確認ください。<br />
            よろしければ「送信」ボタンをクリックしてください。
          </div>
        </div>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="">マンション名</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                <label id="">{{ values.mansionName }}</label>
              </div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="">部屋番号</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">{{ values.roomNo }}</div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="L_NAME">お名前</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                {{ values.sei }} {{ values.mei }}
              </div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="L_NAME">ふりがな</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                {{ values.kanaSei }} {{ values.kanaMei }}
              </div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="">ご登録メールアドレス</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">{{ values.email }}</div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="">電話番号</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">{{ values.phone }}</div>
            </div>
          </dd>
        </dl>

        <dl class="formTable" style="display: none">
          <dt class="formTable_ttl">
            <label for="">書類送付先等住所</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                {{ values.address }}
              </div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="">お問い合わせ種別</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">{{ values.category }}</div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="">お問い合わせ内容</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                <pre>{{ values.text }}</pre>
              </div>
            </div>
          </dd>
        </dl>

        <div class="cn-back-btn">
          <a href="javascript:void(0);" @click="status = 'edit'">戻る</a>
        </div>
        <div class="cn-back-btn">
          <a href="javascript:void(0);" @click="onPost">送信</a>
        </div>
      </section>
    </div>
    <!--/.main-->
    <div class="main" v-else-if="status == 'complete'">
      <section class="main-section">
        <h3 class="section-title green-left-head">お問い合わせ 完了</h3>

        <div class="faq-wrapper">
          <span class="caution"
            >お問い合わせを送信しました。<br />
            ご要望・ご質問の内容によっては、返信・回答までのお時間を要することがございます。あらかじめご了承ください。</span
          >
          <br /><br />
          <span class="backLink" style="display: none">
            <router-link
              :to="{
                name: 'logout',
              }"
              style="display: block; color: #000562; text-decoration: underline"
            >
              →iuc SALON ログインページへ</router-link
            ></span
          >
        </div>
      </section>
    </div>
    <!--/.main-->

    <div class="ftr">
      <div class="ftr-top-wrapper">
        <ul class="ftr-link">
          <li class="ftr-link-item">
            <a href="/i-salon/docs/kiyaku.html">利用規約</a>
          </li>
          <li class="ftr-link-item">
            <a href="/i-salon/docs/faq.html">よくある質問</a>
          </li>
        </ul>
      </div>
      <div class="ftr-bottom-wrapper">
        <div class="copyright">
          &copy; 2023 ITOCHU Urban Community
        </div>
      </div>
    </div>
    <!--/.ftr-->
  </div>
  <!--/.wrapper-->
</template>

<script>
import { required, email, sameAs, decimal } from "vuelidate/lib/validators";
import FormService from "../services/form.service";
// import Navi from "../mixin/navi";
export default {
  name: "contact",
  // mixins: [Navi],
  data() {
    return {
      status: "edit",
      values: {
        mansionName: "",
        roomNo: "",
        sei: "",
        mei: "",
        kanaSei: "",
        kanaMei: "",
        email: "",
        address: "",
        category: "",
        text: "",
        confirm: false,
        phone: "",
      },
      schema: null,
    };
  },
  validations: {
    values: {
      mansionName: {},
      roomNo: {},
      sei: { required },
      mei: { required },
      kanaSei: { required },
      kanaMei: { required },
      email: { required, email },
      address: {},
      category: { required },
      text: { required },
      confirm: {
        sameAs: sameAs(() => true),
      },
      phone: { decimal },
    },
  },
  mounted: async function() {
    console.log("+++ contact.mounted");
    // const mc_code = this.$route.params.mc_code
    //   ? this.$route.params.mc_code
    //   : "";
    // console.log(mc_code);
    // const result = await FormService.findOne({
    //   category: "contact",
    //   mansion: mc_code,
    // });
    // console.log(result);
    // await this.$nextTick();
    // this.schema = result.data.schema;
    // console.log(this.schema);
    // this.$emit("changeMansion", this.mansion.name);
  },
  methods: {
    scrollTop: function() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    onConfirm() {
      console.log("onConfirm");
      console.log(this.values);
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        this.status = "confirm";
        this.scrollTop();
      }
    },
    async onPost() {
      console.log("onConfirm");
      console.log(this.values);
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        // (category, sei, mei, mansionName,mansion, roomNo, result)
        try {
          this.status = "complete";
          const result = await FormService.answer(
            "contact",
            this.values.sei,
            this.values.mei,
            this.values.mansionName,
            "",
            this.values.roomNo,
            this.values,
            true,
          );
          console.log(result);
          this.scrollTop();
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style>
.flex-wrapper {
  display: flex;
}
</style>
