var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"_newinfo bodnews"},[_c('div',{staticClass:"_newinfo-inner"},[_c('h2',{staticClass:"_newinfo-ttl"},[_vm._v(" 理事会からのお知らせ "),_c('div',{staticClass:"-more"},[_c('router-link',{staticClass:"-more-link",attrs:{"to":{
            name: 'bod-information-1',
            params: { mc_code: _vm.mcCode },
          }}},[_c('img',{attrs:{"src":"/i-salon/assets/img/main/ic-more.png","alt":"MORE"}})])],1)]),(_vm.news.length > 0)?_c('div',{staticClass:"_newinfo-list"},_vm._l((_vm.news),function(item,index){return _c('p',{key:index,staticClass:"_newinfo-item -mq"},[_c('router-link',{staticClass:"-more-link",attrs:{"to":{
            name: 'bod-information-detail',
            params: { mc_code: _vm.mcCode, id: item._id },
          }}},[_vm._v(" "+_vm._s(_vm._f("moment")(item.registration_at,"YYYY.MM.DD"))+" "),_c('span',{staticClass:"tag",class:{
              tag01: item.label == 'tag01',
              tag02: item.label == 'tag02',
              tag03: item.label == 'tag03',
              tag04: item.label == 'tag04',
              tag05: item.label == 'tag05',
              tag06: item.label == 'tag06',
              tag07: item.label == 'tag07',
              tag08: item.label == 'tag08',
            }},[_vm._v(_vm._s(_vm.statusText(item.label)))]),_vm._v(" "+_vm._s(item.title)+" ")])],1)}),0):_c('div',{staticClass:"_newinfo-list"},[_c('p',[_vm._v("現在、理事会からのお知らせはありません。")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }