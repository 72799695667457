import axios from "axios";
import authHeader from "./auth-header";

const BASE_API_URL = `${process.env.VUE_APP_API_ENDPOINT}/api/icraft/`;
// console.log(BASE_API_URL);
class ExtApiService {
  async icraft(obj) {
    const res = await axios.post(BASE_API_URL + `post`, obj, {
      headers: authHeader(),
    });
    return res;
  }
}

export default new ExtApiService();
