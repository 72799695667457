<template>
  <div id="chat-view" class="section message-list messages">
    <!-- <div class="section message-list" id="message-container"> -->
    <div class="oneArea">
      <!-- <div class="onebox">
        <span class="talk-date">{{ today }}</span>
      </div> -->
      <div
        class="message-box"
        style="width:100%"
        v-for="message in fileredMessages"
        :key="message.name"
        :class="{ me: message.user == name }"
        :style="{
          backgroundColor: message.user == 'admin' ? 'aquamarine' : '',
        }"
      >
        <div style="width:100px">{{ message.disp_time }}</div>
        <div style="width:100px">{{ message.user }}</div>
        <div style="flex-grow:1;text-align: left;word-break: break-all;">
          {{ message.text }}
        </div>
        <div
          style="margin-right:20px;display:flex;flex-direction:row;align-items: center;"
        >
          <div style="margin-right:20px">
            <el-button
              @click="$emit('onfilter', message.user)"
              style="width:100px;"
              :disabled="message.user == 'admin' || message.user == 'to'"
              >{{ toName(message) }}</el-button
            >
          </div>
          <el-switch
            v-model="message.is_visible"
            active-text="公開"
            inactive-text="非公開"
            @change="onSurrogate(message)"
          >
          </el-switch>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import io from "socket.io-client";
import moment from "moment";

// const ENDPOINT = "http://ec2-44-232-167-73.us-west-2.compute.amazonaws.com:5000/";

const ENDPOINT = process.env.VUE_APP_CHAT_SERVER;
let socket;

// const name = "user2";
// const room = "room1";
// const disp_name = "user2";

export default {
  name: "messages",
  props: ["room", "name", "dispName", "mode", "roomName", "filter", "openRoom"],
  computed: {
    today: () => moment().format("YYYY-MM-DD"),
    fileredMessages: function() {
      if (this.filter == "") {
        return this.messages;
      } else {
        return this.messages.filter(
          item => item.user == this.filter || item.to == this.filter,
        );
      }
    },
  },
  data: function() {
    return {
      messages: [],
    };
  },
  mounted: function() {
    // // console.log("+++ Messages");

    socket = io(ENDPOINT);
    // // console.log(this.$route.query);

    let self = this;
    this.$nextTick(function() {
      let room = self.room;
      let name = self.name;
      let roomName = self.roomName;
      // console.log(room);
      let disp_name = self.dispName;
      socket.emit("join", { name, room, disp_name, roomName }, error => {
        if (error) {
          alert(error);
        } else {
          // console.log("ok");
        }
      });
      socket.on("message", message => {
        // setMessages((messages) => [...messages, message]);
        // console.log("message");
        // console.log(message);
        // if (
        //   this.filter != '' &&
        //   message.user != this.filter &&
        //   message.to != this.filter
        // ) {
        //   return;
        // }
        var element = document.getElementById("chat-view");
        this.messages.push(message);
        const is_bottom =
          element.scrollHeight - element.scrollTop === element.clientHeight;
        this.$nextTick(function() {
          if (is_bottom) {
            scrollTo(element, 500);
          }
        });
      });
      // eslint-disable-next-line
      socket.on("roomData", ({ users }) => {
        // setUsers(users);
        // console.log(users);
      });
      // const self = this;
      socket.on("private", function(data) {
        // console.log("+++ private1");
        // console.log(data);
        self.$emit("allowmessage", data.msg);
      });
    });
  },
  beforeDestroy: function() {
    socket.disconnect();
  },
  methods: {
    toName(message) {
      return message.user != "admin" ? message.user : message.to;
    },
    onSurrogate: function(val) {
      // console.log("onSurrogate");
      // console.log(val);
      // console.log(this.messages.indexOf(val));
      this.$set(this.messages, this.messages.indexOf(val), val);
      if (val.is_visible) {
        // console.log("表示");
        this.onConfirm(val, true);
      } else {
        // console.log("削除");
        var messageObj = {
          _id: val._id,
          src_id: val.src_id,
          room: this.openRoom,
          is_visible: false,
        };
        // console.log(messageObj);
        socket.emit("removeMessage", messageObj, () => {
          // console.log("sended");
        });
      }
    },
    // eslint-disable-next-line
    onConfirm: function(message, no_safe) {
      // console.log("onConfirm");
      // console.log(message);
      message.is_visible = true;
      const messageObj = {
        room: this.openRoom,
        name: "admin",
        text: message.text,
        disp_name: "admin",
        no_safe: no_safe,
        to: message.user,
        _id: message._id,
      };
      // console.log(messageObj);
      socket.emit("sendMessageSurrogate", messageObj, () => {
        // console.log("sended");
      });
    },
    onPrivate: function(msg) {
      // console.log("onPrivate");
      socket.emit("private", { msg: msg, to: "user1" });
    },
  },
};

function scrollTo(element, duration) {
  if (!element) {
    return;
  }
  var target = element.scrollHeight;
  target = Math.round(target);
  duration = Math.round(duration);
  if (duration < 0) {
    return false;
  }
  if (duration === 0) {
    element.scrollTop = target;
    return true;
  }

  var start_time = Date.now();
  var end_time = start_time + duration;

  var start_top = element.scrollTop;
  var distance = target - start_top;

  // based on http://en.wikipedia.org/wiki/Smoothstep
  var smooth_step = function(start, end, point) {
    if (point <= start) {
      return 0;
    }
    if (point >= end) {
      return 1;
    }
    var x = (point - start) / (end - start); // interpolation
    return x * x * (3 - 2 * x);
  };

  // This is to keep track of where the element's scrollTop is
  // supposed to be, based on what we're doing
  var previous_top = element.scrollTop;

  // This is like a think function from a game loop
  var scroll_frame = function() {
    if (element.scrollTop !== previous_top) {
      return false;
    }

    // set the scrollTop for this frame
    var now = Date.now();
    var point = smooth_step(start_time, end_time, now);
    var frameTop = Math.round(start_top + distance * point);
    element.scrollTop = frameTop;

    // check if we're done!
    if (now >= end_time) {
      return true;
    }

    // If we were supposed to scroll but didn't, then we
    // probably hit the limit, so consider it done; not
    // interrupted.
    if (element.scrollTop === previous_top && element.scrollTop !== frameTop) {
      return true;
    }
    previous_top = element.scrollTop;

    // schedule next frame for execution
    setTimeout(scroll_frame, 0);
  };
  // boostrap the animation process
  setTimeout(scroll_frame, 0);
}
</script>

<style>
#chat-view {
  background-color: #e7edfd;
  overflow: auto;
  max-width: 100vw;
}

.message-list.messages {
}

.message-box {
  display: flex;
  margin: 4px 0;
}
.message-box div {
  padding: 4px 10px;
  font-size: 1.6rem;
}
.message-box div:last-child {
  white-space: nowrap;
}
.message-box div:first-child,
.message-box div:nth-child(2) {
  min-width: 100px;
}

.message-box.me {
  background-color: #d8e2fa !important;
}
</style>
