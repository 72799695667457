console.log(`mode=${process.env.VUE_APP_MODE}`);
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
// import "fetch-polyfill";

import VueLodash from "vue-lodash";
import lodash from "lodash";

import VueMoment from "vue-moment";
import moment from "moment";
import utils from "./mixin/utils";

import axios from "axios";
import { Service } from "axios-middleware";
import "./registerServiceWorker";

import firebase from "firebase";

import VueLocalStorage from "vue-local-storage";
import Vuelidate from "vuelidate";
import VueFormulate from "@braid/vue-formulate";
import { ja } from "@braid/vue-formulate-i18n";
import "./assets/snow.css";
// import "./assets/ckeditor.css";

import VueUploadComponent from "vue-upload-component";

import DefaultLayout from "./layouts/DefalutLayout.vue";
import AdminLayout from "./layouts/AdminLayout.vue";
import FreeLayout from "./layouts/FreeLayout.vue";
import FullLayout from "./layouts/FullLayout.vue";

import VCalendar from "v-calendar";

import VueCookies from "vue-cookies";

// import VJsoneditor from "v-jsoneditor";
// console.log(process.env);
var firebaseConfig = {
  apiKey: `${process.env.VUE_APP_FIREBASE_API_KEY}`,
  authDomain: `${process.env.VUE_APP_FIREBASE_AUTH_DOMAIN}`,
  projectId: `${process.env.VUE_APP_FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.VUE_APP_FIREBASE_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID}`,
  appId: `${process.env.VUE_APP_FIREBASE_APP_ID}`,
  measurementId: `${process.env.VUE_APP_FIREBASE_MEASURMENT_ID}`,
};

// console.log(firebaseConfig);

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

let messaging = null;
console.log("firebase.messaging.isSupported", firebase.messaging.isSupported());
if (firebase.messaging.isSupported()) {
  console.log("++B");
  messaging = firebase.messaging();
  // not fire
  // eslint-disable-next-line
  self.addEventListener("notificationclick", function(event) {
    // log send to server
  });

  // fired
  // eslint-disable-next-line
  self.addEventListener("notificationclose", function(event) {
    // log send to server
  });
  messaging
    .getToken({
      vapidKey: `${process.env.VUE_APP_FIREBASE_VAPID_KEY}`,
    })
    .then(currentToken => {
      if (currentToken) {
        console.log(`currentToken=${currentToken}`);
        // sendTokenToServer(currentToken);
        // updateUIForPushEnabled(currentToken);
      } else {
        // Show permission request.
        console.log(
          "No registration token available. Request permission to generate one.",
        );
        // Show permission UI.
        // updateUIForPushPermissionRequired();
        // setTokenSentToServer(false);
      }
    })
    .catch(err => {
      console.log("An error occurred while retrieving token. ", err);
      // showToken("Error retrieving registration token. ", err);
      // setTokenSentToServer(false);
    });
}
// console.log("++C");

// axios.interceptors.response.use(
//   response => {
//     return Promise.resolve({
//       data: response.data,
//     });
//   },
//   error => {
//     return Promise.resolve({
//       error: error.response,
//     });
//   },
// );

const service = new Service(axios);

service.register({
  onRequest(config) {
    //
    return config;
  },
  onSync(promise) {
    //
    return promise;
  },
  onResponse(response) {
    //
    return response;
  },
  onResponseError(error) {
    console.log("onResponseError");
    console.log(error.response.status);
    if (error.response.status == "401") {
      window.location = process.env.VUE_APP_ISALON_LOGOUT_ENDPOINT;
    }
    throw error;
    // return error;
  },
});

Vue.config.productionTip = false;

moment.locale("ja");
Vue.use(VueMoment, {
  moment,
});

Vue.use(VueLocalStorage);
Vue.use(Vuelidate);

Vue.use(VueLodash, { name: "custom", lodash: lodash });

// const uploadApi = `${process.env.VUE_APP_API_ENDPOINT}/api/file/upload`;
// const axiosInstance = axios.create({
//   baseURL: process.env.VUE_APP_API_ENDPOINT,
// });
Vue.use(VueFormulate, {
  plugins: [ja],
  locale: "ja",
  // uploader: async function(file, progress, error, options) {
  //   try {
  //     const formData = new FormData();
  //     formData.append("file", file);
  //     const result = await fetch(options.uploadUrl, {
  //       method: "POST",
  //       body: formData,
  //     });
  //     progress(100); // (native fetch doesn’t support progress updates)
  //     return await result.json();
  //   } catch (err) {
  //     error("Unable to upload file");
  //   }
  // },
  // uploader: axiosInstance,
  // uploadUrl: "/api/file/attach",
});
Vue.mixin(utils);

Vue.component("default-layout", DefaultLayout);
Vue.component("admin-layout", AdminLayout);
Vue.component("free-layout", FreeLayout);
Vue.component("full-layout", FullLayout);

Vue.component("file-upload", VueUploadComponent);

Vue.use(VCalendar, {
  componentPrefix: "vc", // Use <vc-calendar /> instead of <v-calendar />
});

// default options config: { expires: '1d', path: '/', domain: '', secure: '', sameSite: 'Lax' }
const cookieOption = {
  expires: process.env.VUE_APP_COOKIE_EXPIRES,
  path: "/",
  domain: process.env.VUE_APP_COOKIE_DOMAIN,
  secure: true,
  sameSite: process.env.VUE_APP_COOKIE_SAMESITE,
};
console.log("cookieOption", cookieOption);
Vue.use(VueCookies, cookieOption);

// Vue.use(VJsoneditor);

router.beforeEach((to, from, next) => {
  // if (!to.currentUser) next({ name: "login" });
  // else next();

  const publicList = [
    "login",
    "sso",
    "password-reminder",
    "password-reset",
    "verify-email",
    "user-registration",
    "contact",
    "notfound404",
    "isalon_top",
    "signup",
  ];
  //
  if (to.params.mc_code) {
    store.dispatch("mansion/reset", to.params.mc_code);
  }
  if (to.name.toLowerCase() == "signup") {
    next();
  } else if (
    !store.state.auth.status.loggedIn &&
    !publicList.includes(to.name)
  ) {
    next({ name: "login" });
  } else {
    next();
  }
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  // ...

  const currentUser = store.state.auth.user;

  let page_path = "";
  if (currentUser) {
    const mansion = currentUser.mansion;

    page_path = `/${mansion.dai_bunrui}/${mansion.chu_bunrui}/${mansion.iuc_code}/${mansion.ipd_code}/${currentUser.status}${to.fullPath}`;
    Vue.$gtag.config({ user_id: currentUser.loginID });
  } else {
    page_path = to.fullPath;
  }
  Vue.$gtag.pageview({
    page_path,
  });
});

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
