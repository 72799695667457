import axios from "axios";
import { encryptStorage } from "../libs/encrypt-storage";

const API_URL = `${process.env.VUE_APP_API_ENDPOINT}/api/auth/`;
// console.log(API_URL);

class AuthService {
  async login(user) {
    try {
      const response = await axios.post(API_URL + "signin", {
        id: user.id,
        password: user.password,
      });
      console.log("++login");
      if (!(response instanceof Error)) {
        if (response.data.accessToken) {
          encryptStorage.setItem("user", response.data);

          console.log(response);
          return response.data;
        } else {
          return null;
        }
      } else {
        throw response;
      }
    } catch (error) {
      console.log("++++error");
      console.log(error);
      // alert(error);
      console.log("ERROR!! occurred in Backend.!");
      throw error;
    }
  }

  logout() {
    encryptStorage.removeItem("user");
  }

  register(user) {
    // console.log("register");
    // console.log(user);
    return axios.post(API_URL + "signup", {
      id: user.id,
      username: user.username,
      email: user.email,
      password: user.password,
      mansion: user.mansion,
      roomNo: user.roomNo,
      role: user.role,
    });
  }
  // {PORTAL: "wckUO6PWYBnW6sTV", ID: "gaizumidemo", GMT: "20201225030248", SHA: "f18fe6f55e1a6ef95de1f85516d4339cfb661fe826ffc0bd95f938db267710c6", TENANTID: "0322"}GMT: "20201225030248"ID: "gaizumidemo"PORTAL: "wckUO6PWYBnW6sTV"SHA: "f18fe6f55e1a6ef95de1f85516d4339cfb661fe826ffc0bd95f938db267710c6"TENANTID: "0322"__proto__: Object
  async sso(query) {
    console.log("service sso");
    try {
      const result = await axios.post(
        API_URL + `sso?timestamp=${new Date().getTime()}`,
        query,
      );
      console.log("service sso 2");
      console.log(result);
      if (result.data.accessToken) {
        encryptStorage.setItem("user", result.data);
      }
      return result;
    } catch (error) {
      // alert(error);
      console.log("ERROR!! occurred in Backend.");
    }
    // return await axios.post(API_URL + "sso", query);
  }
}

export default new AuthService();
