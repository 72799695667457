<template>
  <div class="mainContentBox">
    <div class="mainContentBoxInner" v-if="status == 'edit'">
      <div class="pageTitleWrapper contact">
        <h2 class="pageTitle">お問い合わせ</h2>
      </div>

      <div class="pageCaption contactCaption">
        <div
          class="contactCaptionDetail"
          style="border: 1px solid #f33; color: #f33; padding: 10px;text-align:center;"
        >
          本Webサイトに関するお問い合わせフォームとなります。<br />
          管理業務、その他に関する問い合わせ先は下記アイフロント24までお電話ください。
        </div>
        <br />
        <div class="contactCaptionDetail">
          ■管理業務、お部屋内の設備・共用部分の不具合、その他全般に関するお問い合わせ
          <div>
            <div
              class="blocktitle"
              style="
            font-size: 110%;
            margin: 10px 0 0 0;
            border: none;
            "
            >
              管理会社コールセンター（24時間365日対応）
            </div>
            <span
              class="telnumbn"
              style="
            display: block;
            margin: 0 0 10px 0;
            width: 260px;
            "
              ><a href="tel:0120987550"
                ><img
                  src="https://www.mansion-concierge.com/i-salon/assets/img/common/bnr_i_front.gif"
                  alt="アイフロント２４"
                /><span>電話をかける</span></a
              ></span
            >
          </div>
          ※回答までにお時間をいただく場合がございます。<br />
          ※お問い合わせ内容によっては受付のみとなりますので予めご了承ください。<br />
          その場合は担当部署のご案内、もしくは担当部署より翌営業日以降にご連絡をさせていただきます。<br />
          <br />
          <br />
          別途［Q&amp;A］をご用意しております。ぜひご覧ください。<br />
          <a
            href="https://www.mansion-concierge.com/iuc-salon/qa.php"
            target="_blank"
            style="color: rgb(0, 5, 98); text-decoration: underline;"
            >→Q&amp;A</a
          ><br />
          <br />
          個人情報の取扱いに関しては、下記
          <span>プライバシーポリシーをご参照ください。</span><br />
          <a href="http://www.itc-uc.co.jp/privacy/" target="_blank"
            >・伊藤忠アーバンコミュニティ株式会社</a
          ><br />
        </div>
      </div>

      <section class="mainContent-form">
        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="">マンション名</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                <label id="">{{ mansion.name }}</label>
              </div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="">部屋番号</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                <label id="">{{ currentUser.roomNo }}</label>
              </div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <span class="icoForm-requir">必須</span>
            <label for="L_NAME">お名前</label>
          </dt>
          <dd class="formTable_data">
            <dl class="formTableBox">
              <dt class="formTableBox_ttl"><label for="L_NAME">姓</label></dt>
              <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                <input
                  type="text"
                  id="L_NAME"
                  maxlength="40"
                  class="m-formBox_input--name u-ime-ja"
                  placeholder="例:伊藤"
                  name="lastName"
                  v-model.trim="$v.values.sei.$model"
                />
                <div class="error" v-if="!$v.values.sei.required">必須です</div>
              </dd>
              <dt class="formTableBox_ttl"><label for="F_NAME">名</label></dt>
              <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                <input
                  type="text"
                  id="F_NAME"
                  maxlength="40"
                  class="formTableBox_input--name u-ime-ja"
                  placeholder="例:忠太郎"
                  name="firstName"
                  v-model.trim="$v.values.mei.$model"
                />
                <div class="error" v-if="!$v.values.mei.required">必須です</div>
              </dd>
            </dl>

            <span class="formErr_inline"> </span>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <span class="icoForm-requir">必須</span>
            <label for="L_NAME">ふりがな</label>
          </dt>
          <dd class="formTable_data">
            <dl class="formTableBox">
              <dt class="formTableBox_ttl"><label for="L_NAME">せい</label></dt>
              <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                <input
                  type="text"
                  id="L_NAME"
                  maxlength="40"
                  class="m-formBox_input--name u-ime-ja"
                  placeholder="例:いとう"
                  name="lastName"
                  v-model.trim="$v.values.kanaSei.$model"
                />
                <div class="error" v-if="!$v.values.kanaSei.required">
                  必須です
                </div>
              </dd>
              <dt class="formTableBox_ttl"><label for="F_NAME">めい</label></dt>
              <dd class="formTableBox_itemBox formTableBox_itemBox_name">
                <input
                  type="text"
                  id="F_NAME"
                  maxlength="40"
                  class="formTableBox_input--name u-ime-ja"
                  placeholder="例:ちゅうたろう"
                  name="firstName"
                  v-model.trim="$v.values.kanaMei.$model"
                />
                <div class="error" v-if="!$v.values.kanaMei.required">
                  必須です
                </div>
              </dd>
            </dl>

            <span class="formErr_inline"> </span>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <span class="icoForm-requir">必須</span>
            <label for="">メールアドレス</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                <label id="">{{ values.email }}</label>
                <label id=""
                  ><input
                    type="hidden"
                    id=""
                    class="u-input--w100p u-ime-en"
                    maxlength="128"
                    name=""
                    v-model.trim="$v.values.email.$model"
                /></label>
                <div class="error" v-if="!$v.values.email.required">
                  必須です
                </div>
                <div class="error" v-if="!$v.values.email.email">
                  Emailアドレスを入力してください
                </div>
              </div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="">電話番号</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                <label id=""
                  ><input
                    type="text"
                    id=""
                    class="u-input--w100p u-ime-en"
                    maxlength="128"
                    name=""
                    v-model.trim="$v.values.phone.$model"
                /></label>
                <div class="error" v-if="!$v.values.phone.decimal">
                  半角数字を入力してください
                </div>
                <div class="formTableCautionInner">
                  ※ハイフン（-）なしで数字のみご入力ください。
                </div>
              </div>
            </div>
          </dd>
        </dl>

        <dl class="formTable" style="display:none;">
          <dt class="formTable_ttl">
            <label for="">書類送付先等住所</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                <label id=""
                  ><input
                    type="text"
                    id=""
                    class="u-input--w100p u-ime-ja"
                    maxlength="512"
                    name=""
                    v-model.trim="$v.values.address.$model"
                /></label>

                <div class="formTableCautionInner">
                  ※郵送物ご依頼等のお問い合わせの場合は、こちらに送付先のご住所を入力してください。（郵便番号の記載もお願いいたします）
                </div>
              </div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <span class="icoForm-requir">必須</span>
            <label for="">お問い合わせ種別</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                <select
                  class="select-box"
                  v-model.trim="$v.values.category.$model"
                >
                  <option value="" selected="">選択してください</option>
                  <option
                    value="ご契約及びご入居について"
                    v-if="currentUser.status == 'contractor'"
                  >
                    ご契約及びご入居について
                  </option>
                  <option
                    value="セレクトメニューについて"
                    v-if="currentUser.status == 'contractor'"
                  >
                    セレクトメニューについて
                  </option>
                  <option
                    value="オプションについて"
                    v-if="currentUser.status == 'contractor'"
                  >
                    オプションについて
                  </option>
                  <option
                    value="本ウェブサイト全般について"
                    v-if="currentUser.status != 'contractor'"
                  >
                    本ウェブサイト全般について
                  </option>
                  <option value="伊藤忠ファミリーフェアについて">
                    伊藤忠ファミリーフェアについて
                  </option>
                </select>
                <div class="error" v-if="!$v.values.category.required">
                  必須です
                </div>
              </div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <span class="icoForm-requir">必須</span>
            <label for="">お問い合わせ内容</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                <textarea
                  rows="8"
                  placeholder="※本ウェブサイトに関するお問い合わせフォームとなります。
管理に関する問い合わせ先は窓口が異なりますので、アイフロント24（0120-987-550）までお問い合わせください。"
                  class="u-input--w100p u-ime-ja"
                  v-model.trim="$v.values.text.$model"
                ></textarea>

                <div class="error" v-if="!$v.values.text.required">
                  必須です
                </div>
              </div>
            </div>
          </dd>
        </dl>

        <div class="cn-back-btn">
          <a href="javascript:void(0);" @click="onConfirm">次へ</a>
        </div>
      </section>
    </div>

    <div class="mainContentBoxInner" v-if="status == 'confirm'">
      <div class="pageTitleWrapper contact">
        <h2 class="pageTitle">お問い合わせ</h2>
      </div>

      <div class="pageCaption">
        <div class="contactCaptionDetail">
          入力内容をご確認ください。<br />
          よろしければ「送信」ボタンをクリックしてください。
        </div>
      </div>

      <section class="mainContent-form">
        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="">マンション名</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                <label id="">{{ mansion.name }}</label>
              </div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="">部屋番号</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">{{ currentUser.roomNo }}</div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="L_NAME">お名前</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                {{ values.sei }} {{ values.mei }}
              </div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="L_NAME">ふりがな</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                {{ values.kanaSei }} {{ values.kanaMei }}
              </div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="">メールアドレス</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">{{ values.email }}</div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="">電話番号</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">{{ values.phone }}</div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="">書類送付先等住所</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                {{ values.address }}
              </div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="">お問い合わせ種別</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">{{ values.category }}</div>
            </div>
          </dd>
        </dl>

        <dl class="formTable">
          <dt class="formTable_ttl">
            <label for="">お問い合わせ内容</label>
          </dt>
          <dd class="formTable_data">
            <div class="formTableBox">
              <div class="formTableBox_itemBox">
                <pre>{{ values.text }}</pre>
              </div>
            </div>
          </dd>
        </dl>
        <div class="cn-back-btn">
          <a href="javascript:void(0);" @click="status = 'edit'">戻る</a>
        </div>
        <div class="cn-back-btn">
          <a href="javascript:void(0);" @click="onPost">送信</a>
        </div>
      </section>
    </div>

    <div class="mainContentBoxInner" v-else-if="status == 'complete'">
      <div class="pageTitleWrapper contact">
        <h2 class="pageTitle">お問い合わせ</h2>
      </div>

      <div class="pageCaption">
        <div class="contactCaptionDetail">
          お問い合わせを送信しました。<br />
          ご要望・ご質問の内容によっては、返信・回答までのお時間を要することがございます。あらかじめご了承ください。
        </div>
      </div>

      <section class="mainContent-form">
        <div class="cn-back-btn">
          <router-link
            :to="{
              name: 'top',
              params: { mc_code: mcCode },
            }"
            >トップページへ</router-link
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { required, email, decimal } from "vuelidate/lib/validators";
import FormService from "../../services/form.service";
// import Navi from "../mixin/navi";
export default {
  name: "contact",
  // mixins: [Navi],
  data() {
    return {
      status: "edit",
      values: {
        sei: "",
        mei: "",
        kanaSei: "",
        kanaMei: "",
        email: "",
        address: "",
        category: "",
        text: "",
        phone: "",
      },
      schema: null,
    };
  },
  validations: {
    values: {
      sei: { required },
      mei: { required },
      kanaSei: { required },
      kanaMei: { required },
      email: { required, email },
      address: {},
      category: { required },
      text: { required },
      phone: { decimal },
    },
  },
  mounted: async function() {
    console.log("+++ contact.mounted");
    // const mc_code = this.$route.params.mc_code
    //   ? this.$route.params.mc_code
    //   : "";
    // console.log(mc_code);
    // const result = await FormService.findOne({
    //   category: "contact",
    //   mansion: mc_code,
    // });
    // console.log(result);
    await this.$nextTick();
    this.values.email = this.currentUser.email;
  },
  methods: {
    scrollTop: function() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    onConfirm() {
      console.log("onConfirm");
      console.log(this.values);
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        this.status = "confirm";
        this.scrollTop();
      }
    },
    async onPost() {
      console.log("onConfirm");
      console.log(this.values);
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log("ERROR");
      } else {
        this.status = "complete";
        // (category, sei, mei, mansionName,mansion, roomNo, result)
        const result = await FormService.private(
          "contact",
          this.values.sei,
          this.values.mei,
          this.mansion.name,
          this.mansion._id,
          this.currentUser.roomNo,
          this.values,
          false,
        );
        this.scrollTop();
        console.log(result);
      }
    },
  },
};
</script>

<style>
/* @import "/i-salon/css/isalon/contact_layout.css"; */
.flex-wrapper {
  display: flex;
}
</style>
