<template>
  <el-main>
    <h3 class="section-title green-left-head title-margin">申し込み管理</h3>
    <p>
      <el-button type="primary" @click="onDownload()">ダウンロード</el-button>
    </p>
    <el-table :data="result.data.docs" stripe style="width: 100%">
      <el-table-column
        prop="status"
        label="ステータス"
        width="140"
        :render-header="renderHeader"
      >
      </el-table-column>
      <el-table-column
        prop="roomNo"
        label="部屋番号"
        width="140"
        :render-header="renderHeader"
      ></el-table-column>
      <el-table-column
        prop="sei"
        label="姓"
        width="100"
        :render-header="renderHeader"
      ></el-table-column>
      <el-table-column
        prop="mei"
        label="名"
        width="100"
        :render-header="renderHeader"
      ></el-table-column>
      <el-table-column
        prop="orderName"
        label="フォーム名称"
        :render-header="renderHeader"
      >
        <!-- <template slot-scope="scope">
          <span>
            {{ scope.row.orderSchema.name }}
          </span>
        </template> -->
      </el-table-column>
      <el-table-column
        prop="orderDirectory"
        label="フォーム名"
        width="200"
        :render-header="renderHeader"
      >
        <!-- <template slot-scope="scope">
          <span>
            {{ scope.row.orderSchema.directory }}
          </span>
        </template> -->
      </el-table-column>
      <el-table-column
        prop="createdAt"
        label="作成日"
        width="200"
        :render-header="renderHeader"
        ><template slot-scope="scope">
          <span>{{
            scope.row.createdAt | moment("YYYY/MM/DD hh:mm:ss")
          }}</span></template
        ></el-table-column
      >
      <el-table-column
        prop="updatedAt"
        label="最終更新日"
        width="200"
        :render-header="renderHeader"
        ><template slot-scope="scope">
          <span>{{
            scope.row.updatedAt | moment("YYYY/MM/DD hh:mm:ss")
          }}</span></template
        ></el-table-column
      >
      <!-- <el-table-column prop="start_at" label="開始"> </el-table-column>
      <el-table-column prop="end_at" label="終了"> </el-table-column> -->
      <el-table-column fixed="right" label="操作" fit="true" width="120">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="onEdit(scope.row._id)"
            >編集</el-button
          >
          <!-- <el-button type="text" size="small" @click="onRmove(scope.row._id)"
            >削除</el-button
          > -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :total="result.data.totalDocs"
      :page-size="result.data.limit"
      @current-change="onCurrentChange"
    >
    </el-pagination>
  </el-main>
</template>

<script>
import moment from "moment";
import store from "../../../store";
import OrderService from "../../../services/order.service";
export default {
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (currentUser && !currentUser.permissions.includes("Admin")) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  data() {
    return {
      result: { data: [] },
    };
  },
  computed: {
    sortCaretAsc: function() {
      return function(prop) {
        if (this.sortProp === prop && this.sortOrder === "asc") {
          return "sort-caret ascending active";
        }
        return "sort-caret ascending";
      };
    },
    sortCaretDesc: function() {
      return function(prop) {
        if (this.sortProp === prop && this.sortOrder === "desc") {
          return "sort-caret descending active";
        }
        return "sort-caret descending";
      };
    },
  },
  async mounted() {
    await this.$nextTick();
    const order = { updatedAt: -1 };
    this.result = await OrderService.list({
      page: 1,
      order,
      mansion: this.$route.params.mc_code,
    });
    // this.result = await this.find({
    //   page: 1,
    //   order,
    //   mansion: this.$route.params.mc_code,
    // });
    console.log(this.result.data);
  },
  methods: {
    async sortChange(prop, direction) {
      console.log("sortChange");
      // { field: 'asc', test: -1 }
      const order = {};
      order[prop] = direction;
      console.log(`${prop}/${direction}`);
      await this.find({ page: 1, order, mansion: this.$route.params.mc_code });
    },
    renderHeader(h, e) {
      console.log("renderHeader");
      return h("div", null, [
        h("span", { class: "caret-wrapper" }, [
          h(
            "i",
            {
              class: this.sortCaretAsc(e.column.property),
              on: {
                click: () => this.sortChange(e.column.property, 1),
              },
            },
            null,
          ),
          h(
            "i",
            {
              class: this.sortCaretDesc(e.column.property),
              on: {
                click: () => this.sortChange(e.column.property, -1),
              },
            },
            null,
          ),
        ]),
        e.column.label,
      ]);
    },
    async find(query) {
      console.log("+++++ find");
      console.log(query);
      this.result = await OrderService.list(query);
      // this.result = res.data;
      console.log(this.result);
    },
    async onCurrentChange(page) {
      // console.log(page);
      this.result = await OrderService.list({
        mansion: this.$route.params.mc_code,
        page: page,
      });
      console.log(this.result);
    },
    onEdit(id) {
      console.log(id);
      this.$router.push({
        name: "admin_order_edit",
        params: { id: id, mc_code: this.currentUser.mansion._id },
      });
    },
    async onDownload() {
      const blob = await OrderService.download(this.currentUser.mansion._id);
      // console.log("++++ aaaa");
      // console.log(blob.type);

      // aタグの生成
      var a = document.createElement("a");
      // レスポンスからBlobオブジェクト＆URLの生成
      var blobUrl = window.URL.createObjectURL(new Blob([blob.data]));
      document.body.appendChild(a);
      a.style = "display: none";
      // 生成したURLをセット
      a.href = blobUrl;
      // ダウンロードの時にファイル名として表示される
      a.download = `order_${this.currentUser.mansion.name}_${moment().format(
        "YYYYMMDD_hhmmss",
      )}.json`;
      // クリックイベント発火
      // a.click();
      var event = new MouseEvent("click");
      a.dispatchEvent(event);
    },
  },
};
</script>

<style></style>
