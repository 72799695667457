import axios from "axios";
// import qs from "qs";
import authHeader from "./auth-header";

const END_POINT = `${process.env.VUE_APP_API_ENDPOINT}`;

class PreviewService {
  async find(query) {
    console.log("+++ Order.list");
    console.log(query);
    const result = await axios.get(END_POINT + `/api/preview`, {
      params: query,
      headers: authHeader(),
    });
    return result;
  }

  async findWithReserve(previewid) {
    const result = await axios.get(
      END_POINT + `/api/preview/with-reserve/${previewid}`,
      {
        headers: authHeader(),
      },
    );
    return result;
  }

  async findByID(id) {
    const result = await axios.get(END_POINT + `/api/preview/${id}`, {
      headers: authHeader(),
    });
    return result;
  }

  async create(params) {
    console.log("+++ find");
    console.log(params);
    const result = await axios.post(END_POINT + `/api/preview`, params, {
      headers: authHeader(),
    });
    return result;
  }

  async update(params) {
    const result = await axios.put(
      END_POINT + `/api/preview/${params._id}`,
      params,
      {
        headers: authHeader(),
      },
    );
    return result;
  }

  async remove(id) {
    const result = await axios.delete(END_POINT + `/api/preview/${id}`, {
      headers: authHeader(),
    });
    return result;
  }
}

export default new PreviewService();
