var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main shoko kumiai"},[_c('section',{staticClass:"main-section"},[(_vm.isPermission('Chairman') || _vm.isPermission('BODArchiveAdmin'))?_c('p',{staticClass:"btn-item"},[_c('router-link',{attrs:{"to":{
          name: 'admin-bod-archive-edit',
          params: { mc_code: _vm.currentUser.mansion._id, id: 'create' },
        }}},[_c('button',{staticClass:"el-button el-button--primary",attrs:{"type":"button"}},[_c('i',{staticClass:"el-icon-circle-plus"}),_c('span',[_vm._v("新規書類追加")])])])],1):_vm._e(),_c('h3',{staticClass:"section-title green-left-head"},[_vm._v("管理組合書庫")]),_c('div',{staticClass:"pageCaption"},[_vm._v(" 管理組合様が自由に掲載できるページです。理事長様に掲載の権限がございます。 ")])]),(_vm.isPermission('Chairman') || _vm.isPermission('BODArchiveAdmin'))?_c('section',{staticClass:"main-section buttons cloumBtns"},_vm._l((_vm.categories),function(item,index){return _c('p',{key:index,staticClass:"section-block center",class:("archive" + (item._id))},[_c('router-link',{attrs:{"to":{
          name: 'bod-archive-list',
          params: { category: item._id, mc_code: _vm.currentUser.mansion._id },
        }}},[_c('button',{staticClass:"bt bt-green"},[_vm._v(_vm._s(item.title))])])],1)}),0):_c('section',{staticClass:"main-section buttons cloumBtns"},_vm._l((_vm.norm_categories),function(item,index){return _c('p',{key:index,staticClass:"section-block center",class:("archive" + (item._id))},[_c('router-link',{attrs:{"to":{
          name: 'bod-archive-list',
          params: { category: item._id, mc_code: _vm.currentUser.mansion._id },
        }}},[_c('button',{staticClass:"bt bt-green"},[_vm._v(" "+_vm._s(item.title)+" ")])])],1)}),0),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top-back-btn"},[_c('a',{attrs:{"href":"https://iuc-salon.mansion-concierge.com/"}},[_vm._v("トップページへ")])])}]

export { render, staticRenderFns }