<template>
  <el-main id="bod-edit" class="rijitool">
    <h3 class="section-title green-left-head">
      理事会&nbsp;{{ param == "create" ? "新規作成" : "編集" }}
    </h3>
    <el-col :span="24">
      <el-form ref="form" :model="form" label-width="25%">
        <el-form-item label="理事会メンバー">
          <el-tooltip
            content="初回登録時の理事会メンバーになります。後から変更はできません。"
            placement="top"
          >
            <el-input type="textarea" :rows="2" :value="memberRooms"></el-input>
          </el-tooltip>
        </el-form-item>
        <el-form-item v-if="form.members.length == 0">
          <!-- <el-form-item > -->
          <el-tooltip content="互換性のために用意されています" placement="top">
            <el-button type="danger" @click="onResetMember"
              >リセットメンバー</el-button
            >
          </el-tooltip>
        </el-form-item>
        <el-form-item label="調整">
          <el-tooltip content="登録後に使用できます" placement="top">
            <el-switch
              v-model="form.isOfficial"
              active-color="#13ce66"
              inactive-color="#ff4949"
              active-text="調整済み"
              inactive-text="調整中"
              @change="onChangeOffcial()"
              :disabled="isFirst"
            >
            </el-switch>
          </el-tooltip>
        </el-form-item>
        <el-form-item label="理事会タイトル">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="説明">
          <el-input v-model="form.body" type="textarea" :rows="2"></el-input>
        </el-form-item>
        <el-form-item label="開催日">
          <el-col :span="15">
            <el-date-picker
              v-model="form.date"
              type="date"
              placeholder="日付選択"
              style="width: 100%;"
            >
            </el-date-picker>
          </el-col>
        </el-form-item>
        <el-form-item label="開催時間">
          <el-col :span="11">
            <el-time-select
              v-model="form.range.start"
              :picker-options="{
                start: '00:00',
                step: '00:15',
                end: '23:45',
              }"
              placeholder="開始時間"
              style="width: 100%;"
            >
            </el-time-select
          ></el-col>
          <el-col :span="1" class="line">〜</el-col>
          <el-col :span="11">
            <el-time-select
              v-model="form.range.end"
              :picker-options="{
                start: '00:00',
                step: '00:15',
                end: '23:45',
              }"
              placeholder="終了時間"
              style="width: 100%;"
            >
            </el-time-select>
          </el-col>
        </el-form-item>

        <div>
          <div v-for="(item, index) in form.schedules" :key="index">
            <el-form-item :label="`開催日候補${index + 1}`">
              <el-col :span="15">
                <el-switch
                  v-model="item.isUse"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="onChangeUse(item)"
                  active-text="決定"
                  inactive-text="未使用"
                >
                </el-switch>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-col :span="15">
                <el-date-picker
                  v-model="item.date"
                  type="date"
                  placeholder="日付選択"
                  style="width: 100%;"
                >
                </el-date-picker>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-col :span="11">
                <el-time-select
                  v-model="item.range.start"
                  :picker-options="{
                    start: '00:00',
                    step: '00:15',
                    end: '23:45',
                  }"
                  placeholder="開始時間"
                  style="width: 100%;"
                >
                </el-time-select
              ></el-col>
              <el-col :span="1" class="line">〜</el-col>
              <el-col :span="11">
                <el-time-select
                  v-model="item.range.end"
                  :picker-options="{
                    start: '00:00',
                    step: '00:15',
                    end: '23:45',
                  }"
                  placeholder="終了時間"
                  style="width: 100%;"
                >
                </el-time-select>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-col>
                現在の回答数：
                <span>○={{ item.results[2].length }}</span>
                :
                <span>△={{ item.results[1].length }}</span>
                :
                <span>×={{ item.results[0].length }}</span>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-col :span="10">
                <el-button
                  type="warning"
                  @click="onDeleteSchedule(index)"
                  :disabled="form.schedules.length < 2"
                  >削除</el-button
                >
              </el-col>
            </el-form-item>
          </div>
        </div>
        <el-form-item>
          <el-button type="success" @click="onAddSchedule"
            >候補日追加</el-button
          >
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item label="Web会議タイトル">
          <el-input v-model="form.remote.title"></el-input>
        </el-form-item>
        <el-form-item label="Web会議URL">
          <el-input v-model="form.remote.url"></el-input>
        </el-form-item>

        <el-divider></el-divider>
        <div v-for="(item, index) in form.agendas" :key="index">
          <el-form-item :label="`議案タイトル${index + 1}`">
            <el-input v-model="item.title"></el-input>
          </el-form-item>
          <el-form-item :label="`議案内容${index + 1}`">
            <el-input v-model="item.body" type="textarea" :rows="2"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="warning"
              @click="onDeleteAgnda(index)"
              :disabled="form.agendas.length < 2"
              >削除</el-button
            >
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="success" @click="onAddAgenda">議案追加</el-button>
        </el-form-item>
        <el-form-item label="議事録ファイル">
          <el-upload
            class="upload-demo"
            :action="uploadApi"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :before-remove="beforeRemove"
            :on-exceed="handleExceed"
            :file-list="form.files"
            :on-success="onSuccess"
            :headers="header"
            :multiple="false"
            :data="{ id: $route.params.id, category: 'bod' }"
            accept=".pdf"
          >
            <el-button size="small" type="primary">ファイル選択</el-button>
            <!-- <div slot="tip" class="el-upload__tip">
              jpg/png files with a size less than 500kb
            </div> -->
          </el-upload>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">登録</el-button>
          <el-tooltip content="登録後に使用できます" placement="top">
            <el-button type="primary" @click="onVoteReset" :disabled="isFirst"
              >投票リセット</el-button
            >
          </el-tooltip>
          <!-- <el-button type="primary" @click="onTest">テスト</el-button> -->
          <el-button type="danger" @click="onDelete" :disabled="isFirst"
            >削除</el-button
          >
          <el-button
            @click="
              $router.push({
                name: 'bod',
                params: { mc_code: currentUser.mansion._id },
              })
            "
            >戻る</el-button
          >
        </el-form-item>

        <el-divider></el-divider>
        <el-form-item v-for="(vote, index) in votes" :key="index + 999">
          <div>
            {{
              vote.agendaIndex == "-1" ? "出欠" : `議題${vote.agendaIndex + 1}`
            }}
            <span v-for="(item, aindex) in vote.tag" :key="aindex + 9999">
              {{ item }}:{{ vote.result[aindex].length }}
            </span>
          </div>
        </el-form-item>
      </el-form>
    </el-col>
  </el-main>
</template>

<script>
import BoDService from "../../../services/bod.service";
import VoteService from "../../../services/vote.service";
import authHeader from "../../../services/auth-header";
import UserService from "../../../services/user.service";

import store from "../../../store";
export default {
  name: "bod-edit",
  beforeRouteEnter(to, from, next) {
    const currentUser = store.state.auth.user;
    console.log(currentUser);
    if (currentUser && !currentUser.permissions.includes("Chairman")) {
      console.log(":401");
      next({
        name: "notfound404",
        params: { mc_code: this.currentUser.mansion._id },
      });
    } else {
      next();
    }
  },
  mounted: async function() {
    console.log("++++ mounted1");
    console.log(this.$route.params);
    if (this.$route.params.id != "create") {
      console.log("mounted:create");
      store.dispatch("bod/item", this.$route.params.id).then(
        docs => {
          console.log("+++munted");
          console.log(docs);
          if (docs == null) {
            this.$router.push({
              name: "notfound404",
              params: { mc_code: this.currentUser.mansion._id },
            });
          } else {
            this.form = docs;
            // console.log(this.form);
            this.$store.dispatch("vote/find", { bod: this.form._id });
          }
        },
        // eslint-disable-next-line
        error => {
          // console.log(error);
        },
      );
    } else {
      this.onResetMember();
    }
  },
  data() {
    return {
      uploadApi: `${process.env.VUE_APP_API_ENDPOINT}/api/file/upload`,
      dialogVisible: false,
      // votes: [],
      form: {
        isOfficial: false,
        title: "",
        body: "",
        date: "",
        range: { start: "", end: "" },
        schedules: [
          {
            isUse: false,
            date: "",
            range: { start: "", end: "" },
            results: [[], [], []],
          },
        ],
        agendas: [
          {
            index: 0,
            title: "",
            body: "",
            file: "",
            comment: "",
            result: "",
            resultRow: null,
          },
        ],
        remote: {
          title: "",
          url: "",
        },
        participants: [],
        members: [],
        files: [],
      },
    };
  },
  computed: {
    memberRooms() {
      console.log("!members");
      console.log(this.form.members);
      const strs = [];
      for (let index = 0; index < this.form.members.length; index++) {
        const element = this.form.members[index];
        strs.push(element.roomNo);
      }
      return strs.join(",");
    },
    isFirst: function() {
      return this.$route.params.id == "create";
    },
    header: function() {
      return authHeader();
    },
    param: function() {
      return this.$route.params.id;
    },
    votes: function() {
      // console.log("com votes");
      // console.log(this.$store.getters["vote/values"]);
      return this.$store.getters["vote/values"];
    },
  },
  methods: {
    async onResetMember() {
      const res = await UserService.findByIndex(110, 100);
      console.log("+++load2");
      console.log(res);
      this.form.members = res.data;
    },
    async load() {
      this.$store.dispatch("vote/find", { bod: this.form._id });
    },
    async onTest() {
      // const res = await VoteService.find(this.form._id);
      // // console.log(res);
      this.$store.dispatch("vote/find", { bod: this.form._id });
    },

    async onVoteReset() {
      if (!confirm("投票リセットしますか？")) {
        return;
      }
      const res = await VoteService.reset(this.form._id, this.form.members);
      // console.log(res);
      // this.form.agendas = res.data;

      this.$store.dispatch("vote/setVote", res.data);
    },
    async onDelete() {
      if (!confirm("削除しても良いですか？")) {
        return;
      }
      // console.log(this.form);
      // eslint-disable-next-line
      const res = await BoDService.remove(this.form._id);
      // console.log(res);
      // var res = await BoDService.remove(this.form._id);
      // // console.log(res);
      // const res = await this.$store.dispatch("bod/remove", this.form._id);
      // // console.log(res);
      // console.log("-----");
      this.$router.push({
        name: "bod",
        params: { mc_code: this.currentUser.mansion._id },
      });
    },
    onAddAgenda: function() {
      this.form.agendas.push({
        index: 0,
        title: "",
        body: "",
        file: "",
        comment: "",
        result: "",
        resultRow: null,
      });
    },
    onChangeOffcial: async function() {
      if (this.form.isOfficial) {
        // eslint-disable-next-line
        const res = await VoteService.reset(this.form._id);
        // console.log(res);
      }
    },
    onChangeUse: function(item) {
      // console.log(item);
      // console.log(item.isUse);
      // // console.log(this.form.dates[index].isUse);
      if (item.isUse) {
        this.form.date = item.date;
        this.form.range = {};
        this.form.range.start = item.range.start;
        this.form.range.end = item.range.end;
        this.form.schedules.forEach(element => {
          if (element != item) {
            element.isUse = false;
          }
        });
      }
    },
    onDeleteAgnda: function(index) {
      this.form.agendas.splice(index, 1);
    },
    onDeleteSchedule: function(index) {
      this.form.schedules.splice(index, 1);
    },
    onAddSchedule: function() {
      this.form.schedules.push({
        isUse: false,
        date: "",
        range: { start: "", end: "" },
        results: [[], [], []],
      });
    },
    onSubmit: function() {
      // console.log("onSubmit");
      // console.log(this.form);
      BoDService.update(this.form).then(
        // eslint-disable-next-line
        docs => {
          // console.log(docs);
          this.$router.push({
            name: "bod",
            params: { mc_code: this.currentUser.mansion._id },
          });
          // this.$router.push({ name: "admin_meeting_index" });
        },
        // eslint-disable-next-line
        error => {
          // console.log(error);
          this.$message.error("更新できませんでした");
        },
      );
    },
    // eslint-disable-next-line
    handleRemove(file, fileList) {
      // console.log(file, fileList);
      // console.log("remove");
      // console.log(file);
      // console.log(fileList);
      this.form.files = [];
      // console.log(this.meeting.files);
    },
    // eslint-disable-next-line
    handlePreview(file) {
      // console.log(file);
    },
    // eslint-disable-next-line
    handleExceed(files, fileList) {
      this.$message.warning(
        `The limit is 3, you selected ${
          files.length
        } files this time, add up to ${files.length + fileList.length} totally`,
      );
    },
    // eslint-disable-next-line
    beforeRemove(file, fileList) {
      // eslint-disable-line
      return this.$confirm(`削除します。よろしいですか？`);
    },
    // eslint-disable-next-line
    onSuccess(file, fileList) {
      // console.log("onSuccess");
      // console.log(file);
      // console.log(fileList);
      // console.log(this.meeting.files);
      this.form.files = [];
      this.form.files.push(fileList);
    },
  },
};
</script>

<style>
input[type="text"] {
  width: 100%;
}

.line {
  text-align: center;
}
</style>
