import { encryptStorage } from "../libs/encrypt-storage";
export default function authHeader() {
  // let user = encryptStorage.getItem("user");
  let user = null;
  try {
    user = encryptStorage.getItem("user");
  } catch (error) {
    console.warn(error);
    user = null;
  }
  if (user && user.accessToken) {
    // return { Authorization: 'Bearer ' + user.accessToken }; // for Spring Boot back-end
    return {
      "x-access-token": user.accessToken,
    }; // for Node.js Express back-end
  } else {
    return {};
  }
}
